import React, { useContext, useEffect, useState } from "react";
import { Icon } from "../../../../components/Component";
import { FileManagerContext } from "../FileManagerContext";
import { serviceGetDirectories } from "services/directory";
import { ActiveFileDepartmentContext } from "contexts/activeFileDepartment";

const Move = ({ file, toggle, toggleCreateModal, cookies, departmentId }) => {
  const { contextData, moveFolder } = useContext(FileManagerContext);

  const [data, setData] = contextData;
  const [folderId, setFolderId] = useState("")
  const [selected, setSelected] = useState("");
  const [currentFolder, setCurrentFolder] = useState(null);
  const [listFolder, setListFolder] = useState([]);

  useEffect(() => {
    refreshData();
  }, []);

  const refreshData = async () => {
    const resp = await serviceGetDirectories(cookies.SIDikey,"", departmentId,1);
    const dataFolder = resp.data.data
    const filteredFolder = dataFolder.filter(item=>item.id !==file.directoryId)
    setListFolder(filteredFolder);
   
  };

  useEffect(() => {
    let location = window.location.pathname.split("/");
    let findFolder = location.find((item) => item === "folder");
    if (findFolder) {
      setCurrentFolder(location[location.length - 1]);
    }
  }, []);

  return (
    <React.Fragment>
      <div className="modal-header align-center border-bottom-0">
        <h5 className="modal-title">Move item to...</h5>
        <a
          href="#close"
          onClick={(ev) => {
            ev.preventDefault();
            toggle();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
      </div>
      <div className="modal-body pt-0 mt-n2">
     
        <div className="nk-fmg-listing is-scrollable">
          <div className="nk-files nk-files-view-list is-compact">
            <div className="nk-files-list">
            {listFolder.map((item) => (
              <div
                key={item.id}
                onClick={() => {
                
                  setFolderId(item.id);
              
                }}
                className={`${folderId === item.id ? "active" : ""}`}
              >
                <div className="nk-fmg-menu-item">
                  <Icon name="folder"></Icon>
                  <span className="nk-fmg-menu-text">{item.name}</span>
                </div>
              </div>
            ))}
             
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer bg-light">
        <div className="modal-footer-between">
          <div className="g">
        
          </div>
          <div className="g">
            <ul className="btn-toolbar g-3">
              <li>
                <a
                  href="#file-share"
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggle();
                  }}
                  className="btn btn-outline-light btn-white"
                >
                  Cancel
                </a>
              </li>
              <li>
                <a
                  href="link"
                  onClick={(ev) => {
                    ev.preventDefault();
                    moveFolder(file, folderId)
                    toggle();
                  }}
                  className="btn btn-primary file-dl-toast"
                >
                  Move
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Move;
