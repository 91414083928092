import React, { useState, useEffect, useContext } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { UncontrolledTooltip } from "reactstrap";
import LogManagementData from "./LogManagementData";
import { Card, CardBody } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  Button,
  RSelect,
} from "../../components/Component";
import { LogManagementContext } from "./LogManagementContext";
import getTextColor from "components/custom/getTextColor";
import { getLogs } from "services/logManagement";
import { useCookies } from "react-cookie";
import moment from "moment";
import { getDepartments } from "services/departments";

const statusOption = [
  {
    label: "ALL",
    value: "all",
  },
  {
    label: "SUCCESS",
    value: "success",
  },
  {
    label: "FAILED",
    value: "failed",
  },
];

const LogManagement = () => {
  const { contextData } = useContext(LogManagementContext);
  const [data, setData] = contextData;
  const [cookies, setCookie] = useCookies(["user"]);
  const [orderBy, setOrderBy] = useState("DESC");
  const [isSorting, setIsSorting] = useState(false);
  const [sortKeyword, setSortKeyword] = useState("");
  const [departmentData, setDepartmentData] = useState([]);
  const [businessOption, setbusinessOption] = useState([]);
  console.log("🚀 ~ file: LogManagement.js:56 ~ LogManagement ~ businessOption:", businessOption);

  const [queryName, setQueryName] = useState("");
  const [queryEmail, setQueryEmail] = useState("");
  const [queryPhone, setQueryPhone] = useState("");
  const [queryStatus, setQueryStatus] = useState("");
  const [queryRemark, setQueryRemark] = useState("");
  const [queryDescription, setQueryDescription] = useState("");
  const [queryBusiness, setQueryBusiness] = useState("");

  const [fieldName, setFieldName] = useState("");
  const [fieldEmail, setFieldEmail] = useState("");
  const [fieldPhone, setFieldPhone] = useState("");
  const [fieldStatus, setFieldStatus] = useState("");
  const [fieldRemark, setFieldRemark] = useState("");
  const [fieldDescription, setFieldDescription] = useState("");
  const [fieldBusiness, setFieldBusiness] = useState("");
  const [fieldSortBy, setFieldSortBy] = useState("");

  const [queryText, setQueryText] = useState("");
  const [fieldText, setFieldText] = useState("");

  const [onSearchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const roleFunctions = JSON.parse(localStorage.getItem("permission"))["log"].access || [];
  const [sort, setSortState] = useState("");
  const [metaData, setMetaData] = useState({
    itemCount: 0,
    page: 1,
    take: 0,
    pageCount: 0,
    hasPreviousPage: false,
    hasNextPage: false,
  });
  const refreshData = async (pageNumber) => {
    console.log("Calling refreshData with pageNumber:", pageNumber);
    console.log("roleFunctions", roleFunctions);

    try {
      const respLog = await getLogs(cookies.SIDikey, pageNumber, "", "", cookies.departmentIds, roleFunctions);
      console.log("respLog", respLog.status);
      setMetaData(respLog.data.meta);

      if (new RegExp(/20+/).test(respLog.status)) {
        const finalData = respLog.data.data.map((log) => ({
          id: log.id,
          createdAt: log.createdAt,
          updatedAt: log.updatedAt,
          createdBy: log.createdBy,
          status: log.status,
          remark: log.remark,
          description: log.description,
        }));
        setData(finalData);
      } else {
        console.error("Error in API response", respLog);
      }
    } catch (error) {
      console.error("Failed to fetch logs:", error);
    }
  };
  const refresthDepartmentData = async () => {
    const responseGetDepartments = await getDepartments(
      cookies.SIDikey,
      "",
      cookies.departmentIds,
      cookies.id,
      roleFunctions
    );
    setDepartmentData(responseGetDepartments.data.data);
  };

  const refreshDataByQuery = async (pageNumber, query, field, departmentSelected, order) => {
    const respLog = await getLogs(cookies.SIDikey, pageNumber, query, field, departmentSelected, roleFunctions, order);
    console.log("respLogQuery", respLog);
    setMetaData(respLog.data.meta);
    if (new RegExp(/20+/).test(respLog.status)) {
      const resultData = respLog.data;
      const finaldata = [];
      resultData.data.forEach((log) => {
        const tempData = {
          id: log.id,
          createdAt: log.createdAt,
          updatedAt: log.updatedAt,
          createdBy: log.createdBy,
          status: log.status,
          remark: log.remark,
          description: log.description,
        };
        finaldata.push(tempData);
      });
      setData(finaldata);
    } else {
      console.log(respLog);
    }
  };

  // const refreshDataByField = async (query, field, pageNumber, order) => {
  //   const respLog = await getLogsByField(cookies.SIDikey, pageNumber, query, field, order);
  //   setMetaData(respLog.data.meta);
  //   if (new RegExp(/20+/).test(respLog.status)) {
  //     const resultData = respLog.data;
  //     const finaldata = [];
  //     resultData.data.forEach((log) => {
  //       const tempData = {
  //         id: log.id,
  //         createdAt: log.createdAt,
  //         updatedAt: log.updatedAt,
  //         createdBy: log.createdBy,
  //         status: log.status,
  //         remark: log.remark,
  //         description: log.description,
  //       };
  //       finaldata.push(tempData);
  //     });
  //     setData(finaldata);
  //   } else {
  //     console.log(respLog);
  //   }
  // };
  // Sorting data
  const onSortClicked = (id, order) => {
    setOrderBy(order);
    setFieldSortBy("," + id);
    if (fieldSortBy === "," + id) {
      refreshDataByQuery(1, queryText, fieldText, cookies.departmentIds, order);
    }
    setIsSorting(true);
    setSortKeyword(id);
  };
  const onSelectChangeBusiness = (e) => {
    if (e.value !== "ALL") {
      // setQueryBusiness(e.label.toLowerCase());
      console.log(e.value);
      // setQueryBusiness("," + e.value);
      // setFieldBusiness(",departments.name");
      refreshDataByQuery(1, queryText, fieldText, e.value, orderBy);
    } else {
      setFieldBusiness("");
      setQueryBusiness("");
      refreshDataByQuery(1, queryText, fieldText, cookies.departmentIds, orderBy);
    }
  };

  const onStatusChange = (e) => {
    if (e.value !== "all") {
      setQueryStatus("," + e.value);
      setFieldStatus(",log.status");
      // refreshDataByQuery(1, queryText, fieldText, orderBy);
    } else {
      setFieldStatus("");
      setQueryStatus("");
    }
  };

  useEffect(() => {
    if (departmentData) {
      setbusinessOption([
        { value: "ALL", label: "ALL" },
        ...departmentData.map((item) => ({ label: item.name, value: item.id })),
      ]);
    }
  }, [departmentData]);

  useEffect(() => {
    // Initialize the data when the component mounts
    const initializeData = async () => {
      await refreshData(1);

      const newData = LogManagementData.map((item) => ({
        ...item,
        checked: false,
      }));
      setData(newData);
    };
    initializeData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Changing state value when searching name
  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = LogManagementData.filter(
        (item) =>
          item.name.toLowerCase().includes(onSearchText.toLowerCase()) ||
          item.email.toLowerCase().includes(onSearchText.toLowerCase())
      );
      setData(filteredObject);
    } else {
      setData(LogManagementData);
    }
  }, [onSearchText, setData]);

  // onChange function for searching name
  useEffect(() => {
    setQueryText(queryName + queryEmail + queryPhone + queryStatus + queryRemark + queryDescription + queryBusiness);
    setFieldText(
      fieldName + fieldEmail + fieldPhone + fieldStatus + fieldRemark + fieldDescription + fieldBusiness + fieldSortBy
    );
  }, [
    fieldBusiness,
    fieldDescription,
    fieldEmail,
    fieldName,
    fieldPhone,
    fieldRemark,
    fieldStatus,
    fieldSortBy,
    queryBusiness,
    queryDescription,
    queryEmail,
    queryName,
    queryPhone,
    queryRemark,
    queryStatus,
  ]);

  useEffect(() => {
    refresthDepartmentData();
  }, []);

  useEffect(() => {
    if ((queryText !== "" && fieldText !== 0) || fieldText !== "") {
      console.log("🚀 ~ useEffect ~ queryText:", queryText);
      console.log("🚀 ~ useEffect ~ fieldText:", fieldText);
      refreshDataByQuery(1, queryText, fieldText, cookies.departmentIds, orderBy);
    } else {
      refreshData(1);
    }
  }, [fieldText, queryText]);

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) =>
    queryText !== ""
      ? refreshDataByQuery(pageNumber, queryText, fieldText, cookies.departmentIds, orderBy)
      : refreshData(pageNumber);

  return (
    <React.Fragment>
      <Head title="Log Management"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Log Management
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
          <Card className="card-bordered my-4 card-bluesky">
            <CardBody className="card-bluesky-body">
              <strong>Info:</strong>
              <p>Memantau aktivitas sistem pada backend</p>
            </CardBody>
          </Card>
        </BlockHead>

        <Block>
          <DataTable className="card-stretch" style={{ borderRadius: "10px" }}>
            <DataTableBody compact style={{ minHeight: "400px" }}>
              <DataTableHead style={{ backgroundColor: "#F5F6FB" }}>
                <DataTableRow>
                  <span className="sub-text">Name</span>
                </DataTableRow>
                <DataTableRow>
                  <span className="sub-text">Email</span>
                </DataTableRow>
                <DataTableRow>
                  <span className="sub-text">Phone Number</span>
                </DataTableRow>
                <DataTableRow>
                  <span className="sub-text">Department</span>
                </DataTableRow>
                <DataTableRow>
                  <div className="sub-text d-flex justify-content-between align-items-center" style={{ width: 150 }}>
                    Status{" "}
                    <Button size="xs" id="status">
                      <Icon name="sort" />
                    </Button>
                  </div>
                  <UncontrolledTooltip autohide={false} placement="top" target="status">
                    <div className="tooltip-ticket-sorting">
                      <Button
                        color={orderBy === "ASC" ? "primary" : "secondary"}
                        onClick={() => onSortClicked("log.status", "ASC")}
                        size="xs"
                      >
                        ASC
                      </Button>
                      <Button
                        color={orderBy === "DESC" ? "primary" : "secondary"}
                        onClick={() => onSortClicked("log.status", "DESC")}
                        size="xs"
                      >
                        DESC
                      </Button>
                    </div>
                  </UncontrolledTooltip>
                </DataTableRow>
                <DataTableRow>
                  <div className="sub-text d-flex justify-content-between align-items-center" style={{ width: 150 }}>
                    Remarks{" "}
                    <Button size="xs" id="remarks">
                      <Icon name="sort" />
                    </Button>
                  </div>
                  <UncontrolledTooltip autohide={false} placement="top" target="remarks">
                    <div className="tooltip-ticket-sorting">
                      <Button
                        color={orderBy === "ASC" ? "primary" : "secondary"}
                        onClick={() => onSortClicked("log.remark", "ASC")}
                        size="xs"
                      >
                        ASC
                      </Button>
                      <Button
                        color={orderBy === "DESC" ? "primary" : "secondary"}
                        onClick={() => onSortClicked("log.remark", "DESC")}
                        size="xs"
                      >
                        DESC
                      </Button>
                    </div>
                  </UncontrolledTooltip>
                </DataTableRow>
                <DataTableRow>
                  <div className="sub-text d-flex justify-content-between align-items-center">
                    Description{" "}
                    <Button size="xs" id="desc">
                      <Icon name="sort" />
                    </Button>
                  </div>
                  <UncontrolledTooltip autohide={false} placement="top" target="desc">
                    <div className="tooltip-ticket-sorting">
                      <Button
                        color={orderBy === "ASC" ? "primary" : "secondary"}
                        onClick={() => onSortClicked("log.description", "ASC")}
                        size="xs"
                      >
                        ASC
                      </Button>
                      <Button
                        color={orderBy === "DESC" ? "primary" : "secondary"}
                        onClick={() => onSortClicked("log.description", "DESC")}
                        size="xs"
                      >
                        DESC
                      </Button>
                    </div>
                  </UncontrolledTooltip>
                </DataTableRow>
                <DataTableRow>
                  <div className="sub-text d-flex justify-content-between align-items-center">
                    Time
                    <Button size="xs" id="time">
                      <Icon name="sort" />
                    </Button>
                  </div>
                  <UncontrolledTooltip autohide={false} placement="top" target="time">
                    <div className="tooltip-ticket-sorting">
                      <Button
                        color={orderBy === "ASC" ? "primary" : "secondary"}
                        onClick={() => onSortClicked("log.updatedAt", "ASC")}
                        size="xs"
                      >
                        ASC
                      </Button>
                      <Button
                        color={orderBy === "DESC" ? "primary" : "secondary"}
                        onClick={() => onSortClicked("log.updatedAt", "DESC")}
                        size="xs"
                      >
                        DESC
                      </Button>
                    </div>
                  </UncontrolledTooltip>
                </DataTableRow>
              </DataTableHead>
              <DataTableHead>
                <DataTableRow>
                  <div className="form-control-wrap mt-1" style={{ width: 150 }}>
                    <div className="form-icon form-icon-right">
                      <Icon name="search"></Icon>
                    </div>
                    <input
                      onChange={(e) => {
                        setQueryName("," + e.target.value);
                        setFieldName(",createdBy.firstName");
                        if (e.target.value === "") {
                          let fieldN = fieldName;
                          setQueryName("");
                          setFieldName(fieldN.replace(",createdBy.firstName", ""));
                        }
                      }}
                      type="text"
                      className="form-control w-100"
                      id="name-search"
                    />
                  </div>
                </DataTableRow>
                <DataTableRow>
                  {" "}
                  <div className="form-control-wrap mt-1" style={{ width: 150 }}>
                    <div className="form-icon form-icon-right">
                      <Icon name="search"></Icon>
                    </div>
                    <input
                      onChange={(e) => {
                        setQueryEmail("," + e.target.value);
                        setFieldEmail(",createdBy.email");
                        if (e.target.value === "") {
                          let fieldN = fieldEmail;
                          setQueryEmail("");
                          setFieldEmail(fieldN.replace(",createdBy.email", ""));
                        }
                      }}
                      type="text"
                      className="form-control w-100"
                      id="name-search"
                    />
                  </div>
                </DataTableRow>
                <DataTableRow>
                  {" "}
                  <div className="form-control-wrap mt-1" style={{ width: 150 }}>
                    <div className="form-icon form-icon-right">
                      <Icon name="search"></Icon>
                    </div>
                    <input
                      onChange={(e) => {
                        setQueryPhone("," + e.target.value);
                        setFieldPhone(",createdBy.phone");
                        if (e.target.value === "") {
                          let fieldN = fieldPhone;
                          setQueryPhone("");
                          setFieldPhone(fieldN.replace(",createdBy.phone", ""));
                        }
                      }}
                      type="text"
                      className="form-control w-100"
                      id="name-search"
                    />
                  </div>
                </DataTableRow>
                <DataTableRow>
                  <div style={{ width: 150 }}>
                    <RSelect onChange={onSelectChangeBusiness} options={businessOption} />
                  </div>
                </DataTableRow>
                <DataTableRow>
                  <div style={{ width: 150 }}>
                    <RSelect onChange={onStatusChange} options={statusOption} />
                  </div>
                </DataTableRow>
                <DataTableRow>
                  <div className="form-control-wrap mt-1" style={{ width: 150 }}>
                    <div className="form-icon form-icon-right">
                      <Icon name="search"></Icon>
                    </div>
                    <input
                      onChange={(e) => {
                        setQueryRemark("," + e.target.value);
                        setFieldRemark(",log.remark");
                        if (e.target.value === "") {
                          let fieldN = fieldRemark;
                          setQueryRemark("");
                          setFieldRemark(fieldN.replace(",log.remark", ""));
                        }
                      }}
                      type="text"
                      className="form-control w-100"
                      id="name-search"
                    />
                  </div>
                </DataTableRow>
                <DataTableRow>
                  <div className="form-control-wrap mt-1" style={{ width: 150 }}>
                    <div className="form-icon form-icon-right">
                      <Icon name="search"></Icon>
                    </div>
                    <input
                      onChange={(e) => {
                        setQueryDescription("," + e.target.value);
                        setFieldDescription(",log.description");
                        if (e.target.value === "") {
                          let fieldN = fieldDescription;
                          setQueryDescription("");
                          setFieldDescription(fieldN.replace(",log.description", ""));
                        }
                      }}
                      type="text"
                      className="form-control w-100"
                      id="name-search"
                    />
                  </div>
                </DataTableRow>
              </DataTableHead>
              {/*Head*/}
              {currentItems.length > 0
                ? currentItems.map((item) => {
                    return (
                      <DataTableItem key={item.id}>
                        <DataTableRow>
                          <span>{item.createdBy?.firstName}</span>
                        </DataTableRow>
                        <DataTableRow>
                          <span>{item.createdBy?.email}</span>
                        </DataTableRow>
                        <DataTableRow>
                          <span>{item.createdBy?.phone}</span>
                        </DataTableRow>
                        <DataTableRow>
                          {item?.createdBy?.departments.map((department) => (
                            <span
                              key={department.id}
                              className="badge rounded-pill text-bg-primary"
                              style={{
                                background: department.color,
                                borderColor: department.color,
                                color: getTextColor(department.color),
                              }}
                            >
                              {department.name}
                            </span>
                          ))}
                        </DataTableRow>
                        <DataTableRow>
                          <span className={item.status === "SUCCESS" ? "text-success" : "text-danger"}>
                            {item.status}
                          </span>
                        </DataTableRow>
                        <DataTableRow>
                          <span>{item.remark}</span>
                        </DataTableRow>
                        <DataTableRow>
                          <span>{item.description}</span>
                        </DataTableRow>
                        <DataTableRow>
                          <span>{moment(item.updatedAt).format("MMM DD, YYYY, HH:mm")}</span>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                : null}
            </DataTableBody>
            <div className="card-inner">
              {currentItems.length > 0 ? (
                <PaginationComponent
                  itemPerPage={metaData.take}
                  totalItems={metaData.itemCount}
                  paginate={paginate}
                  currentPage={metaData.page}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )}
            </div>
          </DataTable>
        </Block>
      </Content>
    </React.Fragment>
  );
};
export default LogManagement;
