import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Form, FormGroup, Modal, ModalBody } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  RSelect,
} from "../../components/Component";
import { useCookies } from "react-cookie";
import Swal from "sweetalert2";
import { serviceCreateChatbot, serviceGetChatbot } from "services/chatbot";

const ruleTypeOptions = [
  { label: "REGULER", value: "REGULER" },
  { label: "MASTER", value: "MASTER" },
];

const ModalAddButton = ({
  ruleParentId,
  setRuleParentId,
  isModalAddButton,
  setIsModalAddButton,
  selectedDeptId,
  selectedChannel,
}) => {
  const { errors, register, handleSubmit } = useForm();

  const [cookies, setCookie] = useCookies(["user"]);

  const [formData, setFormData] = useState({
    ruleName: "",
    ruleTemplateType: "",
    ruleBody: "",
    ruleButtonType: "",
    triggerValue: "",
    triggerAction: "",
    triggerType: "",
    headerType: "",
    headerText: "",
    headerMedia: null,
    buttonsType: "",
    callToActionProperties: {
      typeOfActions: "",
      buttonText: "",
      urlLink: "",
      phoneNumber: "",
    },
  });

  const buttonTypeOptions = [
    { value: "QUICK_REPLY", label: "BUTTON" },
    { value: "LIST", label: "LIST" },
  ];
  const [buttonTypeState, setButtonTypeState] = useState({ label: "BUTTON", value: "QUICK_REPLY" });

  const buttonDummyOpt = [
    { value: "CHATBOT", label: "Chatbot" },
    { value: "CPC", label: "Profit Center Contact" },
    { value: "ROTATOR", label: "Rotator" },
  ];

  const [butonType, setButonType] = useState(buttonDummyOpt[0]);

  useEffect(() => {
    if (ruleParentId) {
      if (isModalAddButton) {
        checkButtons();
      }
    } else {
      setIsModalAddButton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalAddButton, setIsModalAddButton]);

  const checkButtons = async () => {
    const resp = await serviceGetChatbot(cookies.SIDikey, ruleParentId);
    if (resp.data.buttonRules.length >= 3) {
      Swal.fire("Add Button Disabled", "Jumlah Button Sudah Maksimal", "error");
      setIsModalAddButton(false);
    }
  };

  const onFormSubmit = async (data) => {
    const {
      ruleTemplateType,
      ruleName,
      triggerValue,
      ruleDescription,
      headerType,
      headerMedia,
      ruleBody,
      // urlLink,
    } = data;
    let submittedData = {
      ruleTemplateType: ruleTemplateType || "REGULER",
      ruleName: ruleName,
      ruleDescription: ruleDescription,
      ruleBody: ruleBody,
      ruleButtonType: buttonTypeState.value,
      headerType: headerType || "NONE",
      headerMedia: headerMedia || "",
      headerMediaUrl: "",
      ruleParentId: ruleParentId,
      chatTrigger: {
        triggerType: "BUTTON",
        triggerValue: triggerValue,
      },
      status: 1,
      buttonType: butonType.value,
      departmentId: selectedDeptId,
      channelId: selectedChannel.id,
    };
    const resp = await serviceCreateChatbot(cookies.SIDikey, submittedData);
    if (/20+/.test(resp.status)) {
      Swal.fire("Add Chatbot Rule", "Berhasil", "success");
      setTimeout(() => {
        window.history.pushState(
          `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/chat-bot"}`,
          "chat-bot",
          `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/chat-bot"}`
        );
        window.location.reload();
      }, 500);
    } else {
      Swal.fire("Gagal Add Chatbot Rule", "Periksa kembali isian", "error");
    }
    console.log(resp);
  };

  const onFormCancel = () => {
    setIsModalAddButton(false);
    setRuleParentId(false);
    // resetForm();
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={isModalAddButton}
        backdrop="static"
        keyboard={false}
        toggle={() => setIsModalAddButton(false)}
        className="modal-dialog-centered"
        size="lg"
      >
        <ModalBody>
          <a
            href="#cancel"
            onClick={(ev) => {
              ev.preventDefault();
              onFormCancel();
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">Tambah Button</h5>
            <Block className="d-flex justify-content-end"></Block>
            <div className="mt-4">
              <Form className="row gy-4" onSubmit={handleSubmit(onFormSubmit)}>
                <Col md="12">
                  <label className="form-label">Button type</label>
                  <RSelect
                    name="callCenter"
                    placeholder="Choose Button Type"
                    // ref={register({ required: "This field is required" })}
                    options={buttonDummyOpt}
                    value={butonType}
                    onChange={(e) => setButonType(e)}
                  />
                </Col>
                <Col md="12">
                  <FormGroup>
                    <label className="form-label">Button Text</label>
                    <input
                      className="form-control"
                      type="text"
                      name="triggerValue"
                      placeholder="Text di Button : Cara Ngorder, Info"
                      ref={register({
                        required: "This field is required",
                        maxLength: { value: 20, message: "Text tidak boleh melebihi 20 karakter" },
                      })}
                    />
                    {errors.triggerValue && <span className="custom-invalid">{errors.triggerValue.message}</span>}
                  </FormGroup>
                </Col>
                <div className="mt-2 mb-2"></div>
                <label className="form-label">Response (ketika button ini di klik)</label>
                <Col md="12">
                  <label className="form-label">Tipe Response</label>
                  <RSelect
                    name="ruleTypeButton"
                    placeholder="Tipe Rule {List : bisa diisi list}"
                    // ref={register({ required: "This field is required" })}
                    options={buttonTypeOptions}
                    value={buttonTypeState}
                    onChange={(e) => setButtonTypeState(e)}
                  />
                  {errors.ruleName && <span className="custom-invalid">{errors.ruleName.message}</span>}
                </Col>
                <Col md="12">
                  <FormGroup>
                    <label className="form-label">Response Rule Name</label>
                    <input
                      className="form-control"
                      type="text"
                      name="ruleName"
                      placeholder="Nama Rule untuk Response Button Ini"
                      ref={register({ required: "This field is required" })}
                    />
                    {errors.ruleName && <span className="custom-invalid">{errors.ruleName.message}</span>}
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <label className="form-label">Response Text</label>
                    <textarea
                      className="form-control"
                      type="text"
                      name="ruleBody"
                      placeholder="Response Text : Halo Selamat Siang Kak!"
                      ref={register({ required: "This field is required" })}
                    />
                    {errors.ruleBody && <span className="custom-invalid">{errors.ruleBody.message}</span>}
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <label className="form-label">Rule Description</label>
                    <input
                      className="form-control"
                      type="text"
                      name="ruleDescription"
                      placeholder="Rule Descrtiption untuk Response"
                      ref={register({ required: "This field is required" })}
                    />
                    {errors.ruleDescription && <span className="custom-invalid">{errors.ruleDescription.message}</span>}
                  </FormGroup>
                </Col>

                <Col size="12">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button
                        outline
                        color="primary"
                        onClick={(ev) => {
                          ev.preventDefault();
                          onFormCancel();
                        }}
                      >
                        Batal
                      </Button>
                    </li>
                    <li>
                      <Button color="primary" size="md" type="submit">
                        Simpan
                      </Button>
                    </li>
                  </ul>
                </Col>
              </Form>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default ModalAddButton;
