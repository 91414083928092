import React, { useContext, useState } from "react";
import CreateFolder from "../../../pages/app/file-manager/modals/CreateFolder";
import Details from "../../../pages/app/file-manager/modals/Details";
import Share from "../../../pages/app/file-manager/modals/Share";
import Copy from "../../../pages/app/file-manager/modals/Copy";
import Move from "../../../pages/app/file-manager/modals/Move";
import { Icon } from "../../Component";
import { FileManagerContext } from "../../../pages/app/file-manager/FileManagerContext";
import { DropdownItem, DropdownMenu, DropdownToggle, Modal, UncontrolledDropdown } from "reactstrap";
import { useCookies } from "react-cookie";
import { ActiveFileDepartmentContext } from "contexts/activeFileDepartment";

const FileManagerDropdown = ({ file,cookies }) => {
  const { deleteFolder, downloadFile, deleteFile } = useContext(FileManagerContext);
  const {value} = useContext(ActiveFileDepartmentContext);
  const [activeDepartment, setActiveDepartment] = value ;

  const [detailModal, setDetailModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [copyModal, setCopyModal] = useState(false);
  const [moveModal, setMoveModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);

  const toggleCreateModal = () => {
    setCreateModal(!createModal);
  };

  const toggleDetailModal = () => {
    setDetailModal(!detailModal);
  };
  const toggleShareModal = () => {
    setShareModal(!shareModal);
  };
  const toggleCopyModal = () => {
    setCopyModal(!copyModal);
  };
  const toggleMoveModal = () => {
    setMoveModal(!moveModal);
  };

  return (
    <div className="nk-file-actions">
      <UncontrolledDropdown>
        <DropdownToggle
          tag="a"
          href="#folder"
          onClick={(ev) => ev.preventDefault()}
          className="dropdown-toggle btn btn-sm btn-icon btn-trigger"
        >
          <Icon name="more-h"></Icon>
        </DropdownToggle>
        <DropdownMenu right>
          <ul className="link-list-opt no-bdr">
            <li>
              <DropdownItem
                tag="a"
                onClick={(ev) => {
                  ev.preventDefault();
                  setDetailModal(true);
                }}
                href="#item"
              >
                <Icon name="eye"></Icon>
                <span>Details</span>
              </DropdownItem>
            </li>
          
            <li>
              <DropdownItem
                tag="a"
                onClick={(ev) => {
                  ev.preventDefault();
                  setMoveModal(true);
                }}
                href="#item"
              >
                <Icon name="forward-arrow"></Icon>
                <span>Move</span>
              </DropdownItem>
            </li>
            <li>
              <DropdownItem
                tag="a"
                onClick={(ev) => {
                  ev.preventDefault();
                  // console.log(file)
                  downloadFile(file);
                }}
                href="#item"
                className="file-dl-toast"
              >
                <Icon name="download"></Icon>
                <span>Downloads</span>
              </DropdownItem>
            </li>
            <li>
              <DropdownItem
                tag="a"
                onClick={(ev) => {
                  ev.preventDefault();
                  deleteFile(file.id);
                }}
                href="#item"
              >
                <Icon name="trash"></Icon>
                <span>Delete</span>
              </DropdownItem>
            </li>
          </ul>
        </DropdownMenu>
      </UncontrolledDropdown>

      <Modal isOpen={detailModal} size="md" toggle={toggleDetailModal}>
        <Details file={file} toggle={toggleDetailModal} toggleShare={toggleShareModal} />
      </Modal>

      <Modal isOpen={shareModal} size="md" toggle={toggleShareModal}>
        <Share file={file} toggle={toggleShareModal} />
      </Modal>

      <Modal isOpen={copyModal} size="md" toggle={toggleCopyModal}>
        <Copy file={file} toggle={toggleCopyModal} toggleCreateModal={toggleCreateModal} />
      </Modal>

      <Modal isOpen={moveModal} size="md" toggle={toggleMoveModal}>
        <Move departmentId={activeDepartment} cookies={cookies} file={file} toggle={toggleMoveModal} toggleCreateModal={toggleCreateModal} />
      </Modal>

      <Modal isOpen={createModal} size="md" toggle={toggleCreateModal}>
        <CreateFolder toggle={toggleCreateModal} />
      </Modal>
    </div>
  );
};

export default FileManagerDropdown;
