import { axiosPrivate } from "api/axiosCore";

const limit = 10;
const basic_url = "api/page-actions/";
const ALL_DEPT = "Access_All_Dept";
const OWN_DEPT = "Access_Own_Dept";
const OWN = "Access_Own";

export const serviceGetSodActions = async (token, query, roleFunctions, page, limit = 10) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    roleFunctions = roleFunctions || [];
    var response = null;
    const queryData = query ? "&q=" + query : "";
    response = await axiosPrivate.get(basic_url + `?order=DESC&take=${limit}&page=${page || 1}` + queryData);
    console.log(response);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetListSodActions = async (token) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const response = await axiosPrivate.get(`${basic_url}/list`);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const servicePostSodActions = async (token, data) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.post(basic_url, data);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const servicePutSodActions = async (id, token, data) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.put(basic_url + id, data);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceDeleteSodActions = async (id, token) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.delete(basic_url + id);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};
