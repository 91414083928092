export const masterRoleList = [
	{
		"id": 1,
		"roleName": "Superadmin",
		"action": "Edit",
		"createdAt": "Dec 3, 2022",
		"createdBy": "Adrian Ewing",
		"modifiedAt": "Jul 8, 2022",
		"modifiedBy": "Nyssa Deleon"
	},
	{
		"id": 2, 
		"roleName": "Admin",
		"createdAt": "Feb 18, 2022",
		"createdBy": "Jeanette Lloyd",
		"modifiedAt": "Apr 1, 2022",
		"modifiedBy": "Graham Mcmillan"
	},
	{
		"id": 3,
		"roleName": "CS",
		"createdAt": "Feb 24, 2023",
		"createdBy": "Kirby Levine",
		"modifiedAt": "Oct 10, 2022",
		"modifiedBy": "Fallon Berry"
	},
	{
		"id": 4,
		"roleName": "Sales",
		"createdAt": "May 17, 2023",
		"createdBy": "Nita Adams",
		"modifiedAt": "Sep 2, 2022",
		"modifiedBy": "Nash Mcclain"
	},
	{
		"id": 5,
		"roleName": "Auditor",
		"createdAt": "Apr 10, 2023",
		"createdBy": "Xena Haynes",
		"modifiedAt": "May 7, 2023",
		"modifiedBy": "Wylie Lancaster"
	},
]