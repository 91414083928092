import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import moment from "moment";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  TooltipComponent,
} from "components/Component";
import Content from "layout/content/Content";
import Head from "layout/head/Head";

import { Alert, Card } from "reactstrap";
import { Link } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import { getDashboardCustomerLeadProspect } from "../../services/dashboard";
import { end } from "@popperjs/core";

const CustomerWarmProspect = (props) => {
  const [cookies] = useCookies(["user"]);
  const { selectedDepartment } = props
  const [data, setData] = useState(null);
  const [startDate, setStartDate] = useState(new Date(moment().subtract(1, "months").format("YYYY-MM-DD")));
  const [endDate, setEndDate] = useState(new Date(moment().format("YYYY-MM-DD")));
  const [sort, setSort] = useState(false)
  const [metaData, setMetaData] = useState({
    itemCount: 0,
    page: 0,
    take: 0,
    pageCount: 0,
    hasPreviousPage: false,
    hasNextPage: false,
  });
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [type, setType] = useState("WARM");

  const getDataWarmProspect = async (page, startDate, endDate) => {
    const sortData = sort ? "ASC" : "DESC"
    const responseGetDataWarmProspect = await getDashboardCustomerLeadProspect(
      sortData,
      cookies.SIDikey,
      page,
      take,
      cookies.id,
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).format("YYYY-MM-DD"),
      cookies.role.name,
      selectedDepartment.value,
      type
    );
    setData(responseGetDataWarmProspect.data.data);
    setMetaData(responseGetDataWarmProspect.data.meta);
  };

  useEffect(() => {
    getDataWarmProspect(page, startDate, endDate);
  }, [startDate, endDate, sort, selectedDepartment]);

  const handleDate = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      getDataWarmProspect(page, start, end);
    }
  };

  const paginate = (pageNumber) => getDataWarmProspect(pageNumber);

  return (
    <Card className="card-full">
      <Head title="Level"></Head>
      <Content>
        <BlockHead size="sm">
          <div className="d-flex justify-content-between w-100">
            <div className="card-title">
              <h6 className="title">Customer Warm Prospect</h6>
            </div>
            <div className="position-relative w-20 d-flex justify-content-center align-items-center">
              <ReactDatePicker
                className="w-100"
                selectsRange
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                onChange={handleDate}
                dateFormat={"yyyy-MM-dd"}
              />
              {/* <Icon name="download-cloud" className="custom-dropzone-text ml-3 "/> */}
            </div>
          </div>
        </BlockHead>
        <Block>
          <DataTable className="card-stretch">
            <DataTableBody>
              <DataTableHead>
                <DataTableRow className="">
                  <span className="text">No</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="text">Customer Name</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="text">Last Chat</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="text">Last Chat at</span>
                  {/* <Button 
                    size="xs" 
                    id="ticketId"
                    onClick={(ev) => {
                      setSort(!sort)
                    }}
                  >
                    <Icon name="sort" />
                  </Button> */}
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="text">Follow Up Date</span>
                </DataTableRow>
              </DataTableHead>
              {/*Head*/}
              {data?.length > 0
                ? data.map((item, i) => {
                    return (
                      <DataTableItem key={item.id}>
                        <DataTableRow size="mb">
                          <span className="tb-amount">{i + 1}</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="tb-amount">{item?.chat?.name}</span>
                        </DataTableRow>

                        <DataTableRow size="md">
                          <span style={{ display: "flex" }}>
                            "
                            <span className="tb-amount text-ellipsis" style={{ maxWidth: 400 }}>
                              {item?.chat?.lastMessage?.text}
                            </span>
                            "
                            <Link
                              href="/chat-platform"
                              to={"/chat-platform?chatID=" + item?.chat?.id}
                              style={{ marginLeft: 4 }}
                            >
                              <Icon className="text-blue" name="external" />
                            </Link>
                          </span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="tb-amount">{moment(item?.chat?.lastMessage?.createdAt).format("LLL")}</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="tb-amount">
                            {item?.chat?.contact?.followUpDate !== null
                              ? moment(item?.chat?.contact?.followUpDate).format("YYYY-MM-DD")
                              : ""}
                          </span>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                : null}
            </DataTableBody>
            <div className="my-4 d-flex justify-content-end">
              {
                data?.length > 0 ? (
                  <PaginationComponent
                    itemPerPage={metaData?.take}
                    totalItems={metaData?.itemCount}
                    paginate={paginate}
                    currentPage={metaData?.page}
                  />
                ) : null
                // (
                //   <div className="text-center">
                //     <span className="text-silent">No data found</span>
                //   </div>
                // )
              }
            </div>
          </DataTable>
        </Block>
      </Content>
    </Card>
  );
};
export default CustomerWarmProspect;
