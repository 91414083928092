export const utilNumberPhoneConvention = (number) =>{

    const phonePattern = /^(\+?\d{1,4}[-.\s]?)?(\(?\d{3}\)?[-.\s]?)?[\d-.\s]{7,15}$/;

    if(!phonePattern.test(number)){
        return number;
    }

    let fN = '+';
    fN = fN + number.slice(0,2) +' ';
    fN = fN + number.slice(2,5) + '-';
    fN = fN + number.slice(5,9) + '-';
    fN = fN + number.slice(9,);

    return fN
}