import { axiosPrivate } from "../api/axiosCore";

export const servicePutCrmResolve = async (token, id, data) => {
    try {
      axiosPrivate.interceptors.request.use((config) => {
        config.headers["Authorization"] = `Bearer ${token}`;
        return config;
      });
  
      const response = await axiosPrivate.put("api/crm/resolve/" + id, data);
  
      return response;
    } catch (error) {
      if (!error.response) {
        return { status: "No Server Response" };
      } else {
        return error.response;
      }
    }
  };

export const serviceGetsCrmResolve = async (token, page) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.get("api/crm/resolve/?take=50&page="+page);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetResolveByContact = async (token,id) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.get(`api/crm?order=ASC&page=1&take=20&q=${id}&f=contact.id`);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetCrmById = async (token, crmId, ticketId = "") => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    
    let basicUrl = "api/crm/"+crmId;
    
    if(ticketId){
      basicUrl = "api/crm/?take=50&ticketId="+ticketId;
    }


    let response = null;
    response = await axiosPrivate.get(basicUrl);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetCrmResolveByParam = async (token, contactId, deptId, phoneNumber, chatId, page, status="") => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const basicUrl = "api/crm/?take=10&order=DESC&page="+ page;

    let response = null;
    if(phoneNumber){
      response = await axiosPrivate.get(basicUrl + `&c=AND&departmentId=${deptId}&status=${status}&phone=` + phoneNumber);
    }else if(contactId){
      response = await axiosPrivate.get(basicUrl + `&q=contactId='${contactId}'`);
    }else if(chatId){
      response = await axiosPrivate.get(basicUrl + `&q=chatId='${chatId}'`);
    }

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceCreateCrmResolve = async (token, data, status) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    let response
    if(status === "RESOLVED") {
      response = await axiosPrivate.post(`api/crm/resolve/`, data);
    } else {
      console.log("status", "send pending")
      response = await axiosPrivate.post("api/crm/internal", data);
    }

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceCrmChangeStatusBulk = async (token, data) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.put("api/crm/change-status-bulk", JSON.parse(data));

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceDeleteCrmProduct = async (token, id) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.delete("api/crm/" + id);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};