import React, { useState } from "react";

const InputSwitch = ({ label, id, checked, setChecked, caption,onChange }) => {

  return (
    <div>
      <input
        type="checkbox"
        className="custom-control-input"
        onClick={() => {setChecked(!checked)}}
        id={id}
        checked={checked}
        onChange={onChange}
      />
      <label className="custom-control-label" htmlFor={id}>
        {label}
      </label>
      <span className="sub-text">
        {caption}
      </span>
    </div>
  );
};

export default InputSwitch;
