import { axiosPrivate } from "../api/axiosCore";

export const serviceGetMedia = async (token, id) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const query = "api/messages/gallery?order=DESC&limit=15&chatId="+id;
    const response = await axiosPrivate.get(query);
    
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};