/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useLayoutEffect, useRef, useMemo, useCallback } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
// import contacts from "./contacts";
import {
  serviceGetAllContacts,
  serviceGetContacts,
  serviceGetContactsByQuery,
  servicePutContact,
  serviceGetContactByPhone,
} from "services/contact";
import { Badge } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  Button,
  RSelect,
} from "../../components/Component";
import { useForm } from "react-hook-form";
import { ContactContext } from "./ContactContext";
import { getTags } from "../../services/tags";
import { useCookies } from "react-cookie";
import "../../assets/css/contact.css";
import ContactDetail from "./ContactDetail";
import ContactUpload from "./ContactUpload";
import ContactSync from "./ContactSync";
import moment from "moment";
import ContactCreate from "./ContactCreate";
import Papa from "papaparse";
import ReactDatePicker from "react-datepicker";

const Contact = () => {
  const nonFixedRef = useRef([]);
  nonFixedRef.current = [];
  const [nonFixedHeight, setNonFixedHeight] = useState([]);
  const { contextData } = useContext(ContactContext);
  const [data, setData] = contextData;
  const [dataFilterTag, setDataFilterTag] = useState([]);
  const [modalUploadCSV, setModalUploadCSV] = useState(false);
  const [modalSync, setModalSync] = useState(false);
  const [selectedDatefilter, setSelectedDatefilter] = useState();
  const [metaData, setMetaData] = useState({
    itemCount: 0,
    page: 1,
    take: 0,
    pageCount: 0,
    hasPreviousPage: false,
    hasNextPage: false,
  });
  const [cookies] = useCookies(["user"]);

  const [sm, updateSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [filterName, setFilterName] = useState([]);
  const [filterValue, setFilterValue] = useState([]);

  // const [tablesm, updateTableSm] = useState(false);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [itemPerPage, setItemPerPage] = useState(10);
  // const [sort, setSortState] = useState("");

  const [modalDetail, setModalDetail] = useState(false);
  const [modalCreate, setModalCerate] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [contacts, setContacts] = useState([]);
  const [mappedData, setMappedData] = useState([]);
  const [fieldName, setFieldName] = useState("");

  const [queryName, setQueryName] = useState("");
  const [queryPhone, setQueryPhone] = useState("");

  const [sortName, setSortName] = useState();
  const [sortVal, setsortVal] = useState();
  const [sortValElm, setsortValElm] = useState();

  const [queryText, setQueryText] = useState("");
  const [fieldText, setFieldText] = useState("");
  const [fieldPhone, setFieldPhone] = useState("");

  const [downloadLoading, setDownloadLoading] = useState(false);
  const [selectedSyncList, setSelectedSyncList] = useState([]);

  // const [roleFunctions] = useState(DefaultRoleFunctions.contact[cookies.role.name]);
  const roleFunctions = JSON.parse(localStorage.getItem("permission"))["contacts"].access || [];

  const clientOption = [
    { value: "All", label: "All" },
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  const clientSort = [
    { value: "None", label: "None" },
    { value: "DESC", label: "Sort Newest" },
    { value: "ASC", label: "Sort Latest" },
  ];

  const membershipOption = [
    { value: "All", label: "All" },
    { value: "Futures", label: "Futures" },
    { value: "Stocks", label: "Stocks" },
  ];

  const clientTypeOption = [
    { value: "All", label: "All" },
    { value: "Loyal", label: "Loyal" },
    { value: "New", label: "New" },
    { value: "Basic Client", label: "Basic Client" },
  ];

  const refreshTag = async () => {
    const responseGetTags = await getTags(cookies.SIDikey);
    if (new RegExp(/20+/).test(responseGetTags.status)) {
      const resultData = responseGetTags.data;
      const finalData = [{ value: "all", label: "All" }];
      resultData.data.forEach((tag) => {
        const tempData = {
          value: tag.id,
          label: tag.name,
          color: tag.color,
        };
        finalData.push(tempData);
      });
      setDataFilterTag(finalData);
    } else {
      console.log(responseGetTags);
    }
  };

  const onDetailClick = (data) => {
    setDetailData(data);
    setModalDetail(true);
  };

  const onCreateClick = (data) => {
    setModalCerate(true);
  };

  // Sorting data
  const sortFunc = (params) => {
    let defaultData = data;
    if (params === "asc") {
      let sortedData = defaultData.sort((a, b) => a.name.localeCompare(b.name));
      setData([...sortedData]);
    } else if (params === "dsc") {
      let sortedData = defaultData.sort((a, b) => b.name.localeCompare(a.name));
      setData([...sortedData]);
    }
  };

  const refreshContacts = async (pageNumber) => {
    const resp = await serviceGetContacts(cookies.SIDikey, pageNumber);
    setMetaData(resp.data.meta);
    if (resp.status === 200) {
      setContacts(resp.data.data);
      setData(resp.data.data);
    }
  };

  const refreshContactsByQuery = async (pageNumber, query, field, filterValue, filterName, selectedDatefilter) => {
    let dateFilter = "";
    if (selectedDatefilter) {
      dateFilter = moment(selectedDatefilter).format("YYYY-MM-DD");
    }
    const queryValue = filterValue ? filterValue.toString() + query.replace(/,+/g, ",") : query.replace(/,+/g, ",");
    const queryField = filterName ? filterName.toString() + field : field;
    const resp = await serviceGetContactsByQuery(
      cookies.SIDikey,
      pageNumber,
      queryValue,
      queryField,
      sortVal,
      sortName,
      dateFilter
    );
    if (resp.status === 200) {
      setContacts(resp.data.data);
      setMetaData(resp.data.meta);
      setData(resp.data.data);
    }
  };
  const convertObjectToCSV = (data) => {
    const csv = Papa.unparse(data);
    return csv;
  };

  const handleDownload = async () => {
    setDownloadLoading(true);
    const resp = await serviceGetAllContacts(cookies.SIDikey);
    const csv = convertObjectToCSV(resp);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `contact_page.csv`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setDownloadLoading(false);
  };
  // unselects the data on mount
  useEffect(() => {
    refreshTag();
    refreshContacts(1);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = contacts.filter((item) => {
        return (
          item.name.toLowerCase().includes(onSearchText.toLowerCase()) ||
          item.email.toLowerCase().includes(onSearchText.toLowerCase())
        );
      });
      setData([...filteredObject]);
    } else {
      setData([...contacts]);
    }
  }, [onSearchText, setData]);

  useEffect(() => {
    if ((queryText !== "" && fieldText !== 0) || filterValue.length > 0 || sortValElm || selectedDatefilter) {
      setonSearch(true);
      refreshContactsByQuery(1, queryText, fieldText, filterValue, filterName, selectedDatefilter);
    } else {
      setonSearch(false);
      refreshContacts(1);
    }
  }, [fieldText, queryText, filterValue, sortValElm, selectedDatefilter]);

  useEffect(() => {
    if (!modalDetail) {
      refreshContacts(metaData.page);
    }
  }, [modalDetail]);

  useLayoutEffect(() => {
    refreshHeight();
  }, []);

  useEffect(() => {
    setQueryText(queryName + queryPhone);
    setFieldText(fieldName + fieldPhone);
  }, [fieldName, fieldText, queryName, queryText, fieldPhone, queryPhone]);

  const refreshHeight = () => {
    if (nonFixedRef.current) {
      const tempHeight = [];
      nonFixedRef.current.map((ref) => {
        const height = ref.offsetHeight;
        tempHeight.push(height);
      });
      setNonFixedHeight(tempHeight);
    }
  };

  const addToRefs = (el) => {
    if (el && !nonFixedRef.current.includes(el)) {
      nonFixedRef.current.push(el);
    }
  };
  useEffect(() => {
    const tempData = data.map((item) => ({
      ...item,
      createdBy: item?.createdBy?.firstName ?? "",
      updatedBy: item?.updatedBy?.firstName ?? "",
      phones: item?.phones[0]?.phone ?? "",
      emails: item?.emails[0]?.email ?? "",
    }));
    setMappedData(tempData);
  }, [data]);

  /**
   // onChange function for searching name
   const onFilterChange = (e) => {
     setSearchText(e.target.value);
   };
 
   // function to toggle the search option
   const toggle = () => setonSearch(!onSearch);
 
   const { errors, register, handleSubmit } = useForm();
   * 
   */

  // Change Page
  const paginate = (pageNumber) =>{
    console.log("query", queryText, filterValue, filterValue.length === 0)
    // queryText === "" ? refreshContacts(pageNumber) : refreshContactsByQuery(pageNumber, queryText, fieldText);
    if ((queryText === "") && (filterValue.length === 0)){
      console.log("tes")
      refreshContacts(pageNumber)
    } else {
      console.log("tes2")
      refreshContactsByQuery(pageNumber, queryText, fieldText, filterValue, filterName, selectedDatefilter);
    }
  }

  // SelectedSync
  const selectedSync = (e, name, id) => {
    if (e.target.checked) {
      setSelectedSyncList([...selectedSyncList, { name: name, phone: e.target.name, id: id }]);
    } else {
      const newData = [...selectedSyncList];
      const SelectedIndex = selectedSyncList.findIndex((item) => item.phone === e.target.name);
      newData.splice(SelectedIndex, 1);
      setSelectedSyncList(newData);
    }
  };

  const handleSort = (event, action) => {
    const fieldName = "contact." + action.name;
    const fieldSort = event.value;
    if (fieldSort === "None") {
      setsortValElm();
      setsortVal();
      setSortName();
    } else {
      setsortValElm(event);
      setsortVal(fieldSort);
      setSortName(fieldName);
    }
  };

  const handleFilter = (event, action) => {
    let fieldName, fieldValue;
    if (event.target) {
      // this for text field change
      switch (event.target.name) {
        case "email":
          fieldName = "emails." + event.target.name;
          fieldValue = event.target.value;
          break;
        case "birthday":
          setSelectedDatefilter(event.target.value);
          return;
        default:
          fieldName = "contact." + event.target.name;
          fieldValue = event.target.value;
      }
    } else {
      // this for dropdown change
      fieldName = "contact." + action.name;
      fieldValue = event.value;
    }

    const findFilter = filterName.findIndex((item) => item === fieldName);

    if (fieldValue === "All" || fieldValue === "") {
      filterName.splice(findFilter, 1);
      filterValue.splice(findFilter, 1);
      setFilterName([...filterName]);
      setFilterValue([...filterValue]);
    } else {
      if (findFilter < 0) {
        setFilterName([...filterName, fieldName]);
        setFilterValue([...filterValue, fieldValue]);
      } else {
        filterValue[findFilter] = fieldValue;
        setFilterValue([...filterValue]);
      }
    }
  };

  return (
    <React.Fragment>
      <Head title="Contact"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Contact
              </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent className="d-flex" style={{ gap: "10px" }}>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    {/* {!roleFunctions.includes("download") || (
                      <li>
                        <a
                          href="#export"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="btn btn-white btn-outline-light"
                        >
                          <Icon name="upload-cloud"></Icon>
                          <span>Upload</span>
                        </a>
                      </li>
                    )} */}
                    {/* <li className="nk-block-tools-opt">
                      <Button color="danger" outline>
                        Reset
                      </Button>
                    </li> */}
                  </ul>
                </div>
              </div>{" "}
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    {!roleFunctions.includes("download") || (
                      <>
                        <li>
                          <Button
                            href="#import"
                            onClick={(e) => {
                              e.preventDefault();
                              setModalUploadCSV(true);
                            }}
                            className="btn btn-white btn-outline-light"
                          >
                            <Icon name={downloadLoading ? "loader" : "upload-cloud"}></Icon>
                            <span>Upload</span>
                          </Button>
                        </li>
                        <li>
                          <Button
                            href="#export"
                            onClick={(e) => {
                              e.preventDefault();
                              handleDownload();
                            }}
                            className="btn btn-white btn-outline-light"
                          >
                            <Icon name={downloadLoading ? "loader" : "download-cloud"}></Icon>
                            <span>Download</span>
                          </Button>
                        </li>
                      </>
                    )}{" "}
                    {!roleFunctions.includes("create") || (
                      <Button color="primary" className="btn" onClick={() => setModalSync(true)}>
                        <i className="ni ni-plus"></i>
                        <span>Sync Contact</span>
                      </Button>
                    )}
                    {/* <li className="nk-block-tools-opt">
                      <Button color="danger" outline>
                        Reset
                      </Button>
                    </li> */}
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <DataTable className="card-stretch">
            <Row>
              <Col md="5" lg="4" sm="6" xs="6" className="fixed-column border-right">
                <DataTableBody compact>
                  <DataTableHead style={{ backgroundColor: "#F5F6FB" }}>
                    <DataTableRow noWrap>
                      <span className="sub-text">No</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Fullname</span>
                    </DataTableRow>
                  </DataTableHead>
                  <DataTableHead>
                    <DataTableRow></DataTableRow>
                    <DataTableRow>
                      <div className="form-control-wrap">
                        <input
                          onChange={(e) => {
                            setQueryName("," + e.target.value);
                            setFieldName(",contact.name");
                            if (e.target.value === "") {
                              let fieldN = fieldName;
                              setFieldName(fieldN.replace(",contact.name", ""));
                            }
                          }}
                          style={{ height: 38 }}
                          type="text"
                          className="form-control"
                          name="searchName"
                        />
                      </div>
                    </DataTableRow>
                  </DataTableHead>
                  {/*Head*/}
                  {data.length > 0
                    ? data.map((item, index) => {
                        return (
                          <DataTableItem key={index}>
                            <DataTableRow>
                              <div className="contactCheckbox">
                                <span>{(metaData.page - 1) * metaData.take + (index + 1)}</span>

                                <input
                                  checked={
                                    selectedSyncList.find((itm) => itm.phone === item?.phones[0]?.phone) ? true : false
                                  }
                                  type="checkbox"
                                  name={item?.phones[0]?.phone}
                                  onChange={(e) => selectedSync(e, item.name, item.id)}
                                  disabled={item?.phones[0]?.phone ? false : true}
                                />
                              </div>
                            </DataTableRow>
                            <DataTableRow
                              customStyle={nonFixedHeight.length > 0 ? { height: nonFixedHeight[index] } : {}}
                              noWrap
                            >
                              <a
                                href="#detail"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  onDetailClick(item);
                                }}
                              >
                                <span>{item.name || "-"}</span>
                              </a>
                            </DataTableRow>
                          </DataTableItem>
                        );
                      })
                    : null}
                </DataTableBody>
              </Col>
              <Col md="7" lg="8" sm="6" xs="6" className="nfixed-column">
                <DataTableBody compact>
                  <DataTableHead style={{ backgroundColor: "#F5F6FB" }}>
                    <DataTableRow noWrap>
                      <span className="sub-text">Tanggal Lahir</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Phone 1</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Phone 2</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Email 1</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Email 2</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Astro Client</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Client A-Club</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Sales A-Club</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Client Type</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text" style={{ width: 250 }}>
                        Membership
                      </span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Client A-Shop</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text" style={{ width: 250 }}>
                        Product A-Shop
                      </span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Client CAT</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Member Orbi</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Sales Orbi</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">UOB Client</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">UOB Sales</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Sucor Client</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Sucor Sales</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Modify At</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Modify By</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Created At</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Created By</span>
                    </DataTableRow>
                  </DataTableHead>
                  <DataTableHead>
                    <DataTableRow>
                      <div className="form-control-wrap">
                        <ReactDatePicker
                          className="form-control date-picker"
                          showYearDropdown
                          scrollableYearDropdown
                          dropdownMode="select"
                          onChange={(date) =>
                            handleFilter({
                              target: {
                                value: date,
                                name: "birthday",
                              },
                            })
                          }
                          selected={selectedDatefilter || ""}
                          name="birthday"
                        />
                      </div>
                    </DataTableRow>
                    <DataTableRow>
                      <div className="form-control-wrap">
                        <input
                          onChange={(e) => {
                            setQueryPhone("," + e.target.value);
                            setFieldPhone(",phones.phone");
                            if (e.target.value === "") {
                              let fieldN = fieldPhone;
                              setFieldPhone(fieldN.replace(",phones.phone", ""));
                            }
                          }}
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </DataTableRow>
                    <DataTableRow>
                      <div className="form-control-wrap">
                        <input
                          onChange={(e) => {
                            setQueryPhone("," + e.target.value);
                            setFieldPhone(",phones.phone");
                            if (e.target.value === "") {
                              let fieldN = fieldPhone;
                              setFieldPhone(fieldN.replace(",phones.phone", ""));
                            }
                          }}
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </DataTableRow>
                    <DataTableRow>
                      <div className="form-control-wrap">
                        <input type="text" className="form-control" name="email" onChange={handleFilter} />
                      </div>
                    </DataTableRow>
                    <DataTableRow>
                      <div className="form-control-wrap">
                        <input type="text" className="form-control" name="email" onChange={handleFilter} />
                      </div>
                    </DataTableRow>
                    <DataTableRow>
                      <RSelect
                        name="clientAstro"
                        options={clientOption}
                        defaultValue={{ value: "all", label: "All" }}
                        onChange={handleFilter}
                      />
                    </DataTableRow>
                    <DataTableRow>
                      <RSelect
                        name="clientAclub"
                        options={clientOption}
                        defaultValue={{ value: "all", label: "All" }}
                        onChange={handleFilter}
                      />
                    </DataTableRow>
                    <DataTableRow>
                      <div className="form-control-wrap">
                        <input type="text" className="form-control" name="salesAclub" onChange={handleFilter} />
                      </div>
                    </DataTableRow>
                    <DataTableRow>
                      <RSelect
                        name="clientType"
                        options={clientTypeOption}
                        defaultValue={{ value: "all", label: "All" }}
                        onChange={handleFilter}
                      />
                    </DataTableRow>
                    <DataTableRow>
                      <RSelect
                        name="membership"
                        options={membershipOption}
                        defaultValue={{ value: "all", label: "All" }}
                        onChange={handleFilter}
                      />
                    </DataTableRow>
                    <DataTableRow>
                      <RSelect
                        name="clientAshop"
                        options={clientOption}
                        defaultValue={{ value: "all", label: "All" }}
                        onChange={handleFilter}
                      />
                    </DataTableRow>
                    <DataTableRow>
                      <div className="form-control-wrap">
                        <input type="text" className="form-control" name="productAshop" onChange={handleFilter} />
                      </div>
                    </DataTableRow>
                    <DataTableRow>
                      <RSelect
                        name="clientCat"
                        options={clientOption}
                        defaultValue={{ value: "all", label: "All" }}
                        onChange={handleFilter}
                      />
                    </DataTableRow>
                    <DataTableRow>
                      <RSelect
                        name="clientOrbi"
                        options={clientOption}
                        defaultValue={{ value: "all", label: "All" }}
                        onChange={handleFilter}
                      />
                    </DataTableRow>
                    <DataTableRow>
                      <div className="form-control-wrap">
                        <input type="text" className="form-control" name="salesOrbi" onChange={handleFilter} />
                      </div>
                    </DataTableRow>
                    <DataTableRow>
                      <RSelect
                        name="clientUob"
                        options={clientOption}
                        defaultValue={{ value: "all", label: "All" }}
                        onChange={handleFilter}
                      />
                    </DataTableRow>
                    <DataTableRow>
                      <div className="form-control-wrap">
                        <input type="text" className="form-control" name="salesUob" onChange={handleFilter} />
                      </div>
                    </DataTableRow>
                    <DataTableRow>
                      <RSelect
                        name="clientSucor"
                        options={clientOption}
                        defaultValue={{ value: "all", label: "All" }}
                        onChange={handleFilter}
                      />
                    </DataTableRow>
                    <DataTableRow>
                      <div className="form-control-wrap">
                        <input type="text" className="form-control" name="salesSucor" onChange={handleFilter} />
                      </div>
                    </DataTableRow>
                    <DataTableRow>
                      <div className="form-control-wrap">
                        <RSelect
                          name="updatedAt"
                          options={clientSort}
                          defaultValue={{ value: "None", label: "None" }}
                          value={sortName === "contact.updatedAt" ? sortValElm : { value: "None", label: "None" }}
                          onChange={handleSort}
                        />
                      </div>
                    </DataTableRow>
                    <DataTableRow></DataTableRow>
                    <DataTableRow>
                      <div className="form-control-wrap">
                        <RSelect
                          name="createdAt"
                          options={clientSort}
                          defaultValue={{ value: "None", label: "None" }}
                          value={sortName === "contact.createdAt" ? sortValElm : { value: "None", label: "None" }}
                          onChange={handleSort}
                        />
                      </div>
                    </DataTableRow>
                    <DataTableRow></DataTableRow>
                  </DataTableHead>
                  {/*Head*/}
                  {data.length > 0
                    ? data.map((item, index) => {
                        const createdBy =
                          item.createdBy !== null ? item.createdBy.firstName + " " + item.createdBy.lastName : "-";
                        const updatedBy =
                          item.updatedBy !== null
                            ? item.updatedBy.firstName + " " + item.updatedBy.lastName
                            : createdBy;
                        const email1 = item.emails.length > 0 ? item.emails[0].email : "-";
                        const email2 = item.emails.length > 1 ? item.emails[1].email : "-";
                        const phone1 =
                          item.phones.length > 0
                            ? item.phones[0].phone.includes("+")
                              ? item.phones[0].phone
                              : "+" + item.phones[0].phone
                            : "-";
                        const phone2 =
                          item.phones.length > 1
                            ? +item.phones[1].phone.includes("+")
                              ? item.phones[1].phone
                              : "+" + item.phones[1].phone
                            : "-";
                        return (
                          <DataTableItem key={item.id}>
                            <DataTableRow noWrap>
                              <span>{!!item.birthday ? moment(item.birthday).format("MMM DD, YYYY") : "-"}</span>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <span>{phone1}</span>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <span>{phone2}</span>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <span>{email1}</span>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <span>{email2}</span>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <span>{item.clientAstro != null ? item.clientAstro : "-"}</span>
                            </DataTableRow>
                            <DataTableRow>
                              <span>{item.clientAclub != null ? item.clientAclub : "-"}</span>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <span>{item.salesAclub != null ? item.salesAclub : "-"}</span>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <span>{item.clientType != null ? item.clientType : "-"}</span>
                            </DataTableRow>
                            <DataTableRow>
                              {item.membership
                                ? item.membership?.map((membership) => {
                                    return (
                                      <Badge color="primary" pill key={membership}>
                                        {membership}
                                      </Badge>
                                    );
                                  })
                                : "-"}
                            </DataTableRow>
                            <DataTableRow>
                              <span>{item.clientAshop != null ? item.clientAshop : "-"}</span>
                            </DataTableRow>
                            <DataTableRow ref={addToRefs}>
                              <span>{item.productAshop != null ? item.productAshop : "-"}</span>
                            </DataTableRow>
                            <DataTableRow>
                              <span>{item.clientCat != null ? item.clientCat : "-"}</span>
                            </DataTableRow>
                            <DataTableRow>
                              <span>{item.clientOrbi != null ? item.clientOrbi : "-"}</span>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <span>{item.salesOrbi != null ? item.salesOrbi : "-"}</span>
                            </DataTableRow>
                            <DataTableRow>
                              <span>{item.clientUob != null ? item.clientUob : "-"}</span>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <span>{item.salesUob != null ? item.salesUob : "-"}</span>
                            </DataTableRow>
                            <DataTableRow>
                              <span>{item.clientSucor != null ? item.clientSucor : "-"}</span>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <span>{item.salesSucor != null ? item.salesSucor : "-"}</span>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <span>{moment(item.updatedAt).format("MMM DD, YYYY, HH:mm")}</span>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <span>{updatedBy}</span>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <span>{moment(item.createdAt).format("MMM DD, YYYY, HH:mm")}</span>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <span>{createdBy}</span>
                            </DataTableRow>
                          </DataTableItem>
                        );
                      })
                    : null}
                </DataTableBody>
              </Col>
            </Row>

            <div className="card-inner">
              {data.length > 0 ? (
                <PaginationComponent
                  itemPerPage={metaData.take}
                  totalItems={metaData.itemCount}
                  paginate={paginate}
                  currentPage={metaData.page}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )}
            </div>
          </DataTable>
        </Block>
        {modalDetail ? (
          <ContactDetail
            modalDetail={modalDetail}
            modalToggle={() => setModalDetail(false)}
            cookies={cookies}
            clientOption={clientOption}
            membershipOption={membershipOption}
            contactData={detailData}
            roleFunctions={roleFunctions}
          />
        ) : null}
        {modalCreate ? (
          <ContactCreate
            roleFunctions={roleFunctions}
            cookies={cookies}
            clientOption={clientOption}
            membershipOption={membershipOption}
            modalCreate={modalCreate}
            modalToggle={() => setModalCerate(false)}
            refreshContacts={refreshContacts}
            pageNumber={metaData.page}
          />
        ) : null}
        <ContactUpload
          modalUploadCSV={modalUploadCSV}
          setModalUploadCSV={setModalUploadCSV}
          servicePutContact={servicePutContact}
          serviceGetContactByPhone={serviceGetContactByPhone}
          token={cookies.SIDikey}
          refreshContacts={refreshContacts}
        />
        <ContactSync
          modalSync={modalSync}
          setModalSync={setModalSync}
          servicePutContact={servicePutContact}
          serviceGetContactByPhone={serviceGetContactByPhone}
          token={cookies.SIDikey}
          refreshContacts={refreshContacts}
          selectedSyncList={selectedSyncList}
          cookies={cookies}
        />
      </Content>
    </React.Fragment>
  );
};
export default Contact;
