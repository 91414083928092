// chat, ticket, user, campaign, quickReply, tag, department, contact
export const DefaultRoleFunctions = {
    chat:{
        'SUPER ADMIN':['Access_All_Dept','Read', 'Create', 'Update', 'Delete', 'Upload', 'Assign'],
        'HEAD':['Access_Own_Dept','Read', 'Create', 'Update', 'Delete', 'Upload', 'Assign'],
        'ADMIN':['Access_Own_Dept','Read', 'Create', 'Update', 'Delete', 'Upload', 'Assign'],
        'SALES':['Access_Own','Read', 'Create', 'Update', 'Delete', 'Upload', 'Chat','Assign'],
        'CS':['Access_Own_Dept','Read', 'Create', 'Update', 'Delete', 'Upload', 'Chat', 'Assign'],
        'AUDITOR':['Read'],
        'SYSTEM ADMIN':['Access_All_Dept','Read']
    },
    ticket:{
        'SUPER ADMIN':['Access_All_Dept','Read', 'Create', 'Update', 'Delete', 'Upload', 'Chat', 'Assign','Download'],
        'HEAD':['Access_Own_Dept','Read', 'Create', 'Update', 'Delete', 'Upload', 'Chat', 'Assign','Download'],
        'ADMIN':['Access_Own_Dept','Read'],
        'SALES':['Access_Own','Read'],
        'CS':['Access_Own_Dept','Read'],
        'AUDITOR':['Read'],
        'SYSTEM ADMIN':['Access_All_Dept','Read']
    },
    user:{
        'SUPER ADMIN':['Access_All_Dept','Read', 'Create', 'Update', 'Delete', 'Upload', 'Chat', 'Assign'],
        'HEAD':['Access_Own_Dept','Read', 'Create', 'Update', 'Delete', 'Upload', 'Chat', 'Assign'],
        'ADMIN':['Access_Own_Dept','Read', 'Create', 'Update', 'Delete', 'Upload', 'Chat', 'Assign'],
        'CS':['Access_Own_Dept','Read'],
        'SALES':['Access_Own_Dept','Read'],
        'AUDITOR':['Read'],
        'SYSTEM ADMIN':['Access_All_Dept','Read', 'Create', 'Update', 'Delete', 'Upload', 'Chat', 'Assign'],
    },
    campaign:{
        'SUPER ADMIN':['Access_All_Dept','Read','Create','Update','Delete','Upload','Chat','Assign'],
        'HEAD':['Access_Own_Dept','Read','Create','Update','Delete','Upload','Chat','Assign'],
        'ADMIN':['Access_Own_Dept','Read','Create','Update','Delete','Upload','Chat','Assign'],
        'CS':['Access_Own','Read'],
        'SALES':['Access_Own_Dept','Read'],
        'AUDITOR':['Read'],
        'SYSTEM ADMIN':['Access_All_Dept','Read'],
    },
    quickReply:{
        'SUPER ADMIN':['Access_All_Dept','Read','Create','Update','Delete','Upload','Chat','Assign'],
        'HEAD':['Access_Own_Dept','Read','Create','Update','Delete','Upload','Chat','Assign'],
        'ADMIN':['Access_Own_Dept','Read','Create','Update','Delete','Upload','Chat','Assign'],
        'CS':['Access_Own_Dept','Read','Create'],
        'SALES':['Access_Own_Dept','Read','Create'],
        'AUDITOR':['Read'],
        'SYSTEM ADMIN':['Access_All_Dept','Read']
    },
    tag:{
        'SUPER ADMIN':['Access_All_Dept','Read','Create','Update','Delete','Upload','Chat','Assign'],
        'HEAD':['Access_Own_Dept','Read','Create','Update','Delete','Upload','Chat','Assign'],
        'ADMIN':['Access_Own_Dept','Read','Create','Update','Delete','Upload','Chat','Assign'],
        'CS':['Access_Own_Dept','Read','Create'],
        'SALES':['Access_Own_Dept','Read','Create'],
        'AUDITOR':['Read'],
        'SYSTEM ADMIN':['Access_All_Dept','Read']
    },
    department:{
        'SUPER ADMIN':['Access_All_Dept','Read','Create','Update','Delete','Upload','Chat','Assign'],
        'HEAD':[],
        'ADMIN':[],
        'CS':[],
        'SALES':[],
        'AUDITOR':['Read'],
        'SYSTEM ADMIN':['Access_All_Dept','Read','Create','Update','Delete','Upload','Chat','Assign']
    },
    contact:{
        'SUPER ADMIN':['Access_All_Dept','Read','Create','Update','Delete','Upload','Chat','Assign','Download'],
        'HEAD':['Access_Own_Dept','Read','Create','Update','Delete','Upload','Chat','Assign','Download'],
        'ADMIN':['Access_Own_Dept','Read','Create','Update','Delete','Upload','Chat','Assign'],
        'CS':['Access_Own_Dept','Read'],
        'SALES':['Access_Own_Dept','Read'],
        'AUDITOR':['Read'],
        'SYSTEM ADMIN':['Access_All_Dept','Read']
    },
    channel:{
        'SUPER ADMIN':['Access_All_Dept','Read','Create','Update','Delete','Upload','Chat','Assign'],
        'HEAD':[],
        'ADMIN':[],
        'CS':[],
        'SALES':[],
        'AUDITOR':['Read'],
        'SYSTEM ADMIN':['Access_All_Dept','Read']
    },
}