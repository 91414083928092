export const tagList = [
  {
    text: "A-Club",
    color: "#0364FF",
    chartConfiguration: {
      labels: ["", "", ""],
      dataUnit: "BTC",
      legend: false,
      datasets: [
        {
          borderColor: "transparent",
          backgroundColor: ["#0364FF", "#d9e5f7"],
          data: [200000000, 40000000],
        },
      ],
      centerText: "83%",
      total: 240000000,
    },
  },
  {
    text: "Orbi",
    color: "#FFDA05",
    chartConfiguration: {
      labels: ["", "", ""],
      dataUnit: "BTC",
      legend: false,
      datasets: [
        {
          borderColor: "transparent",
          backgroundColor: ["#FFDA05", "#d9e5f7"],
          data: [200000000, 40000000],
        },
      ],
      centerText: "83%",
      total: 240000000,
    },
  },
  {
    text: "A-Shop",
    color: "#C49104",
    chartConfiguration: {
      labels: ["", "", ""],
      dataUnit: "BTC",
      legend: false,
      datasets: [
        {
          borderColor: "transparent",
          backgroundColor: ["#C49104", "#d9e5f7"],
          data: [200000000, 40000000],
        },
      ],
      centerText: "83%",
      total: 240000000,
    },
  },
  {
    text: "CAT",
    color: "#ADADAD",
    chartConfiguration: {
      labels: ["", "", ""],
      dataUnit: "BTC",
      legend: false,
      datasets: [
        {
          borderColor: "transparent",
          backgroundColor: ["#ADADAD", "#d9e5f7"],
          data: [200000000, 40000000],
        },
      ],
      centerText: "83%",
      total: 240000000,
    },
  },
  {
    text: "Security",
    color: "#2B9C51",
    chartConfiguration: {
      labels: ["", "", ""],
      dataUnit: "BTC",
      legend: false,
      datasets: [
        {
          borderColor: "transparent",
          backgroundColor: ["#2B9C51", "#d9e5f7"],
          data: [200000000, 40000000],
        },
      ],
      centerText: "83%",
      total: 240000000,
    },
  },
];

export const ticketSystemData = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  stacked: true,
  dataUnit: "USD",
  datasets: [
    {
      label: tagList[0].text,
      backgroundColor: tagList[0].color,
      barPercentage: 0.5,
      categoryPercentage: 0.5,
      data: [110, 80, 125, 55, 95, 75, 90, 110, 80, 125, 55, 95],
    },
    {
      label: tagList[1].text,
      backgroundColor: tagList[1].color,
      barPercentage: 0.5,
      categoryPercentage: 0.5,
      data: [75, 90, 110, 80, 125, 55, 95, 75, 90, 110, 80, 125],
    },
    {
      label: tagList[2].text,
      backgroundColor: tagList[2].color,
      barPercentage: 0.5,
      categoryPercentage: 0.5,
      data: [110, 80, 125, 55, 95, 75, 90, 110, 80, 125, 55, 95],
    },
    {
      label: tagList[3].text,
      backgroundColor: tagList[3].color,
      barPercentage: 0.5,
      categoryPercentage: 0.5,
      data: [75, 90, 110, 80, 125, 55, 95, 75, 90, 110, 80, 125],
    },
    {
      label: tagList[4].text,
      backgroundColor: 'linear-gradient(90deg, rgba(0,255,0,1) 0%, rgba(20,0,255,1) 35%, rgba(255,0,214,1) 65%, RGBA(255, 0, 0, 1) 100%)',
      barPercentage: 0.5,
      categoryPercentage: 0.5,
      data: [110, 80, 125, 55, 95, 75, 90, 110, 80, 125, 55, 95],
    },
  ],
};

export const trafficSourceList = {
  labels: ["Instagram", "Telegram", "Whatsapp", "Youtube"],
  dataUnit: "People",
  legend: true,
  datasets: [
    {
      borderColor: "#fff",
      backgroundColor: ["#4461F2", "#4ECE10", "#FEC446", "#F44336"],
      data: [10, 20, 30, 40],
    },
  ],
};

export const LeadConvertionRateData = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  dataUnit: "BTC",
  lineTension: 0.4,
  legend: false,
  categoryPercentage: 0.9,
  barPercentage: 0.6,
  datasets: [
    {
      label: "Total Send",
      backgroundColor: "white",
      pointBorderWidth: 2,
      borderColor: "#798bff",
      fill: false,
      categoryPercentage: 0.9,
      barPercentage: 0.6,
      data: [80, 54, 105, 120, 82, 85, 60, 80, 54, 105, 120, 82],
    },
  ],
};

export const ChatPerDayData = {
  labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  dataUnit: "BTC",
  lineTension: 0.4,
  legend: false,
  categoryPercentage: 0.9,
  barPercentage: 0.6,
  datasets: [
    {
      label: "Total Send",
      backgroundColor: "white",
      pointBorderWidth: 2,
      borderColor: "#798bff",
      fill: false,
      categoryPercentage: 0.9,
      barPercentage: 0.6,
      data: [8, 24, 15, 26, 11, 4, 0],
    },
  ],
};

export const CampaignOverviewDataLine = {
  labels: ["22 Nov", "23 Nov", "24 Nov", "25 Nov", "26 Nov", "27 Nov", "28 Nov", "29 Nov", "30 Nov"],
  dataUnit: "BTC",
  lineTension: 0.4,
  categoryPercentage: 0.9,
  barPercentage: 0.6,
  datasets: [
    {
      label: "Total Send",
      backgroundColor: "white",
      pointBorderWidth: 2,
      borderColor: "#33A02C",
      fill: false,
      categoryPercentage: 0.9,
      barPercentage: 0.6,
      data: [6, 8, 14, 18, 22, 23, 27, 31, 35, 38, 40],
    },
    {
      label: "Total Closing",
      backgroundColor: "white",
      pointBorderWidth: 2,
      borderColor: "#FF7F00",
      fill: false,
      categoryPercentage: 0.9,
      barPercentage: 0.6,
      data: [5, 7, 13, 17, 18, 23, 24, 28, 31, 33, 36],
    },
  ],
};

export const CampaignOverviewDataBar = {
  labels: ["22 Nov", "23 Nov", "24 Nov", "25 Nov", "26 Nov", "27 Nov", "28 Nov", "29 Nov", "30 Nov"],
  dataUnit: "BTC",
  stacked: true,
  datasets: [
    {
      label: "Total Send",
      backgroundColor: "#33A02C",
      categoryPercentage: 0.5,
      barPercentage: 0.5,
      data: [6, 8, 14, 18, 22, 23, 27, 31, 35, 38, 40],
    },
    {
      label: "Total Closing",
      backgroundColor: "#FF7F00",
      categoryPercentage: 0.5,
      barPercentage: 0.5,
      data: [5, 7, 13, 17, 18, 23, 24, 28, 31, 33, 36],
    },
  ],
};
