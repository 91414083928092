import { Icon } from "components/Component";
import { useState, useRef, useEffect, useContext } from "react";
import SimpleBar from "simplebar-react";
import { useDebounce } from "use-debounce";
import { dateProcessing } from "./ChatPartials";
import { ReplyContext } from "contexts/replyContext";

const ChatSearchSidebar = ({ searchSidebar, messages, cookies, close }) => {
  const [sideFilteredMessages, setSideFilteredMessages] = useState([]);
  const [inputText, setInputText] = useState("");
  const [searchText] = useDebounce(inputText, 1000);
  const searchBody = useRef(null);
  const searchInput = useRef(null);
  const { value, value2 } = useContext(ReplyContext);
  const [contextMessage, setContextMessage] = value2;

  const inputOnChange = (e) => {
    setInputText(e.target.value);
  };

  const searchMessage = (text) => {
    const tempMessages = [];
    messages.map((message) => {
      if (message?.text?.toLowerCase().includes(text.toLowerCase())) {
        tempMessages.push(message);
      }
    });
    if (tempMessages.length > 0) {
      tempMessages.sort((a, b) => b.timestamp - a.timestamp);
      setSideFilteredMessages(tempMessages);
    }
  };

  const boldText = (text) => {
    const regex = new RegExp(searchText, "gi");
    return {
      __html: text.replace(regex, function (x) {
        return x.bold();
      }),
    };
  };

  const onClickedMessage = (e, message) => {
    e.preventDefault();
    setContextMessage(message);
    close();
  };

  useEffect(() => {
    if (inputText !== "") {
      searchMessage(searchText);
    } else {
      setSideFilteredMessages([]);
    }
  }, [searchText, setSideFilteredMessages]);

  useEffect(() => {
    setInputText("");
    setSideFilteredMessages([]);
  }, [searchSidebar]);
  return (
    <SimpleBar className={`nk-chat-profile ${searchSidebar ? "visible" : ""}`}>
      <div className="d-flex align-items-center search-message-header">
        <Icon name="cross" style={{ fontSize: "12pt", cursor: "pointer" }} onClick={close} />
        <span className="search-message-title ml-2">Search Messages</span>
      </div>
      <div className="search-message-body" ref={searchBody}>
        <div className="search-message-input" ref={searchInput}>
          <div className="form-group">
            <div className="form-control-wrap">
              <div className="form-icon form-icon-left">
                <Icon name="search" />
              </div>
              <input
                value={inputText}
                className="form-control"
                type="text"
                onChange={(e) => inputOnChange(e)}
                placeholder="search message..."
              />
            </div>
          </div>
        </div>
        <SimpleBar
          style={
            {
              // maxHeight:
              //   searchBody.current && searchInput.current
              //     ? searchBody.current.offsetHeight - searchInput.current.offsetHeight
              //     : "",
            }
          }
        >
          {sideFilteredMessages.length > 0 ? (
            sideFilteredMessages.map((message) => {
              return (
                <div
                  className="search-message-content border-bottom"
                  style={{ cursor: "pointer" }}
                  key={message.id}
                  onClick={(e) => {
                    onClickedMessage(e, message);
                  }}
                >
                  <div style={{ fontSize: 11 }}>{dateProcessing(message.timestamp)}</div>
                  <div className="">
                    <Icon
                      name={`${
                        message.status === "READ"
                          ? "check-circle-fill"
                          : message.status === "DELIVERED"
                          ? "check-circle"
                          : message.status === "SENT"
                          ? "check"
                          : ""
                      }`}
                    ></Icon>
                    <span dangerouslySetInnerHTML={boldText(message.text)}></span>
                  </div>
                </div>
              );
            })
          ) : searchText !== "" ? (
            <p className="text-center">No Message Found</p>
          ) : null}
        </SimpleBar>
      </div>
    </SimpleBar>
  );
};

export default ChatSearchSidebar;
