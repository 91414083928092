import axios from "axios";
import { axiosPrivate } from "../api/axiosCore";

export const serviceGetHistoryMessages = async (token, id) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const response = await axiosPrivate.get("api/messages?order=ASC&limit=20&chatId=" + id);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetHistoryMessagesAfterCursor = async (token, id, afterCursor, includeCursor) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const response = await axiosPrivate.get(
      "api/messages?order=ASC&limit=20&chatId=" + id + "&afterCursor=" + afterCursor + "&includeCursor=" + includeCursor
    );

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceDownloadFileMessage = async (token, id) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.get("api/messages/download-media/" + id, { responseType: "blob" });

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};


export const serviceGetMessages = async (token, id) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const response = await axiosPrivate.get("api/messages?limit=30&chatId=" + id);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetMessagesAfterCursor = async (token, id, afterCursor, includeCursor) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const response = await axiosPrivate.get(
      "api/messages?order=DESC&limit=30&chatId=" + id + "&afterCursor=" + afterCursor + "&includeCursor=" + includeCursor
    );

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceSendMessageRotator = async (token, msg) => {
  // send message to BACKEND
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Content-Type"] = "multipart/form-data";
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const response = await axiosPrivate.post("api/messages", msg);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceSendMessage = async (token, msg) => {
  // send message to BACKEND
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Content-Type"] = "multipart/form-data";
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    msg["media"] = null;
    const response = await axiosPrivate.post("api/messages/with-media", msg);
    console.log('gagal nih', response);
    if(response?.statusCode === 422){
      msg['fromMe'] = "true";
      const resp = await axiosPrivate.post("api/messages/with-media", msg);
      console.log('gimana nih', resp);
      return resp;
    }
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceSendMessageDocument = async (token, msg, document) => {
  // send message to BACKEND
  try {

    var bodyFormData = new FormData();
    for (const key in msg) {
      bodyFormData.append(key, msg[key]);
    }
    bodyFormData.append("media", document);

    const resp = axios.post(process.env.REACT_APP_BASE_URL + "api/messages/with-media", bodyFormData,{
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
        'AccessControl-Allow-Origin' : 'http://localhost:3000',
      }});

    return resp;

  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceSendMessageImage = async (token, msg, image) => {
  // send message to BACKEND
  try {
    var bodyFormData = new FormData();
    for (const key in msg) {
      bodyFormData.append(key, msg[key]);
    }
    bodyFormData.append("media", image);

    const resp = axios.post(process.env.REACT_APP_BASE_URL+"api/messages/with-media", bodyFormData,{
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
        'AccessControl-Allow-Origin' : 'http://localhost:3000',
      }});

    return resp;
    
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceSendMessageVideo = async (token, msg, video) => {
  // send message to BACKEND
  try {
    var bodyFormData = new FormData();
    for (const key in msg) {
      bodyFormData.append(key, msg[key]);
    }
    bodyFormData.append("media", video);

    const resp = axios.post(process.env.REACT_APP_BASE_URL+"api/messages/with-media", bodyFormData,{
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
        'AccessControl-Allow-Origin' : 'http://localhost:3000',
      }});

    return resp;
    
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceSendMessageFile = async (token, file) => {
  // send message to BACKEND
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    console.log("sebelum kirim", file);
    const response = await axiosPrivate.post("api/messages", file);
    console.log(response);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetFilterMessage = async (token, role, query, userId) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    let response = null;
    if(role === 'SALES' || role === 'CS'){
      response = await axiosPrivate.get("api/chat-searches?userId="+userId+"&limit=30&q=" + query);
    }else {
      response = await axiosPrivate.get("api/chat-searches?&limit=30&q=" + query);
    }

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetFilterMessageAfterCursor = async (token, role, query, userId,page) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    let response = null;
    if (role === 'SUPER ADMIN'){
      response = await axiosPrivate.get("api/chat-searches?page="+page+"&limit=30&q=" + query);
    }else{
      response = await axiosPrivate.get("api/chat-searches?userId="+userId+"&limit=30&q=" + query);
    }

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};
