import Content from "layout/content/Content";
import Head from "../../layout/head/Head";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  Icon,
  Row,
  Col,
  DataTableItem,
  TooltipComponent,
  PaginationComponent,
} from "components/Component";
import moment from "moment";
import { Button, Card, CardBody, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { useCookies } from "react-cookie";
import { DefaultRoleFunctions } from "data/DefaultRoleFunctions";
import { ChatbotContext } from "./ChatbotContext";
import { Link } from "react-router-dom";
import {
  serviceGetChatbots,
  serviceGetChatbot,
  serviceDeleteChatbot,
  serviceGetChatbotsByQuery,
} from "services/chatbot";
import ModalAddButton from "./ModalAddButton";
import ModalAddList from "./ModalAddList";
import Swal from "sweetalert2";
import { truncate } from "utils/Utils";
import he from "he";

const Chatbot = () => {
  const nonFixedRef = useRef([]);
  nonFixedRef.current = [];
  const [sm, updateSm] = useState(false);
  const [tablesm, updateTableSm] = useState(false);
  const [nonFixedHeight, setNonFixedHeight] = useState([]);

  const { contextData } = useContext(ChatbotContext);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [metaPage, setMetaPage] = useState({
    itemCount: 0,
    page: 0,
    take: 0,
    pageCount: 0,
    hasPreviousPage: false,
    hasNextPage: false,
  });

  const [cookies] = useCookies(["user"]);
  let roleFunctions = JSON.parse(localStorage.getItem("permission"))["chat-rules"].access || [];
  const [search, setSearch] = useState("");
  const [openItems, setOpenItems] = useState([]);
  const [isModalAddButton, setIsModalAddButton] = useState(false);
  const [isModalAddList, setIsModalAddList] = useState(false);
  const [ruleParentId, setRuleParentId] = useState("");
  const [selectedDeptId, setSelectedDeptId] = useState();
  const [selectedChannel, setSelectedChannel] = useState();
  const toggleItem = (item, index) => {
    if (openItems.includes(index)) {
      setOpenItems(openItems.filter((item) => item !== index));
      handleDropdownClick(
        item?.headerType ? item.id : item.ruleButtonId,
        index,
        item?.level || 0,
        item?.headerType ? true : false,
        "close"
      );
    } else {
      setOpenItems([...openItems, index]);
      handleDropdownClick(
        item?.headerType ? item.id : item.ruleButtonId,
        index,
        item?.level || 0,
        item?.headerType ? true : false,
        "open"
      );
    }
  };

  const refreshHeight = () => {
    if (nonFixedRef.current) {
      const tempHeight = [];
      nonFixedRef.current.map((ref) => {
        const height = ref.offsetHeight;
        tempHeight.push(height);
        return height;
      });
      setNonFixedHeight(tempHeight);
    }
  };

  useEffect(() => {
    refreshHeight();
    refreshData(1);
    setOpenItems([]);
  }, []);

  const addToRefs = (el) => {
    if (el && !nonFixedRef.current.includes(el)) {
      nonFixedRef.current.push(el);
    }
  };

  const refreshData = async (page) => {
    let depIDs = "All";
    if (!roleFunctions.includes("Access_All_Dept")) {
      depIDs = cookies.departmentIds;
    }
    const resp = await serviceGetChatbots(cookies.SIDikey, page, depIDs);
    if (resp.status === 200) {
      setMetaPage(resp.data.meta);
      setData(resp.data.data);
      // setOpenItems(resp.data.data);
    }
  };

  const refreshDataByQuery = async (page, query) => {
    const resp = await serviceGetChatbotsByQuery(cookies.SIDikey, page, query);
    if (resp.status === 200) {
      setMetaPage(resp.data.meta);
      setData(resp.data.data);
      // setOpenItems(resp.data.data);
    }
  };

  useEffect(() => {
    if (search !== "") {
      refreshDataByQuery(1, search);
    } else {
      refreshData(1);
      setSearch("");
    }
  }, [search]);

  const handleDropdownClick = async (id, index, level, isRule, type) => {
    if (type === "open") {
      const resp = await serviceGetChatbot(cookies.SIDikey, id);
      if (resp.status === 200) {
        const dt = isRule ? [...resp.data.buttonRules] : [resp.data];
        const dataMapped = dt.map((item) => ({
          ...item, // Spread existing properties of the object
          level: level + 1, // Add the new key-value pair
          parentIndex: index,
        }));
        const tempData = [...data];
        tempData.splice(index + 1, 0, ...dataMapped);
        setData(tempData);
        // if(resp.data.id === id)
      }
    } else {
      const getChildData = data.filter((item) => item.parentIndex !== index);
      setData(getChildData);
    }
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure for delete ?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const responseDelete = await serviceDeleteChatbot(cookies.SIDikey, id);
          if (/20+/g.test(responseDelete.status)) {
            Swal.fire("Berhasil Delete Chatbot Rule", "Delete Chatbot Rule", "success");
            refreshData(1);
          } else {
            Swal.fire("Gagal Delete Chatbot Rule", responseDelete.data?.message[0]?.message, "error");
          }
        } catch (error) {
          Swal.fire("Gagal Delete Chatbot Rule", "Delete Chatbot Rule", "error");
        }
      }
    });
    return;
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    refreshData(pageNumber);
  };

  return (
    <React.Fragment>
      <Head title="Chatbot Rule"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Chatbot Rule
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
          <Card className="card-bordered my-4 card-bluesky">
            <CardBody className="card-bluesky-body">
              <strong>Info:</strong>
              <p>Untuk membuat template respon chatbot kepada customer</p>
            </CardBody>
          </Card>

          <Block className="d-flex justify-content-end">
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                {!roleFunctions.includes("create") || (
                  <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                    <ul className="nk-block-tools g-3">
                      <li className="nk-block-tools-opt">
                        <Link to={`${process.env.PUBLIC_URL + "chatbot/add"}`}>
                          <Button color="primary" className="btn">
                            <i className="ni ni-plus"></i>
                            <span>Tambah Rule</span>
                          </Button>
                        </Link>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </BlockHeadContent>
          </Block>
        </BlockHead>

        <Block>
          <div className="card-inner position-relative card-tools-toggle">
            <div className="card-title-group">
              <div className="form-control-wrap w-25">
                <div className="form-icon form-icon-left">
                  <Icon name="search"></Icon>
                </div>
                <input
                  onChange={(e) => setSearch(e.target.value)}
                  type="text"
                  className="form-control w-100"
                  id="default-04"
                  placeholder="Cari rule"
                />
              </div>
            </div>
          </div>
          <DataTable className="card-strecth" style={{ borderRadius: "10px" }}>
            <Row>
              <Col xs="6" sm="5" md="5" lg="12" className="fixed-column ">
                <DataTableBody compact>
                  <DataTableHead style={{ backgroundColor: "#F5F6FB" }}>
                    <DataTableRow noWrap>
                      <span className="sub-text"></span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Rule Name</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Response</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Channel</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Active Time</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Category</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Trigger</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Type</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Status</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">From</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">To</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Action</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Modify At</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Modify By</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Created At</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Created By</span>
                    </DataTableRow>
                  </DataTableHead>
                  {data.map((item, index) => (
                    <React.Fragment key={index}>
                      {item?.headerType ? (
                        <DataTableItem className="align-items-center parent">
                          {console.log("item", item)}
                          <div
                            className="p-0 h-full d-flex align-items-center flex-column mt-2 test-accordion"
                            style={{ marginLeft: `${(item.level || 0) * 15}px` }}
                          >
                            <Button
                              className="p-1"
                              color="link"
                              onClick={(e) => {
                                e.preventDefault();
                                toggleItem(item, index);
                              }}
                            >
                              {/* {`${(item.level || 0) * 20 }`} */}
                              <Icon name={openItems.includes(index) ? "sort-down" : "caret-right-fill"}></Icon>
                            </Button>
                          </div>
                          <DataTableRow noWrap>
                            <div className="col-md-2 d-flex align-items-center">{item.ruleName}</div>
                          </DataTableRow>
                          {/* <div className="align-items-center"></div> */}
                          <DataTableRow>
                            <div
                              className="col-lg-12 d-flex align-items-center overflow-hidden"
                              style={{ minWidth: "300px", whiteSpace: "normal" }}
                            >
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: truncate(he.decode(he.decode(he.decode(item.ruleBody))), 70),
                                }}
                              ></span>
                            </div>
                          </DataTableRow>
                          <DataTableRow noWrap>
                            <div className="d-flex align-items-center">{item?.channel?.name}</div>
                          </DataTableRow>
                          <DataTableRow noWrap>
                            <div className="d-flex align-items-center">
                              {item.fromTime} - {item.toTime}
                            </div>
                          </DataTableRow>
                          <DataTableRow noWrap>
                            <div className="d-flex align-items-center">{item.ruleButtonType}</div>
                          </DataTableRow>
                          <DataTableRow noWrap>
                            <div className="d-flex align-items-center">{item?.chatTrigger?.triggerValue}</div>
                          </DataTableRow>
                          <DataTableRow noWrap>
                            <div className="d-flex align-items-center">{item?.chatTrigger?.triggerType}</div>
                          </DataTableRow>
                          <DataTableRow noWrap>
                            <div className="d-flex align-items-center">{item.status === 1 ? "ON" : "OFF"}</div>
                          </DataTableRow>
                          <DataTableRow noWrap>
                            <div className="d-flex align-items-center">{item?.fromTime?.slice(0, -3) || "00:00"}</div>
                          </DataTableRow>
                          <DataTableRow noWrap>
                            <div className="d-flex align-items-center">{item?.toTime?.slice(0, -3) || "24:00"}</div>
                          </DataTableRow>
                          <DataTableRow noWrap>
                            <ul className="nk-tb-actions table-action">
                              <li href="#add">
                                <UncontrolledDropdown>
                                  <DropdownToggle tag="div" className="btn btn-icon btn-sm btn-trigger">
                                    <TooltipComponent
                                      tag="a"
                                      containerClassName="btn btn-trigger btn-icon"
                                      id={"add" + item.id}
                                      icon="plus-sm"
                                      direction="top"
                                      text="Add"
                                    />
                                    {/* <Icon name="plus-sm"></Icon> */}
                                  </DropdownToggle>
                                  <DropdownMenu left>
                                    <ul>
                                      {item.ruleButtonType === "QUICK_REPLY" && (
                                        <li onClick={() => console.log("Add Button")}>
                                          <DropdownItem
                                            tag="div"
                                            href="#dropdown"
                                            onClick={(ev) => {
                                              setRuleParentId(item.id);
                                              setIsModalAddButton(true);
                                              setSelectedDeptId(item.departmentId);
                                              setSelectedChannel(item.channel);
                                            }}
                                          >
                                            <span>+ Button</span>
                                          </DropdownItem>
                                        </li>
                                      )}
                                      {item.ruleButtonType === "LIST" && (
                                        <li onClick={() => console.log("Add List")}>
                                          <DropdownItem
                                            tag="div"
                                            href="#dropdown"
                                            onClick={(ev) => {
                                              setRuleParentId(item.id);
                                              setIsModalAddList(item);
                                              setSelectedDeptId(item.departmentId);
                                              setSelectedChannel(item.channel);
                                            }}
                                          >
                                            <span>+ List</span>
                                          </DropdownItem>
                                        </li>
                                      )}
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </li>
                              {item?.headerType && (
                                <li className="nk-tb-action">
                                  <Link to={`${process.env.PUBLIC_URL + "chatbot/edit/" + item.id}`}>
                                    <TooltipComponent
                                      tag="a"
                                      containerClassName="btn btn-trigger btn-icon"
                                      id={"edit" + item.id}
                                      icon="edit-alt-fill"
                                      direction="top"
                                      text="Edit"
                                    />
                                  </Link>
                                </li>
                              )}
                              <li className="nk-tb-action" onClick={(e) => handleDelete(item.id)}>
                                <TooltipComponent
                                  tag="a"
                                  containerClassName="btn btn-trigger btn-icon"
                                  id={"delete" + item.id}
                                  icon="trash-fill"
                                  direction="top"
                                  text="Hapus"
                                />
                              </li>
                            </ul>
                          </DataTableRow>
                          <DataTableRow noWrap>
                            <span>{moment(item.updatedAt).format("MMM DD, YYYY, HH:mm")}</span>
                          </DataTableRow>
                          <DataTableRow noWrap>
                            <span>{item.updatedBy?.firstName + item.updatedBy?.lastName || "System"}</span>
                          </DataTableRow>
                          <DataTableRow noWrap>
                            <span>{moment(item.createdAt).format("MMM DD, YYYY, HH:mm")}</span>
                          </DataTableRow>
                          <DataTableRow noWrap>
                            <span>{item.createdBy?.firstName + item.createdBy?.lastName || "System"}</span>
                          </DataTableRow>
                        </DataTableItem>
                      ) : (
                        <>
                          {data?.at(index - 1) && data?.at(index - 1)?.headerType && item.buttonText && (
                            <DataTableItem className="bg-transparent sub-child">
                              <DataTableRow className="bg-putih border-0"></DataTableRow>
                              <DataTableRow noWrap className="bg-light">
                                <span className="sub-text">Text</span>
                              </DataTableRow>
                              <DataTableRow noWrap className="bg-light">
                                <span className="sub-text">Type</span>
                              </DataTableRow>
                              <DataTableRow noWrap className="bg-light">
                                <span className="sub-text">Response</span>
                              </DataTableRow>
                              <DataTableRow noWrap className="bg-light">
                                <span className="sub-text">Category</span>
                              </DataTableRow>
                              <DataTableRow noWrap className="bg-light">
                                <span className="sub-text">Trigger</span>
                              </DataTableRow>
                              <DataTableRow noWrap className="bg-light">
                                <span className="sub-text">Type</span>
                              </DataTableRow>
                              <DataTableRow noWrap className="bg-light">
                                <span className="sub-text">Status</span>
                              </DataTableRow>
                              <DataTableRow noWrap className="bg-light">
                                <span className="sub-text">From</span>
                              </DataTableRow>
                              <DataTableRow noWrap className="bg-light">
                                <span className="sub-text">To</span>
                              </DataTableRow>
                              <DataTableRow noWrap className="bg-light">
                                <span className="sub-text">Action</span>
                              </DataTableRow>
                              <DataTableRow noWrap className="bg-light">
                                <span className="sub-text">Modify At</span>
                              </DataTableRow>
                              <DataTableRow noWrap className="bg-light">
                                <span className="sub-text">Modify By</span>
                              </DataTableRow>
                              <DataTableRow noWrap className="bg-light">
                                <span className="sub-text">Created At</span>
                              </DataTableRow>
                              <DataTableRow noWrap className="bg-light">
                                <span className="sub-text">Created By</span>
                              </DataTableRow>
                            </DataTableItem>
                          )}
                          <DataTableItem className="align-items-center">
                            <div
                              className="p-0 h-full d-flex align-items-center flex-column mt-2"
                              style={{ marginLeft: `${(item.level || 0) * 30}px` }}
                            >
                              <Button
                                className="p-1"
                                color="link"
                                onClick={(e) => {
                                  e.preventDefault();
                                  toggleItem(item, index);
                                }}
                              >
                                {/* {`${(item.level || 0) * 20 }`} */}
                                <Icon name={openItems.includes(index) ? "sort-down" : "caret-right-fill"}></Icon>
                              </Button>
                            </div>
                            <DataTableRow>
                              <div className={`col-md-2 d-flex align-items-center`}>
                                {item.buttonText ? item.buttonText : item.ruleBody}
                              </div>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <div className="col-md-2 d-flex align-items-center">
                                {item.actionType ? item.actionType : item.ruleButtonType}
                              </div>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <div className="col-md-5 d-flex align-items-center">
                                {item.buttonDescription ? item.buttonDescription : item.ruleDescription}
                              </div>
                            </DataTableRow>
                          </DataTableItem>
                        </>
                      )}
                    </React.Fragment>
                  ))}
                </DataTableBody>
              </Col>
            </Row>
            <div className="card-inner">
              {data.length > 0 ? (
                <PaginationComponent
                  itemPerPage={metaPage.take}
                  totalItems={metaPage.itemCount}
                  paginate={paginate}
                  currentPage={metaPage.page}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )}
            </div>
          </DataTable>
        </Block>
        {isModalAddButton && (
          <ModalAddButton
            ruleParentId={ruleParentId}
            setRuleParentId={setRuleParentId}
            isModalAddButton={isModalAddButton}
            setIsModalAddButton={setIsModalAddButton}
            selectedDeptId={selectedDeptId}
            selectedChannel={selectedChannel}
          ></ModalAddButton>
        )}
        {isModalAddList && (
          <ModalAddList
            ruleParentId={ruleParentId}
            setRuleParentId={setRuleParentId}
            isModalAddList={isModalAddList}
            setIsModalAddList={setIsModalAddList}
            selectedDeptId={selectedDeptId}
            selectedChannel={selectedChannel}
          ></ModalAddList>
        )}
      </Content>
    </React.Fragment>
  );
};

export default Chatbot;
