import { axiosPrivate } from "../api/axiosCore";

export const getCountries = async (token) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const basicUrl = `api/countries`
    const response = await axiosPrivate.get(basicUrl);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};