export const astroProducts = 
[
    [
        {
          id: 516,
          product_name: "Crypto Gold",
          product_price: "3000000.00",
          is_active: null,
          created_at: "2022-08-03T10:37:38.000000Z",
          updated_at: "2022-08-03T10:37:38.000000Z",
        },
        {
          id: 517,
          product_name: "Crypto Platinum",
          product_price: "10000.00",
          is_active: null,
          created_at: "2022-08-03T10:37:39.000000Z",
          updated_at: "2022-08-03T10:37:39.000000Z",
        },
        {
          id: 515,
          product_name: "Crypto Ruby",
          product_price: "3000000.00",
          is_active: null,
          created_at: "2022-08-03T10:37:38.000000Z",
          updated_at: "2022-08-03T10:37:38.000000Z",
        },
        {
          id: 511,
          product_name: "Futures Gold",
          product_price: "9000000.00",
          is_active: null,
          created_at: "2022-08-03T10:37:36.000000Z",
          updated_at: "2022-08-03T10:37:36.000000Z",
        },
        {
          id: 513,
          product_name: "Futures Platinum",
          product_price: "18000000.00",
          is_active: null,
          created_at: "2022-08-03T10:37:37.000000Z",
          updated_at: "2022-08-03T10:37:37.000000Z",
        },
        {
          id: 509,
          product_name: "Futures Ruby",
          product_price: "4500000.00",
          is_active: null,
          created_at: "2022-08-03T10:37:35.000000Z",
          updated_at: "2022-08-03T10:37:35.000000Z",
        },
        {
          id: 519,
          product_name: "Futures Ruby 2 Month",
          product_price: "3000000.00",
          is_active: null,
          created_at: "2022-08-03T10:37:39.000000Z",
          updated_at: "2022-08-03T10:37:39.000000Z",
        },
        {
          id: 510,
          product_name: "Stocks Gold",
          product_price: "9000000.00",
          is_active: null,
          created_at: "2022-08-03T10:37:36.000000Z",
          updated_at: "2022-08-03T10:37:36.000000Z",
        },
        {
          id: 512,
          product_name: "Stocks Platinum",
          product_price: "18000000.00",
          is_active: null,
          created_at: "2022-08-03T10:37:37.000000Z",
          updated_at: "2022-08-03T10:37:37.000000Z",
        },
        {
          id: 508,
          product_name: "Stocks Ruby",
          product_price: "4500000.00",
          is_active: null,
          created_at: "2022-08-03T10:37:35.000000Z",
          updated_at: "2022-08-03T10:37:35.000000Z",
        },
        {
          id: 518,
          product_name: "Stocks Ruby 2 Months",
          product_price: "3000000.00",
          is_active: null,
          created_at: "2022-08-03T10:37:39.000000Z",
          updated_at: "2022-08-03T10:37:39.000000Z",
        },
    ],
    [        
        {
            "id": 522,
            "product_name": "",
            "product_price": "5643.00",
            "is_active": 0,
            "created_at": "2022-08-05T17:53:57.000000Z",
            "updated_at": "2022-08-05T17:53:57.000000Z",
            
        },
        {
            "id": 466,
            "product_name": "3 Tips Menghindari Market Crash",
            "product_price": "89000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:02.000000Z",
            
        },
        {
            "id": 471,
            "product_name": "5 Instant Way To Profitable Trader",
            "product_price": "89000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:02.000000Z",
            
        },
        {
            "id": 520,
            "product_name": "A-CLUB ACADEMY SPECIAL PROMO MERDEKA",
            "product_price": "4500000.00",
            "is_active": 0,
            "created_at": "2022-08-03T13:38:06.000000Z",
            "updated_at": "2023-03-01T02:59:03.000000Z",
            
        },
        {
            "id": 445,
            "product_name": "Astrology Series",
            "product_price": "407000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:01.000000Z",
            
        },
        {
            "id": 444,
            "product_name": "Avoid The Crash Market Package",
            "product_price": "356000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:35.000000Z",
            "updated_at": "2023-03-01T02:59:01.000000Z",
            
        },
        {
            "id": 536,
            "product_name": "BEST OFFER - Couple Deals Investment | WEFIS 2023",
            "product_price": "600000.00",
            "is_active": 0,
            "created_at": "2022-08-25T13:51:01.000000Z",
            "updated_at": "2023-03-01T02:59:03.000000Z",
            
        },
        {
            "id": 467,
            "product_name": "Basic Candlestick For A Better Trading",
            "product_price": "129000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:02.000000Z",
            
        },
        {
            "id": 446,
            "product_name": "Basic Elliot Wave All Series",
            "product_price": "600000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:01.000000Z",
            
        },
        {
            "id": 482,
            "product_name": "Basic Elliot Wave Part 1",
            "product_price": "300000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:02.000000Z",
            
        },
        {
            "id": 481,
            "product_name": "Basic Elliot Wave Part 2",
            "product_price": "300000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:02.000000Z",
            
        },
        {
            "id": 456,
            "product_name": "Basic Fundamental Analysis",
            "product_price": "149000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:01.000000Z",
            
        },
        {
            "id": 488,
            "product_name": "Basic Of Moon Trading",
            "product_price": "149000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:02.000000Z",
            
        },
        {
            "id": 436,
            "product_name": "Belajar Trading Saham",
            "product_price": "299000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:35.000000Z",
            "updated_at": "2023-03-01T02:59:01.000000Z",
            
        },
        {
            "id": 457,
            "product_name": "Big Trend Big Profits",
            "product_price": "300000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:01.000000Z",
            
        },
        {
            "id": 437,
            "product_name": "Bundling Belajar Pemula",
            "product_price": "5299000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:35.000000Z",
            "updated_at": "2023-03-01T02:59:01.000000Z",
            
        },
        {
            "id": 442,
            "product_name": "Bundling Special Digging The Gold",
            "product_price": "3895000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:35.000000Z",
            "updated_at": "2023-03-01T02:59:01.000000Z",
            
        },
        {
            "id": 439,
            "product_name": "Bundling Spesial Milyarder",
            "product_price": "1635000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:35.000000Z",
            "updated_at": "2023-03-01T02:59:01.000000Z",
            
        },
        {
            "id": 546,
            "product_name": "CAT",
            "product_price": "100000.00",
            "is_active": null,
            "created_at": "2022-11-14T08:26:20.000000Z",
            "updated_at": "2022-11-14T08:26:20.000000Z",
            
        },
        {
            "id": 501,
            "product_name": "CAT Certified",
            "product_price": "58500000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:03.000000Z",
            
        },
        {
            "id": 545,
            "product_name": "CAT PRIVATE COURSE",
            "product_price": "35000000.00",
            "is_active": null,
            "created_at": "2022-11-14T08:26:20.000000Z",
            "updated_at": "2022-11-14T08:26:20.000000Z",
            
        },
        {
            "id": 550,
            "product_name": "CAT Private Course",
            "product_price": "35000000.00",
            "is_active": 1,
            "created_at": "2022-12-08T13:44:19.000000Z",
            "updated_at": "2023-03-01T02:59:03.000000Z",
            
        },
        {
            "id": 549,
            "product_name": "CAT Starter",
            "product_price": "7000000.00",
            "is_active": 1,
            "created_at": "2022-12-08T13:39:56.000000Z",
            "updated_at": "2023-03-01T02:59:03.000000Z",
            
        },
        {
            "id": 555,
            "product_name": "CFT PROGRAM: HOW TO SET A TRADING PLAN & MONEY MANAGEMENT",
            "product_price": "249000.00",
            "is_active": 0,
            "created_at": "2023-02-02T14:05:26.000000Z",
            "updated_at": "2023-03-01T02:59:03.000000Z",
            
        },
        {
            "id": 449,
            "product_name": "Cara Baru Menggunakan Support Resistance",
            "product_price": "190000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:01.000000Z",
            
        },
        {
            "id": 468,
            "product_name": "Cara Menentukan Stop Loss, Break Even, & Take PROFIT Minimal",
            "product_price": "89000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:02.000000Z",
            
        },
        {
            "id": 448,
            "product_name": "Chart Pattern Bundling Package",
            "product_price": "1380000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:01.000000Z",
            
        },
        {
            "id": 483,
            "product_name": "Chart Pattern Series 1 : Trading with Pennant and Flag",
            "product_price": "295000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:02.000000Z",
            
        },
        {
            "id": 480,
            "product_name": "Chart Pattern Series 2 : Trading with Double Top and Double Bottom",
            "product_price": "295000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:02.000000Z",
            
        },
        {
            "id": 479,
            "product_name": "Chart Pattern Series 3 : The Golden Wedges Pattern",
            "product_price": "395000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:02.000000Z",
            
        },
        {
            "id": 447,
            "product_name": "Chart Pattern Series 4: Make Money with Head & Shoulders",
            "product_price": "395000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:01.000000Z",
            
        },
        {
            "id": 458,
            "product_name": "Choosing Trading Style To Maximize Profit",
            "product_price": "89000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:01.000000Z",
            
        },
        {
            "id": 538,
            "product_name": "FIBONACCI-BAJAKAN",
            "product_price": "300000.00",
            "is_active": 0,
            "created_at": "2022-10-14T10:56:35.000000Z",
            "updated_at": "2023-03-01T02:59:03.000000Z",
            
        },
        {
            "id": 558,
            "product_name": "FUNDAMENTAL STORY: BRIS IJ : POSTED A HUGE OF NET PROFIT IN 2022",
            "product_price": "139000.00",
            "is_active": 1,
            "created_at": "2023-02-15T17:50:27.000000Z",
            "updated_at": "2023-03-01T02:59:03.000000Z",
            
        },
        {
            "id": 553,
            "product_name": "FUNDAMENTAL STORY: INCO IJ : THE FUTURE OF ELECTRIC VEHICLES IN INDONESIA",
            "product_price": "139000.00",
            "is_active": 0,
            "created_at": "2023-01-11T15:36:56.000000Z",
            "updated_at": "2023-03-01T02:59:03.000000Z",
            
        },
        {
            "id": 495,
            "product_name": "Futures Gold",
            "product_price": "9000000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:02.000000Z",
            
        },
        {
            "id": 496,
            "product_name": "Futures Platinum",
            "product_price": "18000000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:02.000000Z",
            
        },
        {
            "id": 493,
            "product_name": "Futures Ruby",
            "product_price": "4500000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:02.000000Z",
            
        },
        {
            "id": 554,
            "product_name": "Futures Story: New Year Rally Fizzles Ahead of Fed Cues",
            "product_price": "139000.00",
            "is_active": 1,
            "created_at": "2023-01-18T18:55:31.000000Z",
            "updated_at": "2023-03-01T02:59:03.000000Z",
            
        },
        {
            "id": 557,
            "product_name": "Futures Story: Oil Price Rises Sharply",
            "product_price": "139000.00",
            "is_active": 0,
            "created_at": "2023-02-08T18:32:00.000000Z",
            "updated_at": "2023-03-01T02:59:03.000000Z",
            
        },
        {
            "id": 469,
            "product_name": "High Probability Trading Profits",
            "product_price": "149000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:02.000000Z",
            
        },
        {
            "id": 470,
            "product_name": "How To Choose A Good Stock",
            "product_price": "149000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:02.000000Z",
            
        },
        {
            "id": 459,
            "product_name": "How To Detect Dangerous Market And Avoid Them",
            "product_price": "89000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:01.000000Z",
            
        },
        {
            "id": 460,
            "product_name": "How To Trading In Mercury Retrograde",
            "product_price": "129000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:01.000000Z",
            
        },
        {
            "id": 507,
            "product_name": "How to Make US $10.000 With Trading Nasdaq",
            "product_price": "249000.00",
            "is_active": 0,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:03.000000Z",
            
        },
        {
            "id": 487,
            "product_name": "Human Factor",
            "product_price": "89000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:02.000000Z",
            
        },
        {
            "id": 453,
            "product_name": "Introducing Technical Analysis",
            "product_price": "129000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:01.000000Z",
            
        },
        {
            "id": 461,
            "product_name": "Introduction To The Financial Market",
            "product_price": "300000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:01.000000Z",
            
        },
        {
            "id": 472,
            "product_name": "Making Chart Patterns And Indicators Work Together",
            "product_price": "149000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:02.000000Z",
            
        },
        {
            "id": 451,
            "product_name": "Making Profit With Rising And Falling Wedge",
            "product_price": "149000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:01.000000Z",
            
        },
        {
            "id": 473,
            "product_name": "Mastering Technical Analysis Power Principles",
            "product_price": "129000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:02.000000Z",
            
        },
        {
            "id": 474,
            "product_name": "Mastering Trading Discipline",
            "product_price": "129000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:02.000000Z",
            
        },
        {
            "id": 475,
            "product_name": "Mind Management For Better Trading",
            "product_price": "129000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:02.000000Z",
            
        },
        {
            "id": 450,
            "product_name": "Mind and Psychology Trading",
            "product_price": "1200000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:01.000000Z",
            
        },
        {
            "id": 486,
            "product_name": "Money Magnet Deluxe",
            "product_price": "618000.00",
            "is_active": 0,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:02.000000Z",
            
        },
        {
            "id": 462,
            "product_name": "Money Magnet Strategy",
            "product_price": "398000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:02.000000Z",
            
        },
        {
            "id": 521,
            "product_name": "NASDAQ SPECIAL SERIES",
            "product_price": "2329000.00",
            "is_active": 1,
            "created_at": "2022-08-03T13:50:39.000000Z",
            "updated_at": "2023-03-01T02:59:03.000000Z",
            
        },
        {
            "id": 504,
            "product_name": "NASDAQ Trading Secret : A Complete Guide to Make Money in Nasdaq Market",
            "product_price": "329000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:03.000000Z",
            
        },
        {
            "id": 490,
            "product_name": "NFP Report",
            "product_price": "224000.00",
            "is_active": 0,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:02.000000Z",
            
        },
        {
            "id": 540,
            "product_name": "NFP Report DESEMBER 2022",
            "product_price": "224000.00",
            "is_active": 0,
            "created_at": "2022-11-04T17:08:06.000000Z",
            "updated_at": "2023-03-01T02:59:03.000000Z",
            
        },
        {
            "id": 551,
            "product_name": "NFP Report FEBRUARI 2023",
            "product_price": "224000.00",
            "is_active": 0,
            "created_at": "2023-01-06T18:13:01.000000Z",
            "updated_at": "2023-03-01T02:59:03.000000Z",
            
        },
        {
            "id": 547,
            "product_name": "NFP Report JANUARI 2023",
            "product_price": "224000.00",
            "is_active": 0,
            "created_at": "2022-12-02T18:04:37.000000Z",
            "updated_at": "2023-03-01T02:59:03.000000Z",
            
        },
        {
            "id": 556,
            "product_name": "NFP Report MARET 2023",
            "product_price": "224000.00",
            "is_active": 1,
            "created_at": "2023-02-06T09:11:58.000000Z",
            "updated_at": "2023-03-01T02:59:03.000000Z",
            
        },
        {
            "id": 463,
            "product_name": "Overbought And Oversold",
            "product_price": "89000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:02.000000Z",
            
        },
        {
            "id": 502,
            "product_name": "Price Action Accelerate Private Course",
            "product_price": "35000000.00",
            "is_active": 0,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:03.000000Z",
            
        },
        {
            "id": 441,
            "product_name": "Price Action Trading: Pemula Bisa Gampang Profit!",
            "product_price": "2999000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:35.000000Z",
            "updated_at": "2023-03-01T02:59:01.000000Z",
            
        },
        {
            "id": 443,
            "product_name": "Profit Hunter for Stock Market",
            "product_price": "387000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:35.000000Z",
            "updated_at": "2023-03-01T02:59:01.000000Z",
            
        },
        {
            "id": 455,
            "product_name": "Profit With Moon Trading",
            "product_price": "129000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:01.000000Z",
            
        },
        {
            "id": 464,
            "product_name": "Profit With Using Predicted Ranges",
            "product_price": "89000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:02.000000Z",
            
        },
        {
            "id": 465,
            "product_name": "Rahasia Jadi Milyarder",
            "product_price": "249000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:02.000000Z",
            
        },
        {
            "id": 498,
            "product_name": "Royal Diamond",
            "product_price": "30000000.00",
            "is_active": 0,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:03.000000Z",
            
        },
        {
            "id": 552,
            "product_name": "Seasonal Report : How To make Money When Dividend is Coming",
            "product_price": "139000.00",
            "is_active": 1,
            "created_at": "2023-01-11T10:31:44.000000Z",
            "updated_at": "2023-03-01T02:59:03.000000Z",
            
        },
        {
            "id": 476,
            "product_name": "Simple And Very Easy Break Out Trading",
            "product_price": "129000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:02.000000Z",
            
        },
        {
            "id": 499,
            "product_name": "Special Report : Fundamental Story : BMRI | Higher Loan Growth, Higher Net Income",
            "product_price": "99000.00",
            "is_active": 0,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:03.000000Z",
            
        },
        {
            "id": 500,
            "product_name": "Special Report : US YIELD VS IHSG",
            "product_price": "149000.00",
            "is_active": 0,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:03.000000Z",
            
        },
        {
            "id": 494,
            "product_name": "Stocks Gold",
            "product_price": "9000000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:02.000000Z",
            
        },
        {
            "id": 497,
            "product_name": "Stocks Platinum",
            "product_price": "18000000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:02.000000Z",
            
        },
        {
            "id": 492,
            "product_name": "Stocks Ruby",
            "product_price": "4500000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:02.000000Z",
            
        },
        {
            "id": 506,
            "product_name": "TEST PRODUCT 2 NOT FOR SALE",
            "product_price": "10000.00",
            "is_active": 0,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:03.000000Z",
            
        },
        {
            "id": 525,
            "product_name": "TEST PRODUCT IN APP 1",
            "product_price": "10000.00",
            "is_active": 0,
            "created_at": "2022-08-25T09:16:19.000000Z",
            "updated_at": "2023-03-01T02:59:03.000000Z",
            
        },
        {
            "id": 534,
            "product_name": "TEST PRODUCT IN APP 10 BAJAKAN",
            "product_price": "10000.00",
            "is_active": 0,
            "created_at": "2022-08-25T09:21:28.000000Z",
            "updated_at": "2023-03-01T02:59:03.000000Z",
            
        },
        {
            "id": 526,
            "product_name": "TEST PRODUCT IN APP 2",
            "product_price": "10000.00",
            "is_active": 0,
            "created_at": "2022-08-25T09:17:11.000000Z",
            "updated_at": "2023-03-01T02:59:03.000000Z",
            
        },
        {
            "id": 527,
            "product_name": "TEST PRODUCT IN APP 3",
            "product_price": "10000.00",
            "is_active": 0,
            "created_at": "2022-08-25T09:17:38.000000Z",
            "updated_at": "2023-03-01T02:59:03.000000Z",
            
        },
        {
            "id": 528,
            "product_name": "TEST PRODUCT IN APP 4",
            "product_price": "10000.00",
            "is_active": 0,
            "created_at": "2022-08-25T09:18:12.000000Z",
            "updated_at": "2023-03-01T02:59:03.000000Z",
            
        },
        {
            "id": 529,
            "product_name": "TEST PRODUCT IN APP 5",
            "product_price": "10000.00",
            "is_active": 0,
            "created_at": "2022-08-25T09:18:52.000000Z",
            "updated_at": "2023-03-01T02:59:03.000000Z",
            
        },
        {
            "id": 530,
            "product_name": "TEST PRODUCT IN APP 6",
            "product_price": "10000.00",
            "is_active": 0,
            "created_at": "2022-08-25T09:19:23.000000Z",
            "updated_at": "2023-03-01T02:59:03.000000Z",
            
        },
        {
            "id": 531,
            "product_name": "TEST PRODUCT IN APP 7",
            "product_price": "10000.00",
            "is_active": 0,
            "created_at": "2022-08-25T09:20:12.000000Z",
            "updated_at": "2023-03-01T02:59:03.000000Z",
            
        },
        {
            "id": 532,
            "product_name": "TEST PRODUCT IN APP 8",
            "product_price": "10000.00",
            "is_active": 0,
            "created_at": "2022-08-25T09:20:34.000000Z",
            "updated_at": "2023-03-01T02:59:03.000000Z",
            
        },
        {
            "id": 533,
            "product_name": "TEST PRODUCT IN APP 9",
            "product_price": "10000.00",
            "is_active": 0,
            "created_at": "2022-08-25T09:21:03.000000Z",
            "updated_at": "2023-03-01T02:59:03.000000Z",
            
        },
        {
            "id": 505,
            "product_name": "TEST PRODUCT NOT FOR SALE",
            "product_price": "10000.00",
            "is_active": 0,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:03.000000Z",
            
        },
        {
            "id": 548,
            "product_name": "Tes Product 100",
            "product_price": "435643.00",
            "is_active": 0,
            "created_at": "2022-12-07T13:57:17.000000Z",
            "updated_at": "2022-12-07T13:57:57.000000Z",
            
        },
        {
            "id": 524,
            "product_name": "Test Product In App 2 Insert",
            "product_price": "1000000.00",
            "is_active": 0,
            "created_at": "2022-08-08T11:41:31.000000Z",
            "updated_at": "2023-03-01T02:59:03.000000Z",
            
        },
        {
            "id": 503,
            "product_name": "Test Productdsss",
            "product_price": "43643.00",
            "is_active": 0,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:03.000000Z",
            
        },
        {
            "id": 491,
            "product_name": "The 70 Pips Gold Trading System",
            "product_price": "277000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:02.000000Z",
            
        },
        {
            "id": 485,
            "product_name": "The Art of Fibonacci",
            "product_price": "2618000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:02.000000Z",
            
        },
        {
            "id": 484,
            "product_name": "The Art of Fibonacci Platinum Edition",
            "product_price": "3618000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:02.000000Z",
            
        },
        {
            "id": 489,
            "product_name": "The Darkside Of Over The Counter Market",
            "product_price": "89000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:02.000000Z",
            
        },
        {
            "id": 537,
            "product_name": "The New Moon Trading Secret",
            "product_price": "3495000.00",
            "is_active": 1,
            "created_at": "2022-08-29T08:24:20.000000Z",
            "updated_at": "2023-03-01T02:59:03.000000Z",
            
        },
        {
            "id": 440,
            "product_name": "The Secret of Making Money in NASDAQ",
            "product_price": "2000000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:35.000000Z",
            "updated_at": "2023-03-01T02:59:01.000000Z",
            
        },
        {
            "id": 452,
            "product_name": "The Ultimate Technical Analysis",
            "product_price": "2499000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:01.000000Z",
            
        },
        {
            "id": 477,
            "product_name": "The Value of Information",
            "product_price": "300000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:02.000000Z",
            
        },
        {
            "id": 438,
            "product_name": "Tradepreneur Trading System",
            "product_price": "5000000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:35.000000Z",
            "updated_at": "2023-03-01T02:59:01.000000Z",
            
        },
        {
            "id": 539,
            "product_name": "ULTIMATE-BAJAKAN",
            "product_price": "200000.00",
            "is_active": 0,
            "created_at": "2022-10-14T11:01:15.000000Z",
            "updated_at": "2023-03-01T02:59:03.000000Z",
            
        },
        {
            "id": 454,
            "product_name": "Understanding Global Indicator",
            "product_price": "149000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:01.000000Z",
            
        },
        {
            "id": 478,
            "product_name": "Understanding Supply and Demand",
            "product_price": "99000.00",
            "is_active": 1,
            "created_at": "2022-08-02T13:44:36.000000Z",
            "updated_at": "2023-03-01T02:59:02.000000Z",
            
        },
        {
            "id": 535,
            "product_name": "WEFIS - World Economic Forum Investment & Strategy 2023",
            "product_price": "500000.00",
            "is_active": 0,
            "created_at": "2022-08-25T13:48:30.000000Z",
            "updated_at": "2023-03-01T02:59:03.000000Z",
            
        },
        {
            "id": 523,
            "product_name": "sdffgh",
            "product_price": "675465.00",
            "is_active": 0,
            "created_at": "2022-08-05T17:59:37.000000Z",
            "updated_at": "2022-08-05T17:59:37.000000Z",
            
        }
    ]
]