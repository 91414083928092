import { axiosPrivate } from "api/axiosCore";
import axios from "axios";

const limit = 10;
const ALL_DEPT = "Access_All_Dept";
const OWN_DEPT = "Access_Own_Dept";
const OWN = "Access_Own";

export const serviceGetCampaigns = async (token, roleFunctions, departmentId, userId, page, selectedResource) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    roleFunctions = roleFunctions || [];
    departmentId = departmentId || "";
    let basicUrl = `api/campaigns?take=${limit}&page=${page || 1}&order=DESC`;
    var response = null;

    if (selectedResource && selectedResource !== "all") {
      basicUrl = `api/campaigns?take=${limit}&page=${page || 1}&order=DESC&leadSourceId=${selectedResource}`;
    }

    if (roleFunctions.includes(ALL_DEPT)) {
      response = await axiosPrivate.get(basicUrl);
    } else if (roleFunctions.includes(OWN_DEPT) && roleFunctions.includes(OWN)) {
      response = await axiosPrivate.get(basicUrl + `&departmentIds=${departmentId}`); //deptId
    } else if (roleFunctions.includes(OWN_DEPT)) {
      response = await axiosPrivate.get(basicUrl + `&departmentIds=${departmentId}`); //deptId
    } else if (roleFunctions.includes(OWN)) {
      response = await axiosPrivate.get(basicUrl + `&userId=${userId}`); //deptId
    }

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetAllCampaigns = async (token) => {
  try {
    let hasNextPage = true;
    let page = 1;
    let campaigns = [];

    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    while (hasNextPage) {
      var basicUrl = `api/campaigns?order=DESC&take=20&page=${page}`;
      const response = await axiosPrivate.get(basicUrl);
      const responseData = response.data.data;
      campaigns.push(...responseData);
      if (!response.data.meta.hasNextPage) {
        hasNextPage = false;
      } else {
        page += 1;
      }
    }
    return campaigns;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetListCampaigns = async (token, departmentId, order, f, role) => {
  const basicUrl = `api/campaigns`;
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    let response = null;
    
    if (role.includes("Access_All_Dept") && departmentId) {
      response = await axiosPrivate.get(`${basicUrl}/list?order=${order}&f=${f}&departmentIds=${departmentId}`);
    }else if (role.includes("Access_All_Dept")) {
      response = await axiosPrivate.get(`${basicUrl}/list?order=${order}&f=${f}`);
    } else if (role.includes("Access_Own_Dept")) {
      response = await axiosPrivate.get(`${basicUrl}/list?order=${order}&f=${f}&departmentIds=${departmentId}`);
    } else if (role.includes("Access_Own")) {
      response = await axiosPrivate.get(`${basicUrl}/list?order=${order}&f=${f}&departmentIds=${departmentId}`);
    } else {
      response = await axiosPrivate.get(`${basicUrl}/list?order=${order}&f=${f}`);
    }

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetCampaignsByQuery = async (
  token,
  roleFunctions,
  departmentId,
  userId,
  page,
  query,
  field,
  selectedResource
) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    roleFunctions = roleFunctions || [];
    departmentId = departmentId || "";
    let basicUrl = `api/campaigns?take=10&page=${page || 1}&order=DESC`;

    if (selectedResource && selectedResource !== "all") {
      basicUrl = `api/campaigns?take=${limit}&page=${page || 1}&order=DESC&leadSourceId=${selectedResource}`;
    }

    const q = query ? `&q=${query}` : "";
    const f = field ? `&f=${field}` : "";
    var response = null;
    if (roleFunctions.includes(ALL_DEPT)) {
      if(typeof departmentId === 'string'){
        response = await axiosPrivate.get(basicUrl + `&departmentIds=${departmentId}` + q + f + "&c=AND"); //deptId
      }else{
        response = await axiosPrivate.get(basicUrl + q + f + "&c=AND");
      }
    } else if (roleFunctions.includes(OWN_DEPT) && roleFunctions.includes(OWN)) {
      response = await axiosPrivate.get(basicUrl + `&departmentIds=${departmentId}` + q + f + "&c=AND"); //deptId
    } else if (roleFunctions.includes(OWN_DEPT)) {
      response = await axiosPrivate.get(basicUrl + `&departmentIds=${departmentId}` + q + f + "&c=AND"); //deptId
    } else if (roleFunctions.includes(OWN)) {
      response = await axiosPrivate.get(basicUrl + `&userId=${userId}` + q + f + "&c=AND"); //deptId
    }

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetCampaignsByField = async (
  token,
  roleFunctions,
  departmentId,
  userId,
  page,
  order = "DESC",
  field,
  query,
  fieldQuery,
  selectedResource,
) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    roleFunctions = roleFunctions || [];
    departmentId = departmentId || "";

    var basicUrl = `api/campaigns?take=10&page=${page || 1}&order=${order}`;
    
    if (selectedResource && selectedResource !== "all") {
      basicUrl = `api/campaigns?take=${limit}&page=${page || 1}&order=DESC&leadSourceId=${selectedResource}`;
    }
    
    const q = query ? `&q=${query}` : "";
    const f = field || query ? `&f=${fieldQuery}${field ? `,${field}` : ""}` : "";

    var response = null;
    if (roleFunctions.includes(ALL_DEPT)) {
      response = await axiosPrivate.get(basicUrl + q + f + "&c=AND");
    } else if (roleFunctions.includes(OWN_DEPT)) {
      response = await axiosPrivate.get(basicUrl + `&departmentIds=${departmentId}` + f + q + "&c=AND"); //deptId
    } else if (roleFunctions.includes(OWN)) {
      response = await axiosPrivate.get(basicUrl + `&userId=${userId}` + f + q + "&c=AND"); //deptId
    }

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetCampaign = async (token, campaignId) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const basicUrl = `api/campaigns/${campaignId}?order=DESC`;
    var response = null;
    response = await axiosPrivate.get(basicUrl);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const servicePostCampaigns = async (token, data) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.post("api/campaigns", data);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const servicePutCampaigns = async (id, token, data) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.put("api/campaigns/" + id, data);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};
export const servicePutCampaignsProspek = async (id, token, data) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.put("api/campaigns/prospek/" + id, data);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};
export const serviceDeleteCampaigns = async (id, token) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.delete("api/campaigns/" + id);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const getLinkOption = async (props) => {
  const { token } = props;
  try {
    let page = 1;
    let hasNextPage = true;
    let allLink = [];
    while (hasNextPage) {
      axios.interceptors.request.use((config) => {
        config.headers["Authorization"] = `Bearer ${token}`;
        config.headers["Content-Type"] = "application/json";
        config.headers["AccessControl-Allow-Origin"] = "http://localhost:3000";
        return config;
      });
      const response = await axios.get(process.env.REACT_APP_BASE_URL + `api/links?page=${page}&take=50`);
      response.data.data.map((data) => {
        allLink.push(data);
      });
      if (!response.data.meta.hasNextPage) {
        hasNextPage = false;
      } else {
        page += 1;
      }
    }
    return allLink;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetClickCount = async (shortUrl) => {
  try {
    axios.interceptors.request.use((config) => {
      config.headers["X-Api-Key"] = process.env.REACT_APP_SHLINK_KEY;
      config.headers["Content-Type"] = "application/json";
      config.headers["AccessControl-Allow-Origin"] = "http://localhost:3000";
      return config;
    });
    const response = await axios.get(
      process.env.REACT_APP_SHLINK + `rest/v3/short-urls/${shortUrl}/visits?page=1&itemsPerPage=1`
    );
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};
