import { axiosPrivate } from "api/axiosCore";

const ALL_DEPT = "Access_All_Dept";
const OWN_DEPT = "Access_Own_Dept";
const OWN = "Access_Own";

export const serviceGetTickets = async (token, roleFunctions, departmentId, userId, page) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    roleFunctions = roleFunctions || [];
    departmentId = departmentId || "";
    let basicUrl = `api/tickets?order=DESC&take=10&page=${page || 1}`;
    var response = null;

    if(!roleFunctions.includes('View_Chatbot')){
      basicUrl += '&excludeBot=true'
    }
    if (roleFunctions.includes(ALL_DEPT)) {
      response = await axiosPrivate.get(basicUrl);
    } else if (roleFunctions.includes(OWN_DEPT)) {
      response = await axiosPrivate.get(basicUrl + `&departmentIds=${departmentId}`); //deptId
    } else if (roleFunctions.includes(OWN)) {
      response = await axiosPrivate.get(basicUrl + `&userId=${userId}`); //deptId
    }

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetAllTickets = async (token) => {
  try {
    let hasNextPage = true;
    let page = 1;
    let tickets = [];

    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    while (hasNextPage) {
      const basicUrl = `api/tickets?order=DESC&take=10&page=${page}`;
      const response = await axiosPrivate.get(basicUrl);
      const responseData = response.data.data;
      tickets.push(...responseData);
      if (!response.data.meta.hasNextPage) {
        hasNextPage = false;
      } else {
        page += 1;
      }
    }
    return tickets;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetTicketById = async (token, ticketId) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const response = await axiosPrivate.get("api/tickets/" + ticketId);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetTicketByIds = async ({token, ticketIDs}) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const response = await axiosPrivate.get("api/tickets/?ticketIds=" + ticketIDs);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetTicketsByQuery = async (
  token,
  userId,
  departmentId,
  query,
  roleFunctions,
  page,
  field,
  sort,
  queryStatus,
  queryRating
) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    roleFunctions = roleFunctions || [];
    departmentId = departmentId || "";
    let prospectTagIDs = []
    let leadTagIDs = []
    let queryList = []
    let fieldList = []

    if(field.includes("tags.prospect") || field.includes("tags.lead")) {
      const tagsList = query.split(",").filter(function(itm){ return itm.replace(/(\r\n|\n|\r)/gm,"")})
      const tagsfieldList = field.split(",").filter(function(itm){ return itm.replace(/(\r\n|\n|\r)/gm,"")})
      tagsList.map((itm, i) => {
        if(tagsfieldList[i] === "tags.prospect") {
          prospectTagIDs.push(itm)
        } else if(tagsfieldList[i] === "tags.lead") {
          leadTagIDs.push(itm)
        } else {
          queryList.push(itm)
          fieldList.push(tagsfieldList[i])
        }
      })
    } else {
      queryList = query
      fieldList = field
    }

    const sortField = sort.field ? sort.field : "ticket.updatedAt";
    const sortOrder = sort.order ? sort.order : "DESC";
    let basicUrl = `api/tickets?order=${sortOrder}&take=10&page=${page || 1}`;
    const q = queryList.length > 0 ? `&q=${queryList.toString()}` : "";
    const f = fieldList.length > 0 ? `&f=${fieldList.toString()},${sortField}` : "";
    const ratingFilter = queryRating ? "&rating=" + queryRating : "";
    const statusFilter = queryStatus ? "&status=" + queryStatus : "";
    const prospectTagIDsFilter = prospectTagIDs.length > 0 ? "&prospectId=2e035380-9fe5-4b01-9ece-9421400ced03&prospectTagId=" + prospectTagIDs.toString() : "";
    const leadTagIDsFilter = leadTagIDs.length > 0 ? "&leadId=32c38e87-dd2f-40dc-b2a2-40a08cfad79e&leadTagId=" + leadTagIDs.toString() : "";

    if(!roleFunctions.includes('View_Chatbot')){
      basicUrl += '&excludeBot=true'
    }

    var response = null;
    if (roleFunctions.includes(ALL_DEPT)) {
      response = await axiosPrivate.get(
        basicUrl +
          ratingFilter +
          (departmentId && `&departmentId=${departmentId}`) +
          q +
          f +
          "&c=AND&t=CONTAIN" +
          statusFilter + "" + prospectTagIDsFilter + "" + leadTagIDsFilter
      );
    } else if (roleFunctions.includes(OWN_DEPT)) {
      response = await axiosPrivate.get(
        basicUrl + ratingFilter + `&departmentId=${departmentId}` + q + f + "&c=AND&t=CONTAIN" + statusFilter + "" + prospectTagIDsFilter + "" + leadTagIDsFilter
      ); //deptId
    } else if (roleFunctions.includes(OWN)) {
      response = await axiosPrivate.get(
        basicUrl + ratingFilter + `&userId=${userId}` + q + f + "&c=AND&t=CONTAIN" + statusFilter + "" + prospectTagIDsFilter + "" + leadTagIDsFilter
      ); //deptId
    }

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetTicketsByField = async (
  token,
  userId,
  departmentId,
  field,
  roleFunctions,
  page,
  order = "DESC",
  queryFilter,
  queryStatus,
  queryRating
) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    roleFunctions = roleFunctions || [];
    departmentId = departmentId || "";
    let tagIDs = []
    let queryList = []
    let fieldList = []
    if(queryFilter.fieldText.includes("tags.name")) {
      const tagsList = queryFilter.queryText.split(",").filter(function(itm){ return itm.replace(/(\r\n|\n|\r)/gm,"")})
      const tagsfieldList = queryFilter.fieldText.split(",").filter(function(itm){ return itm.replace(/(\r\n|\n|\r)/gm,"")})
      tagsList.map((itm, i) => {
        if(tagsfieldList[i] === "tags.name") {
          tagIDs.push(itm)
        } else {
          queryList.push(itm)
          fieldList.push(tagsfieldList[i])
        }
      })
    }
    const queryText = queryList.length > 0 ? "&q=" + queryList : "";
    const queryField = fieldList.length > 0 ? fieldList + "," : "";
    const ratingFilter = queryRating ? "&rating=" + queryRating : "";
    const statusFilter = queryStatus ? "&status=" + queryStatus : "";
    const tagIDsFilter = tagIDs.length > 0 ? "&tagIds=" + tagIDs.toString() : "";
    let basicUrl = `api/tickets?order=${order}&take=10&page=${page || 1}${queryText}`;
    const f = field ? `&f=${queryField}${field}` : "";
    var response = null;

    if(!roleFunctions.includes('View_Chatbot')){
      basicUrl += '&excludeBot=true'
    }

    if (roleFunctions.includes(ALL_DEPT)) {
      response = await axiosPrivate.get(basicUrl + ratingFilter + f + `&c=AND&t=CONTAIN` + statusFilter + "" + tagIDsFilter);
    } else if (roleFunctions.includes(OWN_DEPT)) {
      response = await axiosPrivate.get(basicUrl + `&departmentId=${departmentId}` + f + `&c=AND&t=CONTAIN`); //deptId
    } else if (roleFunctions.includes(OWN)) {
      response = await axiosPrivate.get(basicUrl + `&userId=${userId}` + f + `&c=AND&t=CONTAIN`); //deptId
    }

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetTicketsById = async (token, ticketId) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const response = await axiosPrivate.get(`api/tickets?page=1&take=1&q=${ticketId}&f=ticket.id`);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetTicketsByUserId = async (token, userID, take = 1, order = "DESC") => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const response = await axiosPrivate.get(`api/tickets?order=${order}&page=1&take=${take}&userId=${userID}`);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetTicketSummary = async (token, departmentId, userId, roleFunctions) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    let basicUrl = `api/tickets/summary`;
    let response = null;

    if (roleFunctions.includes(ALL_DEPT)) {
      response = await axiosPrivate.get(basicUrl);
    } else if (roleFunctions.includes(OWN_DEPT)) {
      response = await axiosPrivate.get(basicUrl + `?departmentId=${departmentId}`); //deptId
    } else if (roleFunctions.includes(OWN)) {
      response = await axiosPrivate.get(basicUrl + `?userId=${userId}`); //deptId
    }

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const servicePostTickets = async (token, data) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.post("api/tickets", data);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const servicePutTickets = async (id, token, data) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.put("api/tickets/" + id, data);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceDeleteTickets = async (id, token) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.delete("api/tickets/" + id);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};
