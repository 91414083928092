import { axiosPrivate } from "../api/axiosCore";

const limit = 15;
const ALL_DEPT = "Access_All_Dept";
const OWN_DEPT = "Access_Own_Dept";
const OWN = "Access_Own";

export const servicePutFile = async (id, token, data) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.put("api/files/" + id, data);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const servicePostMoveFile = async (token, data) => {
  try {
    const myInterceptor = axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.post("api/files/move/", data);
    axiosPrivate.interceptors.request.eject(myInterceptor);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetFiles = async (token, page, roleFunctions, dirId) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const basicUrl = `api/files/?order=DESC&page=${page || 1}&take=10&directoryId=${dirId}`;
    let response = null;
    /**
     * Ini edit ya mas harry mas rizal, kalau sudah ada dto departmentId, dan await di akhir comment saja
      if (roleFunctions.includes(ALL_DEPT)){
        response = await axiosPrivate.get(basicUrl); //deptId
      }else if(roleFunctions.includes(OWN_DEPT)){
        response = await axiosPrivate.get(basicUrl+`&departmentId=${departmentId}`); //deptId
      }else if(roleFunctions.includes(OWN)){
        response = await axiosPrivate.get(basicUrl+`&userId=${userId}`); //deptId
      }
    */
    response = await axiosPrivate.get(basicUrl);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetFilesFromQuery = async (token, page, dirId,query) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const q = query ?? ""
    const basicUrl = `api/files/?order=DESC&page=${page || 1}&take=10&f=file.name&q=${q}&directoryId=${dirId}&c=AND`;
    let response = null;
    response = await axiosPrivate.get(basicUrl);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};


export const serviceDeleteFile = async (id, token) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.delete("api/files/" + id);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetFile = async (id, token) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.get("api/files/" + id);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};
export const serviceDownloadFile = async (token, id) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.get("api/files/download/" + id, { responseType: "blob" });

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceCreateFile = async (token, data) => {
  try {
    const myInterceptor = axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      config.headers["Content-Type"] = "multipart/form-data";
      return config;
    });

    // const dataJson = JSON.stringify(data);

    const response = await axiosPrivate.post("api/files", data);
    axiosPrivate.interceptors.request.eject(myInterceptor);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};
