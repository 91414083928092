import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Col,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  Row,
} from "components/Component";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { getDepartments } from "services/departments";
import { servicePutUsersWeigts, serviceGetUsersCount } from "services/users";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { DefaultRoleFunctions } from "data/DefaultRoleFunctions";
import DatePicker from "react-datepicker";
import { FormGroup, Spinner } from "reactstrap";

const UserRotatorSummary = ({ ...props }) => {
  const [loading, setLoading] = useState(false);
  const [cookies] = useCookies(["user"]);
  const [listChannels, setListChannels] = useState([]);
  const [listUser, setListUser] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const [weight, setWeight] = useState(0);
  const [daten, setDaten] = useState(new Date());
  const [currentDepartment, setCurrentDepartment] = useState(null);
  const [dateFinal, setDateFinal] = useState(
    new Date().toLocaleDateString("zh-Hans-CN", {
      // you can use undefined as first argument
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
  );

  const refreshData = async () => {
    // mas harry, add loading spinner befeore service api calls
    try {
      setLoading(true);
      const responseGetUsers = await serviceGetUsersCount(
        cookies.SIDikey,
        DefaultRoleFunctions.user["SUPER ADMIN"],
        cookies.departmentId,
        dateFinal
      );
      if (new RegExp(/20+/).test(responseGetUsers.status)) {
        console.log(responseGetUsers);
        return;
      } else {
        console.log(responseGetUsers);
      }
      setLoading(false);
    } catch (error) {
      return error.response;
    }
  };

  const refreshDepartment = async () => {
    // mas harry, add loading spinner befeore service api calls
    try {
      setLoading(true);
      const responseGetDepartments = await getDepartments(cookies.SIDikey, '', cookies.departmentIds, cookies.id, ['Access_All_Dept']);
      if (new RegExp(/20+/).test(responseGetDepartments.status)) {
        const resultData = responseGetDepartments.data.data;
        if (resultData.length > 0) {
          // setActiveTab(resultData[0].name);
        }
        setListChannels(resultData);
      } else {
        console.log(responseGetDepartments);
      }
      setLoading(false);
    } catch (error) {
      return error.response;
    }
  };

  const selectTab = async (department, departmentName) => {
    setActiveTab(departmentName);
    setCurrentDepartment(department);
    try {
      setLoading(true);
      const resp = await serviceGetUsersCount(cookies.SIDikey, "SUPER ADMIN", department.id, dateFinal);
      if (new RegExp(/20+/).test(resp.status)) {
        setListUser(resp.data.filter((el) => el.user.role === "SALES"));
      }
    } catch (error) {
      return error.response;
    }
    setLoading(false);
    // mas harry, add loading spinner befeore service api calls
  };

  useEffect(() => {
    var total = 0;
    if (!!listUser) {
      listUser.forEach((l) => (total += parseInt(l.weight)));
    }
    setWeight(total);
  }, [listUser, setListUser]);

  useEffect(() => {
    refreshDepartment();
    refreshData();
  }, []);

  useEffect(() => {
    if (currentDepartment) {
      selectTab(currentDepartment, currentDepartment.name);
    }
  }, [dateFinal]);

  return (
    <React.Fragment>
      <Head title="Relasi Admin" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                User
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
          <BlockTitle tag="h6" className="mt-4">
            <span style={{ color: "#4461F2" }}>Rotator Summary</span>
          </BlockTitle>

          <Block className="d-flex justify-content-end" size={1}>
            <FormGroup>
              <label className="form-label">Pilih Tanggal</label>
              <div className="form-control-wrap">
                <DatePicker
                  selected={daten}
                  className="form-control"
                  onChange={(date) => {
                    setDaten(date);
                    setDateFinal(
                      date.toLocaleDateString("zh-Hans-CN", {
                        // you can use undefined as first argument
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      })
                    );
                  }}
                  maxDate={new Date()}
                />
              </div>
            </FormGroup>
          </Block>
        </BlockHead>
        <Block className="position-relative">
          <DataTable className="card-stretch">
            <Row>
              <Col md="2" lg="2" className="relation-channel">
                <div>
                  <ul className="relation-channel">
                    {listChannels.length > 0
                      ? listChannels.map((channel, idx) => {
                          return (
                            <li
                              key={idx}
                              className={
                                activeTab === channel.name ? "relation-channel-list active" : "relation-channel-list"
                              }
                              onClick={() => selectTab(channel, channel.name)}
                            >
                              {channel.name}
                            </li>
                          );
                        })
                      : null}
                  </ul>
                </div>
              </Col>
              <Col md="10" lg="10">
                <DataTableBody compact>
                  <DataTableHead>
                    <DataTableRow>
                      <span className="sub-text">No</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="sub-text">Nama</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="sub-text">Email</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="sub-text">Department</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="sub-text">Jumlah Chat Masuk</span>
                    </DataTableRow>
                  </DataTableHead>
                  {/* <DataTableBody> */}
                  {listUser ? (
                    listUser.map((item, idx) => {
                      return (
                        <DataTableHead key={idx}>
                          <DataTableRow>{idx + 1}</DataTableRow>
                          <DataTableRow>{item.user.firstName + " " + item?.user.lastName}</DataTableRow>
                          <DataTableRow>{item.user.email}</DataTableRow>
                          <DataTableRow>{item.user.department.name}</DataTableRow>
                          <DataTableRow>{item.count}</DataTableRow>
                        </DataTableHead>
                      );
                    })
                  ) : (
                    <DataTableHead>No Sales in this dept</DataTableHead>
                  )}
                </DataTableBody>
              </Col>
            </Row>
          </DataTable>
          {!activeTab && (
            <div className="position-absolute" style={{ top: "50%", left: "50%" }}>
              Mohon pilih department di samping
            </div>
          )}
          {loading && (
            <div className="position-absolute" style={{ top: "50%", left: "50%" }}>
              <Spinner size="sm" color="dark" />
            </div>
          )}
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default UserRotatorSummary;
