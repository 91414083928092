import { axiosPrivate } from "../api/axiosCore";

const ALL_DEPT = 'Access_All_Dept'
const OWN_DEPT = 'Access_Own_Dept'
// const OWN = 'Access_Own'

export const getLogs = async (token, page, query, field, departmentId, roleFunctions=[], order="DESC") => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const q = query ? `&q=${query}` : "";
    const f = field ? `&f=${field}` : "";
    const basicUrl = `api/logs?take=10&page=${page || 1}&order=${order}`;
    // const response = await axiosPrivate.get(basicUrl + q + f + "&c=AND");
    var response = null

    if (roleFunctions.includes(ALL_DEPT)) {
      console.log("departmentId.length", departmentId.length)
      if (departmentId.length < 2 || departmentId.length === 36) {
        console.log("tests")
        response = await axiosPrivate.get(basicUrl + q + f + `&c=AND&departmentIds=${departmentId}`);  
      } else {
        response = await axiosPrivate.get(basicUrl + q + f + `&c=AND`);
      }
    } else if (roleFunctions.includes(OWN_DEPT)) {
      response = await axiosPrivate.get(basicUrl + q + f + `&c=AND&departmentIds=${departmentId}`); //deptId
    } else {
      response = await axiosPrivate.get(basicUrl + q + f + "&c=AND");
    }

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};
// export const getLogsByField = async (token, page, query, field, order="DESC") => {
//   try {
//     axiosPrivate.interceptors.request.use((config) => {
//       config.headers["Authorization"] = `Bearer ${token}`;
//       return config;
//     });
//     const q = query ? `&q=${query}` : "";
//     const f = field ? `&f=${field}` : "";
//     const basicUrl = `api/logs?take=10&page=${page || 1}&order=${order}`;
//     const response = await axiosPrivate.get(basicUrl + q + f);

//     return response;
//   } catch (error) {
//     if (!error.response) {
//       return { status: "No Server Response" };
//     } else {
//       return error.response;
//     }
//   }
// };

export const getLog = async (token, logId) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.get("api/logs/?id=" + logId);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};
