import { FormGroup, CustomInput, Modal, ModalBody, Progress, Badge } from "reactstrap";
import { Icon, Col, Button, RSelect } from "../../components/Component";
import { useCallback, useEffect, useState } from "react";
import Papa from "papaparse";

const ContactUpload = (props) => {
  const { modalUploadCSV, setModalUploadCSV, servicePutContact, serviceGetContactByPhone, token, refreshContacts } =
    props;
  const [progress, setProgress] = useState(0);
  const [errorUpload, setErrorUpload] = useState([]);
  const [uploadButton, setUploadButton] = useState(true);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [file, setFile] = useState({
    fileName: "",
    invalidFile: false,
  });
  const [contactObject, setContactObject] = useState();

  const convertCSVToObject = (file) => {
    Papa.parse(file, {
      delimiter: ",",
      newline: "",
      skipEmptyLines: true,
      header: true,
      complete: (result) => {
        const contactJson = result.data.map((item) => {
          item.phones = [{ phone: item.phones }];
          item.emails = [{ email: item.emails }];
          return { ...item };
        });
        setContactObject(contactJson);
      },
    });
  };

  const handleFileChange = ({ target: { files } }) => {
    const cancel = !files.length;
    if (cancel) return;
    const [{ size, name, type }] = files;
    const maxSize = 50000;

    if (size < maxSize && type === "text/csv") {
      setFile({ fileName: name, invalidFile: false });
      convertCSVToObject(files[0]);
      setUploadButton(false);
    } else {
      setFile({ fileName: "", invalidFile: true });
      setUploadButton(true);
    }
  };

  const handleUpload = useCallback(async () => {
    setUploadButton(true);
    if (contactObject) {
      for (let i = 0; i < contactObject.length; i++) {
        const resGetContactId = await serviceGetContactByPhone(token, contactObject[i].phones[0].phone);
        if (resGetContactId.status !== 200 || resGetContactId.data.data.length === 0) {
          const newErrorUpload = errorUpload;
          newErrorUpload.push({ phone: contactObject[i].phones[0].phone, error: "data not found" });
          setErrorUpload(newErrorUpload);
        } else {
          const id = resGetContactId.data.data[0].id;
          const customerData = { ...contactObject[i] };
          delete customerData.phones;
          const resSaveContact = await servicePutContact(token, id, customerData);
          if (resSaveContact.status !== 202) {
            const newErrorUpload = errorUpload;
            newErrorUpload.push({ phone: contactObject[i].phones[0].phone, error: "failed update data" });
            setErrorUpload(newErrorUpload);
          }
        }

        const progressPercent = ((i + 1) / contactObject.length) * 100;
        setProgress(progressPercent);
      }
      refreshContacts(1);
      setUploadButton(false);
    } else {
      setFile({ fileName: "", invalidFile: true });
    }
  }, [contactObject]);

  const handleDownloadError = useCallback(() => {
    setDownloadLoading(true);
    const csv = Papa.unparse(errorUpload);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `contact_page.csv`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setDownloadLoading(false);
  }, [errorUpload]);

  return (
    <Modal
      isOpen={modalUploadCSV}
      backdrop="static"
      keyboard={false}
      scrollable={true}
      className="modal-dialog-centered"
      size="lg"
    >
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            setModalUploadCSV(false);
            setProgress(0);
            setContactObject();
            setErrorUpload([]);
            setFile({
              fileName: "",
              invalidFile: false,
            });
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">Upload CSV File</h5>
          <a href="/sample-upload-contact.csv" download>Sample Csv</a>
          <hr/>
          <div className="mt-4 row gy-2">
            <Col md="8" lg="8">
              <FormGroup>
                {file.invalidFile && <p>file yang anda pilih tidak didukung!</p>}
                <CustomInput
                  type="file"
                  id="csvfile"
                  name="csvfile"
                  label={file.fileName || "choose an csv file"}
                  onChange={handleFileChange}
                  invalid={file.invalidFile}
                />
              </FormGroup>
            </Col>
            <Col md="4" lg="4">
              <Button
                type="submit"
                color="primary"
                className="btn btn-block"
                onClick={handleUpload}
                disabled={uploadButton}
              >
                Upload
              </Button>
            </Col>
          </div>
          {progress > 0 && <p>Jangan tutup halaman ini selama upload berjalan!</p>}
          <Progress value={progress} />
        </div>
        <div className="errorReport">
          {errorUpload.length > 0 &&
            errorUpload.map((item, i) => (
              <p key={i + 1}>
                {item.phone}, Error: {item.error}
              </p>
            ))}
          {errorUpload.length > 0 && (
            <Col md="4" lg="4">
              <Button
                type="submit"
                color="primary"
                className="btn btn-block"
                onClick={handleDownloadError}
                disabled={uploadButton}
              >
                <Icon name={downloadLoading ? "loader" : "download-cloud"}></Icon>
                <span>Download</span>
              </Button>
            </Col>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ContactUpload;
