import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Col,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  Row,
  PaginationComponent,
} from "components/Component";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Button, Card, CardBody, Spinner } from "reactstrap";
import { getDepartments } from "services/departments";
import { serviceGetUsersBySales, servicePutUsersWeigts, serviceUpdateUserRotatorWeight, serviceGetUsersWeightByDeptId } from "services/users";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { TooltipComponent } from "../../components/Component";
import Swal from "sweetalert2";
import { DefaultRoleFunctions } from "data/DefaultRoleFunctions";
import { Link } from "react-router-dom";
import { deleteRotators } from "services/rotators";

const UserRelation = ({ ...props }) => {
  const [loading, setLoading] = useState(false);
  const roleFunctions = JSON.parse(localStorage.getItem("permission"))["departments"].access || [];
  const [cookies] = useCookies(["user"]);
  const [listChannels, setListChannels] = useState([]);
  const [listUser, setListUser] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const [deparment, setDepartment] = useState({});
  const [currentWeights, setCurrentWeights] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [weight, setWeight] = useState(0);
  const [data, setData] = useState([]);
  const [metaData, setMetaData] = useState({
    itemCount: 0,
    page: 0,
    take: 0,
    pageCount: 0,
    hasPreviousPage: false,
    hasNextPage: false,
  });
  const [pageNumber, setPageNumber] = useState(1);

  // const refreshData = async (pageNumber) => {
  // try {
  //   setLoading(true);
  //   const responseGetUsers = await serviceGetUsers(cookies.SIDikey, DefaultRoleFunctions.user["SUPER ADMIN"], null, pageNumber, 10);
  //   if (new RegExp(/20+/).test(responseGetUsers.status)) {
  //     const resultData = responseGetUsers.data.data;
  //     setMetaData(responseGetUsers.data.meta);
  //     const finalData = [];
  //     resultData.forEach((user) => {
  //       let createdBy = "";
  //       let updatedBy = "";

  //       if (user.createdBy != null) {
  //         createdBy = user.createdBy.firstName + " " + user.createdBy.lastName;
  //       } else {
  //         createdBy = "-";
  //       }

  //       if (user.updatedBy != null) {
  //         updatedBy = user.updatedBy.firstName + " " + user.updatedBy.lastName;
  //       } else {
  //         updatedBy = "-";
  //       }
  //       // console.log(user)

  //       const tempData = {
  //         id: user.id,
  //         createdAt: user.createdAt,
  //         updatedAt: user.updatedAt,
  //         createdBy: createdBy,
  //         updatedBy: updatedBy,
  //         firstName: user.firstName,
  //         lastName: user.lastName,
  //         role: user.role.name,
  //         email: user.email,
  //         phone: user.phone,
  //         department: {
  //           id: user.department?.id || "1232wdwqe123",
  //           name: user.department?.name || "Outsider",
  //           color: user.department?.color || "#fffff",
  //         },
  //         active: true,
  //         weight: user?.weight || 0,
  //       };
  //       finalData.push(tempData);
  //     });

  //     setData(finalData.filter((fd) => fd.role === "SALES"));
  //   } else {
  //     console.log(responseGetUsers);
  //   }
  //   setLoading(false);
  // } catch (error) {
  //   return error.response;
  // }
  // };

  const refreshDepartment = async () => {
    try {
      setLoading(true);
      const responseGetDepartments = await getDepartments(cookies.SIDikey, '', cookies.departmentIds, cookies.id, roleFunctions);
      if (new RegExp(/20+/).test(responseGetDepartments.status)) {
        const resultData = responseGetDepartments.data.data;
        if (resultData.length > 0) {
          // setActiveTab(resultData[0].name);
        }
        setListChannels(resultData);
      } else {
        console.log(responseGetDepartments);
      }
      setLoading(false);
    } catch (error) {
      return error.response;
    }
  };

  const onDeleteClick = () => {
    if (isEdit) {
      Swal.fire("Error", "Tidak bisa reset ronde ketika sedang dalam mode edit", "error");
      return;
    }
    Swal.fire({
      title: "Are you sure for reset ronde ?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, reset it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const responseDeletRotator = await deleteRotators(deparment.id, cookies.SIDikey);
        if (new RegExp(/20+/).test(responseDeletRotator.status)) {
          Swal.fire({
            icon: "success",
            title: "Reset Ronde Success",
            focusConfirm: false,
          }).then(() => {
            // refreshDepartment();
            selectTab(deparment, activeTab);
          });
        } else {
          console.log(responseDeletRotator);
          Swal.fire("Reset Ronde Failed", responseDeletRotator.data.message[0].detail, "error");
        }
      }
    });
  };

  const selectTab = async (department, departmentName, pageNumber) => {
    // var total = 0;
    setDepartment(department);
    setActiveTab(departmentName);
    // setListUser(data.filter((d) => d.department.id === department.id));
    try {
      setLoading(true);
      const responseGetUsers = await serviceGetUsersWeightByDeptId(cookies.SIDikey, department.id);
      if (new RegExp(/20+/).test(responseGetUsers.status)) {
        // const resultData = responseGetUsers.data.data;
        const resultData = responseGetUsers.data;
        // setMetaData(responseGetUsers.data.meta);
        const finalData = [];
        resultData.forEach((user) => {
          let createdBy = "";
          let updatedBy = "";

          if (user.createdBy != null) {
            createdBy = user.createdBy.firstName + " " + user.createdBy.lastName;
          } else {
            createdBy = "-";
          }

          if (user.updatedBy != null) {
            updatedBy = user.updatedBy.firstName + " " + user.updatedBy.lastName;
          } else {
            updatedBy = "-";
          }

          // console.log(888, user)

          const tempData = {
            id: user.id,
            createdAt: user.createdAt,
            updatedAt: user.updatedAt,
            createdBy: createdBy,
            updatedBy: updatedBy,
            firstName: user.firstName,
            lastName: user.lastName,
            role: user.role.name,
            email: user.email,
            phone: user.phone,
            lastRotator: user?.lastRotator,
            currentWeight: user?.lastRotator ? user?.lastRotator?.currentWeight : "-",
            // department: {
            //   id: user.department?.id || "1232wdwqe123",
            //   name: user.department?.name || "Outsider",
            //   color: user.department?.color || "#fffff",
            // }, 
            active: true,
            weight: user?.departmentUsers[0]?.weight || 0,
          };
          finalData.push(tempData);
        });

        // setData(finalData.filter((fd) => fd.role === "SALES"));
        setData(finalData);
      } else {
        console.log(responseGetUsers);
      }
      setLoading(false);
    } catch (error) {
      return error.response;
    }
  };

  useEffect(() => {
    let total = 0;
    if (data) {
      data.forEach((l) => (total += parseInt(l.weight)));
    }
    setWeight(total);
  }, [data, setData]);

  useEffect(() => {
    refreshDepartment();
    // refreshData(1);
  }, []);

  const handleWeightChange = (e, idx) => {
    const lU = [...data];
    const nilai = e.target.value || 0;
    lU[idx].weight = nilai;
    setData([...lU]);
  };

  const cancelEdit = () => { 
    setData(currentWeights);
    setIsEdit(false);
  };

  const handleSave = async () => {
    if (!!data & (weight === 100)) {
      const weightList = data.map((lu) => {
        return { userId: lu.id, weight: Number(lu.weight), departmentId:deparment.id };
      });
      const finalList = {
        departmentId:deparment.id, 
        items: weightList
      }
      const resp = await serviceUpdateUserRotatorWeight(cookies.SIDikey, finalList);
      if (resp === 200) {
        Swal.fire("Succes", "Bobot berhasil disimpan", "success");
        setIsEdit(false);
      }
    } else {
      Swal.fire("Error", "Total bobot harus 100", "error");
    }
  };

  const paginate = (pageNumber) => {
    selectTab(deparment, activeTab, pageNumber);
  };

  return (
    <React.Fragment>
      <Head title="Relasi Admin" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                User
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
          <BlockTitle tag="h6" className="mt-4">
            <span style={{ color: "#4461F2" }}>Relasi Admin</span>
          </BlockTitle>
          <Card className="card-bordered my-4 card-bluesky">
            <CardBody className="card-bluesky-body">
              <strong>Info:</strong>
              <p>
                Menu ini digunakan untuk melihat semua cs anda terhubung dengan nomor WA mana saja dan Anda dapat
                menambah dari data Admin yang sudah ada atau menghapus dari nomor{" "}
              </p>
            </CardBody>
          </Card>
        </BlockHead>
        <Block></Block>
        <Block className="position-relative">
          {!roleFunctions.includes("create") || (
            <Button
              color="danger"
              className="btn"
              style={{ position: "absolute", right: "20px", top: "-30px" }}
              onClick={onDeleteClick}
            >
              <span>Reset Ronde</span>
            </Button>
          )}
          <DataTable className="card-stretch">
            <Row>
              <Col md="2" lg="2" className="relation-channel">
                <div>
                  <ul className="relation-channel">
                    {listChannels.length > 0
                      ? listChannels.map((channel, idx) => {
                          return (
                            <li
                              key={idx}
                              className={
                                activeTab === channel.name ? "relation-channel-list active" : "relation-channel-list"
                              }
                              onClick={() => selectTab(channel, channel.name, pageNumber)}
                            >
                              {channel.name}
                            </li>
                          );
                        })
                      : null}
                  </ul>
                </div>
              </Col>
              <Col md="10" lg="10">
                <DataTableBody compact>
                  <DataTableHead>
                    <DataTableRow>
                      <span className="sub-text">No</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="sub-text">Nama</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="sub-text">Email</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="sub-text">Hak Akses</span>
                    </DataTableRow>
                    {/* <DataTableRow>
                      <span className="sub-text">Department</span>
                    </DataTableRow> */}
                    <DataTableRow>
                      <span className="sub-text">Bobot Rotasi</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="sub-text">Sisa Bobot</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="sub-text">Aksi</span>
                    </DataTableRow>
                  </DataTableHead>
                  {/* <DataTableBody> */}
                  {data ? (
                    data.map((item, idx) => {
                      return (
                        <DataTableHead key={idx}>
                          <DataTableRow>{idx + 1}</DataTableRow>
                          <DataTableRow>{item.firstName + " " + item?.lastName}</DataTableRow>
                          <DataTableRow>{item.email}</DataTableRow>
                          <DataTableRow>{item.role}</DataTableRow>
                          {/* <DataTableRow>{item.department.name}</DataTableRow> */}
                          {/* {console.log("data", JSON.stringify(currentWeights[idx].weight))} */}
                          <DataTableRow>
                            <input
                              className="form"
                              placeholder={isEdit ? currentWeights[idx].weight : item.weight}
                              type="number"
                              onChange={(e) => handleWeightChange(e, idx)}
                              disabled={!isEdit}
                            />
                          </DataTableRow>
                          <DataTableRow>{item.lastRotator === undefined ? item.weight : item.currentWeight}</DataTableRow>
                          <DataTableRow>
                            <div
                              className="nk-tb-action"
                              onClick={() => {
                                if (isEdit) {
                                  cancelEdit(); // Batalkan edit jika sudah dalam mode edit
                                } else {
                                  setCurrentWeights(JSON.parse(JSON.stringify(data))); // Simpan data sebelum edit dimulai
                                }
                                setIsEdit(!isEdit);
                              }}
                            >
                              <TooltipComponent
                                tag="a"
                                containerClassName="btn btn-trigger btn-icon"
                                id={"edit" + item.id}
                                icon="edit-alt-fill"
                                direction="top"
                                text="Edit"
                              />
                            </div>
                          </DataTableRow>
                        </DataTableHead>
                      );
                    })
                  ) : (
                    <DataTableHead>No Sales in this dept</DataTableHead>
                  )}
                  {activeTab && (
                    <DataTableHead>
                      <DataTableRow></DataTableRow>
                      <DataTableRow></DataTableRow>
                      <DataTableRow></DataTableRow>
                      <DataTableRow></DataTableRow>
                      {/* <DataTableRow></DataTableRow> */}
                      <DataTableRow>Total : {weight}</DataTableRow>
                    </DataTableHead>
                  )}
                </DataTableBody>
                {/* <div className="card-inner">
                  {data.length > 0 ? (
                    <PaginationComponent
                      itemPerPage={metaData?.take}
                      totalItems={metaData?.itemCount}
                      paginate={paginate}
                      currentPage={metaData?.page}
                    />
                  ) : (
                    <div className="text-center">
                      <span className="text-silent">No data found</span>
                    </div>
                  )}
                </div> */}
              </Col>
            </Row>
          </DataTable>
          {!activeTab && (
            <div className="position-absolute" style={{ top: "40%", left: "50%" }}>
              Mohon pilih department di samping
            </div>
          )}
          {loading && (
            <div className="position-absolute" style={{ top: "40%", left: "50%" }}>
              <Spinner size="sm" color="dark" />
            </div>
          )}
          <Block className="d-flex justify-content-start mt-4">
            <Link to={`${process.env.PUBLIC_URL + "/users"}`}>
              <Button color="primary" className="btn btn-outline mr-3" outline>
                Batal
              </Button>
            </Link>
            <Button color="primary" className="btn" onClick={(event) => handleSave()}>
              Simpan
            </Button>
          </Block>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default UserRelation;