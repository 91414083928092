import React, { useState, useEffect } from "react";
import { Card, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
// import { LeadConvertionRateData } from "./homeData";
import { Icon, LineChartExample } from "components/Component";
import { useCookies } from "react-cookie";
import moment from "moment";
import {
  getDashboardLeadConversion
} from "../../services/dashboard";

const LeadConvertionRate = (props) => {
  const { selectedDepartment } = props
  const [cookies] = useCookies(["user"]);
  const [dataChart, setDataChart] = useState(
    {
      labels: [],
      dataUnit: "BTC",
      lineTension: 0.4,
      legend: false,
      categoryPercentage: 0.9,
      barPercentage: 0.6,
      datasets: []
    }
  );
  const [datePeriod, setDatePeriod] = useState("MONTHLY");
  const roleFunctions = JSON.parse(localStorage.getItem("permission"))["dashboard"].access || [];
  
  const getDataLeadConversion = async (datePeriod) => {
    // const responseLeadConversion = await getDashboardLeadConversion(cookies.SIDikey, datePeriod);
    const responseLeadConversion = await getDashboardLeadConversion(cookies.SIDikey, selectedDepartment.value, datePeriod, roleFunctions);
    const labelChart = 
    datePeriod === 'DAILY' ?
      responseLeadConversion.data.map(item => moment(item.date).format('ll'))
    :datePeriod === 'WEEKLY' ?
      responseLeadConversion.data.map(item => moment(item.date).format('MMM DD'))
    :datePeriod === 'MONTHLY' ?
      responseLeadConversion.data.map(item => moment(item.date).format('MMM'))
    :datePeriod === 'YEARLY' ?
      responseLeadConversion.data.map(item => moment(item.date).format('YYYY'))
    : null
    setDataChart({
      labels: labelChart,
      dataUnit: "BTC",
      lineTension: 0.4,
      legend: false,
      categoryPercentage: 0.9,
      barPercentage: 0.6,
      datasets: [
        {
          label: "Rate",
          backgroundColor: "white",
          pointBorderWidth: 2,
          borderColor: "#798bff",
          fill: false,
          categoryPercentage: 0.9,
          barPercentage: 0.6,
          data: responseLeadConversion.data.map(item => item.rate),
        }
      ]
    })
  };

  useEffect(() => {
    getDataLeadConversion(datePeriod)
  }, [selectedDepartment]);

  const handleDatePeriod = (val) => {
    setDatePeriod(val)
    getDataLeadConversion(val)
  }

  return (
    <Card className="card-full overflow-hidden">
      <div className="nk-ecwg nk-ecwg8 h-100 d-flex flex-column">
        <div className="card-inner h-100 d-flex flex-column" style={{ flex: 1 }}>
          <div className="card-title-group mb-3">
            <div className="card-title">
              <h6 className="title">Lead Convertion Rate</h6>
            </div>
            <div className="card-tools">
              <UncontrolledDropdown>
                <DropdownToggle
                  tag="a"
                  href="#toggle"
                  onClick={(ev) => ev.preventDefault()}
                  className="link link-light link-sm dropdown-indicator"
                >
                  {
                    datePeriod === 'DAILY' ?
                      'Daily'
                    : datePeriod === 'WEEKLY' ?
                      'Weekly'
                    : datePeriod === 'MONTHLY' ?
                      'Monthly'
                    : datePeriod === 'YEARLY' ?
                      'Yearly'
                    : null
                  }
                </DropdownToggle>
                <DropdownMenu right className="dropdown-menu-sm">
                  <ul className="link-list-opt no-bdr">
                    <li>
                      <DropdownItem
                        tag="a"
                        href="#dropdown"
                        onClick={(ev) => {
                          ev.preventDefault();
                          handleDatePeriod('DAILY');
                        }}
                        className={datePeriod === "DAILY" ? "active" : ""}
                      >
                        <span>Daily</span>
                      </DropdownItem>
                    </li>
                    <li>
                      <DropdownItem
                        tag="a"
                        href="#dropdown"
                        onClick={(ev) => {
                          ev.preventDefault();
                          handleDatePeriod('WEEKLY');
                        }}
                        className={datePeriod === "WEEKLY" ? "active" : ""}
                      >
                        <span>Weekly</span>
                      </DropdownItem>
                    </li>
                    <li>
                      <DropdownItem
                        tag="a"
                        href="#dropdown"
                        onClick={(ev) => {
                          ev.preventDefault();
                          handleDatePeriod('MONTHLY');
                        }}
                        className={datePeriod === "MONTHLY" ? "active" : ""}
                      >
                        <span>Monthly</span>
                      </DropdownItem>
                    </li>
                    <li>
                      <DropdownItem
                        tag="a"
                        href="#dropdown"
                        onClick={(ev) => {
                          ev.preventDefault();
                          handleDatePeriod('YEARLY');
                        }}
                        className={datePeriod === "YEARLY" ? "active" : ""}
                      >
                        <span>Yearly</span>
                      </DropdownItem>
                    </li>
                  </ul>
                </DropdownMenu>
              </UncontrolledDropdown>
              {/* <div className="btn btn-round btn-icon btn-light dropdown-toggle ml-2" style={{ backgroundColor: 'unset', border: 'unset' }}>
                <Icon name="download-cloud"></Icon>
              </div> */}
            </div>
          </div>
          <div className="nk-ecwg8-ck" style={{ margin: 'auto 0px' }}>
            <LineChartExample data={dataChart} />
          </div>
        </div>
      </div>
    </Card>
  );
};
export default LeadConvertionRate;
