import { Icon } from "components/Component";
import React, { useContext, useEffect, useState } from "react";
import { ModalBody, Modal, Button, Spinner, Row, Col, Badge } from "reactstrap";
import Select from "react-select";
import Swal from "sweetalert2";
import { astroProducts } from "./AstroProductsData";
import { serviceGetProducts, serviceGetResolveAstro } from "services/productAstro";
import { servicePutChat } from "services/chats";
import { servicePutContact } from "services/contact";
import { serviceSendMessage } from "services/messages";
import moment from "moment";
import { delay } from "utils/Utils";
import { socketIO } from "services/socket";
import { servicePutTickets } from "services/tickets";
import { ChatDetailContext } from "contexts/chatDetailContext";
import {
  serviceCreateCrmResolve,
  serviceGetCrmResolveByParam,
  serviceCrmChangeStatusBulk,
  serviceDeleteCrmProduct,
  serviceGetCrmById,
} from "services/crmResolve";
import { serviceResetTag } from "services/chats";
import { Line } from "react-chartjs-2";

const ChatModalResolve = (props) => {
  const { cookies, chat, setChat, messages, setMessages, isModalResolveOpen, setIsModalResolveOpen, AfterAssign } =
    props;
  // const [inputSearch, setInputSearch] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [purchasedProducts, setPurchasedProducts] = useState([]);
  const [loadmore, setLoadmore] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [dataProducts, setDataProducts] = useState(astroProducts[1]);
  const [Uchat, setUchat] = useContext(ChatDetailContext);
  const deptId = chat?.channel?.departmentId;

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "IDR",
  });

  const dateCheck = (input) => {
    return new Date(Number(input)).toLocaleString("en-GB", {
      month: "numeric",
      year: "numeric",
      day: "numeric",
    });
  };
  /*
  debounce afif search text
  const [resultList, setResultList] = useState([...dataProducts]);
  const [dataList, setDataList] = useState([...dataProducts]);
  useEffect(() => {
    if (inputSearch !== "") {
      const newResultList = [];
      dataList.map((data, index) => {
        if (data.product_name.toLowerCase().includes(inputSearch.toLocaleLowerCase())) {
          newResultList.push(data);
        }
      });
      setResultList(newResultList);
    } else {
      setResultList(dataList);
    }
  }, [inputSearch]);
  */

  useEffect(() => {
    setPurchasedProducts([]);
    setLoadmore(1);
    if (isModalResolveOpen) {
      refreshDataProducts();
      refreshDataCustomer();
    } else {
      setSelectedProducts([]);
    }
  }, [isModalResolveOpen]);

  const handleSelectedProduct = (e) => {
    setSelectedProducts(
      e.map((product) => {
        return product;
      })
    );
  };

  const refreshDataCustomer = async () => {
    const resp = await serviceGetCrmResolveByParam(
      cookies.SIDikey,
      chat?.contactId,
      deptId,
      chat.customerPhone,
      chat.id,
      1
    );
    // const resp = await serviceGetCrmResolveByParam(cookies.SIDikey, chat?.contactId, null, chat.id);
    if (resp.status === 200) {
      const prodRaws = resp.data.data;
      const prods = [];
      prodRaws.forEach((prodRaw) => {
        const prodEnd = JSON.parse(prodRaw.productDescription);
        if (prodEnd !== null) {
          prodEnd.forEach((pr) => {
            prods.push({ items: pr, crmId: prodRaw.id, timestamp: prodRaw.createdAt, status: prodRaw.status, ticketId: prodRaw.ticketId });
          });
        }
      });
      setLoadmore(1);
      setPurchasedProducts(prods);
    }
  };

  const refreshDataProducts = async () => {
    let newDeptIdAstro = cookies.pcData.find((itm) => itm.deptId === Uchat.channel?.department.id);
    const resp = await serviceGetProducts(cookies.AstroKey, newDeptIdAstro.pc);
    if (resp.status === 200) {
      setDataProducts(resp.data.data);
    }
  };

  const removeTag = async (id) => {
    await serviceResetTag(cookies.SIDikey, "chatId", [{ id: id, tagIds: [] }]);
  };

  async function serviceGetResolveWithTimeout(cookies, params, timeout = 8000) {
    return new Promise((resolve, reject) => {
      const timer = setTimeout(() => {
        reject(new Error("Request timed out"));
      }, timeout);

      serviceGetResolveAstro(cookies, params)
        .then((response) => {
          clearTimeout(timer);
          resolve(response);
        })
        .catch((err) => {
          clearTimeout(timer);
          reject(err);
        });
    });
  }

  // SUBMIT RESOLVE
  const handleSubmit = async () => {
    setIsLoading(true);
    setLoadmore(1);
    if (!Uchat.contactId) {
      Swal.fire("Contact belum di simpan", "Mohon simpan contact terlebih dahulu!", "error");
      setIsLoading(false);
      return;
    }
    // jika status masih resolved
    // rubah
    if (Uchat.status === "RESOLVED") {
      Swal.fire("Disabled Resolve", `Chat masih dalam keadaan ${Uchat.status}`, "error");
      setIsLoading(false);
      return;
    }
    // jika user bukan userId dan ia adalah sales
    if (Uchat.userId !== cookies.id && (cookies.role.name === "SALES" || cookies.role.name === "CS")) {
      Swal.fire("Disabled Resolve", `Anda bukan PIC`, "error");
      setIsLoading(false);
      return;
    }

    if (cookies.role.name !== "SALES" && cookies.role.name !== "CS") {
      Swal.fire("Disabled Resolve", `Anda Tidak Berwenang`, "error");
      setIsLoading(false);
      return;
    }
    const newChat = chat;
    const ticketId = newChat?.ticketId ?? newChat?.tickets[0].id;

    if (!ticketId) {
      Swal.fire("WARNING", "Ticket ID was not found", "warning");
      setIsLoading(false);
      return;
    }

    const formattedDate = new Date(Date.now()).toLocaleString("en-GB", {
      month: "numeric",
      year: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false // Ensuring 24-hour format
    });
    
    // Splitting the formatted date into date and time components
    const [datePart, timePart] = formattedDate.split(", ");

    if (selectedProducts.length > 0) {
      const selectedPc = cookies.pcData.find((itm) => itm.deptId === Uchat.channel?.department.id);
      const ticket = newChat?.tickets[0];
      let dataLoop = 0;
      const allDataTransaction = [];
      let isResolved = false;
      let isResolvedAstro = false;
      // while (dataLoop < selectedProducts.length) {
      //   const respResolve = await serviceGetResolveAstro(cookies.AstroKey, {
      //     pc: 2,
      //     product: [selectedProducts[dataLoop].item.id],
      //     phone: "+" + Uchat.customerPhone,
      //     ticket: ticket.id,
      //   });
      //   allDataTransaction.push({ respResolve: respResolve, prodId: selectedProducts[dataLoop].item.id });
      //   dataLoop += 1;
      // }

      try {
        const respResolve = await serviceGetResolveWithTimeout(
          cookies.AstroKey,
          {
            pc: selectedPc.pc,
            product: selectedProducts.map((data)=>data.item.id),
            phone: "+" + Uchat.customerPhone,
            ticket: ticket.id,
          },
          2000
        );

        if(respResolve?.data.status === 'success') {
          isResolvedAstro = true;
          allDataTransaction.push({ respResolve: respResolve, prodId: selectedProducts[dataLoop].item.id });
        }

      } catch (error) {
        console.error(`Failed to resolve for product ${selectedProducts[dataLoop].item.id}:`, error);
        Swal.fire("Timeout From Astro", "Astronacci backend taking too long, pleas try again!", "error");
        return false; // Return false if any request fails or times out
      }

      const status = isResolvedAstro ? "RESOLVED" : "PENDING";

      // if(!isResolvedAstro){
      //   Swal.fire("Beluum semua product Resolved.", "", "error");
      //   setIsLoading(false);
      //   return;
      // }

      // newChat.contact.metadata = selectedProducts;
      let produtsString = "";
      let productsArray = [];
      let productsJson = [];

      selectedProducts.forEach((element) => {
        produtsString += `\n (${element.item.id}) ${element.item.product_name} [Rp.${element.item.product_price}]`;
        productsArray.push(parseInt(element.item.id));
        productsJson.push({
          id: element.item.id,
          productName: element.item.product_name,
          price: element.item.product_price,
        });
      });

      let resolveLoop = 0;
      // while (resolveLoop < selectedProducts.length) {
      while (resolveLoop < 1) {

        const dataCrmResolve = {
          phone: chat.customerPhone,
          product: 0,
          pc: selectedPc.pc,
          products: productsArray,
          chatId: chat.id,
          contactId: chat.contactId,
          ticketId: chat?.tickets[0].id,
          productDescription: JSON.stringify([productsJson]),
          status: status,
          sendToCustomer: isResolvedAstro ? 1 : 0,
          // sendToCustomer: selectedProducts.length - 1 === resolveLoop && findNotFoundTransation < 0 ? 1 : 0,
        };
        const createCrmHistory = await serviceCreateCrmResolve(cookies.SIDkey, dataCrmResolve, status);
        if(createCrmHistory.status === 200){
          isResolved = true;
        }
        // dataAllResolve.push(createCrmHistory);
        resolveLoop += 1;
      }

      // const createCrmHistoryStatus = dataAllResolve.findIndex((reso) => new RegExp(/20+/).test(reso.status));

      // if (createCrmHistoryStatus < 0) {
      //   Swal.fire("Gagal Resolve add history", "mohon coba lagi", "error");
      //   setIsLoading(false);
      //   return;
      // }

      if (!isResolvedAstro) {
        Swal.fire("WARNING", "Transaction Not Found in Astro", "warning");
        await delay(1000);
        newChat.status = "PENDING";
        newChat.adminStatus = "PENDING";
      } else {
        Swal.fire("FOUND", "Transaction Found", "success");
        newChat.status = "RESOLVED";
        newChat.adminStatus = "RESOLVED";
      }

      const respPutChat = await servicePutChat(cookies.SIDkey, Uchat.id, {
        status: status,
        adminStatus: status,
        unreadCount: 0,
        unreadCountAdmin: 0,
      });

      if (respPutChat.status !== 202) {
        Swal.fire("Gagal ubah status chat.", "mohon coba lagi", "error");
        setIsLoading(false);
        return;
      }

      // const temp = await serviceCrmChangeStatusBulk(cookies.SIDkey, {
      //   chatId: Uchat.id,
      //   chatData: {
      //     status: newChat.status,
      //     adminStatus: newChat.adminStatus,
      //     unreadCount: 0,
      //     unreadCountAdmin: 0,
      //   },
      //   contactId: newChat.contactId,
      //   contactData: { metadata: selectedProducts },
      //   ticketId: newChat?.tickets[0].id,
      //   ticketData: { status: newChat.adminStatus },
      // });

      newChat.status = status;
      newChat.adminStatus = status;
      newChat.unreadCount = 0;
      newChat.unreadCountAdmin = 0;

      // await servicePutContact(cookies.SIDkey, newChat.contactId, { metadata: selectedProducts });
      await servicePutTickets(Uchat?.tickets[0].id, cookies.SIDikey, { status: status });

      const dataMessage = {
        type: "PURCHASE",
        status: "READ",
        text: `Chat Session ${newChat.status} pada ${datePart} ${timePart}
        \n ${produtsString}`,
        chatId: newChat.id,
        wa_id: newChat.customerPhone,
      };

      dataMessage["fromMe"] = true;

      const resp = await serviceSendMessage(cookies.SIDkey, dataMessage);
      setUchat({ ...newChat });

      // AfterAssign();
      setMessages((messages) => [...messages, resp.data]);

      if (respPutChat.data.status === "RESOLVED") {
        Swal.fire("Submited", "resolve", "success");
        newChat.tags = [];
        removeTag(newChat.id);
      } else {
        Swal.fire("Submited", "pending", "warning");
      }

      refreshDataCustomer();
      
      const resolveMessage = resp.data;
      resolveMessage.chat = Uchat;
      resolveMessage.chat.lastMessage = null;
      resolveMessage.ticketId = Uchat?.tickets[0].id;
      
      if (cookies.role.name === "SALES" || cookies.role.name === "CS") {
        socketIO.emit("admin", JSON.stringify(resolveMessage));
        if (respPutChat.data.status === "RESOLVED") {
          socketIO.emit(
            "admin",
            JSON.stringify({
              type: "tag-update-resolve",
              fromMe: true,
              chatId: Uchat.id,
              chat: {
                id: Uchat.id,
                user: Uchat.user,
                userId: Uchat.user.id,
                channel: Uchat.channel,
              },
              tags: [],
              // ticketId: Uchat?.tickets[0].id,
              ticketId: Uchat?.ticketId,
            })
          );
        }
        socketIO.emit(Uchat.userId, JSON.stringify(resolveMessage));
      } else {
        socketIO.emit(Uchat.userId, JSON.stringify(resolveMessage));
      }
    } else {
      Swal.fire("Pilih Item", "resolve", "error");
    }
    setIsLoading(false);
  };

  // resolve icon based on status and role
  const SolveIcon = () => {
    if (Uchat.status === "RESOLVED") return <i className="bi bi-check-circle modal-resolve-icon"></i>;
    if (Uchat.status === "PENDING")
      return <i className="bi bi-exclamation-circle modal-resolve-icon pending-status"></i>;
    return <i className="bi bi-box-arrow-in-up modal-resolve-icon blue"></i>;
  };

  // add more item to history list
  async function handleLoadMore() {
    const resp = await serviceGetCrmResolveByParam(
      cookies.SIDikey,
      chat?.contactId,
      deptId,
      chat.customerPhone,
      chat.id,
      loadmore + 1
    );
    // const resp = await serviceGetCrmResolveByParam(cookies.SIDikey, chat?.contactId, null, chat.id);
    if (resp.status === 200) {
      const prodRaws = resp.data.data;
      const prods = purchasedProducts;
      prodRaws.forEach((prodRaw) => {
        const prodEnd = JSON.parse(prodRaw.productDescription);
        if (prodEnd !== null) {
          prodEnd.forEach((pr) => {
            prods.push({ items: pr, crmId: prodRaw.id, timestamp: prodRaw.createdAt, status: prodRaw.status, ticketId: prodRaw.ticketId });
          });
        }
      });

      setPurchasedProducts(prods);
      if (loadmore < resp.data.meta.pageCount) {
        setLoadmore(loadmore + 1);
      } else {
        setLoadmore(-1);
      }
    }
  }

  const handleDeletePendingProducts = async (product) => {

    Swal.fire({
      title: "Apakah anda yakin untuk menghapus Product ini?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#1ee0ac",
      confirmButtonText: "Ya, hapus!",
      cancelButtonText: "Tidak",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const respCrmHistory = await serviceGetCrmById(
            cookies.SIDikey,
            product.crmId,
            product.ticketId,
          );

          // delete crm 
          await serviceDeleteCrmProduct(
            cookies.SIDikey,
            product.crmId
          );

          let isAllResolved = true;
          let productsLeft = '';

          if(respCrmHistory.data){
            
            // check products
            let validData = respCrmHistory.data.data.filter(data => !data.products.includes(product.id));

            // if product still left, check all
            if(validData.length > 0){
              setUchat({ ...Uchat, status: 'OPENED', adminStatus: 'OPENED' });
              // if all resolve : resolve
              const selectedPc = cookies.pcData.find((itm) => itm.deptId === Uchat.channel?.department.id);

              for (const prod of validData) {
                
                const parseProd = JSON.parse(prod.productDescription)[0];
                productsLeft += `\n (${parseProd.id}) ${parseProd.productName} [Rp.${parseProd.price}]`;
                
                const productId = prod.products[0];
                const respResolve = await serviceGetResolveAstro(cookies.AstroKey, {
                  pc: selectedPc.pc,
                  product: [productId],
                  phone: "+" + Uchat.customerPhone,
                  ticket: Uchat?.tickets[0].id,
                });
                
                if (respResolve.statusText === "Not Found" || respResolve.status === "No Server Response") {
                  isAllResolved = false
                }else{

                }
              }

              // change chat status to open
              // change ticket status to open
              await servicePutChat(cookies.SIDikey, Uchat.id, { status: "OPENED", adminStatus:'OPENED', unreadCount: 0 });
              if(Uchat.tickets && Uchat.tickets.length > 0){
                await servicePutTickets(Uchat.tickets[0].id, cookies.SIDikey, { status: "OPENED" });
              }

              const updateTicketStatus = {
                type: "status-update",
                id: Uchat.id,
                ticketId: Uchat.tickets ? Uchat.tickets[0].id : Uchat.lastMessage.ticketId,
                chat: {
                  id: Uchat.id,
                  status: "OPENED",
                  user: Uchat.user,
                  channel: Uchat.channel
                },
                chatId: Uchat.id,
                fromMe: true,
              };

              const dataMessage = {
                type: "PURCHASE",
                status: "READ",
                text: `Chat Session OPENED, delete transaction : ${product.crmId}`,
                chatId: Uchat.id,
                wa_id: Uchat.customerPhone,
              };
        
              dataMessage["fromMe"] = true;
              const resp = await serviceSendMessage(cookies.SIDkey, dataMessage);
              
              const resolveMessage = resp.data;
              resolveMessage.chat = Uchat;
              resolveMessage.chat.lastMessage = null;
              resolveMessage.chat.status = 'OPENED';
              resolveMessage.chat.adminStatus = 'OPENED';
              resolveMessage.ticketId = Uchat?.tickets[0].id;

              socketIO.emit("admin", JSON.stringify(resolveMessage));
              socketIO.emit("admin", JSON.stringify(updateTicketStatus));

              AfterAssign();
              setIsModalResolveOpen(false);

              return

            }else{// jika tidak ada barang lainnya
            
              // change chat status to open
              // change ticket status to open
              await servicePutChat(cookies.SIDikey, Uchat.id, { status: "OPENED", adminStatus: "OPENED", unreadCount: 0 });
              if(Uchat.tickets && Uchat.tickets.length>0){
                await servicePutTickets(Uchat.tickets[0].id, cookies.SIDikey, { status: "OPENED" });
              }

              const updateTicketStatus = {
                type: "status-update",
                id: Uchat.id,
                ticketId: Uchat.tickets ? Uchat.tickets[0].id : Uchat.lastMessage.ticketId,
                chat: {
                  id: Uchat.id,
                  status: "OPENED",
                  user: Uchat.user,
                  channel: Uchat.channel
                },
                chatId: Uchat.id,
                fromMe: true,
              };
              socketIO.emit("admin", JSON.stringify(updateTicketStatus));
              setIsModalResolveOpen(false);

            }
          }
        
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  const handleCheckPendingCrm = async (product) => {
    setIsLoading(true);
    const selectedPc = cookies.pcData.find((itm) => itm.deptId === Uchat.channel?.department.id);
    const respResolve = await serviceGetResolveAstro(cookies.AstroKey, {
      pc: selectedPc.pc,
      product: [product.id],
      phone: "+" + Uchat.customerPhone,
      ticket: Uchat?.tickets[0].id,
    });

    if (respResolve.statusText === "Not Found") {
      Swal.fire("Transaksi tidak ditemukan", "Pastikan customer melakukan pembayaran", "error");
      setIsLoading(false);
      return;
    }

    const formattedDate = new Date(Date.now()).toLocaleString("en-GB", {
      month: "numeric",
      year: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false // Ensuring 24-hour format
    });
    
    // Splitting the formatted date into date and time components
    const [datePart, timePart] = formattedDate.split(", ");

    const respCrmHistory = await serviceGetCrmResolveByParam(
      cookies.SIDikey,
      Uchat?.contactId,
      deptId,
      Uchat.customerPhone,
      chat.id,
      1,
      "PENDING"
    );

    const productsJson = { id: product.id, productName: product.productName, price: product.price };

    const dataCrmResolve = {
      phone: Uchat.customerPhone,
      product: product.id,
      pc: selectedPc.pc,
      products: [product.id],
      chatId: Uchat.id,
      contactId: Uchat.contactId,
      ticketId: Uchat?.tickets[0].id,
      productDescription: JSON.stringify([productsJson]),
      status: "RESOLVED",
      sendToCustomer: respCrmHistory.data.data.length > 1 ? 0 : 1, // send rating to customer
    };

    const createCrmHistory = await serviceCreateCrmResolve(cookies.SIDkey, dataCrmResolve, "RESOLVED");

    if (createCrmHistory.statusText !== "Created") {
      Swal.fire("Gagal Resolve", "mohon coba lagi", "error");
      setIsLoading(false);
      return;
    }

    if (respCrmHistory.data.data.length > 1) {
      const putChatStatus = await servicePutChat(cookies.SIDkey, Uchat.id, {
        status: "PENDING",
        adminStatus: "PENDING",
        unreadCount: 0,
        unreadCountAdmin: 0,
      });
      chat.adminStatus = "PENDING";
      chat.status = "PENDING";
      setUchat({ ...chat });
    } else {
      // Remove tag
      removeTag(Uchat.id);
      socketIO.emit(
        "admin",
        JSON.stringify({
          type: "tag-update-resolve",
          fromMe: true,
          chatId: Uchat.id,
          chat: {
            id: Uchat.id,
            user: Uchat.user,
            userId: Uchat.user.id,
          },
          tags: [],
          ticketId: Uchat?.tickets[0].id,
        })
      );

      // add resolve message
      const productString = `\n (${product.id}) ${product.productName} [Rp.${product.price}]`;
      const formattedDate = new Date(Date.now()).toLocaleString("en-GB", {
        month: "numeric",
        year: "numeric",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false, // Ensures 24-hour format, which may help with locale differences
        timeZone: 'UTC' // Set to UTC or your specific timezone as needed
      });

      const dataMessage = {
        type: "PURCHASE",
        status: "READ",
        text: `Chat Session RESOLVED pada ${datePart} ${timePart} \n ${productString}`,
        chatId: Uchat.id,
        wa_id: Uchat.customerPhone,
      };

      dataMessage["fromMe"] = true;
      const resp = await serviceSendMessage(cookies.SIDkey, dataMessage);

      const resolveMessage = resp.data;
      resolveMessage.chat = Uchat;
      resolveMessage.chat.lastMessage = null;
      resolveMessage.ticketId = Uchat?.tickets[0].id;

      socketIO.emit("admin", JSON.stringify(resolveMessage));
    }
    Swal.fire("Submited", "resolve", "success");

    refreshDataCustomer();
    setIsLoading(false);
  };

  return (
    <Modal
      isOpen={isModalResolveOpen}
      backdrop="static"
      toggle={() => setIsModalResolveOpen(false)}
      className="modal-dialog-centered"
      size="lg"
    >
      <ModalBody>
        {isLoading ? (
          <Spinner></Spinner>
        ) : (
          <React.Fragment>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                setIsModalResolveOpen(false);
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="text-center">
              <span className="modal-resolve-title">Status: </span>
              <span className={`modal-resolve-title color-${Uchat.status}`}>{Uchat.status}</span>
              <div>
                <SolveIcon />
              </div>
            </div>
            {purchasedProducts.length > 0 ? (
              <div className="d-flex flex-column justify-content-start align-item-center mb-3 overflow-y-auto">
                <h6>Recent Purchase History</h6>
                <Row className="d-flex border">
                  <Col xs="1">Id</Col>
                  <Col xs="4">Nama</Col>
                  <Col xs="3">Timestamp</Col>
                  <Col xs="1">Status</Col>
                  <Col xs="2"></Col>
                </Row>
                {purchasedProducts.map((product, index) => {
                  let tableIds = '';
                  let tableProducts = '';
                  if(product.items.length > 1){
                    tableIds = product.items.map((pi) => {return <p> {pi.id} <br class="150%space"/> </p>})
                    tableProducts = product.items.map((pi) => {return <p> {pi.productName} <br class="padding-0"/> </p>});
                  }else{
                    tableIds = product.items.id;
                    tableProducts = product.items.productName;
                  }
                  return (
                    <Row key={index} className="history-crm-row border-bottom">
                      <Col xs="1">{tableIds}</Col>
                      <Col xs="4">{tableProducts}</Col>
                      <Col xs="3">{moment(product.timestamp).format("MMM DD, YYYY, HH:mm")}</Col>
                      <Col xs="1"><Badge color={product.status === "RESOLVED" ? "success" : "warning"}>{product.status}</Badge></Col>
                      {props.roleFunctions.includes("chat") ? (
                        <Col xs="2" className="history-crm-button">
                          {product.status === "PENDING" ? (
                            <>
                              <Icon
                                name="reload-alt"
                                className="crm-recheck text-white bg-primary"
                                onClick={() => handleCheckPendingCrm(product)}
                              />
                              <Icon
                                name="trash-alt"
                                className="crm-recheck ml-1 text-white bg-danger"
                                color="primary"
                                onClick={() => handleDeletePendingProducts(product)}
                              />
                            </>
                          ) : (
                            ""
                          )}
                        </Col>
                      ) : (
                        ""
                      )}
                    </Row>
                  );
                })}
                {loadmore > 0 ? (
                  <Button
                    color="secondary"
                    className="mt-2 mb-2 mx-auto w-50"
                    block
                    style={{ justifyContent: "center" }}
                    onClick={(e) => handleLoadMore()}
                  >
                    Load More
                  </Button>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <></>
            )}
            {/* {!props.roleFunctions.includes("chat") || Uchat.status === "PENDING" || Uchat.status === "RESOLVED" ? ( */}
            {!props.roleFunctions.includes("chat") || Uchat.status === "PENDING" || Uchat.status === "RESOLVED" ? (
              ""
            ) : (
              <div>
                {/* 
                  PC ID Select
                  <select name="pc_id" id="pc_id" class="form-select w-full border flex-1 ">
                    <option value="">Select Profit Center</option>
                    <option value="1">A-CLUB</option>
                    <option value="2">A-SHOP</option>
                    <option value="3">CAT</option>
                    <option value="4">ORBI</option>
                    <option value="5">Sekuritas</option>
                    <option value="6">UOB</option>
                    <option value="7">SUCOR</option>
                  </select> */}
                <div className="form-control-wrap">
                  <div className="form-icon form-icon-left">
                    <Icon name="search"></Icon>
                  </div>
                  <Select
                    onChange={(e) => handleSelectedProduct(e)}
                    closeMenuOnSelect={false}
                    // value={selectedProducts?selectedProducts:[]}
                    isMulti
                    options={dataProducts.map((data) => {
                      return {
                        value: data.id,
                        label: `${data.id} - ${data.product_name} (${formatter.format(data.product_price)})`,
                        item: data,
                        timestamp: Date.now(),
                      };
                    })}
                  />
                  <Button
                    color="primary"
                    className="mt-2 mx-auto"
                    block
                    style={{ justifyContent: "center" }}
                    onClick={(e) => handleSubmit()}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            )}
          </React.Fragment>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ChatModalResolve;
