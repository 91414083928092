import React, { useEffect, useState } from "react";
import Head from "layout/head/Head";
import Content from "layout/content/Content";
import { useCookies } from "react-cookie";
import { DropdownToggle, DropdownMenu, UncontrolledDropdown, DropdownItem } from "reactstrap";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  BlockBetween,
} from "components/Component";
import TotalNewLead from "./TotalNewLead";
import TotalNewProspect from "./TotalNewProspect";
import TotalClosing from "./TotalClosing";
import AvgTimeChat from "./AvgTimeChat";
import RatingSales from "./RatingSales";
import RatingSalesAll from "./RatingSalesAll";
import CustomerLeadsOpen from "./CustomerLeadsOpen";
import CustomerHotProspect from "./CustomerHotProspect";
import CustomerWarmProspect from "./CustomerWarmProspect";
import {
  getDepartmentList
} from "../../services/dashboard";

const SalesDashboard = () => {
  const [cookies, setCookies] = useCookies(["user"]);
  const [departmentList, setDepartmentList] = useState([
    { name: "All Department", value: cookies.departmentIds.toString() },
  ]);
  const [sm, updateSm] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(departmentList[0]);
  const roleFunctions = JSON.parse(localStorage.getItem("permission"))["dashboard"].access || [];
  
  const getDataDepartmentList = async () => {
    const responseDepartmentList = await getDepartmentList(cookies.SIDikey, roleFunctions, cookies.departmentIds);
    const arrDepartmentList = [{ name: "All Department", value: cookies.departmentIds.toString() }]
    responseDepartmentList.data.data.map(item => 
      arrDepartmentList.push({
        name: item.name,
        value: item.id
      })
    )
    setDepartmentList(arrDepartmentList)
  };

  useEffect(() => {
    getDataDepartmentList();
  }, []);
  
  return (
    <React.Fragment>
      <Head title="Home"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                Dashboard
              </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v" />
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          onClick={(ev) => ev.preventDefault()}
                          tag="a"
                          className="dropdown-toggle btn btn-white btn-dim btn-outline-light"
                        >
                          <span>
                            <span className="d-none d-md-inline">{selectedDepartment.name}</span>
                          </span>
                          <Icon className="dd-indc" name="chevron-down" />
                        </DropdownToggle>
                        <DropdownMenu right>
                          <ul className="link-list-opt no-bdr">
                            {
                              departmentList.map((item, i) => 
                                <li key={i}>
                                  <DropdownItem
                                    tag="a"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setSelectedDepartment(item);
                                    }}
                                    href="#!"
                                  >
                                    <span>{item.name}</span>
                                  </DropdownItem>
                                </li>
                              )
                            }
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Row className="g-gs">
            <Col sm={4} md lg={4} xl xxl={4}>
              <TotalNewLead selectedDepartment={selectedDepartment} />
            </Col>
            <Col sm={4} md lg={4} xl xxl={4}>
              <TotalNewProspect selectedDepartment={selectedDepartment} />
            </Col>
            <Col sm={4} md lg={4} xl xxl={4}>
              <TotalClosing selectedDepartment={selectedDepartment} />
            </Col>
            <Col sm={6} md lg={6} xl xxl={6}>
              {cookies.role.name === "CS" || cookies.role.name === "HEAD" || cookies.role.name === "SUPER ADMIN" || cookies.role.name === "ADMIN" ? <RatingSalesAll selectedDepartment={selectedDepartment} /> : <RatingSales selectedDepartment={selectedDepartment} />}
            </Col>
            <Col sm={6} md lg={6} xl xxl={6}>
              <AvgTimeChat selectedDepartment={selectedDepartment} />
            </Col>
            <Col xxl="12">
              <CustomerLeadsOpen selectedDepartment={selectedDepartment} />
            </Col>
            <Col xxl="12">
              <CustomerHotProspect selectedDepartment={selectedDepartment} />
            </Col>
            <Col xxl="12">
              <CustomerWarmProspect selectedDepartment={selectedDepartment} />
            </Col>
          </Row>
        </Block>
      </Content>
    </React.Fragment>
  );
};
export default SalesDashboard;
