

import React, { useState, useEffect, useContext } from "react";
import { UserAvatar, Icon } from "../../../components/Component";
import SimpleBar from "simplebar-react";
import { Button, Spinner } from "reactstrap";
import { SharedDoc, SharedImage, SharedVideo } from "./ChatPartials";
import { serviceGetContact, servicePutContact } from "services/contact";
import { serviceGetMedia } from "services/media";
import moment from "moment";
import { serviceGetCampaign, serviceGetCampaigns } from "services/campaign";
import { serviceGetTicketsByUserId } from "services/tickets";
import getTextColor from "components/custom/getTextColor";
import { ChatContext } from "./ChatContext";
import { utilNumberPhoneConvention } from "./utils/ChatUtils";

const ChatSideBar = ({ sidebar, setsidebar,chat, cookies }) => {
  // const { changeNickname, changeTheme, userData, addUserToChat, serviceDeleteUsers, makeAdmin } = useContext(ChatContext);
  const initialClientProfile = {
    birthDate: "",
    email: "",
    instagram: "",
    facebook: "",
    telegram: "",
    note: "",
    tags: "",
  };

  const [medias, setMedias] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [contact, setContact] = useState({});
  const [campaign, setCampaign] = useState({});
  const [photoState, setPhotoState] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [clientProfile, setClientProfile] = useState(initialClientProfile);
  
  const getAllMedia = async () => {
    setIsLoading(true);
    const response = await serviceGetMedia(cookies.SIDikey,chat.id);
    if (response.status === 200){
      setMedias(response.data.data);
    }
    // setIsLoading(false);
  }
  const refreshContact = async () => {
    const response = await serviceGetContact(cookies.SIDikey,chat.contactId);
    if (response.status === 200){
      setContact(response.data);
    }
    // setIsLoading(false);
  }

  const refreshCampaign = async () => {
    let campaignId = null;
    if(chat.tickets) {
      campaignId = chat?.tickets[0].campaignId
    } else {
      const ticketData = await serviceGetTicketsByUserId(cookies.SIDikey, chat.userId)
      campaignId = ticketData.data.data[0].campaignId
    }

    if(campaignId){
      const response = await serviceGetCampaign(cookies.SIDikey,campaignId);
      if (response.status === 200){
        setCampaign(response.data);
      }
    } else {
      setCampaign("-")
    }
    setIsLoading(false);
  }

  useEffect( () => {
    if(sidebar){
      getAllMedia();
      refreshContact();
      refreshCampaign();
    }else{
      setMedias([]);
      setContact({});
      setCampaign({});
    }
  }, [sidebar]);

  const handleClientProfileChange = (field, newValue) => {
    setClientProfile((current) => {
      return {
        ...current,
        [field]: newValue,
      };
    });
  };

  const editContact = async () =>{
    const data = {
      notes:clientProfile.note
    }
    setIsEditMode(false);
  }

  const { isSavedArray } = useContext(ChatContext);
  const [isSaved] = isSavedArray;

  return (
    <SimpleBar className={`nk-chat-profile ${sidebar ? "visible" : ""}`}>
      {isLoading ? 
      <Spinner></Spinner>:
      <React.Fragment>
      <div className="user-card pt-5 sidebar-header">
        <div className="user-card px-4">
          <UserAvatar icon="user-alt-fill"></UserAvatar>
          {/* <div className="user-info">
            <div className="lead-text">
              {isSaved.find((savedUser) => savedUser.id === Uchat.contactId)?.isSaved
                ? Uchat.name
                : "+" + Uchat.customerPhone}
            </div>
            {Uchat.tags.map((item, idx) => {
              return (
                <span
                  key={idx}
                  className="badge rounded-pill text-bg-primary"
                  style={{
                    background: item.color,
                    borderColor: "#050505",
                    color: getTextColor(item.color),
                    margin: "2px",
                  }}
                >
                  {item.name}
                </span>
              );
            })}
          </div> */}
          <div className="user-info">
            <>
              <span className="lead-text">{utilNumberPhoneConvention(chat?.name ?? '')}</span>
              {chat?.name !== contact?.name && <span className="sub-text ">({contact?.name})</span>}
              <span className="sub-text ">{"+" + chat.customerPhone}</span>
            </>
            
            {/* {
              isSaved.find(savedUser => savedUser.id === chat.contactId)?.isSaved ?
                <>
                  <span className="lead-text">{chat.name}</span>
                  <span className="sub-text ">{"+" + chat.customerPhone}</span>
                </>
              :
                <>
                  <span className="lead-text ">{"+" + chat.customerPhone}</span>
                </>
            } */}
          </div>
        </div>
        
        <div className="user-card-menu btn btn-icon btn-trigger text-primary chat-profile-toggle">
          <Icon
            name={"cross"}
            onClick={() => (setsidebar(false))}
          ></Icon>
        </div>
      </div>
      <div className="chat-profile" style={{marginTop:"120px"}}>
        <div className="chat-profile-group">
        <a
            href="#options"
            className={`chat-profile-head`}
            id="chat-options"
          >
            <div>
              <span className="lead-text">Birth Date</span>
                <span className="sub-text">{contact?.birthday?
                  moment(contact?.birthday).format('DD MMM, YYYY')
                  :'-'}
                </span>
            </div>
          </a>
        </div>
        <div className="chat-profile-group">
          <div className={`chat-profile-head`}>
            <span className="lead-text">Campaign</span>
              <span className="sub-text ">{campaign?.name || '-'}
              </span>
          </div>
        </div>
        <div className="chat-profile-group">
          <a
            href="#options"
            className={`chat-profile-head`}
            id="chat-options"
          >
            <div>
              <span className="lead-text">Emails / Social Media</span>
              {isEditMode ? (
                <>
                  <div className="d-flex align-items-center mb-1">
                    <Icon name="emails" className="mr-2" style={{ fontSize: 16 }}></Icon>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Email"
                      value={clientProfile.email}
                      onChange={(event) => handleClientProfileChange("email", event.target.value)}
                    />
                  </div>
                  <div className="d-flex align-items-center mb-1">
                    <Icon name="instagram" className="mr-2" style={{ fontSize: 16 }}></Icon>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Instagram"
                      value={clientProfile.instagram}
                      onChange={(event) => handleClientProfileChange("instagram", event.target.value)}
                    />
                  </div>
                  <div className="d-flex align-items-center mb-1">
                    <Icon name="facebook-f" className="mr-2" style={{ fontSize: 16 }}></Icon>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Facebook"
                      value={clientProfile.facebook}
                      onChange={(event) => handleClientProfileChange("facebook", event.target.value)}
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <Icon name="telegram" className="mr-2" style={{ fontSize: 16 }}></Icon>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Telegram"
                      value={clientProfile.telegram}
                      onChange={(event) => handleClientProfileChange("telegram", event.target.value)}
                    />
                  </div>
                </>
              ) : (
                <span className="sub-text">
                  {!!contact?.emails ? 
                    contact?.emails.map((email,index)=>{
                      return <div key={index}>- {email.email} <br></br></div>
                    })
                  : 'tidak'}
                </span>
              )}
            </div>
          </a>
        </div>
        <div className="chat-profile-group">
          <a
            href="#options"
            // onClick={(ev) => {
            //   ev.preventDefault();
            //   setOptionState(!optionState);
            // }}
            className={`chat-profile-head`}
            id="chat-options"
          >
            <div>
              <span className="lead-text">Note</span>
              {isEditMode ? (
                <input
                  className="form-control"
                  type="text"
                  placeholder="Note"
                  value={contact?.notes}
                  onChange={(event) => handleClientProfileChange("note", event.target.value)}
                />
              ) : (
                <span className="sub-text">{contact?.notes}</span>
              )}
            </div>
          </a>
        </div>
        {/* <div className="chat-profile-group">
          <a
            href="#options"
            // onClick={(ev) => {
            //   ev.preventDefault();
            //   setOptionState(!optionState);
            // }}
            className={`chat-profile-head`}
            id="chat-options"
          >
            <div>
              <span className="lead-text">Tags</span>
              {isEditMode ? (
                <input
                  className="form-control"
                  type="text"
                  placeholder="Tags"
                  value={clientProfile.tags}
                  onChange={(event) => handleClientProfileChange("tags", event.target.value)}
                />
              ) : (
                <span className="sub-text">[Dummy Tags]</span>
              )}
            </div>
          </a>
        </div> */}
        {!isEditMode && (
          <div className="chat-profile-group">
            <a
              href="#options"
              // onClick={(ev) => {
              //   ev.preventDefault();
              //   setOptionState(!optionState);
              // }}
              className={`chat-profile-head`}
              id="chat-options"
            >
              <div>
                <span className="lead-text">Follow-up Date</span>
                <span className="sub-text">
                  
                  {chat.lastMessage?.createdAt ? moment(chat.lastMessage?.createdAt).format('DD/MM/YYYY') : '' }
                </span>
              </div>
            </a>
          </div>
        )}
                <div className="chat-profile-group">
        <a
            href="#options"
            className={`chat-profile-head`}
            id="chat-options"
          >
            <div>
              <span className="lead-text">Tags</span>
              {chat.tags?.map((item, idx) => {
                return (
                  <span
                    key={idx}
                    className="badge rounded-pill text-bg-primary"
                    style={{
                      background: item.color,
                      borderColor: "#ccc",
                      color: getTextColor(item.color),
                      margin: "2px",
                    }}
                  >
                    {item.name}
                  </span>
                );
              })}
            </div>
          </a>
        </div>
        {/* .chat-profile-group */}
        {/* {chat.group && (
          <div className="chat-profile-group">
            <a
              href="#member"
              onClick={(ev) => {
                ev.preventDefault();
                setMemberState(!memberState);
              }}
              className="chat-profile-head"
            >
              <h6 className="title overline-title">Members</h6>
              <span className="indicator-icon">
                <Icon name={`chevron-${memberState ? "up" : "down"}`}></Icon>
              </span>
            </a>
            <div className={`chat-profile-body collapse ${memberState ? "" : "show"}`} id="chat-photos">
              <div className="chat-profile-body-inner">
                <ul className="chat-members">
                  <li>
                    <UncontrolledDropdown>
                      <DropdownToggle
                        tag="a"
                        color="transparent"
                        className="chat-members-link add-opt"
                        href="#dropdown"
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                      >
                        <Icon name="plus" className="icon-circle bg-light"></Icon>
                        <span className="sub-text">Add Member</span>
                      </DropdownToggle>
                      <DropdownMenu>
                        <ul className="link-list-plain">
                          {userData.map((user, key) => {
                            return (
                              <li key={key}>
                                <DropdownItem
                                  tag="a"
                                  href="#dropdown"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    addUserToChat(chat.id, user);
                                  }}
                                >
                                  <UserAvatar
                                    text={findUpper(user.name)}
                                    theme={user.theme}
                                    className="xs"
                                    image={null}
                                  />
                                  <div className="user-name">{user.name}</div>
                                </DropdownItem>
                              </li>
                            );
                          })}
                        </ul>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </li>

                  <li>
                    {chat.user.map((user, idx) => {
                      return (
                        <div className="user-card" key={idx}>
                          <a href="#card" onClick={(ev) => ev.preventDefault()}>
                            <UserAvatar
                              size="sm"
                              theme={user.theme}
                              image={user.image}
                              text={findUpper(user.name)}
                            ></UserAvatar>
                            <div className="user-name">{user.name}</div>
                            <div className="user-role">{user.role && user.role}</div>
                          </a>
                          <div className="user-actions">
                            <UncontrolledDropdown>
                              <DropdownToggle tag="a" className="btn btn-icon btn-sm btn-trigger dropdown-toggle">
                                <Icon name="more-h"></Icon>
                              </DropdownToggle>
                              <DropdownMenu right>
                                <ul className="link-list-opt no-bdr">
                                  <li>
                                    <DropdownItem
                                      tag="a"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        serviceDeleteUsers(chat.id, user.id);
                                      }}
                                      href="#delete"
                                    >
                                      Remove
                                    </DropdownItem>
                                  </li>
                                  <li>
                                    <DropdownItem
                                      tag="a"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        makeAdmin(chat.id, user.id);
                                      }}
                                      href="#make-admin"
                                    >
                                      Make Admin
                                    </DropdownItem>
                                  </li>
                                </ul>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        </div>
                      );
                    })}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )} */}
        {/* <div className="chat-profile-group">
          <a
            href="#settings"
            onClick={(ev) => {
              ev.preventDefault();
              setSettingState(!settingState);
            }}
            className="chat-profile-head"
          >
            <h6 className="title overline-title">Settings</h6>
            <span className="indicator-icon">
              <Icon name={`chevron-${settingState ? "up" : "down"}`}></Icon>
            </span>
          </a>
          <div className={`chat-profile-body collapse ${settingState ? "" : "show"}`} id="chat-settings">
            <div className="chat-profile-body-inner">
              <ul className="chat-profile-settings">
                <li>
                  <div className="custom-control custom-control-sm custom-switch">
                    <input type="checkbox" className="custom-control-input" id="customSwitch2" />
                    <label className="custom-control-label" htmlFor="customSwitch2">
                      Notifications
                    </label>
                  </div>
                </li>
                <li>
                  <a
                    className="chat-option-link"
                    href="#ignore"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    <Icon className="icon icon-circle bg-light ni ni-bell-off-fill"></Icon>
                    <div>
                      <span className="lead-text">Ignore Messages</span>
                      <span className="sub-text">You won’t be notified when message you.</span>
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    className="chat-option-link"
                    href="#feedback"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    <Icon className="icon icon-circle bg-light ni ni-alert-fill"></Icon>
                    <div>
                      <span className="lead-text">Something Wrong</span>
                      <span className="sub-text">Give feedback and report conversion.</span>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div> */}
        {/* .chat-profile-group */}
        <div className="chat-profile-group">
          <a
            href="#shared"
            onClick={(ev) => {
              ev.preventDefault();
              setPhotoState(!photoState);
            }}
            className="chat-profile-head"
          >
            <h6 className="title overline-title">Shared Docs</h6>
            <span className="indicator-icon">
              <Icon name={`chevron-${photoState ? "up" : "down"}`}></Icon>
            </span>
          </a>
          {isLoading ? <>Loading</> :
          <div className={`chat-profile-body collapse ${photoState ? "" : "show"}`} id="chat-photos">
            <div className="chat-profile-body-inner">
              <ul className="chat-profile-media row">
                {
                  medias.map((element,index) => {
                    if(!element.content){
                      return <div key={index}></div>;
                    }
                    if(element.type === 'IMAGE'){
                      return <SharedImage link={process.env.REACT_APP_S3_URL + element.content} key={index}/>
                    }else if(element.type === 'VIDEO'){
                      return <SharedVideo link={process.env.REACT_APP_S3_URL + element.content} key={index}/>
                    }else if(element.type === 'TEXT'){
                    }else{
                      return <SharedDoc link={process.env.REACT_APP_S3_URL + element.content} extension={"."+element.type} key={index}/>
                    }
                    return <div key={index}></div>;
                  })
                }
              </ul>
            </div>
          </div>}
          
        </div>
        {/* .chat-profile-group */}
        {isEditMode && (
          <div className="chat-profile-head">
            <Button color="primary" block onClick={editContact}>
              Save
            </Button>
          </div>
        )}
      </div>
      </React.Fragment>
      }

    </SimpleBar>
  );
};
export default ChatSideBar;
