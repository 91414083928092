import React, { useState, useEffect, useContext, useRef, useLayoutEffect } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { filterRole, filterStatus } from "../pre-built/user-manage/UserData";
import QuickReplyData from "./QuickReplyData";
import {
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  DropdownItem,
  Card,
  CardBody,
  UncontrolledTooltip,
} from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  Button,
  RSelect,
  TooltipComponent,
} from "../../components/Component";
import { Link } from "react-router-dom";
import { QuickReplyContext } from "./QuickReplyContext";
import { useCookies } from "react-cookie";
import Dropzone from "react-dropzone";
import Swal from "sweetalert2";
import { serviceDeleteQuickReply, serviceGetAllQuickReply, serviceGetQuickReply } from "services/quickReply";
import { serviceGetProfile } from "services/settings";
import moment from "moment";
import Papa from "papaparse";

const QuickReply = () => {
  const nonFixedRef = useRef([]);
  const nonFixedSlug = useRef([]);
  nonFixedSlug.current = [];
  nonFixedRef.current = [];
  const [nonFixedHeight, setNonFixedHeight] = useState([]);
  const [allData, setAllData] = useState([]);
  const { contextData } = useContext(QuickReplyContext);
  const [data, setData] = contextData;
  const [cookies] = useCookies(["user"]);
  const [dataProfile, setDataProfile] = useState([]);
  const [sm, updateSm] = useState(false);
  const [tablesm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [modal, setModal] = useState({
    import: false,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [isSorting, setIsSorting] = useState(false);
  const [sortKeyword, setSortKeyword] = useState("");
  const [orderBy, setOrderBy] = useState("DESC");
  const [sort, setSortState] = useState("");
  const [files, setFiles] = useState([]);
  const [formData, setFormData] = useState({
    link: "",
    departemenTarget: "",
    waTarget: "",
    waNumber: "",
    active: true,
  });
  const [metaData, setMetaData] = useState({
    itemCount: 0,
    page: 1,
    take: 0,
    pageCount: 0,
    hasPreviousPage: false,
    hasNextPage: false,
  });

  const roleFunctions = JSON.parse(localStorage.getItem("permission"))["quick-replies"].access || [];

  const getProfile = async () => {
    const responseProfile = await serviceGetProfile(cookies.SIDikey);
    const resultData = responseProfile.data.departments;
    const finalData = [];
    for (let departments of resultData) {
      const tempData = {
        id: departments.id,
        color: departments.color,
        name: departments.name,
      };
      finalData.push(tempData);
    }
    setDataProfile(finalData);
  };

  const refreshData = async (pageNumber, SearchText) => {
    const departmentsids = dataProfile.map((item) => item.id);
    SearchText = onSearchText || null;

    const responseGetQuickReply = await serviceGetQuickReply(
      cookies.SIDikey,
      SearchText ?? "",
      roleFunctions,
      departmentsids,
      cookies.id,
      pageNumber,
      "quickReply.command,quickReply.updatedAt",
      "DESC",
      "AND"
    );
    setMetaData(responseGetQuickReply.data.meta);
    if (new RegExp(/20+/).test(responseGetQuickReply.status)) {
      const resultData = responseGetQuickReply.data.data;

      const finalData = [];
      resultData.forEach((quickReply) => {
        let updatedBy = "";
        if (quickReply.updatedBy != null) {
          updatedBy = quickReply.updatedBy.firstName + " " + quickReply.updatedBy.lastName;
        } else {
          updatedBy = "-";
        }
        const tempData = {
          id: quickReply.id,
          name: quickReply.name,
          command: quickReply.command,
          content: quickReply.response,
          file: quickReply.media,
          slug: quickReply.departmentsForHumans,
          departments: quickReply.departments,
          createdBy: quickReply.createdBy.firstName + " " + quickReply.createdBy.lastName,
          createdAt: moment(quickReply.createdAt).format("MMM DD, YYYY, HH:mm"),
          updatedAt: moment(quickReply.updatedAt).format("MMM DD, YYYY, HH:mm"),
          updatedBy: updatedBy,
        };
        finalData.push(tempData);
      });
      setData(finalData);
    } else {
      console.log(responseGetQuickReply);
    }
  };

  const refreshAllQuickReply = async () => {
    const resp = await serviceGetAllQuickReply(cookies.SIDikey);
    const tempData = resp.map((item) => {
      return {
        ...item,
        createdBy: item.createdBy?.firstName,
        updatedBy: item.updatedBy?.firstName,
        departments: item.departments?.map((i) => i.name).toString(),
      };
    });
    setAllData(tempData);
  };

  const refreshDataByField = async (field, pageNumber, order) => {
    const responseGetQuickReply = await serviceGetQuickReply(
      cookies.SIDikey,
      onSearchText ?? "",
      roleFunctions,
      cookies.departmentId,
      cookies.id,
      pageNumber,
      "quickReply.command,quickReply.updatedAt",
      order,
      "AND",
      field
    );
    setMetaData(responseGetQuickReply.data.meta);
    if (new RegExp(/20+/).test(responseGetQuickReply.status)) {
      const resultData = responseGetQuickReply.data.data;
      const finalData = [];
      resultData.forEach((quickReply) => {
        let updatedBy = "";
        if (quickReply.updatedBy != null) {
          updatedBy = quickReply.updatedBy.firstName + " " + quickReply.updatedBy.lastName;
        } else {
          updatedBy = "-";
        }
        const tempData = {
          id: quickReply.id,
          name: quickReply.name,
          command: quickReply.command,
          content: quickReply.response,
          slug: quickReply.departmentsForHumans,
          departments: quickReply.departments,
          createdBy: quickReply.createdBy.firstName + " " + quickReply.createdBy.lastName,
          createdAt: moment(quickReply.createdAt).format("MMM DD, YYYY, HH:mm"),
          updatedAt: moment(quickReply.updatedAt).format("MMM DD, YYYY, HH:mm"),
          updatedBy: updatedBy,
        };
        finalData.push(tempData);
      });
      setData(finalData);
    } else {
      console.log(responseGetQuickReply);
    }
  };

  const handleDropChange = (acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  };

  // Sorting data
  const sortFunc = (params) => {
    let defaultData = data;
    if (params === "asc") {
      let sortedData = defaultData.sort((a, b) => a.name.localeCompare(b.name));
      setData([...sortedData]);
    } else if (params === "dsc") {
      let sortedData = defaultData.sort((a, b) => b.name.localeCompare(a.name));
      setData([...sortedData]);
    }
  };

  const refreshHeight = () => {
    if (nonFixedRef.current) {
      const tempHeight = [];
      nonFixedRef.current.map((ref, index) => {
        let height = 0;
        if (ref.offsetHeight > nonFixedSlug.current[index].offsetHeight) {
          height = ref.offsetHeight;
        } else {
          height = nonFixedSlug.current[index].offsetHeight;
        }
        tempHeight.push(height);
        return height;
      });
      setNonFixedHeight(tempHeight);
    }
  };

  useLayoutEffect(() => {
    refreshHeight();
  }, [data]);

  const addToRefs = (el) => {
    if (el && !nonFixedRef.current.includes(el)) {
      nonFixedRef.current.push(el);
    }
  };

  const addToRefsSlug = (el) => {
    if (el && !nonFixedSlug.current.includes(el)) {
      nonFixedSlug.current.push(el);
    }
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        await getProfile(); // Mendapatkan dataProfile
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    fetchProfile();
  }, []);

  // unselects the data on mount
  // Mengambil data berdasarkan dataProfile
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Pastikan dataProfile sudah terisi sebelum memanggil refreshData
        if (dataProfile.length > 0) {
          await refreshData(1);
        }

        // Panggil fungsi lainnya
        await refreshAllQuickReply();
      } catch (error) {
        console.error("Error refreshing data:", error);
      }
    };

    fetchData();
  }, [dataProfile]); // Bergantung pada dataProfile

  // Memproses tagData dan mengupdate state data
  useEffect(() => {
    if (QuickReplyData) {
      let newData = QuickReplyData.map((item) => {
        item.checked = false;
        return item;
      });
      setData([...newData]);
    }
  }, [QuickReplyData]);

  // unselects the data on mount
  useEffect(() => {
    console.log(onSearchText);
    refreshData(1);
    refreshAllQuickReply();
    let newData;
    newData = QuickReplyData.map((item) => {
      item.checked = false;
      return item;
    });
    setData([...newData]);
  }, [onSearchText]); // eslint-disable-line react-hooks/exhaustive-deps

  // Changing state value when searching name
  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = QuickReplyData.filter((item) => {
        return (
          item.name.toLowerCase().includes(onSearchText.toLowerCase()) ||
          item.email.toLowerCase().includes(onSearchText.toLowerCase())
        );
      });
      setData([...filteredObject]);
    } else {
      setData([...QuickReplyData]);
    }
  }, [onSearchText, setData]);

  // function to change the selected property of an item
  const onSelectChange = (e, id) => {
    let newData = data;
    let index = newData.findIndex((item) => item.id === id);
    newData[index].checked = e.currentTarget.checked;
    setData([...newData]);
  };

  // function to set the action to be taken in table header
  // const onActionText = (e) => {
  //   setActionText(e.value);
  // };

  // function to reset the form
  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      balance: "",
      phone: "",
      status: "Active",
    });
  };

  // function to close the form modal
  const onFormCancel = () => {
    setModal({ import: false });
    resetForm();
  };

  const onDeleteClick = (id, name) => {
    Swal.fire({
      title: "Are you sure for delete " + name + " ?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const responseDeleteQuickReply = await serviceDeleteQuickReply(id, cookies.SIDikey);

        if (new RegExp(/20+/).test(responseDeleteQuickReply.status)) {
          Swal.fire({
            icon: "success",
            title: "Succesfull Transasction",
            focusConfirm: false,
          }).then(() => {
            refreshData(1);
          });
        } else {
          console.log(responseDeleteQuickReply);
          Swal.fire({
            icon: "error",
            title: "Unsuccesfull Transasction",
            focusConfirm: false,
          });
        }
      }
    });
  };

  const onSortClicked = (id, order) => {
    setOrderBy(order);
    refreshDataByField(id, 1, order);
    setIsSorting(true);
    setSortKeyword(id);
  };
  // function which selects all the items
  const selectorCheck = (e) => {
    let newData;
    newData = data.map((item) => {
      item.checked = e.currentTarget.checked;
      return item;
    });
    setData([...newData]);
  };
  const convertObjectToCSV = (data) => {
    const csv = Papa.unparse(data);
    return csv;
  };
  const handleDownload = (objectData) => {
    const csv = convertObjectToCSV(objectData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `quick_reply.csv`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  // const paginate = (pageNumber) => refreshData(pageNumber);
  const paginate = (pageNumber) => {
    if (isSorting) {
      refreshDataByField(sortKeyword, pageNumber, orderBy);
    } else {
      refreshData(pageNumber);
    }
  };

  // const sortedItems = currentItems?.sort((a, b) => new moment(b.updatedAt) - new moment(a.updatedAt));

  return (
    <React.Fragment>
      <Head title="Quick Reply"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Quick Reply
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
          <Card className="card-bordered my-4 card-bluesky">
            <CardBody className="card-bluesky-body">
              <strong>Info:</strong>
              <p>
                Create a template to ease the chat reply process then connect it with the Department Slug according to
                your reference
              </p>
            </CardBody>
          </Card>
          <Block className="d-flex justify-content-end">
            <BlockHeadContent style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
              <div className="card-inner position-relative card-tools-toggle">
                <div className="card-title-group">
                  <div className="form-control-wrap ">
                    <div className="form-icon form-icon-left">
                      <Icon name="search"></Icon>
                    </div>
                    <input
                      type="text"
                      className="form-control w-100"
                      id="default-04"
                      placeholder="Search by Command"
                      onInput={(e) => setSearchText(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="toggle-wrap nk-block-tools-toggle" style={{ display: "flex", alignItems: "center" }}>
                <div className="toggle-expand-content" style={{ display: sm ? "flex" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    {!roleFunctions.includes("download") || (
                      <li>
                        <Button
                          disabled={allData.length === 0}
                          href="#export"
                          onClick={(e) => {
                            e.preventDefault();
                            handleDownload(allData);
                          }}
                          className="btn btn-white btn-outline-light"
                        >
                          <Icon name="download-cloud"></Icon>
                          <span>Download</span>
                        </Button>
                      </li>
                    )}
                    {!roleFunctions.includes("create") || (
                      <li className="nk-block-tools-opt">
                        <Link to={`${process.env.PUBLIC_URL + "quick-reply/add"}`}>
                          <Button color="primary" className="btn">
                            <i className="ni ni-plus"></i>
                            <span>Tambah Baru</span>
                          </Button>
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </Block>
        </BlockHead>

        <Block>
          <DataTable className="card-stretch" style={{ borderRadius: "10px" }}>
            <Row>
              <Col md="4" lg="3" sm="5" xs="6" className="fixed-column border-right">
                <DataTableBody compact>
                  <DataTableHead style={{ backgroundColor: "#F5F6FB" }}>
                    <DataTableRow noWrap>
                      <span className="sub-text">No</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <div
                        className="sub-text d-flex justify-content-between align-items-center"
                        style={{ width: 150 }}
                      >
                        Nama
                        <Button id="qrName" size="xs">
                          <Icon name="sort" />
                        </Button>
                      </div>
                      <UncontrolledTooltip autohide={false} placement="top" target="qrName">
                        <div className="tooltip-ticket-sorting">
                          <Button
                            color={orderBy === "ASC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("quickReply.name", "ASC")}
                            size="xs"
                          >
                            ASC
                          </Button>
                          <Button
                            color={orderBy === "DESC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("quickReply.name", "DESC")}
                            size="xs"
                          >
                            DESC
                          </Button>
                        </div>
                      </UncontrolledTooltip>
                    </DataTableRow>
                  </DataTableHead>
                  {/*Head*/}
                  {currentItems.length > 0
                    ? currentItems.map((item, index) => {
                        return (
                          <DataTableItem key={item.id}>
                            <DataTableRow>
                              <span>{(metaData.page - 1) * metaData.take + (index + 1)}</span>
                            </DataTableRow>
                            <DataTableRow
                              customStyle={nonFixedHeight.length > 0 ? { height: nonFixedHeight[index] } : {}}
                            >
                              <span>{item.name}</span>
                            </DataTableRow>
                          </DataTableItem>
                        );
                      })
                    : null}
                </DataTableBody>
              </Col>
              <Col md="8" lg="9" sm="7" xs="6" className="nfixed-column">
                <DataTableBody compact>
                  <DataTableHead style={{ backgroundColor: "#F5F6FB" }}>
                    <DataTableRow noWrap>
                      <div className="sub-text d-flex justify-content-between align-items-center">
                        Command
                        <Button id="qrCommand" size="xs">
                          <Icon name="sort" />
                        </Button>
                      </div>
                      <UncontrolledTooltip autohide={false} placement="top" target="qrCommand">
                        <div className="tooltip-ticket-sorting">
                          <Button
                            color={orderBy === "ASC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("quickReply.command", "ASC")}
                            size="xs"
                          >
                            ASC
                          </Button>
                          <Button
                            color={orderBy === "DESC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("quickReply.command", "DESC")}
                            size="xs"
                          >
                            DESC
                          </Button>
                        </div>
                      </UncontrolledTooltip>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <div className="sub-text d-flex justify-content-between align-items-center">
                        Isi Command
                        <Button id="qrCommandContent" size="xs">
                          <Icon name="sort" />
                        </Button>
                      </div>
                      <UncontrolledTooltip autohide={false} placement="top" target="qrCommandContent">
                        <div className="tooltip-ticket-sorting">
                          <Button
                            color={orderBy === "ASC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("quickReply.response", "ASC")}
                            size="xs"
                          >
                            ASC
                          </Button>
                          <Button
                            color={orderBy === "DESC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("quickReply.response", "DESC")}
                            size="xs"
                          >
                            DESC
                          </Button>
                        </div>
                      </UncontrolledTooltip>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <div className="sub-text d-flex justify-content-between align-items-center">
                        Slug Department
                        <Button id="qrDepartment" size="xs">
                          <Icon name="sort" />
                        </Button>
                      </div>
                      <UncontrolledTooltip autohide={false} placement="top" target="qrDepartment">
                        <div className="tooltip-ticket-sorting">
                          <Button
                            color={orderBy === "ASC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("department.name", "ASC")}
                            size="xs"
                          >
                            ASC
                          </Button>
                          <Button
                            color={orderBy === "DESC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("department.name", "DESC")}
                            size="xs"
                          >
                            DESC
                          </Button>
                        </div>
                      </UncontrolledTooltip>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Aksi</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <div className="sub-text d-flex justify-content-between align-items-center">
                        Modify At
                        <Button id="qrModifyAt" size="xs">
                          <Icon name="sort" />
                        </Button>
                      </div>
                      <UncontrolledTooltip autohide={false} placement="top" target="qrModifyAt">
                        <div className="tooltip-ticket-sorting">
                          <Button
                            color={orderBy === "ASC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("quickReply.updatedAt", "ASC")}
                            size="xs"
                          >
                            ASC
                          </Button>
                          <Button
                            color={orderBy === "DESC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("quickReply.updatedAt", "DESC")}
                            size="xs"
                          >
                            DESC
                          </Button>
                        </div>
                      </UncontrolledTooltip>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <div className="sub-text d-flex justify-content-between align-items-center">Modify By</div>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <div className="sub-text d-flex justify-content-between align-items-center">
                        Created At
                        <Button id="qrCreatedAt" size="xs">
                          <Icon name="sort" />
                        </Button>
                      </div>
                      <UncontrolledTooltip autohide={false} placement="top" target="qrCreatedAt">
                        <div className="tooltip-ticket-sorting">
                          <Button
                            color={orderBy === "ASC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("quickReply.createdAt", "ASC")}
                            size="xs"
                          >
                            ASC
                          </Button>
                          <Button
                            color={orderBy === "DESC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("quickReply.createdAt", "DESC")}
                            size="xs"
                          >
                            DESC
                          </Button>
                        </div>
                      </UncontrolledTooltip>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <div className="sub-text d-flex justify-content-between align-items-center">Created By</div>
                    </DataTableRow>
                  </DataTableHead>
                  {/*Head*/}
                  {currentItems && currentItems.length > 0
                    ? currentItems.map((item, index) => {
                        return (
                          <DataTableItem key={item.id}>
                            <DataTableRow>
                              <span>{item.command}</span>
                            </DataTableRow>
                            <DataTableRow ref={addToRefs}>
                              <span>{item.content}</span>
                            </DataTableRow>
                            <DataTableRow ref={addToRefsSlug}>
                              <span>{item.slug}</span>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <ul className="nk-tb-actions table-action">
                                <li className="nk-tb-action">
                                  <Link
                                    id={"detail" + item.id}
                                    to={{
                                      pathname: process.env.PUBLIC_URL + "/quick-reply/detail",
                                      state: {
                                        quickReply: item,
                                        edit: false,
                                      },
                                    }}
                                    className="btn btn-trigger btn-icon"
                                  >
                                    <Icon name="eye-fill" />
                                  </Link>
                                  <UncontrolledTooltip autohide={false} placement="top" target={"detail" + item.id}>
                                    Detail
                                  </UncontrolledTooltip>
                                </li>
                                {roleFunctions.includes("update") || item?.createdBy.includes(cookies.firstName) ? (
                                  <li className="nk-tb-action">
                                    <Link
                                      id={"edit" + item.id}
                                      to={{
                                        pathname: process.env.PUBLIC_URL + "/quick-reply/detail",
                                        state: {
                                          quickReply: item,
                                          edit: true,
                                        },
                                      }}
                                      className="btn btn-trigger btn-icon"
                                    >
                                      <Icon name="edit-alt-fill" />
                                    </Link>
                                    <UncontrolledTooltip autohide={false} placement="top" target={"edit" + item.id}>
                                      Edit
                                    </UncontrolledTooltip>
                                  </li>
                                ) : (
                                  ""
                                )}
                                {roleFunctions.includes("delete") || item?.createdBy.includes(cookies.firstName) ? (
                                  <li className="nk-tb-action" onClick={() => onDeleteClick(item.id, item.name)}>
                                    <TooltipComponent
                                      tag="a"
                                      containerClassName="btn btn-trigger btn-icon"
                                      id={"delete" + item.id}
                                      icon="trash-fill"
                                      direction="top"
                                      text="Hapus"
                                    />
                                  </li>
                                ) : (
                                  ""
                                )}
                              </ul>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <span>{item.updatedAt}</span>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <span>{item.updatedBy}</span>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <span>{item.createdAt}</span>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <span>{item.createdBy}</span>
                            </DataTableRow>
                          </DataTableItem>
                        );
                      })
                    : null}
                </DataTableBody>
              </Col>
            </Row>
            <div className="card-inner">
              {currentItems.length > 0 ? (
                <PaginationComponent
                  itemPerPage={metaData.take}
                  totalItems={metaData.itemCount}
                  paginate={paginate}
                  currentPage={metaData.page}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )}
            </div>
          </DataTable>
        </Block>
        <Modal
          isOpen={modal.import}
          toggle={() => setModal({ import: false })}
          className="modal-dialog-centered"
          size="xl"
        >
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Import Quick Reply</h5>
              <div className="mt-4">
                <Dropzone onDrop={(acceptedFiles) => handleDropChange(acceptedFiles)}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()} className="dropzone custom upload-zone custom-dropzone dz-clickable">
                        <input {...getInputProps()} />
                        {files.length === 0 && (
                          <div className="dz-message">
                            <span className="custom-dropzone-text">
                              <Icon name="clip-v" />
                              Tambah File
                            </span>
                          </div>
                        )}
                        {files.map((file) => (
                          <div
                            key={file.name}
                            className="dz-preview dz-processing dz-image-preview dz-error dz-complete"
                          >
                            <div className="dz-image">
                              <img src={file.preview} alt="preview" />
                            </div>
                          </div>
                        ))}
                      </div>
                    </section>
                  )}
                </Dropzone>
                <DataTable className="card-stretch mt-4">
                  <DataTableBody compact>
                    <DataTableHead>
                      <DataTableRow>
                        <span className="sub-text">No</span>
                      </DataTableRow>
                      <DataTableRow>
                        <span className="sub-text">Nama</span>
                      </DataTableRow>
                      <DataTableRow>
                        <span className="sub-text">Command</span>
                      </DataTableRow>
                      <DataTableRow>
                        <span className="sub-text">Isi Command</span>
                      </DataTableRow>
                      <DataTableRow>
                        <span className="sub-text">Slug Department</span>
                      </DataTableRow>
                      <DataTableRow>
                        <span className="sub-text">Aktif</span>
                      </DataTableRow>
                      <DataTableRow>
                        <span className="sub-text">Aksi</span>
                      </DataTableRow>
                    </DataTableHead>
                  </DataTableBody>
                  <div className="text-center my-4 text-soft">
                    <Icon name="archived" className="table-empty-icon" />
                    <div>
                      <span className="table-empty-text">No data found</span>
                    </div>
                  </div>
                </DataTable>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};
export default QuickReply;
