import React, { useState, createContext } from "react";

const tempData = [
  {
    id: 1,
    name: "Opening Chat",
    status:"Draft",
    notes:"Template untuk opening chat",
    created_at:"November 17, 2022 12:54",
    created_by:"Jonathan M.",
    modified_at:"November 17, 2022 12:54",
    modified_by:"Astro New"
  },
  {
    id: 2,
    name: "A-Shop Reply",
    status:"Draft",
    notes:"Template untuk opening chat",
    created_at:"November 17, 2022 12:54",
    created_by:"Jonathan M.",
    modified_at:"November 17, 2022 12:54",
    modified_by:"Astro New"
  },
  {
    id: 3,
    name: "CAT Reply",
    status:"Draft",
    notes:"Template untuk opening chat",
    created_at:"November 17, 2022 12:54",
    created_by:"Jonathan M.",
    modified_at:"November 17, 2022 12:54",
    modified_by:"Astro New"
  },
  {
    id: 4,
    name: "A-Club Reply",
    status:"Draft",
    notes:"Template untuk opening chat",
    created_at:"November 17, 2022 12:54",
    created_by:"Jonathan M.",
    modified_at:"November 17, 2022 12:54",
    modified_by:"Astro New"
  },
  {
    id: 5,
    name: "Orbi Chat",
    status:"Draft",
    notes:"Template untuk opening chat",
    created_at:"November 17, 2022 12:54",
    created_by:"Jonathan M.",
    modified_at:"November 17, 2022 12:54",
    modified_by:"Astro New"
  },
];

export const ChatbotContext = createContext();

export const ChatbotContextProvider = (props) => {
  const [data, setData] = useState(tempData);

  return <ChatbotContext.Provider value={{ contextData: [data, setData] }}>{props.children}</ChatbotContext.Provider>;
};
