import { dateProcessing } from "./ChatPartials";
import { useContext } from "react";
import { ChatContext } from "./ChatContext";
import { Button } from "reactstrap";
import { Icon } from "components/Component";
import { serviceGetChatById } from "services/chats";
import { delay } from "utils/Utils";

export const ListFilterMessage = ({
  cookies,
  result,
  isSearch,
  searchText,
  selectedId,
  setSelectedId,
  setContextMessage,
}) => {
  return (
    <div className={`mt-2 ${!isSearch ? "d-none" : ""}`}>
      <h6 className="title overline-title-alt">Messages</h6>
      <ul className="chat-list">
        {result.length > 0 ? (
          result.map((message, index) => {
            return (
              <FilterMessageItem
                cookies={cookies}
                key={index}
                data={message}
                searchText={searchText}
                setSelectedId={setSelectedId}
                setContextMessage={setContextMessage}
              />
            );
          })
        ) : (
          <p className="m-3">No messages found</p>
        )}
      </ul>
    </div>
  );
};

const FilterMessageItem = ({ cookies, data, searchText, setSelectedId, setContextMessage }) => {
  const { chatState, chatIdState } = useContext(ChatContext);
  const [chat, setChat] = chatState;
  const [chatIdList, setChatIdList] = chatIdState;

  const clickedChat = async (msg) => {
    const dataChat = chat.find((item) => item.id === msg.chat.id);
    if (!dataChat) {
      const singleChat = await serviceGetChatById(cookies.SIDikey, msg.chat.id);
      if (singleChat.status === 200) {
        setChat((chat) => [...chat, singleChat.data]);
        setChatIdList((chatIdList) => [...chatIdList, msg.chat.id]);
        setSelectedId(msg.chat.id);
      }
    } else {
      setSelectedId(msg.chat.id);
    }
    await delay(500);
    setContextMessage(msg);
  };

  const boldText = (text) => {
    const regex = new RegExp(searchText, "gi");
    return {
      __html: text.replace(regex, function (x) {
        return x.bold();
      }),
    };
  };
  return (
    <li
      className="chat-item px-2 py-2"
      onClick={(ev) => {
        ev.preventDefault();
        console.log(data);
        clickedChat(data);
      }}
    >
      <div className="chat-from">
        <div className="name text-truncate pr-2">
          {data.chat.name != null ? data.chat.name : data.chat.custumerPhone}
        </div>
        <span className="time">{dateProcessing(data.timestamp)}</span>
      </div>
      <div className="text">
        <div className="mb-1" style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
          <span className="text-truncate pr-2" dangerouslySetInnerHTML={boldText(data.text)}></span>
        </div>
      </div>
    </li>
  );
};
