import { axiosPrivate } from "../api/axiosfile";

export default async function getExportFile(token, endpoint) {
    try {
        axiosPrivate.interceptors.request.use((config) => {
          config.headers["Authorization"] = `Bearer ${token}`;
          return config;
        });

        const response = await axiosPrivate.get(endpoint);
    
        return response;
      } catch (error) {
        if (!error.response) {
          return { status: "No Server Response" };
        } else {
          return error.response;
        }
      }
}