import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Card, CardBody, Col, Form, FormGroup } from "reactstrap";
import { Block, BlockBetween, BlockHead, BlockHeadContent, BlockTitle, RSelect } from "../../components/Component";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { useCookies } from "react-cookie";
import getTextColor from "../../components/custom/getTextColor";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useCallback } from "react";
import { getDepartments } from "services/departments";
import { servicePutQuickReply, serviceDeleteQuickReply, serviceGetAllQuickReplyCommand } from "services/quickReply";
import Swal from "sweetalert2";

const DetailQuickReply = ({ ...props }) => {
  const location = useLocation();
  const history = useHistory();
  const [editContent, setEditContent] = useState(false);

  const { errors, register, setValue, handleSubmit } = useForm();
  const [dataDepartment, setDataDepartment] = useState();
  const [cookies, setCookie] = useCookies(["user"]);
  const [roleFunctions] = useState(JSON.parse(localStorage.getItem("permission"))["quick-replies"].access || []);
  const [selectedDepartment, setSelectedDepartment] = useState([]);
  const [commandData, setCommandData] = useState([]);
  const [matchCommand, setMatchCommand] = useState(false);

  const [formData, setFormData] = useState({
    id: null,
    name: "",
    department: [],
    command: "",
    content: "",
    file: "",
    createdAt: "",
    updatedAt: "",
    createdBy: "",
    updatedBy: "",
  });

  console.log("roleFunctions", roleFunctions)
  
  const refreshDepartment = async () => {
    const responseGetDepartment = await getDepartments(cookies.SIDikey, '', cookies.departmentIds, cookies.id, roleFunctions);
    if (new RegExp(/20+/).test(responseGetDepartment.status)) {
      const resultData = responseGetDepartment.data.data;
      const finalData = [];
      for(let departement of resultData){
        const tempData = {
          value: departement.id,
          label: departement.name,
          color: departement.color,
        };
        // if (!roleFunctions.includes("Access_All_Dept")) {
        //   if (departement.id !== cookies.departmentIds) {
        //     continue;
        //   }
        // }
        finalData.push(tempData);
       }
    
      setDataDepartment(finalData);
    } else {
      console.log(responseGetDepartment);
    }
  };

  const onDeleteClick = (id, name) => {
    Swal.fire({
      title: "Are you sure for delete " + name + " ?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const responseDeleteQuickReply = await serviceDeleteQuickReply(id, cookies.SIDikey);

        if (new RegExp(/20+/).test(responseDeleteQuickReply.status)) {
          Swal.fire({
            icon: "success",
            title: "Succesfull Transasction",
            focusConfirm: false,
          }).then(() => {
            setTimeout(() => {
              window.history.pushState(
                `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/quick-reply"}`,
                "auth-login",
                `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/quick-reply"}`
              );
              window.location.reload();
            }, 500);
          });
        } else {
          console.log(responseDeleteQuickReply);
          Swal.fire({
            icon: "error",
            title: "Unsuccesfull Transasction",
            focusConfirm: false,
          });
        }
      }
    });
  };

  const initFormData = useCallback((quickReply, edit) => {
    const finalDepartement = [];
    quickReply.departments.map((departement) => {
      const tempDepartment = {
        label: departement.name,
        name: departement.name,
        color: departement.color,
        value: departement.id,
      };
      finalDepartement.push(tempDepartment);
    });
    
    setFormData({
      id: quickReply.id,
      name: quickReply.name,
      department: finalDepartement,
      command: quickReply.command,
      content: quickReply.content,
      file: quickReply?.file,
      createdAt: quickReply.createdAt,
      updatedAt: quickReply.updatedAt,
      createdBy: quickReply.createdBy,
      updatedBy: quickReply.updatedBy,
    });

    setEditContent(edit);
    setSelectedDepartment(finalDepartement)
  }, []);

  const getCommand = async () => {
    const getCommandList = await serviceGetAllQuickReplyCommand(cookies.SIDikey);
    setCommandData(getCommandList);
  };

  useEffect(() => {
    refreshDepartment();
    getCommand();
    if (location.state === undefined) {
      history.replace(`${process.env.PUBLIC_URL}/quick-reply`);
    } else {
      const { quickReply, edit } = location.state;
      initFormData(quickReply, edit);
    }
  }, [initFormData]);


  const onFormSubmit = async (data) => {
    if (matchCommand) {
      Swal.fire({
        icon: "error",
        title: "Command Duplicate",
        focusConfirm: false,
      });

      return false;
    }

    const { name, command, content, file } = data;
    const departmentsIds = [];
    formData.department.forEach((departement) => {
      departmentsIds.push(departement.value);
    });
    const dataToSend = {
      file: file[0],
      name: name,
      departmentIds: departmentsIds,
      command: command,
      response: content,
    };
    var bodyFormData = new FormData();
    for (const key in dataToSend) {
      if (key === "size") {
        bodyFormData.append(key, parseInt(dataToSend[key]));
        console.log(key, bodyFormData);
      } else {
        bodyFormData.append(key, dataToSend[key]);
      }
    }

    if (dataToSend.departmentIds.length === 0) {
      Swal.fire("Department Kosong", `Harap masukkan department`, "error");
      return;
    }


    const responsePutQuickReply = await servicePutQuickReply(formData.id, cookies.SIDikey, dataToSend);
    if (new RegExp(/20+/).test(responsePutQuickReply.status)) {
      Swal.fire({
        icon: "success",
        title: "Succesfull Transasction",
        focusConfirm: false,
      }).then(() => {
        setTimeout(() => {
          window.history.pushState(
            `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/quick-reply"}`,
            "auth-login",
            `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/quick-reply"}`
          );
          window.location.reload();
        }, 500);
      });
    } else {
      console.log(responsePutQuickReply);
      Swal.fire({
        icon: "error",
        title: "Unsuccesfull Transasction",
        focusConfirm: false,
      });
    }
  };

  const departmentSelected = (e) => {
    const finalDepartment = [];
    e.forEach((element) => {
      const tempDepartment = {
        label: element.label,
        name: element.label,
        color: element.color,
        value: element.value,
      };
      finalDepartment.push(tempDepartment);
    });
    setFormData({
      id: formData.id,
      name: formData.name,
      department: finalDepartment,
      command: formData.command,
      content: formData.content,
      file: formData.file,
      createdAt: formData.createdAt,
      updatedAt: formData.updatedAt,
      createdBy: formData.createdBy,
      updatedBy: formData.updatedBy,
    });
  };

  const checkCommand = (dataInput) => {
    if (commandData.length !== 0) {
      const getMatchCommand = commandData.findIndex(
        (item) => item.id !== formData.id && item.comand === dataInput.toLowerCase()
      );
      const duplicateComand = getMatchCommand >= 0 ? true : false;
      setMatchCommand(duplicateComand);
      if (duplicateComand) {
        Swal.fire({
          icon: "error",
          title: "Command Duplicate",
          focusConfirm: false,
        });
      }
    }
  };

  return (
    <React.Fragment>
      <Head title="Detail Quick Reply" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Quick Reply
              </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <ul className="nk-block-tools g-3">
                {(roleFunctions.includes("update") || formData?.createdBy.includes(cookies.firstName)) && (
                  <li className="nk-block-tools-opt">
                    <Button
                      color="primary"
                      className={editContent ? "btn d-none" : ""}
                      onClick={() => setEditContent(true)}
                    >
                      Ubah
                    </Button>
                  </li>
                )}

                {(roleFunctions.includes("Delete") || formData?.createdBy.includes(cookies.firstName)) && (
                  <li className="nk-block-tools-opt">
                    <Button
                      color="danger"
                      outline
                      className="btn"
                      onClick={() => onDeleteClick(formData.id, formData.name)}
                    >
                      Hapus
                    </Button>
                  </li>
                )}
                <li className="nk-block-tools-opt">
                  <Button color="primary" outline className="btn" onClick={() => history.goBack()}>
                    Kembali
                  </Button>
                </li>
              </ul>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <Card className="p-4">
            <BlockHead>
              <BlockBetween>
                <BlockHeadContent>
                  <strong style={{ fontSize: 18 }}>Detail Quick Reply</strong>
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>
            <Block className="mt-4">
              <Form className="row gy-4" onSubmit={handleSubmit(onFormSubmit)}>
                <Col md="5" lg="5">
                  <FormGroup>
                    <label className="form-label">Nama</label>
                    <input
                      className="form-control"
                      type="text"
                      disabled={!editContent}
                      defaultValue={formData.name}
                      name="name"
                      ref={register({
                        required: "This field is required",
                      })}
                      placeholder="Masukkan Nama Quick Reply"
                    ></input>
                    {errors.name && <span className="invalid">{errors.name.message}</span>}
                  </FormGroup>
                  <FormGroup>
                    <label className="form-label">Department</label>
                    {/* <Card className="card-bordered mt-1 mb-3 card-bluesky">
                      <CardBody className="card-bluesky-body">
                        <strong>Apabila Department tidak dipilih, pesan akan muncul pada semua Department</strong>
                      </CardBody>
                    </Card> */}
                    <RSelect
                      name="deparment"
                      isMulti
                      disabled={!editContent}
                      styles={{
                        multiValue: (styles, { data }) => {
                          const color = data.color;
                          return {
                            ...styles,
                            backgroundColor: color + " !important",
                            borderRadius: "1.5rem !important",
                          };
                        },
                        multiValueLabel: (styles, { data }) => {
                          const color = getTextColor(data.color);
                          return {
                            ...styles,
                            color: color,
                          };
                        },
                      }}
                      options={dataDepartment}
                      value={formData.department}
                      placeholder="Pilih Department"
                      onChange={(e) => {
                        setValue("deparment", e.value);
                        departmentSelected(e)
                        setSelectedDepartment(e)
                      }}
                    ></RSelect>
                    {/* {errors.department && <span className="invalid">{errors.department.message}</span>} */}
                    {selectedDepartment.length === 0 && <span className="custom-invalid">This field is required</span>}
                  </FormGroup>
                  <FormGroup>
                    <label className="form-label">Command</label>
                    <input
                      name="command"
                      disabled={!editContent}
                      className="form-control"
                      type="text"
                      defaultValue={formData.command}
                      ref={register({
                        required: "This field is required",
                      })}
                      onInput={(e) => checkCommand(e.target.value)}
                      placeholder="Masukkan Command"
                    />
                    {errors.command && <span className="invalid">{errors.command.message}</span>}
                  </FormGroup>
                </Col>
                <Col md="7" lg="7">
                  <FormGroup>
                    <label className="form-label">Isi Command</label>
                    <textarea
                      className="form-control"
                      name="content"
                      disabled={!editContent}
                      defaultValue={formData.content}
                      ref={register({
                        required: "This field is required",
                      })}
                      placeholder="Contoh: Selamat Pagi"
                    />
                    {errors.content && <span className="invalid">{errors.content.message}</span>}
                  </FormGroup>
                  <FormGroup>
                    <label className="form-label">Media (Opsional)</label>
                    <p className="text-gray">Upload file berupa gambar</p>
                    <div className="form-control-wrap" style={{ maxWidth: 250 }}>
                      <div className="form-file">
                        <input
                          accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                          defaultValue={formData?.file}
                          disabled={!editContent}
                          ref={register()}
                          name="file"
                          type="file"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </FormGroup>
                </Col>
                <Col md="12" lg="12" className={editContent ? "" : "d-none"}>
                  <Link to={`${process.env.PUBLIC_URL + "/quick-reply"}`}>
                    <Button outline type="button" color="primary" className="mr-3">
                      Batal
                    </Button>
                  </Link>
                  <Button color="primary">Simpan</Button>
                </Col>
                <Col md="12" lg="12">
                  <p className="text-soft">
                    Created at {formData.createdAt} by {formData.createdBy}
                  </p>
                  <p className="text-soft">
                    Modified at {formData.updatedAt} by {formData.updatedBy}
                  </p>
                </Col>
              </Form>
            </Block>
          </Card>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default DetailQuickReply;
