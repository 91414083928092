const menu = [

  {
    link: "/",
    icon: "growth",
    text: "Report",
    active: false,
    subMenu:[
      {
        icon: "view-x2",
        text: "Dashboard",
        link: "/",
      },
    ],
  },
  {
    icon: "db-fill",
    text: "Master Data",
    active: false,
    subMenu:[
      {
        icon: "label",
        text: "SOD : Role",
        link: "/master-role",
        sod:'roles'
      },
      {
        icon: "code",
        text: "SOD : Function",
        link: "/master-function",
        sod:'function'
      },
      {
        icon: "opt",
        text: "SOD : Action",
        link: "/master-action",
        sod:'pages-actions'
      },
      {
        icon: "view-row-wd",
        text: "SOD : Level",
        link: "/master-level",
        sod:'access-levels'
      },
      {
        icon: "user-list",
        text: "SOD : (RxFxL)",
        link: "/role-function-level",
        sod:'permission'
      },
      {
        icon: "mobile",
        text: "Channel",
        link: "/channels",
        sod:'channels'
      },
      {
        icon: "network",
        text: "Department",
        link: "/departments",
        sod:'departments'
      },
      {
        icon: "users",
        text: "User",
        link: "/users",
        sod:'users'
      },
      {
        icon: "quote-left",
        text: "Quick Reply",
        link: "/quick-reply",
        sod:'quick-replies'
      },
      {
        icon: "tag",
        text: "Tag",
        link: "/tags",
        sod:'tags'
      },
      {
        icon: "link-alt",
        text: "Link Campaign",
        link: "/campaign",
        sod:'campaigns'
      },
      {
        icon: "contact",
        text: "Contact",
        link: "/contact",
        sod:'contacts'
      },
      // {
      //   icon: "history",
      //   text: "History Chat",
      //   active: false
      // },
      {
        icon: "file-docs",
        text: "File",
        link: "/file",
        sod:'files'
      },
      {
        icon: "chat-circle",
        text: "Chatbot Rule",
        link: "/chat-bot",
        sod: "chat-rules"
      },
    ],
  },
  {
    icon: "thumbs-up",
    text: "Deals",
    active: true,
    subMenu:[
      {
        icon: "msg-circle",
        text: "Chat Platform",
        link: "/chat-platform",
        sod:'chats'
      },
      {
        icon: "ticket",
        text: "Ticket",
        link: "/ticket",
        sod:'tickets'
      },
      {
        icon: "view-col",
        text: "Lead Monitoring",
        link: "/leads-monitoring",
        sod:'lead-monitorings'
      },
    ],
  },

  {
    heading: "border-2"
  },
  {
    icon: "setting",
    text: "Setelan",
    active: true,
    subMenu:[
      {
        icon: "list-thumb",
        text: "Log Management",
        link: "/log-management",
        sod:'log'
      },
      {
        icon: "account-setting",
        text: "System Setting",
        link: "/system-settings",
        sod:"setting"
      }
    ]
  }
];
export default menu;