const ContactData = [
  // {
  //   id: 9508,
  //   name: "Hurait Hernando Hurairo",
  //   email: "astcool1@gmail.com",
  //   emailSecond: "lastcool1@gmail.com",
  //   phone: "082123411124",
  //   phoneSecond: "082123411124",
  //   clientAClub: {value: "Yes", label:"Yes"},
  //   salesAclub: {value:"Ardian Noviarsi", label:"Ardian Noviarsi"},
  //   clientType: {value:"Basic Client", label:"Basic Client"},
  //   membership: [
  //     { value: "Futures", label: "Futures" },
  //     { value: "Stocks", label: "Stocks" },
  //   ],
  //   clientAshop: {value:"No", label:"No"},
  //   productAshop: "Basic Elliot Wave Part 1, Basic Elliot Wave Part 2, Chart P.... see more",
  //   clientCAT: {value: "Yes", label:"Yes"},
  //   clientOrbi: {value: "Yes", label:"Yes"},
  //   salesOrbi: {value: "-", label:"-"},
  //   clientUOB: {value: "Yes", label:"Yes"},
  //   salesUOB: {value:"Astro", label: "Astro"},
  //   clientSucor: {value: "Yes", label:"Yes"},
  //   salesSucor: {value: "-", label:"-"},
  //   createdAt: "November 17, 2022 12:54",
  //   updatedAt: "November 17, 2022 17:54",
  //   createdBy: "Jonathan M.",
  //   updatedBy: "Astro NEW",
  // },
  // {
  //   id: 9509,
  //   name: "christian anderson yuwono",
  //   email: "astcool1@gmail.com",
  //   emailSecond: "lastcool1@gmail.com",
  //   phone: "082123411124",
  //   phoneSecond: "082123411124",
  //   clientAClub: {value: "No", label:"No"},
  //   salesAclub: {value:"Ardian Noviarsi", label:"Ardian Noviarsi"},
  //   clientType: "Basic Client",
  //   membership: [
  //     { value: "Futures", label: "Futures" },
  //     { value: "Stocks", label: "Stocks" },
  //   ],
  //   clientAshop: {value: "No", label:"No"},
  //   productAshop: "Basic Elliot Wave Part 1, Basic Elliot Wave Part 2, Chart P.... see more",
  //   clientCAT: {value: "Yes", label:"Yes"},
  //   clientOrbi: {value: "Yes", label:"Yes"},
  //   salesOrbi: {value: "-", label:"-"},
  //   clientUOB: {value: "Yes", label:"Yes"},
  //   salesUOB: {value:"Astro", label: "Astro"},
  //   clientSucor: {value: "Yes", label:"Yes"},
  //   salesSucor: {value: "-", label:"-"},
  //   createdAt: "November 17, 2022 12:54",
  //   updatedAt: "November 17, 2022 17:54",
  //   createdBy: "Jonathan M.",
  //   updatedBy: "Astro NEW",
  // },
];
export default ContactData;
