import React, { useState, createContext, useEffect } from "react";
import {
  serviceDeleteFile,
  serviceDownloadFile,
  serviceGetFiles,
  servicePostMoveFile,
  servicePutFile,
} from "services/file";
import { currentTime, getDateStructured } from "../../../utils/Utils";
import { folderList } from "./Data";
import { useCookies } from "react-cookie";
import Swal from "sweetalert2";
import { serviceDeleteDirectory } from "services/directory";

export const FileManagerContext = createContext();

export const FileManagerContextProvider = (props) => {
  const [data, setData] = useState([]);
  const [metadata, setMetaData] = useState();
  const [plan, setPlan] = useState("Starter");
  const [cookies] = useCookies(["user"]);
  const [directoryId, setDirectoryId] = useState("");
  const roleFunctions = JSON.parse(localStorage.getItem("permission"))["files"].access || ['Access_Own_Dept'];

  useEffect(() => {
    refreshData();
  }, [directoryId]);

  const refreshData = async (pageNumber = 1) => {
    if(!directoryId){
      setData([]);
      return;
    }
    const resp = await serviceGetFiles(cookies.SIDikey, pageNumber, roleFunctions, directoryId);
    if (resp.status === 200) {
      setData(resp.data.data);
      setMetaData(resp.data.meta);
    } else {
      console.log(resp);
    }
  };

  //delete file (new)
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure for delete " + "" + " ?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const responseDelete = await serviceDeleteFile(id, cookies.SIDikey);
          console.log(responseDelete);
          if (responseDelete.status === 202) {
            Swal.fire("Berhasil Delete File", "Delete File", "success");
          } else {
            Swal.fire("Gagal Delete File", responseDelete.message, "error");
          }
          refreshData();
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  // Creates a new folder
  const createFolder = (filename, svg) => {
    let newFolder = {
      id: data.length + 1,
      meta: {
        type: "folder",
        name: filename,
        svg: svg,
        time: currentTime(),
        date: getDateStructured(new Date()),
        size: 0,
        starred: false,
      },
      subFolder: [],
    };
    let defaultData = data;
    let folderId = window.location.pathname.split("/")[window.location.pathname.split("/").length - 1];
    let findFolder = defaultData.findIndex((item) => item.id === Number(folderId));
    if (folderId !== "" && defaultData[findFolder] !== undefined) {
      defaultData[findFolder].subFolder.push({ fileId: data.length + 1 });
      defaultData = [newFolder, ...defaultData];
      setData(defaultData);
    } else {
      setData([newFolder, ...defaultData]);
    }
  };

  // Creates a file
  const createFile = (files) => {
    let defaultData = data;
    // let folderId = window.location.pathname.split("/")[window.location.pathname.split("/").length - 1];
    // let findFolder = defaultData.findIndex((item) => item.id === Number(folderId));
    // if (folderId !== "" && defaultData[findFolder] !== undefined) {
    //   let fileArray = [];
    //   files.forEach((item) => {
    //     fileArray.push({ fileId: item.id });
    //   });
    //   defaultData[findFolder].subFolder = [...defaultData[findFolder].subFolder, ...fileArray];
    //   defaultData = [...defaultData, ...files];
    //   setData([...defaultData]);
    // } else {
    console.log("createfile", files);
    defaultData = [...defaultData, ...files];
    setData([...defaultData]);
    // }
  };

  // Download a folder/file
  const downloadFile = async (file) => {
    const resp = await serviceDownloadFile(cookies.SIDikey, file.id);

    if (resp.status === 200) {
      const data = resp.data;
      const blob = new Blob([data])
      const downloadLink = document.createElement("a");
      const url = URL.createObjectURL(blob)
      downloadLink.setAttribute("href", url);
      downloadLink.setAttribute("download", file.name);
      downloadLink.style.visibility = "hidden";

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } else {
      console.log(resp);
    }
  
  };

  // Downloads all the selected file
  const selectorDownloadFile = () => {
    let defaultData = data;
    console.log("selectorDownload", data);
    let checkedItems = defaultData.filter((item) => item.meta.checked === true);
    checkedItems.forEach((file) => {
      downloadFile(file);
    });
  };

  // Copy a folder/file
  const copyToFolder = (file, folder) => {
    let defaultData = data;

    if (folder !== "") {
      let findNewFolderIndex = defaultData.findIndex((item) => item.id === folder);

      defaultData[findNewFolderIndex].subFolder.push({ fileId: file.id });
      setData([...defaultData]);
    }
  };

  // Selector copy for lists
  const selectorCopyToFolder = (folder) => {
    let defaultData = data;
    let checkedItems = defaultData.filter((item) => item.meta.checked === true);
    checkedItems.forEach((file) => {
      copyToFolder(file, folder);
    });
  };

  // Move a file
  const moveFolder = async (file, directoryId) => {
    const data = {
      directoryId: directoryId,
      id: file.id,
    };
    try {
      const response = await servicePostMoveFile(cookies.SIDikey, data);
      if (response.status === 200) {
        Swal.fire("Berhasil Pindahkan File", "Pindah File", "success");
      } else {
        Swal.fire("Gagal Pindah File", response.message, "error");
      }
      refreshData();
    } catch (error) {
      console.log(error);
    }
  };

  // Shares a file/folder
  const shareFiles = (id) => {
    let defaultData = data;
    let found = defaultData.findIndex((item) => item.id === id);
    defaultData[found].shared = { sharedTime: currentTime(), sharedDate: "Today" };
    defaultData[found].meta.members = [{ user: "Illiash Hossain", theme: "purple" }];
    setData([...defaultData]);
  };

  // Stars a folder/file
  const onStarClick = (id) => {
    let defaultData = data;
    let found = defaultData.findIndex((item) => item.id === id);
    if (defaultData[found].meta.starred) {
      defaultData[found].meta.starred = false;
    } else {
      defaultData[found].meta.starred = true;
    }
    setData([...defaultData]);
  };

  // Checks a file/folder
  const onFileCheck = (ev, id) => {
    let defaultData = data;
    let found = defaultData.findIndex((item) => item.id === id);
    if (ev.target.checked) {
      defaultData[found].meta.checked = true;
      setData([...defaultData]);
    } else {
      defaultData[found].meta.checked = false;
      setData([...defaultData]);
    }
  };

  // Deletes a file/folder
  const deleteFolder = (id) => {
    Swal.fire({
      title: "Apakah anda yakin untuk menghapus folder ini?",
      text: "File di dalam nya juga akan hilang.",
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#1ee0ac",
      confirmButtonText: "Ya, hapus!",
      cancelButtonText: "Tidak",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const responseDelete = await serviceDeleteDirectory(cookies.SIDikey, id);
          if (responseDelete.status === 202) {
            Swal.fire("Berhasil Hapus Folder", "Hapus Folder", "success");
          } else {
            Swal.fire("Gagal Hapus Folder", responseDelete.message, "error");
          }
          refreshData();
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  // Deletes all the selected file
  const selectorDeleteFolder = () => {
    let defaultData = data;
    let checkedItems = defaultData.filter((item) => item.meta.checked === true);
    checkedItems.forEach((file) => {
      deleteFolder(file.id);
    });
  };

  // Restores a file
  const restoreFolder = (id) => {
    let defaultData = data;
    let found = defaultData.findIndex((item) => item.id === id);
    delete defaultData[found]["recovery"];

    setData([...defaultData]);
  };

  // Removes shares
  const removeShare = (id, type) => {
    let defaultData = data;
    let found = defaultData.findIndex((item) => item.id === id);
    if (type === "incoming") {
      defaultData[found].meta.members = [];
      setData([...defaultData]);
    } else if (type === "outgoing") {
      defaultData[found].shared = false;
      setData([...defaultData]);
    } else {
      defaultData[found].meta.link = false;
      setData([...defaultData]);
    }
  };

  // Permanently deletes folders/files
  const permanentDelete = (id) => {
    let defaultData = data;
    defaultData = defaultData.filter((item) => item.id !== id);
    setData([...defaultData]);
  };

  // Returns the total size for a folder;
  const getTotalSize = (folder) => {
    let folderChildren = folder.subFolder;
    let size = 0;
    if (folderChildren.length === 0) {
      return size;
    } else {
      folderChildren.forEach((el) => {
        let file = getFiles(el.fileId);
        if (file === undefined) {
          size = size + 0;
        } else {
          size = size + file.meta.size;
        }
      });
      return size;
    }
  };

  const getFiles = (id) => {
    return data.find((item) => item.id === id);
  };

  return (
    <FileManagerContext.Provider
      value={{
        setDirectoryId: setDirectoryId,
        contextData: [data, setData],
        meta: [metadata, setMetaData],
        planData: [plan, setPlan],
        refresh: refreshData,
        deleteFile: handleDelete,
        createFolder: createFolder,
        createFile: createFile,
        onStarClick: onStarClick,
        deleteFolder: deleteFolder,
        selectorDeleteFolder: selectorDeleteFolder,
        restoreFolder: restoreFolder,
        removeShare: removeShare,
        shareFiles: shareFiles,
        onFileCheck: onFileCheck,
        downloadFile: downloadFile,
        selectorDownloadFile: selectorDownloadFile,
        copyToFolder: copyToFolder,
        selectorCopyToFolder: selectorCopyToFolder,
        moveFolder: moveFolder,
        permanentDelete: permanentDelete,
        getTotalSize: getTotalSize,
      }}
    >
      {props.children}
    </FileManagerContext.Provider>
  );
};
