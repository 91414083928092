import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL;
const headers = {
    'Content-Type': 'application/json',
    'AccessControl-Allow-Origin' : 'http://localhost:3000',
};


export default axios.create({
    baseURL: baseURL,
    headers: headers
});

export const axiosPrivate = axios.create({
    baseURL: baseURL,
    headers: headers
});