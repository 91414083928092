import React, { useState, useEffect } from "react";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Col,
  Row,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  Button,
  TooltipComponent,
  RSelect,
} from "components/Component";
import Content from "layout/content/Content";
import Head from "layout/head/Head";

import { FormGroup, Modal, ModalBody, Form, Card, CardBody } from "reactstrap";
import { useForm } from "react-hook-form";
import { useCookies } from "react-cookie";
import Swal from "sweetalert2";
import { serviceDeleteSodLevel, serviceGetSodLevel, servicePostSodLevel, servicePutSodLevel } from "services/sod-level";
import moment from "moment";
import { serviceGetListSodActions } from "services/sod-actions";

const MasterLevel = () => {
  const [data, setData] = useState([]);
  const [actionsOption, setActionsOption] = useState([]);
  const [selectedActions, setSelectedActions] = useState([]);
  const [cookies] = useCookies(["user"]);
  const [onSearchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(15);
  const [metaData, setMetaData] = useState({});
  const [editContent, setEditContent] = useState(false);
  const [confirmState, setConfirmState] = useState(false);
  const roleFunctions = JSON.parse(localStorage.getItem("permission"))["access-levels"].access || [];
  const [modal, setModal] = useState({
    detail: false,
    add: false,
  });

  const [formData, setFormData] = useState({
    id: "",
    key: "",
    name: "",
    description: "",
    createdAt: "",
    createdBy: "",
    modifiedAt: "",
    modifiedBy: "",
  });

  const handleDelete = (id) => {
    if (!roleFunctions.includes("delete")) {
      return;
    }
    Swal.fire({
      title: "Are you sure for delete " + id + " ?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteProcess(id);
      }
    });
  };

  const deleteProcess = async (id) => {
    const resp = await serviceDeleteSodLevel(id, cookies.SIDikey);
    if (/20/.test(resp.status)) {
      Swal.fire("Delete Level", "Berhasil Menghapus", "success");
      refreshData(metaData?.page || 1);
    } else {
      Swal.fire("Delete Level Failed", resp.data.message[0].detail, "error");
    }
  };

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  const [sm, updateSm] = useState(false);
  // Change Page
  const paginate = (pageNumber) => refreshData(pageNumber);
  console.log("currentItems", currentItems);
  // unselects the data on mount
  useEffect(() => {
    refreshData(1);
  }, [onSearchText]); // eslint-disable-line react-hooks/exhaustive-deps

  const resetForm = () => {
    setFormData({
      id: "",
      key: "",
      name: "",
      description: "",
      createdAt: "",
      createdBy: "",
      modifiedAt: "",
      modifiedBy: "",
    });
  };
  const { errors, register, handleSubmit, getValues, watch } = useForm();

  const onDetailClick = (id) => {
    data.forEach((item) => {
      if (item.id === id) {
        setSelectedActions(item.pageActions.map((iPage) => ({ value: iPage.id, label: iPage.key })));
        setFormData({
          id: item.id,
          level: item.level,
          accessRight: item.accessRight,
          name: item.name,
          pageActions: item.name,
          description: item.description,
          createdAt: item.createdAt,
          createdBy: item.createdBy || "System",
          modifiedAt: item.updatedAt || "-",
          modifiedBy: item.updatedBy === null ? "System" : item.updatedBy.firstName + " " + item.updatedBy.lastName,
        });
        setModal({ detail: true, add: false });
      }
    });
    console.log(selectedActions);
  };
  const onEditClick = (id) => {
    onDetailClick(id);
    setEditContent(true);
  };

  const onFormCancel = () => {
    setModal({ add: false, detail: false });
    setConfirmState(false);

    setEditContent(false);
    resetForm();
  };

  const refreshData = async (page) => {
    if (!roleFunctions.includes("read")) {
      return;
    }
    const response = await serviceGetSodLevel(cookies.SIDikey, onSearchText, [], page);
    if (response.status === 200) {
      const newData = [];
      const tempData = response.data.data;
      tempData.sort((a, b) => b.updatedAt - a.updatedAt);
      tempData.forEach((da) => {
        da.createdBy = da.createdBy ? da.createdBy.firstName + " " + da.createdBy.lastName : null;
        da.modifiedBy = da.modifiedBy ? da.modifiedBy.firstName + " " + da.modifiedBy.lastName : null;
        const pageActions = da.pageActions.map((dpa) => dpa.key);
        da.actions = pageActions;
        newData.push(da);
      });
      setData(newData);
      const meta = response.data.meta;
      setMetaData(meta);
    }
    const responseActions = await serviceGetListSodActions(cookies.SIDikey);
    if (responseActions.status === 200) {
      const dt = responseActions.data;
      setActionsOption(dt.map((dt) => ({ value: dt.id, label: dt.key })));
    }
  };

  const onAddSubmit = async (submittedData) => {
    if (!roleFunctions.includes("create")) {
      Swal.fire("Add Level", "Unauthorized", "error");
      return;
    }
    submittedData.level = parseInt(submittedData.level);
    submittedData.name = submittedData.description;
    submittedData.accessRight = "Access";
    submittedData.pageActionIds = [...selectedActions.map((a) => a.value)];
    console.log(submittedData);
    const response = await servicePostSodLevel(cookies.SIDikey, submittedData);
    if (response.status === 201) {
      Swal.fire("Add Level", "Berhasil", "success");
      setModal({ detail: false, add: false });
      setSelectedActions([]);
      resetForm();
      refreshData(1);
    } else if (response.status === 400) {
      if (response.data.message === "error.accessLevelAlreadyExists") {
        Swal.fire("Gagal Add Level", "Level dan Name Duplicate", "error");
      }
    } else {
      Swal.fire("Add Level", "Gagal", "error");
    }
  };

  const onEditSubmit = async (submittedData) => {
    if (!roleFunctions.includes("update")) {
      Swal.fire("Edit Level", "Unauthorized", "error");
      return;
    }
    if (submittedData.level) {
      submittedData.level = parseInt(submittedData.level);
    }
    submittedData.pageActionIds = selectedActions.map((sa) => sa.value);
    console.log("onEditSubmit", submittedData);
    const response = await servicePutSodLevel(formData.id, cookies.SIDikey, submittedData);
    if (response.status === 202) {
      Swal.fire("Edit Level", "Berhasil", "success");
      refreshData(1);
    } else if (response.status === 400) {
      if (response.data.message === "error.accessLevelAlreadyExists") {
        Swal.fire("Gagal Edit Level", "Level dan Name Duplicate", "error");
      }
    } else {
      Swal.fire("Edit Level", "Gagal", "error");
    }
  };

  return (
    <React.Fragment>
      <Head title="Level"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Level
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
          <Card className="card-bordered my-4 card-bluesky">
            <CardBody className="card-bluesky-body">
              <strong>Info:</strong>
              <p>This page is used to control the actions for other pages</p>
            </CardBody>
          </Card>
          <Block className="d-flex justify-content-end">
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    {/* <li>
                        <a
                          href="#export"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="btn btn-white btn-outline-light"
                        >
                          <Icon name="download-cloud"></Icon>
                          <span>Export</span>
                        </a>
                      </li> */}

                    <li className="nk-block-tools-opt">
                      <Button color="primary" className="btn" onClick={() => setModal({ add: true })}>
                        <i className="ni ni-plus"></i>
                        <span>Tambah Baru</span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </Block>
        </BlockHead>
        <Block>
          <DataTable className="card-stretch">
            <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group">
                <div className="form-control-wrap w-25">
                  <div className="form-icon form-icon-left">
                    <Icon name="search"></Icon>
                  </div>
                  <input
                    type="text"
                    className="form-control w-100"
                    id="default-04"
                    placeholder="Search"
                    onInput={(e) => setSearchText(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <DataTableBody>
              <DataTableHead>
                <DataTableRow className="nk-tb-col-check">
                  <span className="sub-text">No</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Level</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Name</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Actions</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text"></span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Created At</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Created By</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Modified At</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Modified By</span>
                </DataTableRow>
              </DataTableHead>
              {/*Head*/}
              {currentItems.length > 0
                ? currentItems.map((item, idx) => {
                    return (
                      <DataTableItem key={item.id}>
                        <DataTableRow size="mb">
                          <span className="tb-amount">{(metaData.page - 1) * metaData.take + (idx + 1)}</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="tb-amount">{item.level}</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="tb-amount">{item.name}</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="tb-amount">
                            {item?.actions ? item.actions.map((ia) => `${ia}, `) : item.description}
                          </span>
                        </DataTableRow>
                        <DataTableRow noWrap>
                          <ul className="nk-tb-actions table-action">
                            <li className="nk-tb-action" onClick={() => onDetailClick(item.id)}>
                              <TooltipComponent
                                tag="a"
                                containerClassName="btn btn-trigger btn-icon"
                                id={"detail" + item.id}
                                icon="eye-fill"
                                direction="top"
                                text="Detail"
                              />
                            </li>
                            {roleFunctions.includes("update") && (
                              <li className="nk-tb-action" onClick={() => onEditClick(item.id)}>
                                <TooltipComponent
                                  tag="a"
                                  containerClassName="btn btn-trigger btn-icon"
                                  id={"edit" + item.id}
                                  icon="edit-alt-fill"
                                  direction="top"
                                  text="Edit"
                                />
                              </li>
                            )}

                            {roleFunctions.includes("delete") && (
                              <li className="nk-tb-action" onClick={() => handleDelete(item.id)}>
                                <TooltipComponent
                                  tag="a"
                                  containerClassName="btn btn-trigger btn-icon"
                                  id={"suspend" + item.id}
                                  icon="trash-fill"
                                  direction="top"
                                  text="Hapus"
                                />
                              </li>
                            )}
                          </ul>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="tb-amount">{moment(item.createdAt).format("MMM DD, YYYY, HH:mm")}</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="tb-amount">{item.createdBy || "System"}</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="tb-amount">{moment(item.updatedAt).format("MMM DD, YYYY, HH:mm")}</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="tb-amount">
                            {item.updatedBy === null
                              ? "System"
                              : item.updatedBy?.firstName + " " + item.updatedBy?.lastName}
                          </span>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                : null}
            </DataTableBody>
            <div className="card-inner">
              {currentItems.length > 0 ? (
                <PaginationComponent
                  itemPerPage={metaData?.take}
                  totalItems={metaData?.itemCount}
                  paginate={paginate}
                  currentPage={metaData?.page}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )}
            </div>
          </DataTable>
        </Block>
      </Content>
      <Modal
        isOpen={modal.add}
        backdrop="static"
        keyboard={false}
        toggle={() => setModal({ add: false })}
        className="modal-dialog-centered"
        size="lg"
      >
        <ModalBody>
          <a
            href="#cancel"
            onClick={(ev) => {
              ev.preventDefault();
              onFormCancel();
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">Tambah Level</h5>
            <Block className="d-flex justify-content-end"></Block>
            <div className="mt-4">
              <Form className="row gy-4" onSubmit={handleSubmit(onAddSubmit)}>
                <Col md="12">
                  <FormGroup>
                    <label className="form-label">Level Name</label>
                    <input
                      className="form-control"
                      type="number"
                      name="level"
                      defaultValue={formData.level}
                      placeholder="Level Name"
                      ref={register({ required: "This field is required" })}
                    />
                    {errors.level && <span className="custom-invalid">{errors.level.message}</span>}
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <label className="form-label">Description</label>
                    <input
                      className="form-control"
                      type="text"
                      name="description"
                      defaultValue={formData.description}
                      placeholder="Description"
                      ref={register({ required: "This field is required" })}
                    />
                    {errors.description && <span className="custom-invalid">{errors.description.message}</span>}
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <label className="form-label">Actions</label>
                    <RSelect
                      isMulti
                      type="text"
                      options={actionsOption}
                      onChange={(e) => {
                        setSelectedActions([...e]);
                      }}
                      name="actions"
                      placeholder="Actions"
                      // ref={register({ required: "This field is required" })}
                    />
                    {errors.actions && <span className="custom-invalid">{errors.actions.message}</span>}
                  </FormGroup>
                </Col>

                <Col size="12">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button
                        outline
                        color="primary"
                        onClick={(ev) => {
                          ev.preventDefault();
                          onFormCancel();
                        }}
                      >
                        Batal
                      </Button>
                    </li>
                    <li>
                      <Button color="primary" size="md" type="submit">
                        Simpan
                      </Button>
                    </li>
                  </ul>
                </Col>
              </Form>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modal.detail}
        backdrop="static"
        keyboard={false}
        toggle={() => setModal({ detail: false })}
        className="modal-dialog-centered"
        size="lg"
      >
        <ModalBody>
          <a
            href="#cancel"
            onClick={(ev) => {
              ev.preventDefault();
              onFormCancel();
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">Tambah Level</h5>
            <Block className="d-flex justify-content-end"></Block>
            <div className="mt-4">
              <Form className="row gy-4" onSubmit={handleSubmit(onEditSubmit)}>
                <Col md="12">
                  <FormGroup>
                    <label className="form-label">Level Name</label>
                    <input
                      className="form-control"
                      type="number"
                      name="level"
                      defaultValue={formData.level}
                      disabled={!editContent}
                      placeholder="Level Name"
                      ref={register({ required: "This field is required" })}
                    />
                    {errors.level && <span className="custom-invalid">{errors.level.message}</span>}
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <label className="form-label">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      defaultValue={formData.name}
                      disabled={!editContent}
                      placeholder="Name"
                      ref={register({ required: "This field is required" })}
                    />
                    {errors.description && <span className="custom-invalid">{errors.description.message}</span>}
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <label className="form-label">Description</label>
                    <input
                      className="form-control"
                      type="text"
                      name="description"
                      defaultValue={formData.description}
                      disabled={!editContent}
                      placeholder="Description"
                      ref={register({ required: "This field is required" })}
                    />
                    {errors.description && <span className="custom-invalid">{errors.description.message}</span>}
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <label className="form-label">Actions</label>
                    <RSelect
                      isMulti
                      type="text"
                      options={actionsOption}
                      value={selectedActions}
                      onChange={(e) => {
                        setSelectedActions([...e]);
                      }}
                      name="actions"
                      disabled={!editContent}
                      placeholder="Actions"
                      // ref={register({ required: "This field is required" })}
                    />
                    {errors.actions && <span className="custom-invalid">{errors.actions.message}</span>}
                  </FormGroup>
                </Col>

                <Col size="12" className={editContent ? "" : "d-none"}>
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button
                        outline
                        color="primary"
                        onClick={(ev) => {
                          ev.preventDefault();
                          onFormCancel();
                        }}
                      >
                        Batal
                      </Button>
                    </li>
                    <li>
                      <Button color="primary" size="md" type="submit" disabled={!editContent}>
                        Simpan
                      </Button>
                    </li>
                  </ul>
                </Col>
              </Form>
              <Row className={"row gy-4"}>
                <Col md="2">
                  <div className="toggle-wrap nk-block-tools-toggle">
                    {!roleFunctions.includes("update") || (
                      <Button
                        className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                        onClick={() => updateSm(!sm)}
                      >
                        <Icon name="menu-alt-r"></Icon>
                      </Button>
                    )}
                    <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                      <ul className="nk-block-tools g-3">
                        <li className="nk-block-tools-opt">
                          <Button
                            color="primary"
                            className={editContent ? "btn d-none" : ""}
                            onClick={() => setEditContent(true)}
                          >
                            Ubah
                          </Button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col md="2">
                  <div className="toggle-wrap nk-block-tools-toggle">
                    {!roleFunctions.includes("delete") || (
                      <Button
                        color="danger"
                        outline
                        className={editContent ? "btn d-none" : ""}
                        onClick={() => handleDelete(formData.id)}
                      >
                        Hapus
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
export default MasterLevel;
