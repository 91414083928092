import { axiosPrivate } from "../api/axiosCore";

export const servicePutDirectory = async (id, token, data) => {
    try {
      axiosPrivate.interceptors.request.use((config) => {
        config.headers["Authorization"] = `Bearer ${token}`;
        return config;
      });
  
      const response = await axiosPrivate.put("api/directories/" + id, data);
  
      return response;
    } catch (error) {
      if (!error.response) {
        return { status: "No Server Response" };
      } else {
        return error.response;
      }
    }
  };

export const serviceGetDirectories = async (token, roleFunctions, departementId, page=1) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.get(`api/directories/?take=50&page=${page}&departmentId=${departementId}`);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetDirectory = async (id, token) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.get("api/directories/" + id);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};


export const serviceCreateDirectory = async (token, data) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });


    const response = await axiosPrivate.post("api/directories", data);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceDeleteDirectory = async (token, id) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });


    const response = await axiosPrivate.delete("api/directories/"+ id);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};