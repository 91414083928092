/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from "react";
import { Button, Icon } from "components/Component";
import getTextColor from "components/custom/getTextColor";
import { Modal, ModalBody, Spinner } from "reactstrap";
import { getTagsByTake } from "services/tags";

const ChatModalFilter = (props) => {
  const { cookies, isModalTagFilterOpen, setIsModalTagFilterOpen, setFilterTag, filterTag } = props;

  const [search, setSearch] = useState("");
  const [take, setTake] = useState(10);
  const [itemCount, setItemCount] = useState(10);
  const [tagList, setTagList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tagId, setTagId] = useState([]);
  const [filteredTagList, setFilteredTagList] = useState([]);
  const [selectedFilterItem, setSelectedFilterItem] = useState([]);

  const handleCheckboxClicked = (e, newValue, indexCatgery, index) => {
    if (e.target.checked) {
      setTagId([...tagId, newValue.id]);
      setSelectedFilterItem([...selectedFilterItem, newValue.id])
    } else {
      setTagId(tagId.filter((id) => id !== newValue.id));
      const findSelectedFilter = selectedFilterItem.findIndex((itm) => itm === newValue.id)
      if(findSelectedFilter >= 0) {
        const newFilter = [...selectedFilterItem]
        newFilter.splice(findSelectedFilter,1)
        setSelectedFilterItem([...newFilter])
      }
    }
  };

  const refreshTagsList = async () => {
    setLoading(true);
    const filterTagId = filterTag;
    // const responseGetUser = await getTagsByTake(cookies.SIDikey, search, take);
    const responseGetUser = await getTagsByTake(cookies.SIDikey, search, 100);
    if (new RegExp(/20+/).test(responseGetUser.status)) {
      const data = responseGetUser.data.data;
      let newData = [];
      for (let index = 0; index < data.length; index++) {
        let newObject = { ...data[index], isChecked: false };
        for (let k = 0; k < filterTagId.length; k++) {
          console.log("newObject", data[index].id, filterTagId[k]);
          if (data[index].id === filterTagId[k]) {
            newObject.isChecked = true;
            break;
          }
        }
        newData.push(newObject);
      }
      const finalData = [];
      newData.map((tag) => {
        let isFound = false;
        finalData.map((data) => {
          if (data.id === tag.tagCategoryId) {
            data.tags.push(tag);
            isFound = true;
          }
        });

        if (!isFound) {
          const tempCategory = {
            id: tag.tagCategoryId,
            name: tag.tagCategory.name,
            tags: [],
          };
          tempCategory.tags.push(tag);
          finalData.push(tempCategory);
        }
      });
      setItemCount(responseGetUser.data.meta.itemCount)
      setTagList(finalData);
      setFilteredTagList(finalData);
      setLoading(false);
    }
  };

  const chooseFilterTag = () => {
    setFilterTag([...new Set(selectedFilterItem)]);
    setIsModalTagFilterOpen(false);
  };

  useEffect(() => {
    if (isModalTagFilterOpen === true) {
      refreshTagsList();
    }else{
      setTake(10)
    }
  }, [isModalTagFilterOpen]);

  useEffect(() => {
    // if (search !== "") {
    //   const newFilteredTagList = tagList;
    //   tagList.map((data, index) => {
    //     const newTags = [];
    //     data.tags.map((tag) => {
    //       if (tag.name?.toLowerCase().includes(search.toLowerCase())) {
    //         newTags.push(tag);
    //       }
    //     });
    //     newFilteredTagList[index].tags = newTags;
    //   });
    //   setFilteredTagList(newFilteredTagList);
    // } else {
    //   refreshTagsList();
    //   setFilteredTagList(tagList);
    // }
    setFilteredTagList(filteredTagList);
    refreshTagsList();
  }, [search]);

  const scrollRef = React.createRef();
  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current?.scrollTop + scrollRef.current?.clientHeight >= scrollRef.current?.scrollHeight - 1 && !loading) {
        setTake(take+10)
        if(filteredTagList.map(item => item.tags.length).reduce((a, b) => a + b, 0) !== itemCount){
          refreshTagsList()
        }
      }
    };

    scrollRef.current?.addEventListener("scroll", handleScroll);

    return () => {
      scrollRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, [scrollRef, take]);

  return (
    <Modal
      isOpen={isModalTagFilterOpen}
      backdrop="static"
      keyboard={false}
      toggle={() => setIsModalTagFilterOpen(false)}
      className="modal-dialog-centered"
      
    >
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            setIsModalTagFilterOpen(false);
            setSearch("");
            setSelectedFilterItem(filterTag)
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="mb-4"></div>
        <div className="form-control-wrap">
          <div className="form-icon form-icon-left">
            <Icon name="search"></Icon>
          </div>
          <input
            type="text"
            className="form-round form-control"
            id="default-03"
            placeholder="Search Tag Name"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div style={{ maxHeight: 350, overflowY: "auto" }} className="mt-2 filter" ref={scrollRef}>
          {filteredTagList.length > 0
            ? filteredTagList.map((item, indexCatgery) => {
                return (
                  <React.Fragment key={item.id}>
                    <div className="text-black py-2 font-weight-bold ">
                      <p>{item.name}</p>
                    </div>
                    {item.tags.map((tag, index) => {
                      return (
                        <div className="card-inner-md" key={index} style={{ cursor: "pointer" }}>
                          <div className="user-card">
                            <input
                              type="checkbox"
                              name="source"
                              onChange={(e) => handleCheckboxClicked(e, tag, indexCatgery, index)}
                              className="mr-2"
                              // defaultChecked={tag.isChecked}
                              checked={selectedFilterItem.includes(tag.id) ? true : false}
                            />
                            <span
                              className="badge rounded-pill text-bg-primary"
                              style={{
                                background: tag.color || "white",

                                color: getTextColor(tag.color || "black"),
                              }}
                            >
                              {tag.name}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </React.Fragment>
                );
              })
            : null}
          {
            loading ?
              <div className="d-flex justify-content-center align-item-strecth">
                <Spinner animation="border" role="status" color="blue" />
              </div>
            : null
          }
        </div>
        <div className="d-flex justify-content-around">
          <Button
            color="primary"
            onClick={() => chooseFilterTag()}
            className="col-6"
            style={{ width: "100%", justifyContent: "center" }}
          >
            Filter
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ChatModalFilter;
