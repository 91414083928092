import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Spinner } from "reactstrap";
import { serviceGetChatsPage, servicePutChat } from "services/chats";
import { serviceGetFilterMessage } from "services/messages";
import { socketIO } from "services/socket";
import { servicePutTickets } from "services/tickets";
import SimpleBar from "simplebar-react";
import { Button, Icon } from "../../../components/Component";
import { ChatContext } from "./ChatContext";
import { ListFilterMessage } from "./ChatFilterMessage";
import { ChatItem } from "./ChatPartials";

export const ChatAsideBody = ({
  cookies,
  onInputChange,
  chat,
  setChat,
  chatId,
  setChatId,
  setCheckedTag,
  isModalTagAssignOpen,
  setIsModalTagAssignOpen,
  filteredChatList,
  setFilteredChatList,
  afterCursorChat,
  setAfterCursorChat,
  selectedId,
  setSelectedId,
  chatItemClick,
  filterTab,
  isSearch,
  filteredMessageList,
  setFilteredMessageList,
  afterCursorSearchedMessage,
  setAfterCursorSearchedMessage,
  searchText,
  isLoading,
  status,
  filterTag,
  setContextMessage,
  roleFunctions,
}) => {
  const chatList = isSearch ? filteredChatList : chat;
  const [ids, setIds] = useState([]);
  const [isLoadMoreChat, setLoadMoreChat] = useState(false);
  const handleChangeInput = (e, val) => {
    if (e.target.checked) {
      setChatId([...chatId, val.id]);
      setIds([...ids, val.id]);
    } else {
      setChatId(chatId.filter((id) => id !== val.id));
      setIds(ids.filter((id) => id !== val.id));
    }
  };

  useEffect(() => {
    if (ids.length) {
      setChatId([...ids]);
    }
  }, [ids, setChatId]);

  const loadMoreChats = useCallback(async () => {
    // if (afterCursorChat.hasNextPage) {
    const responseGetChats = await serviceGetChatsPage(
      cookies.SIDikey,
      cookies.id,
      cookies.departmentId,
      status,
      filterTag.toString(),
      afterCursorChat?.page + 1,
      roleFunctions
    );
    const data = responseGetChats.data.data;

    // let contactIds = data.map(item => item.contactId)
    // let contacts = await Promise.all(contactIds.map(id => serviceGetContact(cookies.SIDikey, id)));
    // let newIsSavedArray = contacts.map(contact => ({ id: contact.data.id, name: contact.data.name, isSaved: contact.data.isSaved }));
    // setIsSaved(oldArray => {
    //   const newArray = [...oldArray];
    //   newIsSavedArray.forEach(newItem => {
    //     if (!newArray.some(oldItem => oldItem.id === newItem.id)) {
    //       newArray.push(newItem);
    //     }
    //   });
    //   return newArray;
    // });

    const metaData = responseGetChats.data.meta;
    setAfterCursorChat(metaData);
    // sorting chat order by lastmessage timestamp
    if (data.length > 1) {
      data.sort((a, b) => b.lastMessage?.timestamp - a.lastMessage?.timestamp);
    }

    setChat((chat) => [...chat, ...data]);
    setLoadMoreChat(false);
    // }
  }, [
    afterCursorChat?.page,
    cookies.SIDikey,
    cookies.departmentId,
    cookies.id,
    filterTag,
    roleFunctions,
    setAfterCursorChat,
    setChat,
    status,
  ]);

  const loadMoreSearchedMessage = async (e) => {
    if (afterCursorSearchedMessage?.hasNextPage) {
      const responseGetMessages = await serviceGetFilterMessage(
        cookies.SIDikey,
        cookies.role.name,
        searchText,
        cookies.id,
        afterCursorSearchedMessage.page + 1
      );

      if (new RegExp(/20+/).test(responseGetMessages.status)) {
        const messageData = responseGetMessages.data.messages.data;
        setFilteredMessageList((filteredMessageList) => [...filteredMessageList, ...messageData]);
        setAfterCursorSearchedMessage(responseGetMessages.data.messages.meta);
      } else {
        console.log("Error get Messages from DB", responseGetMessages);
      }
    }
  };
  const handleClickChat = async (e, item) => {
    // solusi unopened lagi
    // setSelectedId(item.id);
    console.log("item", item);
    if (!roleFunctions.includes('View_Chatbot')) {
      if (item.status === "UNOPENED") {
        await servicePutChat(cookies.SIDikey, item.id, { status: "OPENED", unreadCount: 0 });
        if(item.tickets && item.tickets.length>0){
          await servicePutTickets(item.tickets[0].id, cookies.SIDikey, { status: "OPENED" });
        }

        const updateTicketStatus = {
          type: "status-update",
          id: item.id,
          ticketId: item.tickets ? item.tickets[0].id : item.lastMessage.ticketId,
          chat: {
            id: item.id,
            status: "OPENED",
            user: item.user,
            channel: item.channel
          },
          chatId: item.id,
          fromMe: true,
        };
        console.log("updateTicketStatus", updateTicketStatus);
        socketIO.emit("admin", JSON.stringify(updateTicketStatus));
      }
      if (item?.tickets[0]?.status === null || item?.tickets[0]?.status === "UNOPENED") {
        await servicePutTickets(item.tickets[0].id, cookies.SIDikey, { status: "OPENED" });
        item.tickets[0].status = "OPENED";
      }
    } else {
      if (item.adminStatus === "UNOPENED") {
        await servicePutChat(cookies.SIDikey, item.id, { adminStatus: "OPENED", unreadCountAdmin: 0 });
      }
    }
  };

  chatList.sort(function (a, b) {
    if (!a.lastMessage) {
      a = a.updatedAt;
    } else {
      a = a.lastMessage.createdAt;
    }
    if (!b.lastMessage) {
      b = b.updatedAt;
    } else {
      b = b.lastMessage.createdAt;
    }
    return new Date(b) - new Date(a);
  });
  const scrollRef = useRef();
  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current?.scrollTop + scrollRef.current?.clientHeight >= scrollRef.current?.scrollHeight - 1) {
        if (!isLoadMoreChat) {
          setLoadMoreChat(true);
          loadMoreChats();
        }
        //isLoadMoreChat === false && loadMoreChats();
      }
    };

    scrollRef.current?.addEventListener("scroll", handleScroll);

    return () => {
      scrollRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, [isLoadMoreChat, loadMoreChats]);

  return (
    <SimpleBar className="nk-chat-aside-body" id="chat-list" scrollableNodeProps={{ ref: scrollRef }}>
      <div className="nk-chat-list">
        <h6 className="title overline-title-alt">{filterTab}</h6>

        <ul className="chat-list">
          {isLoading ? (
            <div className="d-flex justify-content-center align-item-strecth">
              <Spinner animation="border" role="status" color="blue" />
            </div>
          ) : chatList.length !== 0 ? (
            chatList.map((item, idx) => {
              return (
                !item.group && (
                  <div key={idx} className="d-flex" onClick={(e) => handleClickChat(e, item)}>
                    {isModalTagAssignOpen && (
                      <input
                        type="checkbox"
                        onChange={(e) => handleChangeInput(e, item)}
                        style={{ marginRight: "8px" }}
                        checked={ids.some((item2) => item2 === item.id)}
                      />
                    )}
                    <ChatItem
                      setIds={setIds}
                      ids={ids}
                      setIsModalTagAssignOpen={setIsModalTagAssignOpen}
                      item={item}
                      setCheckedTag={setCheckedTag}
                      selectedId={selectedId}
                      setSelectedId={setSelectedId}
                      chatItemClick={chatItemClick}
                      clicked={item.id === selectedId}
                    />
                  </div>
                )
              );
            })
          ) : (
            <p className="m-3"></p>
          )}
          <>
            {afterCursorChat?.hasNextPage !== undefined && afterCursorChat?.hasNextPage && !isSearch ? (
              <div className="d-flex justify-content-center mt-2 mb-3">
                <Button outline color="light" size="sm">
                  {isLoadMoreChat ? (
                    <Spinner></Spinner>
                  ) : (
                    <div className="mb-1 mt-1">
                      <span>Load More Chats</span>
                      <Icon name="reload-alt" />
                    </div>
                  )}
                </Button>
              </div>
            ) : (
              <></>
            )}
          </>
        </ul>

        <ListFilterMessage
          cookies={cookies}
          isSearch={isSearch}
          result={filteredMessageList}
          searchText={searchText}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          setContextMessage={setContextMessage}
        />
        {afterCursorSearchedMessage?.hasNextPage && isSearch ? (
          <div className="d-flex justify-content-center mb-2">
            <Button outline color="light" size="sm" onClick={(e) => loadMoreSearchedMessage(e)}>
              <span>Load More Message</span>
              <Icon name="reload-alt" />
            </Button>
          </div>
        ) : (
          <></>
        )}
      </div>
    </SimpleBar>
  );
};
