import React from "react";
import LogoLight2x from "../../images/mainLogoHorizontalPutih.png";
import LogoDark2x from "../../images/mainLogoHorizontal.png";
import LogoSmall from "../../images/logoNoText.png";
import { Link } from "react-router-dom";

const Logo = ({ mouseEnter, contentMouseEnter, collapseSidebar, fixed, ...props }) => {
  const hideLogoSmall = () => {
    if (collapseSidebar) {
      if (contentMouseEnter || mouseEnter) {
        return true;
      }
    } else {
      return true;
    }
    return false;
  };
  
  return (
    <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
      <img className="logo-light logo-img" src={LogoLight2x} alt="logo" />
      <img className="logo-dark logo-img" src={LogoDark2x} alt="logo" />
      <img
        className={hideLogoSmall() ? "logo-small logo-img logo-img-small d-none" : "logo-small logo-img logo-img-small"}
        src={LogoSmall}
        alt="logo"
      />
    </Link>
  );
};

export default Logo;
