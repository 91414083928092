import React, { useState, useEffect, useContext } from "react";
import Files from "./preview/Files";
import Upload from "./modals/Upload";
import CreateFolder from "./modals/CreateFolder";
import { folderList } from "./Data";
import { Icon, PaginationComponent } from "../../../components/Component";
import { Button, DropdownItem, DropdownMenu, Modal, UncontrolledDropdown } from "reactstrap";
import { FileManagerContext } from "./FileManagerContext";
import { serviceGetFilesFromQuery } from "services/file";

const FileManagerBody = ({ cookies, data, setData, toggleScreenLg, folderId, folderName }) => {
  const [dataList, setDataList] = useState(data);
  console.log("🚀 ~ file: FileManagerBody.js:13 ~ FileManagerBody ~ dataList:", dataList)
  const { refresh, meta } = useContext(FileManagerContext);
  const [metaData] = meta;
  const [createModal, setCreateModal] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [children, setChildren] = useState([]);

  const toggleCreateModal = () => {
    setCreateModal(!createModal);
  };
  const toggleUploadModal = () => {
    setUploadModal(!uploadModal);
  };

  const returnFolder = (id) => {
    return data.find((item) => item.id === id);
  };

  useEffect(() => {
    setDataList(data);
  }, [data, folderId]);

  useEffect(() => {
    setSearchText("");
  }, [window.location.pathname]);

  const searchData = async (input)=>{
    const resp = await serviceGetFilesFromQuery(cookies, 1, folderId,input)
    console.log("🚀 ~ file: FileManagerBody.js:42 ~ searchData ~ resp:", resp)
    if(resp.status === 200){
       setDataList(resp.data.data)
    } else{
      console.log(resp)
    }
  }

  useEffect(() => {
    if (searchText !== "") {
    searchData(searchText)
    } else {
      setDataList(data);
    }
    console.log(searchText);
  }, [searchText]); // eslint-disable-line react-hooks/exhaustive-deps

  const paginate = (pageNumber) => {
    refresh(pageNumber);
  };

  return (
    <div className="nk-fmg-body">
      {window.location.pathname.split("/")[window.location.pathname.split("/").length - 1] !== "settings" &&
        window.location.pathname.split("/")[window.location.pathname.split("/").length - 1] !== "pricing" && (
          <div className="nk-fmg-body-head d-none d-lg-flex">
            <div className="nk-fmg-search">
              <Icon name="search"></Icon>
              <input
                type="text"
                className="form-control border-transparent form-focus-none"
                placeholder="Search files, folders"
                value={searchText}
                onChange={(ev) => setSearchText(ev.target.value)}
              />
            </div>
            <div className="nk-fmg-actions">
              <ul className="nk-block-tools g-3">
                <li>
                  <UncontrolledDropdown>
                    <DropdownMenu right>
                      <ul className="link-list-opt no-bdr">
                        <li>
                          <DropdownItem
                            tag="a"
                            href="#upload"
                            onClick={(ev) => {
                              ev.preventDefault();
                              toggleUploadModal();
                            }}
                          >
                            <Icon name="upload-cloud"></Icon>
                            <span>Upload File</span>
                          </DropdownItem>
                        </li>
                        <li>
                          <DropdownItem
                            tag="a"
                            href="#upload"
                            onClick={(ev) => {
                              ev.preventDefault();
                              toggleCreateModal();
                            }}
                          >
                            <Icon name="folder-plus"></Icon>
                            <span>Create Folder</span>
                          </DropdownItem>
                        </li>
                      </ul>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </li>

                <li>
                  <Button color="primary" onClick={() => toggleUploadModal()}>
                    <Icon name="upload-cloud"></Icon> <span>Upload</span>
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        )}
      <div className="nk-fmg-body-content">
        <Files
          data={dataList}
          setData={setData}
          searchText={searchText}
          setSearchText={setSearchText}
          toggleCreateModal={toggleCreateModal}
          toggleUploadModal={toggleUploadModal}
          toggleScreenLg={toggleScreenLg}
        />
      </div>
      {metaData && (
        <div className="ml-4">
          <PaginationComponent
            itemPerPage={metaData?.take}
            totalItems={metaData?.itemCount}
            paginate={paginate}
            currentPage={metaData?.page}
          />
        </div>
      )}
      <Modal isOpen={createModal} size="md" toggle={toggleCreateModal}>
        <CreateFolder toggle={toggleCreateModal} />
      </Modal>
      <Modal isOpen={uploadModal} size="md" toggle={toggleUploadModal}>
        <Upload cookies={cookies} toggle={toggleUploadModal} folderId={folderId} data={data} setData={setData} />
      </Modal>
    </div>
  );
};

export default FileManagerBody;
