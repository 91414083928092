/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useLayoutEffect, useEffect } from "react";
import ContentAlt from "../../../layout/content/ContentAlt";
import Head from "../../../layout/head/Head";
import SimpleBar from "simplebar-react";
import FileManagerAside from "./FileManagerAside";
import FileManagerBody from "./FileManagerBody";
import { FileManagerContext } from "./FileManagerContext";
import { useCookies } from "react-cookie";
import { Nav, NavItem, NavLink } from "reactstrap";
import { getDepartmentsAll } from "services/departments";
import { ActiveFileDepartmentContext } from "contexts/activeFileDepartment";

const FileManager = () => {
  const { contextData, meta } = useContext(FileManagerContext);

  const [scLg, setScLg] = useState(false);
  const [data, setData] = contextData;

  const [folderId, setFolderId] = useState(null);
  const [folderName, setFolderName] = useState(null);
  const [cookies] = useCookies(["user"]);
  const [activeDepartment, setActiveDepartment] = useState(cookies.departmentId);
  const [departmentList, setDepartmentList] = useState([]);
  const roleFunctions = JSON.parse(localStorage.getItem("permission"))["departments"].access || [];


  useEffect(() => {
    refreshData()
  }, []);
  const refreshData = async () => {
    const responseGetDepartments = await getDepartmentsAll(cookies.SIDikey, ['Access_All_Dept'], 'DESC', 'department.createdAt');
    let depts = responseGetDepartments.data;
    if(!roleFunctions.includes("Access_All_Dept")){
      depts = depts.filter((df) => cookies.departmentIds.some((dp) => df.id === dp));
      console.log('depts', roleFunctions,depts)
    }
    setDepartmentList(depts);
  }

  

  const toggleScreenLg = () => {
    setScLg(!scLg);
  };
  const toggleTab = (tab) => {
    if (activeDepartment !== tab) setActiveDepartment(tab);
  };

  useLayoutEffect(() => {
    if (scLg) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100%";
    }
    if (!scLg) {
      document.body.style.overflow = "auto";
      document.body.style.height = "auto";
    }
  }, [scLg]);

  return (
    <React.Fragment>
      <Head title="File Manager"></Head>
      <ContentAlt>
        <div className="ml-5" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Nav tabs>
            {
              departmentList.map((item, index) => {
                return (
                  <NavItem key={index}>
                    <NavLink
                      style={{ color: activeDepartment === item.id ? "#4461F2" : "", cursor: "pointer" }}
                      onClick={() => {
                        toggleTab(item.id);
                      }}
                    >
                      [{item.name}]
                    </NavLink>
                  </NavItem>
                );
              })
            }
          </Nav>
        </div>
        <div className="nk-fmg">
          <ActiveFileDepartmentContext.Provider value={{ value: [activeDepartment, setActiveDepartment]}}>
          <SimpleBar className={`nk-fmg-aside toggle-screen-lg ${scLg ? "content-active" : ""}`}>
            <FileManagerAside cookies={cookies} setScLg={setScLg} folderId={folderId} setFolderId={setFolderId} folderName={folderName} setFolderName={setFolderName} activeDepartment={activeDepartment} />
          </SimpleBar>
          {scLg && <div className="toggle-overlay" onClick={() => toggleScreenLg()}></div>}
          <FileManagerBody cookies={cookies} data={data} setData={setData} toggleScreenLg={toggleScreenLg} folderId={folderId} folderName={folderName} />
          </ActiveFileDepartmentContext.Provider>
        </div>
      </ContentAlt>
    </React.Fragment>
  );
};

export default FileManager;
