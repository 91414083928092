import React, { useEffect } from "react";
import { FormGroup, Form, Modal, ModalBody, Collapse } from "reactstrap";
import { Icon, Col, Button, RSelect } from "../../components/Component";
import { useForm } from "react-hook-form";
import getTextColor from "../../components/custom/getTextColor";
import { useState } from "react";
import { getTags } from "../../services/tags";
import { ProvinceDataList } from "pages/app/chat/ProvinceDataList";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { serviceCreateContact, servicePutContact } from "services/contact";
import Swal from "sweetalert2";

const ContactCreate = ({
  membershipOption,
  clientOption,
  cookies,
  modalCreate,
  modalToggle,
  roleFunctions,
  refreshContacts,
  pageNumber,
  ...props
}) => {
  const [tagOption, setTagOption] = useState([]);
  const [isOpen, setIsOpen] = useState([]);
  const provinceList = ProvinceDataList;
  const [districtList, setCityList] = useState([]);
  const [subDistrictList, setDistrictList] = useState([]);
  const [changedFormData, setChangedFormData] = useState({});
  const titleOption = [
    { value: "Kak", label: "Kak" },
    { value: "Bapak", label: "Bapak" },
    { value: "Ibu", label: "Ibu" },
  ];
  const [formData, setFormData] = useState({
    greeting: { value: "", label: "" },
    name: "",
    email1: "",
    email2: "",
    phone1: "",
    phone2: "",
    birthday: null,
    province: "",
    district: "",
    subDistrict: "",
    zipCode: "",
    address: "",
    notes: "",
    tags: "",
    clientAclub: "",
    clientType: "",
    membership: "",
    salesAclub: "",
    clientCAT: "",
    clientAshop: "",
    productAshop: "",
    clientOrbi: "",
    salesOrbi: "",
    clientUOB: "",
    salesUOB: "",
    clientSucor: "",
    salesSucor: "",
    createdAt: "",
    updatedAt: "",
    createdBy: "",
    updatedBy: "",
  });

  const openAccordion = (id) => {
    if (!checkOpen(id)) {
      const allOpen = [...isOpen];
      allOpen.push(id);
      setIsOpen(allOpen);
    } else {
      closeAccordion(id);
    }
  };

  const checkOpen = (id) => {
    return isOpen.includes(id);
  };

  const closeAccordion = (id) => {
    const allOpen = [...isOpen];
    const index = allOpen.indexOf(id);
    if (index !== -1) {
      allOpen.splice(index, 1);
      setIsOpen(allOpen);
    }
  };

  const refreshTag = async () => {
    const responseGetTags = await getTags(cookies.SIDikey);
    if (new RegExp(/20+/).test(responseGetTags.status)) {
      const resultData = responseGetTags.data;
      const finalData = [];
      resultData.data.forEach((tag) => {
        const tempData = {
          value: tag.id,
          label: tag.name,
          color: tag.color,
        };
        finalData.push(tempData);
      });
      setTagOption(finalData);
    } else {
      console.log(responseGetTags);
    }
  };

  useEffect(() => {
    refreshTag();
  }, []);

  const handleFormObjectChange = (newValue, name) => {
    setFormData((current) => {
      return {
        ...current,
        [name]: newValue,
      };
    });

    setChangedFormData((current) => {
      return {
        ...current,
        [name]: newValue,
      };
    });
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();

    const tPhones = [];
    const tEmails = [];
    changedFormData.phones = []
    changedFormData.emails = []

    if(changedFormData.phone1){
      tPhones.push(changedFormData.phone1);
    }
    if(changedFormData.phone2){
      tPhones.push(changedFormData.phone2);
    }
    tPhones.forEach((tp)=>{
      changedFormData.phones.push({phone:tp});
    });

    if(changedFormData.email1){
      tEmails.push(changedFormData.email1);
    }
    if(changedFormData.email2){
      tEmails.push(changedFormData.email2);
    }
    tEmails.forEach((te)=>{
      changedFormData.emails.push({email:te});
    });

    console.log("changedFormData", changedFormData);

    // return  
    const resp = await serviceCreateContact(cookies.SIDikey, changedFormData);
    if (/20+/.test(resp.status)) {
      Swal.fire("Contact", "Berhasil", "success");
      refreshContacts(pageNumber);
      modalToggle();
      
    } else {
      if (/422/.test(resp.status)) {
        Swal.fire("Contact", "Nomor HP atau Email tidak sesuai format.", "error");
      }else{
        Swal.fire("Contact", "Network & Server error", "error");
      }
    }
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={modalCreate}
        backdrop="static"
        keyboard={false}
        scrollable={true}
        toggle={() => modalToggle()}
        className="modal-dialog-centered"
        size="lg"
      >
        <ModalBody>
          <a
            href="#cancel"
            onClick={(ev) => {
              ev.preventDefault();
              modalToggle();
              //   onFormCancel();
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">Detail Kontak</h5>
            <div className="mt-4">
              <Form className="row gy-2" onSubmit={(e) => handleSubmitForm(e)}>
                <Col md="4" lg="4">
                  <FormGroup>
                    <label className="form-label">Sapaan</label>
                    <RSelect
                      name="greeting"
                      options={titleOption}
                      defaultValue={formData.greeting}
                      onChange={(e) => handleFormObjectChange(e.value, "greeting")}
                    />
                  </FormGroup>
                </Col>
                <Col md="8" lg="8">
                  <FormGroup>
                    <label className="form-label">Nama</label>
                    <input
                      name="name"
                      className="form-control"
                      type="text"
                      defaultValue={formData.name}
                      onChange={(e) => handleFormObjectChange(e.target.value, "name")}
                    />
                  </FormGroup>
                </Col>

                <Col md="12" lg="12">
                  <label className="form-label">Tanggal Ulang Tahun</label>
                  <ReactDatePicker
                    className="form-control date-picker"
                    showYearDropdown
                    scrollableYearDropdown
                    dropdownMode="select"
                    selected={formData.birthday}
                    onChange={(date) => handleFormObjectChange(date, "birthday")}
                    name="birthday"
                  />
                </Col>

                <Col md="6" lg="6">
                  <FormGroup>
                    <label className="form-label">Phone 1</label>
                    <input
                      name="phone1"
                      className="form-control"
                      type="number"
                      defaultValue={formData.phone1}
                      onChange={(e) => handleFormObjectChange(e.target.value, "phone1")}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="6">
                  <FormGroup>
                    <label className="form-label">Phone 2</label>
                    <input
                      name="phone2"
                      className="form-control"
                      type="number"
                      defaultValue={formData.phone2}
                      onChange={(e) => handleFormObjectChange(e.target.value, "phone2")}
                    />
                  </FormGroup>
                </Col>

                <Col md="6" lg="6">
                  <FormGroup>
                    <label className="form-label">Email 1</label>
                    <input
                      name="email1"
                      className="form-control"
                      type="text"
                      defaultValue={formData.email1}
                      onChange={(e) => handleFormObjectChange(e.target.value, "email1")}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="6">
                  <FormGroup>
                    <label className="form-label">Email 2</label>
                    <input
                      name="email2"
                      className="form-control"
                      type="text"
                      defaultValue={formData.email2}
                      onChange={(e) => handleFormObjectChange(e.target.value, "email2")}
                    />
                  </FormGroup>
                </Col>

                <Col md="6" lg="6">
                  <FormGroup>
                    <label className="form-label">Provinsi</label>
                    <input
                      name="province"
                      className="form-control"
                      type="text"
                      defaultValue={formData.province}
                      onChange={(e) => handleFormObjectChange(e.target.value, "province")}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="6">
                  <FormGroup>
                    <label className="form-label">Kota</label>
                    <input
                      name="city"
                      className="form-control"
                      type="text"
                      defaultValue={formData.district}
                      onChange={(e) => handleFormObjectChange(e.target.value, "district")}
                    />
                  </FormGroup>
                </Col>

                <Col md="6" lg="6">
                  <FormGroup>
                    <label className="form-label">Kecamatan</label>
                    <input
                      name="district"
                      className="form-control"
                      type="text"
                      defaultValue={formData.subDistrict}
                      onChange={(e) => handleFormObjectChange(e.target.value, "subDistrict")}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="6">
                  <FormGroup>
                    <label className="form-label">Kode Pos</label>
                    <input
                      name="zipCode"
                      className="form-control"
                      type="number"
                      defaultValue={formData.zipCode}
                      onChange={(e) => handleFormObjectChange(e.target.value, "zipCode")}
                    />
                  </FormGroup>
                </Col>
                <Col md="12" lg="12">
                  <FormGroup>
                    <label className="form-label">Detail Alamat</label>
                    <textarea
                      name="address"
                      className="form-control no-resize"
                      defaultValue={formData.address}
                      onChange={(e) => handleFormObjectChange(e.target.value, "address")}
                    />
                  </FormGroup>
                </Col>
                <Col md="12" lg="12">
                  <FormGroup>
                    <label className="form-label">Catatan</label>
                    <textarea
                      name="notes"
                      className="form-control no-resize "
                      defaultValue={formData.notes}
                      onChange={(e) => handleFormObjectChange(e.target.value, "notes")}
                    />
                  </FormGroup>
                </Col>
                <Col md="12" lg="12">
                  <FormGroup>
                    <label className="form-label">Tag</label>
                    <RSelect
                      name="tags"
                      options={tagOption}
                      isMulti
                      defaultValue={formData.tags}
                      styles={{
                        multiValue: (styles, { data }) => {
                          const color = data.color;
                          return {
                            ...styles,
                            backgroundColor: color + " !important",
                            borderRadius: "1.5rem !important",
                          };
                        },
                        multiValueLabel: (styles, { data }) => {
                          const color = getTextColor(data.color);
                          return {
                            ...styles,
                            color: color,
                          };
                        },
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="12" lg="12">
                  <div className="accordion">
                    <div className="accordion-item">
                      <div className="accordion-head" onClick={() => openAccordion("A-Club")}>
                        <h6 className="title">A-Club</h6>
                        <span className="accordion-icon"></span>
                      </div>
                      <Collapse className="accordion-body" isOpen={checkOpen("A-Club")}>
                        <div className="accordion-inner row gy-2">
                          <Col md="4" lg="4">
                            <FormGroup>
                              <label className="form-label">Client A-Club</label>
                              <RSelect
                                name="clientAClub"
                                options={clientOption}
                                defaultValue={formData.clientAclub}
                                onChange={(e) => handleFormObjectChange(e.target.value, "clientAclub")}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="8" lg="8">
                            <FormGroup>
                              <label className="form-label">Client Type</label>
                              <RSelect
                                name="clientType"
                                options={clientOption}
                                defaultValue={formData.clientType}
                                onChange={(e) => handleFormObjectChange(e.target.value, "clientType")}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="7" lg="7">
                            <FormGroup>
                              <label className="form-label">Membership</label>
                              <RSelect
                                isMulti
                                name="clientType"
                                options={membershipOption}
                                defaultValue={formData.membership}
                                onChange={(e) => handleFormObjectChange(e.target.value, "membership")}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="5" lg="5">
                            <FormGroup>
                              <label className="form-label">Sales A-Club</label>
                              <RSelect
                                name="salesAClub"
                                options={clientOption}
                                defaultValue={formData.salesAclub}
                                onChange={(e) => handleFormObjectChange(e.target.value, "salesAclub")}
                              />
                            </FormGroup>
                          </Col>
                        </div>
                      </Collapse>
                    </div>
                    <div className="accordion-item">
                      <div className="accordion-head collapsed" onClick={() => openAccordion("CAT")}>
                        <h6 className="title">CAT</h6>
                        <span className="accordion-icon"></span>
                      </div>
                      <Collapse className="accordion-body" isOpen={checkOpen("CAT")}>
                        <div className="accordion-inner row gy-2">
                          <Col md="12" lg="12">
                            <FormGroup>
                              <label className="form-label">Client CAT</label>
                              <RSelect
                                name="clientCAT"
                                options={clientOption}
                                defaultValue={formData.clientCAT}
                                onChange={(e) => handleFormObjectChange(e.target.value, "clientCAT")}
                              />
                            </FormGroup>
                          </Col>
                        </div>
                      </Collapse>
                    </div>
                    <div className="accordion-item">
                      <div className="accordion-head collapsed" onClick={() => openAccordion("A-Shop")}>
                        <h6 className="title">A-Shop</h6>
                        <span className="accordion-icon"></span>
                      </div>
                      <Collapse className="accordion-body" isOpen={checkOpen("A-Shop")}>
                        <div className="accordion-inner row gy-2">
                          <Col md="12" lg="12">
                            <FormGroup>
                              <label className="form-label">Client A-Shop</label>
                              <RSelect
                                name="clientAShop"
                                options={clientOption}
                                defaultValue={formData.clientAshop}
                                onChange={(e) => handleFormObjectChange(e.target.value, "clientAshop")}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="12" lg="12">
                            <FormGroup>
                              <label className="form-label">Product A-Shop</label>
                              <textarea
                                className="form-control no-resize"
                                name="productAshop"
                                defaultValue={formData.productAshop}
                                onChange={(e) => handleFormObjectChange(e.target.value, "productAshop")}
                              />
                            </FormGroup>
                          </Col>
                        </div>
                      </Collapse>
                    </div>
                    <div className="accordion-item">
                      <div className="accordion-head collapsed" onClick={() => openAccordion("Orbi")}>
                        <h6 className="title">Orbi</h6>
                        <span className="accordion-icon"></span>
                      </div>
                      <Collapse className="accordion-body" isOpen={checkOpen("Orbi")}>
                        <div className="accordion-inner row gy-2">
                          <Col md="7" lg="7">
                            <FormGroup>
                              <label className="form-label">Client Orbi</label>
                              <RSelect
                                name="clientOrbi"
                                options={clientOption}
                                defaultValue={formData.clientOrbi}
                                onChange={(e) => handleFormObjectChange(e.target.value, "clientOrbi")}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="5" lg="5">
                            <FormGroup>
                              <label className="form-label">Sales Orbi</label>
                              <RSelect
                                name="salesOrbi"
                                options={clientOption}
                                defaultValue={formData.salesAclub}
                                onChange={(e) => handleFormObjectChange(e.target.value, "salesAclub")}
                              />
                            </FormGroup>
                          </Col>
                        </div>
                      </Collapse>
                    </div>
                    <div className="accordion-item">
                      <div className="accordion-head collapsed" onClick={() => openAccordion("UOB")}>
                        <h6 className="title">UOB</h6>
                        <span className="accordion-icon"></span>
                      </div>
                      <Collapse className="accordion-body" isOpen={checkOpen("UOB")}>
                        <div className="accordion-inner row gy-2">
                          <Col md="7" lg="7">
                            <FormGroup>
                              <label className="form-label">Client UOB</label>
                              <RSelect
                                name="clientUOB"
                                options={clientOption}
                                defaultValue={formData.clientUOB}
                                onChange={(e) => handleFormObjectChange(e.target.value, "clientUOB")}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="5" lg="5">
                            <FormGroup>
                              <label className="form-label">Sales UOB</label>
                              <RSelect
                                name="salesUOB"
                                options={clientOption}
                                defaultValue={formData.salesUOB}
                                onChange={(e) => handleFormObjectChange(e.target.value, "salesUOB")}
                              />
                            </FormGroup>
                          </Col>
                        </div>
                      </Collapse>
                    </div>
                    <div className="accordion-item">
                      <div className="accordion-head collapsed" onClick={() => openAccordion("Sucor")}>
                        <h6 className="title">Sucor</h6>
                        <span className="accordion-icon"></span>
                      </div>
                      <Collapse className="accordion-body" isOpen={checkOpen("Sucor")}>
                        <div className="accordion-inner row gy-2">
                          <Col md="7" lg="7">
                            <FormGroup>
                              <label className="form-label">Client Sucor</label>
                              <RSelect
                                name="clientSucor"
                                options={clientOption}
                                defaultValue={formData.clientSucor}
                                onChange={(e) => handleFormObjectChange(e.target.value, "clientSucor")}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="5" lg="5">
                            <FormGroup>
                              <label className="form-label">Sales Sucor</label>
                              <RSelect
                                name="salesSucor"
                                options={clientOption}
                                defaultValue={formData.salesSucor}
                                onChange={(e) => handleFormObjectChange(e.target.value, "salesSucor")}
                              />
                            </FormGroup>
                          </Col>
                        </div>
                      </Collapse>
                    </div>
                  </div>
                </Col>
                <Col md="12" lg="12" className="border-top pt-2 pb-4">
                  <p className="text-soft">
                    {"Created at " + moment(formData.createdAt).format("MMM DD, YYYY") + " by " + formData.createdBy}
                  </p>
                  <p className="text-soft">
                    {"Modify at " + moment(formData.updatedAt).format("MMM DD, YYYY") + " by " + formData.updatedBy}
                  </p>
                </Col>
                <Col md="6" lg="6">
                  <Button type="button" color="gray" outline onClick={() => modalToggle()} className="btn btn-block">
                    Batal
                  </Button>
                </Col>
                <Col md="6" lg="6">
                  {!roleFunctions.includes("update") || (
                    <Button type="submit" color="primary" className="btn btn-block">
                      Simpan
                    </Button>
                  )}
                </Col>
              </Form>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default ContactCreate;
