/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import SimpleBar from "simplebar-react";
import { useForm } from "react-hook-form";
import { Icon } from "../../../components/Component";
import { Button, Form, FormGroup, Modal, ModalBody } from "reactstrap";
import Swal from "sweetalert2";
import { FileManagerContext } from "./FileManagerContext";
import { serviceCreateDirectory, serviceGetDirectories, serviceDeleteDirectory } from "services/directory";
import { ActiveFileDepartmentContext } from "contexts/activeFileDepartment";


const FileManagerAside = ({ cookies, setScLg, folderId, setFolderId, folderName, setFolderName }) => {
  const { planData, setDirectoryId, directoryId } = useContext(FileManagerContext);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const { errors, register, handleSubmit } = useForm();
  const roleFunctions = JSON.parse(localStorage.getItem("permission"))["directories"]?.access || ['Access_Own_Dept'];

  const {value} = useContext(ActiveFileDepartmentContext);
  const [activeDepartment, setActiveDepartment] = value;
  
  const [listFolder, setListFolder] = useState([]);
  console.log("🚀 ~ file: FileManagerAside.js:24 ~ FileManagerAside ~ listFolder:", listFolder)

  useEffect(() => {
    refreshData();
  }, []);

  useEffect(() => {
    refreshData();
    setScLg(false);
    setFolderId(null);
    setDirectoryId(null);
    setFolderName(null);
  }, [activeDepartment]);

  const refreshData = async () => {
    const resp = await serviceGetDirectories(cookies.SIDikey,roleFunctions, activeDepartment || cookies.departmentId , 1);
    setListFolder(resp.data.data);
  };

  const addData = async (data) => {
    const resp = await serviceCreateDirectory(cookies.SIDikey, data);
    console.log("data", resp);

    if (resp.status === 201) {
      Swal.fire({
        icon: "success",
        title: "Succesfull Transasction",
        focusConfirm: false,
      });
      const currentFolder = listFolder;
      currentFolder.push(resp.data);
      setListFolder([...currentFolder]);
    } else {
      Swal.fire({
        icon: "error",
        title: resp.message,
        focusConfirm: false,
      });
    }
    setIsAddOpen(false);
    console.log("data", resp);
    return resp.data;
  };

  // Deletes a file/folder
  const deleteFolder = (id) => {
    Swal.fire({
      title: "Apakah anda yakin untuk menghapus folder ini?",
      text: "File di dalam nya juga akan hilang.",
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#1ee0ac",
      confirmButtonText: "Ya, hapus!",
      cancelButtonText: "Tidak",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const responseDelete = await serviceDeleteDirectory(cookies.SIDikey, id);
          if (responseDelete.status === 202) {
            Swal.fire("Berhasil Hapus Folder", "Hapus Folder", "success");
          } else {
            Swal.fire("Gagal Hapus Folder", responseDelete.message, "error");
          }
          refreshData();
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  const onFormSubmit = (submitData) => {
    const { name } = submitData;

    const data = {
      name: name,
      departmentId: activeDepartment
    };
    addData(data);
  };

  return (
    <React.Fragment>
      <div className="nk-fmg-aside-wrap">
        <SimpleBar className="nk-fmg-aside-top">
          <ul className="nk-fmg-menu">
            <li className="add-folder" onClick={() => setIsAddOpen(true)}>
              <Icon name="plus" />
              <span>Tambah folder baru</span>
            </li>

            {listFolder.map((item) => (
              <li
                key={item.id}
                onClick={() => {
                  setScLg(false);
                  setFolderId(item.id);
                  setDirectoryId(item.id);
                  setFolderName(item.name);
                }}
                className={`${folderId === item.id ? "active" : ""}`}
              >
                <div className="nk-fmg-menu-item">
                  <Icon name="folder"></Icon>
                  <span className="nk-fmg-menu-text">{item.name}</span>
                </div>
              </li>
            ))}
          </ul>
        </SimpleBar>
        <div>
          {folderId !== null && roleFunctions.includes("delete") && (
            <Button
              style={{
                position: "absolute",
                bottom: 10,
                left: "50%",
                transform: "translateX(-50%)",
                width: "max-content",
              }}
              color="danger"
              onClick={() => deleteFolder(folderId)}
            >
              Hapus Folder
            </Button>
          )}
        </div>
      </div>
      <Modal isOpen={isAddOpen} toggle={() => setIsAddOpen(false)} size="sm">
        <ModalBody>
          <a
            href="#cancel"
            onClick={(ev) => {
              ev.preventDefault();
              setIsAddOpen(false);
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="">
            <h5 className="title">Tambah Folder</h5>
            <div className="mt-4">
              <Form onSubmit={handleSubmit(onFormSubmit)}>
                <FormGroup>
                  <label className="form-label">Nama Folder</label>
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    placeholder="Enter folder name"
                    ref={register({ required: "This field is required" })}
                  />
                  {errors.name && <span className="invalid">{errors.name.message}</span>}
                </FormGroup>
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <Button
                      outline
                      color="primary"
                      onClick={(ev) => {
                        ev.preventDefault();
                        setIsAddOpen(false);
                      }}
                    >
                      Batal
                    </Button>
                  </li>
                  <li>
                    <Button color="primary" size="md" type="submit">
                      Simpan
                    </Button>
                  </li>
                </ul>
              </Form>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default FileManagerAside;
