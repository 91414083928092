import { axiosPrivate } from "api/axiosCore";
import axios from "axios";

const limit = 10;
const ALL_DEPT = "Access_All_Dept";
const OWN_DEPT = "Access_Own_Dept";
const OWN = "Access_Own";

export const serviceGetQuickReply = async (
  token,
  rawquery,
  roleFunctions,
  departmentId,
  userId,
  page,
  field,
  order = "DESC",
  operator,
  fo,
) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    
    let query = "";
    // if (rawquery === "/") {
    //   query = rawquery.replace("/", "");
    // }
    query = rawquery?.replace(/\//g, "");


    roleFunctions = roleFunctions || [];
    departmentId = departmentId || "";
    const queryData = query ? "&q=" + query : "";
    const fieldData = field ? "&f=" + field : "";
    const fieldOrder = fo ? "&fo=" + fo : "";
    const operatorData = operator ? "&c=" + operator : "";
    // var basicUrl = `api/quick-replies?order=${order}&take=${limit}&page=${page || 1}` + queryData + fieldData + operatorData;
    var basicUrl = `api/quick-replies?order=${order}&take=${limit}&page=${page || 1}` + queryData + fieldData + fieldOrder;
    var response = null;

    if (roleFunctions.includes(ALL_DEPT)) {
      response = await axiosPrivate.get(basicUrl);
    } else if (roleFunctions.includes(OWN_DEPT)) {
      response = await axiosPrivate.get(basicUrl + `&departmentIds=${departmentId}` + operatorData); //deptId
    } else if (roleFunctions.includes(OWN)) {
      response = await axiosPrivate.get(basicUrl + `&userId=${userId}`); //deptId
    }

    console.log(response);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};
export const serviceGetAllQuickReply = async (token) => {
  try {
    let hasNextPage = true;
    let page = 1;
    let quickReplies = [];

    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    while (hasNextPage) {
      var basicUrl = `api/quick-replies?order=DESC&take=20&page=${page}`;
      const response = await axiosPrivate.get(basicUrl);
      const responseData = response.data.data;
      quickReplies.push(...responseData);
      if (!response.data.meta.hasNextPage) {
        hasNextPage = false;
      } else {
        page += 1;
      }
    }
    return quickReplies;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetAllQuickReplyCommand = async (token) => {
  try {
    let hasNextPage = true;
    let page = 1;
    let command = [];

    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    while (hasNextPage) {
      var basicUrl = `api/quick-replies?order=DESC&take=${limit}&page=${page || 1}`;
      const response = await axiosPrivate.get(basicUrl);
      const responseData = response.data.data;
      console.log("responseData", responseData);
      for (let i = 0; i < responseData.length; i++) {
        command.push({ comand: responseData[i].command.toLowerCase(), id: responseData[i].id });
      }

      if (!response.data.meta.hasNextPage) {
        hasNextPage = false;
      } else {
        page += 1;
      }
    }
    return command;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const servicePostQuickReply = async (token, data) => {
  console.log(data);
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      config.headers["Content-Type"] = "multipart/form-data";
      return config;
    });

    const response = await axiosPrivate.post("api/quick-replies", data);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const servicePutQuickReply = async (id, token, data) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      config.headers["Content-Type"] = "multipart/form-data";
      return config;
    });

    const response = await axiosPrivate.put("api/quick-replies/" + id, data);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceDeleteQuickReply = async (id, token) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.delete("api/quick-replies/" + id);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetQuickReplyMedia = async (mediaUrl, fileName = null) => {
  try {
    mediaUrl = mediaUrl.startsWith(process.env.REACT_APP_S3_URL_STORAGE)
      ? mediaUrl
      : process.env.REACT_APP_S3_URL_STORAGE + mediaUrl;

    const mimeTypes = {
      jpg: "image/jpeg",
      jpeg: "image/jpeg",
      png: "image/png",
      gif: "image/gif",
      tif: "image/tiff",
      tiff: "image/tiff",
      bmp: "image/bmp",
    };

    const originalFilename = mediaUrl.split("/").pop();
    const extension = originalFilename.split(".").pop();

    const response = await axios.get(mediaUrl, { responseType: "blob" });

    return new File([response.data], `${fileName}.${extension}` ?? originalFilename, { type: mimeTypes[extension] });
  } catch (error) {
    return null;
  }
};
