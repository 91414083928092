import React, { useEffect, useState, useRef } from "react";
import SimpleBar from "simplebar-react";
import { delay } from "../../../utils/Utils";
import moment from "moment";

import {
  serviceGetMessages, serviceGetMessagesAfterCursor,serviceGetHistoryMessages,serviceGetHistoryMessagesAfterCursor
} from "services/messages";
import { MeChat, SessionResolved, YouChat } from "../chat/ChatPartials";
import { Button, Icon } from "components/Component";

const ChatDetailTicket = ({ cookies, id }) => {
  //   const { chatState } = useContext(ChatContext);
  //   const [chat, setChat] = chatState;
  const [replyMessage, setReplyMessage] = useState(null);
  const [contextMessage, setContextMessage] = useState(null);
  const [Uchat, setUchat] = useState({});
  const [messages, setMessages] = useState([]);
  const [afterCursor, setAfterCursor] = useState("");
  const [isScrollTop, setIsScrollTop] = useState(false);
  // const [quickReplyInput, setQuickReplyInput] = useState("");
  // const emojiButonRef = useRef(null);
  const messagesEndRef = useRef(null);

  // const [replyMessage, setReplyMessage] = useState(null);
  // const [contextMessage, setContextMessage] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  const refreshMessages = async () => {
    setIsLoading(true);
    const responseGetMessages = await serviceGetMessages(cookies.SIDikey, id);
    console.log(responseGetMessages);
    setIsLoading(false);
    if (new RegExp(/20+/).test(responseGetMessages.status)) {
      const data = responseGetMessages.data;
      const messagesData = data.data;
      const metaData = data.meta;
      if (metaData.hasNextPage) {
        setAfterCursor(metaData.afterCursor);
      }
      setMessages(messagesData);
      await delay(200);
    } else {
      console.log("error Get Messagefrom DB, chatBody", responseGetMessages);
    }
  };
  useEffect(() => {
    refreshMessages();
    // scrollToBottom();
  }, []);
  

  const dateCheck = (input) => {
    return new Date(Number(input)).toLocaleString("en-GB", {
      month: "numeric",
      year: "numeric",
      day: "numeric",
    });
  };

 
  const dateProcessing = (timestamp) => {
    const today = moment().format("DD/MM/YYYY");
    const compared = moment.unix(timestamp / 1000).format("DD/MM/YYYY");

    if (today === compared) {
      return "Today";
    } else if (moment().subtract(1, "day").format("DD/MM/YYYY") === compared) {
      return "Yesterday";
    }
    return new Date(Number(timestamp)).toLocaleString("en-GB", {
      month: "numeric",
      year: "numeric",
      day: "numeric",
    });
  };


  const loadMoreMessages = async (e) => {
    e.preventDefault();
      // console.log(afterCursor)
      if (afterCursor !== "") {
        // TO SOMETHING HERE
        setIsScrollTop(true);
        const response = await addMessages(afterCursor);
        // console.log(response);
        if (response.status !== 200) {
          console.log("error add messages", response);
        }
      }
  };

  const addMessages = async (cursorSpec) => {

    let includeCursor = "true";
    const response = await serviceGetMessagesAfterCursor(cookies.SIDikey, id, cursorSpec, includeCursor);

    if (response.status === 200) {
      const data = response.data;
      const messagesData = data.data;
      const metaData = data.meta;

      messagesData.shift();
      setMessages((messages) => [...messages, ...messagesData]);
      //add messasges by click

      if (metaData.hasNextPage) {
        setAfterCursor(metaData.afterCursor);
      } else {
        setAfterCursor("");
      }

      setIsScrollTop(true);
      // await delay(200);
      // scrollToStill(messages.at(0)?.id);
    }
    if (afterCursor !== cursorSpec) {
      await new Promise((res) => setTimeout(res, 100));
      includeCursor = "true";
    }
    return response;
  };

  return (
    <SimpleBar
      id="simple-bar-messages"
      className="nk-chat-panel"
      style={{ height: "400px" }}
      // onScroll={onScrolling}
      // scrollableNodeProps={{ ref: messagesEndRef, onScroll: onScrolling }}
    >
      {isLoading ? (
        <>Loading</>
      ) : (
        <>
          {messages.map((item, idx) => {
            <React.Fragment>
              
            </React.Fragment>
            if (item !== undefined) {
              if (dateCheck(Number(messages[idx - 1]?.timestamp)) === dateCheck(Number(item.timestamp))) {
                if (item.type === "PURCHASE") {
                  return <SessionResolved key={item.id} item={item} />;
                } else {
                  if (item.fromMe) {
                    return (
                      <MeChat
                      cookies={cookies}
                        key={item.id}
                        item={item}
                        chat={Uchat}
                        contextMessage={contextMessage}
                        setReplyMessage={setReplyMessage}
                        setContextMessage={setContextMessage}
                        roleFunctions={[]}
                        // onClick={(e) => jumpReply(e)}
                      ></MeChat>
                    );
                  } else {
                    return (
                      <YouChat
                        cookies={cookies}
                        id={item.id}
                        key={item.id}
                        item={item}
                        chat={Uchat}
                        setReplyMessage={setReplyMessage}
                        setContextMessage={setContextMessage}
                        roleFunctions={[]}
                        // onClick={(e) => jumpReply(e)}
                      ></YouChat>
                    );
                  }
                }
              } else {
                if (item.type === "PURCHASE") {
                  return <SessionResolved key={item.id} item={item} />;
                } else {
                  if (item.fromMe) {
                    return (
                      <React.Fragment key={item.id}>
                        <div className="time-separator">
                          {dateProcessing(item.timestamp)}
                        </div>
                        <MeChat
                          item={item}
                          chat={Uchat}
                          setReplyMessage={setReplyMessage}
                          setContextMessage={setContextMessage}
                          roleFunctions={[]}

                          // onClick={(e) => jumpReply(e)}
                        ></MeChat>
                      </React.Fragment>
                    );
                  } else {
                    return (
                        <React.Fragment key={item.id}>
                        <div className="time-separator">
                          {dateProcessing(item.timestamp)}
                        </div>
                        <YouChat
                          setReplyMessage={setReplyMessage}
                          setContextMessage={setContextMessage}
                          id={item.id}
                          item={item}
                          chat={Uchat}
                          roleFunctions={[]}
                          // onClick={(e) => jumpReply(e)}
                          ></YouChat>
                        </React.Fragment>
                    );
                  }
                }
              }
            }
            return <></>;
          })}
          {afterCursor ? (
            <div className="d-flex justify-content-center">
              <Button outline color="light" size="sm" onClick={(e) => loadMoreMessages(e)}>
                <span>Load More Messages</span>
                <Icon name="reload-alt" />
              </Button>
            </div>
          ) : (
            <div className="d-flex justify-content-center">
              <Button outline color="danger" size="sm" disabled>
                <span>The End Of Chat</span>
                <Icon name="na" />
              </Button>
            </div>
          )}
        </>
      )}
    </SimpleBar>
  );
};

export default ChatDetailTicket;
