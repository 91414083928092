import { io } from "socket.io-client";

export const socketIO = io(process.env.REACT_APP_SOCKET_ADDRESS, {
  extraHeaders: {
    Authorization: process.env.REACT_APP_SOCKET_AUTH,
  },
  auth: {
    Authorization: process.env.REACT_APP_SOCKET_AUTH,
  },
  path: "/socket.io/",
  transports: ["websocket"],
});
