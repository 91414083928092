import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Form, FormGroup } from "reactstrap";
import { Block, BlockBetween, BlockHead, BlockHeadContent, BlockTitle, RSelect } from "../../components/Component";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { useCookies } from "react-cookie";
import getTextColor from "../../components/custom/getTextColor";
import { getDepartments } from "services/departments";
import Swal from "sweetalert2";
import { servicePostQuickReply, serviceGetAllQuickReplyCommand } from "services/quickReply";

const AddQuickReply = ({ ...props }) => {
  const { errors, register, setValue, handleSubmit } = useForm();
  const [dataDepartment, setDataDepartment] = useState();
  const [cookies, setCookie] = useCookies(["user"]);
  const [commandData, setCommandData] = useState([]);
  const [matchCommand, setMatchCommand] = useState(false);
  const roleFunctions = JSON.parse(localStorage.getItem("permission"))["quick-replies"].access || [];
  const [selectedDepartment, setSelectedDepartment] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    department: [],
    command: "",
    content: "",
    file: "",
    active: false,
  });

  const refreshDepartment = async () => {
    const responseGetDepartment = await getDepartments(cookies.SIDikey, '', cookies.departmentIds, cookies.id, roleFunctions);
    if (new RegExp(/20+/).test(responseGetDepartment.status)) {
      const resultData = responseGetDepartment.data.data;
      const finalData = [];
      for(let departement of resultData){
        const tempData = {
          value: departement.id,
          label: departement.name,
          color: departement.color,
        };
        // if (!roleFunctions.includes("Access_All_Dept")) {
        //   if (departement.id !== cookies.departmentIds) {
        //     continue;
        //   }
        // }
        finalData.push(tempData);
       }
    
      setDataDepartment(finalData);
    } else {
      console.log(responseGetDepartment);
    }
  };

  const getCommand = async () => {
    const getCommandList = await serviceGetAllQuickReplyCommand(cookies.SIDikey);
    setCommandData(getCommandList);
  };

  useEffect(() => {
    refreshDepartment();
    getCommand();
  }, []);
  
  const onFormSubmit = async (data) => {
    if (matchCommand) {
      Swal.fire({
        icon: "error",
        title: "Command Duplicate",
        focusConfirm: false,
      });

      return false;
    }

    const { name, command, content, file } = data;
    const departmentsIds = [];
    if (formData.department.length > 0) {
      formData.department.forEach((department) => {
        departmentsIds.push(department.value);
      });
    }
    // let submittedData = {
    //   name: name,
    //   departmentIds: departementsIds,
    //   command: command,
    //   response: content,
    //   file: file,
    // };
    const dataToSend = {
      file: file[0],
      name: name,
      departmentIds: departmentsIds,
      command: command,
      response: content,
    };
    var bodyFormData = new FormData();
    for (const key in dataToSend) {
      if (key === "size") {
        bodyFormData.append(key, parseInt(dataToSend[key]));
        console.log(key, bodyFormData);
      } else {
        bodyFormData.append(key, dataToSend[key]);
      }
    }

    if (dataToSend.departmentIds.length === 0) {
      Swal.fire("Department Kosong", `Harap masukkan department`, "error");
      return;
    }

    const responsePostQuickReply = await servicePostQuickReply(cookies.SIDikey, dataToSend);
    if (new RegExp(/20+/).test(responsePostQuickReply.status)) {
      Swal.fire({
        icon: "success",
        title: "Succesfull Transasction",
        focusConfirm: false,
      }).then(() => {
        setTimeout(() => {
          window.history.pushState(
            `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/quick-reply"}`,
            "auth-login",
            `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/quick-reply"}`
          );
          window.location.reload();
        }, 500);
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Unsuccesfull Transasction",
        focusConfirm: false,
      });
    }
  };

  const departmentSelected = (e) => {
    const finalDepartment = [];
    e.forEach((element) => {
      const tempDepartment = {
        label: element.label,
        name: element.label,
        color: element.color,
        value: element.value,
      };
      finalDepartment.push(tempDepartment);
    });
    setFormData({
      name: formData.name,
      department: finalDepartment,
      command: formData.command,
      content: formData.content,
      file: formData.file,
      active: formData.active,
    });
  };

  const onActiveCheck = () => {
    setFormData({
      name: formData.name,
      department: formData.department,
      command: formData.command,
      content: formData.content,
      file: formData.file,
      active: !formData.active,
    });
  };

  const checkCommand = (dataInput) => {
    if (commandData.length !== 0) {
      const getMatchCommand = commandData.findIndex((item) => item.comand === dataInput.toLowerCase());
      const duplicateComand = getMatchCommand >= 0 ? true : false;
      setMatchCommand(duplicateComand);
      if (duplicateComand) {
        Swal.fire({
          icon: "error",
          title: "Command Duplicate",
          focusConfirm: false,
        });
      }
    }
  };

  return (
    <React.Fragment>
      <Head title="Add Quick Reply" />
      <Content>
        <BlockHead size="sm">
          <BlockHeadContent>
            <BlockTitle tag="h3" page>
              Quick Reply
            </BlockTitle>
          </BlockHeadContent>
        </BlockHead>

        <Block>
          <Card className="p-4">
            <BlockHead>
              <BlockBetween>
                <BlockHeadContent>
                  <strong style={{ fontSize: 18 }}>Tambah Quick Reply</strong>
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>
            <Block className="mt-4">
              <Form className="row gy-4" onSubmit={handleSubmit(onFormSubmit)}>
                <Col md="5" lg="5">
                  <FormGroup>
                    <label className="form-label">Nama</label>
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      ref={register({
                        required: "This field is required",
                      })}
                      placeholder="Masukkan Nama Quick Reply"
                    ></input>
                    {errors.name && <span className="invalid">{errors.name.message}</span>}
                  </FormGroup>
                  <FormGroup>
                    <label className="form-label">Department</label>
                    {/* <Card className="card-bordered mt-1 mb-3 card-bluesky">
                      <CardBody className="card-bluesky-body">
                        <strong>Apabila Department tidak dipilih, pesan akan muncul pada semua Department</strong>
                      </CardBody>
                    </Card> */}
                    <RSelect
                      name="deparment"
                      isMulti
                      styles={{
                        multiValue: (styles, { data }) => {
                          const color = data.color;
                          return {
                            ...styles,
                            backgroundColor: color + " !important",
                            borderRadius: "1.5rem !important",
                          };
                        },
                        multiValueLabel: (styles, { data }) => {
                          const color = getTextColor(data.color);
                          return {
                            ...styles,
                            color: color,
                          };
                        },
                      }}
                      options={dataDepartment}
                      placeholder="Pilih Department"
                      onChange={(e) => {
                        setValue("deparment", e);
                        departmentSelected(e)
                        setSelectedDepartment(e)
                      }}
                      defaultValue={""}
                      {...register("deparment", { required: true })}
                    ></RSelect>
                    {/* {errors.department && <span className="invalid">{errors.department.message}</span>} */}
                    {errors.deparment && !selectedDepartment.length && <span className="custom-invalid">This field is required</span>}
                  </FormGroup>
                  <FormGroup>
                    <label className="form-label">Command</label>
                    <input
                      name="command"
                      className="form-control"
                      type="text"
                      ref={register({
                        required: "This field is required",
                      })}
                      onInput={(e) => checkCommand(e.target.value)}
                      placeholder="Masukkan Command"
                    />
                    {errors.command && <span className="invalid">{errors.command.message}</span>}
                  </FormGroup>
                </Col>
                <Col md="7" lg="7">
                  <FormGroup>
                    <label className="form-label">Isi Command</label>
                    <textarea
                      className="form-control"
                      name="content"
                      ref={register({
                        required: "This field is required",
                      })}
                      placeholder="Contoh: Selamat Pagi"
                    />
                    {errors.content && <span className="invalid">{errors.content.message}</span>}
                  </FormGroup>
                  <FormGroup>
                    <label className="form-label">Media (Opsional)</label>
                    <p className="text-gray">Upload file berupa gambar </p>
                    <div className="form-control-wrap" style={{ maxWidth: 250 }}>
                      <div className="form-file">
                        <input
                           accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                          ref={register()}
                          name="file"
                          type="file"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </FormGroup>
                </Col>
                <Col size="12">
                  <Link to={`${process.env.PUBLIC_URL + "/quick-reply"}`}>
                    <Button outline type="button" color="primary" className="mr-3">
                      Batal
                    </Button>
                  </Link>
                  <Button color="primary">Simpan</Button>
                </Col>
              </Form>
            </Block>
          </Card>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default AddQuickReply;
