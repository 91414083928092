import { axiosPrivate } from "../api/axiosCore";

const ALL_DEPT = "Access_All_Dept";
const OWN_DEPT = "Access_Own_Dept";
const OWN = "Access_Own";

export const getTags = async (token, roleFunctions, departementIds, query, page) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const queryData = query ? "&q=" + query : "";
    const basicUrl = `api/tags?order=DESC&take=10&page=${page || 1}` + queryData;
    var response = await axiosPrivate.get(basicUrl);

    if (roleFunctions.includes(ALL_DEPT)) {
      response = await axiosPrivate.get(basicUrl);
    } else if (roleFunctions.includes(OWN_DEPT)) {
      response = await axiosPrivate.get(basicUrl + `&departmentIds=${departementIds}`);
    }
    
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const getTagsForTickets = async (token, query, page) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const queryData = query ? "&q=" + query : "";
    const basicUrl = `api/tags?order=DESC&take=50&page=${page || 1}` + queryData;
    const response = await axiosPrivate.get(basicUrl);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const getTagsByTake = async (token, query, take, page) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const queryData = query ? "&q=" + query : "";
    const basicUrl = `api/tags?order=DESC&take=${take}&page=${page || 1}` + queryData;
    const response = await axiosPrivate.get(basicUrl);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const postTags = async (token, data) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.post("api/tags", data);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const putTags = async (id, token, data) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.put("api/tags/" + id, data);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const deleteTags = async (id, token) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const response = await axiosPrivate.delete("api/tags/" + id);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};
export const getTagLeadSource = async (token, query, page) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const basicUrl = `api/tags/list?order=DESC&take=1000&tagCategoryId=32c38e87-dd2f-40dc-b2a2-40a08cfad79e`;
    const response = await axiosPrivate.get(basicUrl);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};
