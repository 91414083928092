import { axiosPrivate } from "api/axiosCore";
import moment from "moment";

const limit = 15;
const ALL_DEPT = "Access_All_Dept";
const OWN_DEPT = "Access_Own_Dept";
const OWN = "Access_Own";

export const serviceGetLeads = async (token, roleFunctions, departmentId, userId, page) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    roleFunctions = roleFunctions || [];
    departmentId = departmentId || "";
    const basicUrl = `api/lead-monitorings?departmentId=${departmentId}&groupBy=SALESANDCS`;
    var response = null;
    response = await axiosPrivate.get(basicUrl);
    if (roleFunctions.includes(ALL_DEPT)) {
      response = await axiosPrivate.get(basicUrl); //deptId
    } else if (roleFunctions.includes(OWN_DEPT)) {
      response = await axiosPrivate.get(basicUrl); //deptId
    } else if (roleFunctions.includes(OWN)) {
      response = await axiosPrivate.get(basicUrl + `&userId=${userId}`); //deptId
    }

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceFilterLeads = async (
  token,
  roleFunctions,
  departmentId,
  userId,
  departmentIds,
  page,
  filter,
  startDate,
  endDate
) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    roleFunctions = roleFunctions || [];
    departmentId = departmentId || "";
    const date =
      startDate && endDate
        ? `&startDate=${moment(startDate)?.format("YYYY-MM-DD")}&endDate=${moment(endDate)?.format("YYYY-MM-DD")}`
        : "";
    const basicUrl = `api/lead-monitorings?groupBy=SALESANDCS${date}`;
    let response = null;
    if (roleFunctions.includes(ALL_DEPT)) {
      response = await axiosPrivate.get(basicUrl + `&departmentId=${departmentId}`); //deptId
    } else if (roleFunctions.includes(OWN) && roleFunctions.includes(OWN_DEPT)) {
      response = await axiosPrivate.get(basicUrl + `&departmentId=${departmentId}&userId=${userId}`); //deptId
    } else if (roleFunctions.includes(OWN_DEPT)) {
      response = await axiosPrivate.get(basicUrl + `&departmentId=${departmentId}`); //deptId
    } else if (roleFunctions.includes(OWN)) {
      response = await axiosPrivate.get(basicUrl + `&departmentId=${departmentId}&userId=${userId}`); //deptId
    }
    // const secResponse = await axiosPrivate.get(basicUrl);

    // add more status for filter
    const dataForCard = addMoreStatus(response.data);
    const dataForFilter = addMoreStatus(response.data);

    let newData = { cardData: dataForCard, filterData: dataForFilter };
    if (filter.length > 0) {
      newData = filterLead(filter, dataForCard, dataForFilter);
    }
    //console.log("newData", filter)
    return newData;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

const addMoreStatus = (response) => {
  for (let i = 0; i < response.length; i++) {
    const chats = response[i].chats;
    for (let j = 0; j < chats.length; j++) {
      chats[j].leadStatus = chats[j].unreadCount === 0 ? chats[j].status : "UNOPENED";
    }
  }

  return response;
};

function filterLead(selectedFilterOptions, dataLeads, filterData) {
  if (selectedFilterOptions.length > 0) {
    let newDataFilter = [];
    let filterForData = [...selectedFilterOptions];
    let dataForFilter = [];
    filterForData.pop();
    dataForFilter = getDataForFilter(filterData, filterForData);
    newDataFilter = getDataForFilter(dataLeads, selectedFilterOptions);

    return { cardData: newDataFilter, filterData: dataForFilter };
  }
}

function getDataForFilter(dataLeads, selectedFilterOptions) {
  let newDefaultData = [...dataLeads];
  let newDataFilter = [];
  let chatsData = [];
  let chatsDataFiltered = [];

  const findSales = selectedFilterOptions.find((opt) => opt.key === "sales");
  if (findSales?.opt?.length > 0) {
    newDataFilter = newDefaultData.filter((lead) => {
      return findSales.opt.some((sales) => {
        const salesName = sales.split(" ");
        return lead.firstName === salesName[0] && lead.lastName === salesName[1];
      });
    });
  } else {
    newDataFilter = [...dataLeads];
  }

  newDataFilter.map((filter) => {
    const getChatData = filter.chats;
    getChatData.map((chat) => {
      chat.sales = filter.firstName + " " + filter.lastName;
    });
    chatsData = chatsData.concat(getChatData);
  });

  if (chatsData.length > 0) {
    chatsDataFiltered = chatsData.filter((chat) => {
      return selectedFilterOptions.some((filter) => {
        if (filter.param !== "roots") {
          return filter.opt.some((op) => {
            if (filter.param === "chats") return op === chat[filter.key];
            return op === chat.contact[filter.key];
          });
        }
      });
    });
  }

  if (chatsDataFiltered.length > 0) {
    newDataFilter.map((filter) => {
      const getChatBySales = chatsDataFiltered.filter(
        (chat) => chat.sales === filter.firstName + " " + filter.lastName
      );
      filter.chats = getChatBySales;
    });
  }

  return newDataFilter;
}

export const servicePostLeads = async (token, data) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.post("api/campaigns", data);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const servicePutLeads = async (id, token, data) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.put("api/campaigns/" + id, data);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceDeleteLeads = async (id, token) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.delete("api/campaigns/" + id);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};
