import { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Nav,
  NavItem,
  NavLink,
  Button,
  Input,
  Label,
  Card,
} from "reactstrap";
import { Icon } from "components/Component";
import DatePicker from "react-datepicker";
import { DefaultKeyLeadsFilter, DefaultLeadFilters } from "data/DefaultLeadFilters";
import { getDepartments } from "services/departments";
import { serviceGetProfile } from "services/settings";

const KanbanFilter = ({
  setKanbanFilter,
  departmentsFilter,
  setDepartmentsFilter,
  cookies,
  dataLeads,
  selectedFilterOptions,
  setSelectedFilterOptions,
  changeOption,
  setChangeOption,
  selectedSort,
  setSelectedSort,
  handleDate,
  startDate,
  endDate,
}) => {
  const initialFilterList = DefaultLeadFilters;
  // const keyFilterList = localStorage.getItem("leadFilters") || DefaultKeyLeadsFilter;
  const keyFilterList = DefaultKeyLeadsFilter;
  const [activeTab, setActiveTab] = useState(cookies.departmentIds);
  const [filterList, setFilterList] = useState(initialFilterList);
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false);
  const [isFilterItemDropdownOpen, setIsFilterItemDropdownOpen] = useState(false);
  const [dataProfile, setDataProfile] = useState([]);

  const [sortList, setSortList] = useState(initialFilterList);
  const [isSortDropdownOpen, setIsSortDropdownOpen] = useState(false);
  const [isMoreDropdownOpen, setIsMoreDropdownOpen] = useState(false);
  const [groupList, setGroupList] = useState(initialFilterList);
  const [isGroupDropdownOpen, setIsGroupDropdownOpen] = useState(false);
  const [propertyList, setPropertyList] = useState(initialFilterList);
  const [keyPropertyList, setKeyPropertyList] = useState(keyFilterList);
  const [isPropertyDropdownOpen, setIsPropertyDropdownOpen] = useState(false);
  const [Departments, setDepartments] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  const [keepItemOpen, setKeepItemOpen] = useState(false);
  const [checkboxStatus, setCheckboxStatus] = useState({});

  // const [startDate, endDate] = dateRange;
  let roleFunctions = JSON.parse(localStorage.getItem("permission"))["lead-monitorings"].access || [];

  
  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  if(departmentsFilter){
    try {
      toggleTab(departmentsFilter);
    } catch (error) {
      // console.log('No Initials Dept')
    }
  }
  
  const handleCLickedProperty = (item) => {
    let pl = { ...keyPropertyList };
    pl[item.text] = !pl[item.text];
    setKeyPropertyList(pl);
    setKanbanFilter(pl);
    localStorage.setItem("leadsFilter", pl);
  };

  async function getProfile() {
    try {
      const responseProfile = await serviceGetProfile(cookies.SIDikey);
      const resultData = responseProfile.data.departments;
      console.log("Profile Data:", resultData); // Log untuk memastikan data yang diterima

      const finalData = resultData.map((department) => ({
        id: department.id,
        color: department.color,
        name: department.name,
      }));

      setDataProfile(finalData);
    } catch (error) {
      console.error("Error fetching profile:", error);
    }
  }

  // Get departments list
  async function departmentsList() {
    const ALL_DEPT = "Access_All_Dept";
    const OWN_DEPT = "Access_Own";

    try {
      // Mendapatkan data departments
      const getDepartmentsData = await getDepartments(
        cookies.SIDikey,
        "",
        cookies.departmentIds,
        cookies.id,
        roleFunctions
      );

      if (roleFunctions.includes(ALL_DEPT)) {
        setDepartments(getDepartmentsData.data.data);
      } else if (cookies.departmentIds && cookies.departmentIds.length > 0) {
        const findDepartment = getDepartmentsData.data.data.filter((item) => cookies.departmentIds.includes(item.id));
        setDepartments(findDepartment);
      } else {
        setDepartments([]);
      }
    } catch (error) {
      console.error("Error fetching departments:", error);
      setDepartments([]);
    }
  }

  // get filter data
  function getFilterOptionsData() {
    const OWN = "Access_Own";
    for (let i = 0; i < filterList.length; i++) {
      if (filterList[i].type === "checkbox") {
        const paramKey = filterList[i].text;
        const keyOptions = filterList[i].keyOptions;
        const paramValue = filterList[i].value;
        if (paramKey === "Sales" && dataLeads.length > 0) {
          let opt = dataLeads.map((lead) => lead.firstName + " " + lead.lastName);
          if (roleFunctions.includes(OWN)) {
            const temp = dataLeads.find((dl) => dl.id === cookies.id);
            opt = [temp.firstName + " " + temp.lastName];
          }
          if (Array.isArray(filterList[i].options)) {
            filterList[i].options = [...new Set([...filterList[i].options, ...opt])];
          } else {
            filterList[i].options = opt;
          }
        } else {
          let optionsData = [];
          for (let j = 0; j < dataLeads.length; j++) {
            const chatData = dataLeads[j].chats;
            for (let k = 0; k < chatData.length; k++) {
              const dataParam = keyOptions === "chats" ? chatData[k] : chatData[k].contact;
              if (!optionsData.includes(dataParam[paramValue]) && dataParam[paramValue]) {
                optionsData.push(dataParam[paramValue]);
              }
            }
          }
          if (Array.isArray(filterList[i].options)) {
            filterList[i].options = [...new Set([...filterList[i].options, ...optionsData])];
          } else {
            filterList[i].options = optionsData;
          }
        }
      }
    }
  }

  useEffect(() => {
    // Pastikan getProfile dipanggil sebelum departmentsList
    getProfile();
  }, []);

  useEffect(() => {
    if (dataProfile.length > 0) {
      departmentsList();
    }
  }, [dataProfile]);

  useEffect(() => {
    if (dataLeads) {
      getFilterOptionsData();
    }
  }, [dataLeads]);

  const handlefilterClick = (filterdata) => {
    const getActiveFilter = selectedFilter.findIndex((i) => i.text === filterdata.text);
    if (getActiveFilter >= 0) {
      selectedFilter.splice(getActiveFilter, 1);
    } else {
      const newSelectedData = [...selectedFilter, filterdata];
      setSelectedFilter(newSelectedData);
    }
  };
  const handleDeleteFilter = () => {
    setSelectedFilter([]);
  };

  const handleSort = (item) => {
    if (selectedSort.key === item.keyOptions && selectedSort.val === item.value) {
      setSelectedSort({});
    } else {
      setSelectedSort({ key: item.keyOptions, val: item.value });
    }
  };
  const handleDeleteSort = () => {
    console.log("sort delete", selectedSort);
    setSelectedSort([]);
  };

  function handleFilterCheckBox(item, e, i, opt) {
    const { value, checked } = e.target;
    setCheckboxStatus((prevStatus) => ({ ...prevStatus, [value]: checked }));

    if (checked) {
      const getCurrentFilter = selectedFilterOptions.findIndex((filter) => filter.key === item.value);
      if (getCurrentFilter >= 0) {
        const newFilterValue = [...selectedFilterOptions[getCurrentFilter].opt, value];
        selectedFilterOptions[getCurrentFilter].opt = newFilterValue;
      } else {
        const newSelectedFilter = {
          param: item.keyOptions,
          key: item.value,
          opt: [value],
        };
        const setSelectedFilter = [...selectedFilterOptions, newSelectedFilter];
        setSelectedFilterOptions(setSelectedFilter);
      }
    } else {
      const getCurrentFilter = selectedFilterOptions.findIndex((filter) => filter.key === item.value);
      const newSelectedFilter = selectedFilterOptions[getCurrentFilter].opt.filter((e) => e !== value);
      selectedFilterOptions[getCurrentFilter].opt = newSelectedFilter;
      if (newSelectedFilter.length === 0) {
        selectedFilterOptions.splice(getCurrentFilter, 1);
      } else {
        selectedFilterOptions[getCurrentFilter].opt = newSelectedFilter;
      }

      //setSelectedFilterOptions(newSelectedFilter);
    }
    setChangeOption(changeOption + 1);
  }

  function handleFilterInput(item, opt) {
    const getCurrentFilter = selectedFilterOptions.findIndex((filter) => filter.key === item.value);
    if (getCurrentFilter >= 0) {
      selectedFilterOptions[getCurrentFilter].opt = opt;
    } else {
      const newSelectedFilter = {
        param: item.keyOptions,
        key: item.value,
        opt: [opt],
      };
      const setSelectedFilter = [...selectedFilterOptions, newSelectedFilter];
      setSelectedFilterOptions(setSelectedFilter);
    }
    setChangeOption(changeOption + 1);
  }

  const handleReset = () => {
    selectedFilterOptions.forEach((filter) => {
      filter.opt = [];
    });
    setChangeOption(changeOption + 1);

    setCheckboxStatus({});
  };

  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
      <div>
        <Nav tabs>
          {Departments.map((item, index) => {
            return (
              <NavItem key={index}>
                <NavLink
                  style={{ color: activeTab === item.id ? "#4461F2" : "", cursor: "pointer" }}
                  onClick={() => {
                    toggleTab(item.id);
                    setDepartmentsFilter(item.id);
                  }}
                >
                  [{item.name}]
                </NavLink>
              </NavItem>
            );
          })}
        </Nav>
        <div
          className="data-filter-leads"
          style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}
        >
          {selectedFilter.map((item, index) => {
            return (
              <div className="filter-item">
                <Dropdown
                  isOpen={isFilterItemDropdownOpen === item.text}
                  toggle={() => {
                    if (isFilterItemDropdownOpen !== item.text) {
                      setIsFilterItemDropdownOpen(item.text);
                      setKeepItemOpen(true);
                    } else {
                      setIsFilterItemDropdownOpen(null);
                      setKeepItemOpen(false);
                    }
                  }}
                >
                  <DropdownToggle caret outline>
                    {item.text}
                  </DropdownToggle>
                  <Card className="filter-item-wrap">
                    {item.type === "checkbox" ? (
                      <div>
                        <div
                          className="sub-text d-flex justify-content-between align-items-center"
                          style={{ width: 140 }}
                        >
                          Sort Options
                          <Button
                            size="xs"
                            href="#"
                            onClick={() => {
                              setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC");
                              setKeepItemOpen(true);
                            }}
                          >
                            {sortOrder === "ASC" ? "ASC" : "DESC"}
                          </Button>
                        </div>
                        {item.options &&
                          [...item.options]
                            .sort((a, b) => (sortOrder === "ASC" ? a.localeCompare(b) : b.localeCompare(a)))
                            .map((opt, i) => {
                              return (
                                <div className="filter-opt">
                                  <Input
                                    type="checkbox"
                                    id={opt + "" + i}
                                    value={opt}
                                    checked={checkboxStatus[opt] || false}
                                    onChange={(e) => {
                                      handleFilterCheckBox(item, e, i, item.options);
                                    }}
                                  />
                                  <Label check for={opt + "" + i}>
                                    {opt}
                                  </Label>
                                </div>
                              );
                            })}
                        <a href="#" onClick={handleReset}>
                          Reset
                        </a>
                      </div>
                    ) : (
                      <FormGroup>
                        <h4>{item.text}</h4>
                        <Input
                          name="filterdata"
                          placeholder={"write " + item.text}
                          type="text"
                          className="input-filter"
                          onInput={(e) => handleFilterInput(item, e.target.value)}
                        />
                      </FormGroup>
                    )}
                  </Card>
                </Dropdown>
              </div>
            );
          })}
        </div>
      </div>
      <div className="d-flex align-items-end mb-2 justify-content-end">
        <Dropdown
          isOpen={isFilterDropdownOpen}
          toggle={() => setIsFilterDropdownOpen((prevState) => !prevState)}
          className="mr-2"
        >
          <DropdownToggle caret outline>
            Filter
          </DropdownToggle>
          <DropdownMenu right>
            {filterList.map((item, index) => (
              <DropdownItem key={index} className="d-flex align-items-center" onClick={() => handlefilterClick(item)}>
                <Icon name={item.icon} className="mr-2"></Icon>
                {item.text}
                {selectedFilter.findIndex((i) => i.text === item.text) >= 0 && (
                  <Icon name="check" className="ml-auto"></Icon>
                )}
              </DropdownItem>
            ))}
            <DropdownItem divider />
            <DropdownItem className="d-flex align-items-center" onClick={handleDeleteFilter}>
              <Icon name="trash" className="mr-2"></Icon>
              Delete
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <Dropdown
          isOpen={isSortDropdownOpen}
          toggle={() => setIsSortDropdownOpen((prevState) => !prevState)}
          className="mr-2"
        >
          <DropdownToggle caret outline>
            Sort
          </DropdownToggle>
          <DropdownMenu right>
            {sortList.map((item, index) => (
              <DropdownItem key={index} className="d-flex align-items-center" onClick={() => handleSort(item)}>
                <Icon name={item.icon} className="mr-2"></Icon>
                {item.text}
                {item.value === selectedSort.val && <Icon name="check" className="ml-auto"></Icon>}
              </DropdownItem>
            ))}
            <DropdownItem divider />
            <DropdownItem className="d-flex align-items-center" onClick={handleDeleteSort}>
              <Icon name="trash" className="mr-2"></Icon>
              Delete
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <FormGroup className="mb-0 w-100">
          <div className="form-control-wrap mr-2">
            <DatePicker
              className="form-control date-picker"
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={handleDate}
              dateFormat={"yyyy-MM-dd"}
              isClearable={true}
            />
          </div>
        </FormGroup>

        <Dropdown isOpen={isMoreDropdownOpen} toggle={() => setIsMoreDropdownOpen((prevState) => !prevState)}>
          <DropdownToggle color="default" className="pl-1 pr-1">
            <Icon name="more-v" style={{ cursor: "pointer", fontSize: 16 }}></Icon>
          </DropdownToggle>
          <DropdownMenu right className="p-3">
            <p style={{ fontWeight: 600 }}>Options</p>
            <div
              className="d-flex justify-content-between align-items-center mb-1"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setIsPropertyDropdownOpen(true);
                setIsMoreDropdownOpen(false);
              }}
            >
              Properties
              <Icon name="forward-alt-fill"></Icon>
            </div>
          </DropdownMenu>
        </Dropdown>
        <Dropdown
          isOpen={isGroupDropdownOpen}
          toggle={() => setIsGroupDropdownOpen((prevState) => !prevState)}
          className="mr-2"
        >
          <DropdownToggle style={{ width: 0, height: 0, padding: 0, border: "none", margin: 0 }}></DropdownToggle>
          <DropdownMenu right>
            <p
              style={{ fontWeight: 600, marginBottom: 0, padding: 12, cursor: "pointer" }}
              onClick={() => {
                setIsGroupDropdownOpen(false);
                setIsMoreDropdownOpen(true);
              }}
            >
              <Icon name="back-alt-fill" className="mr-1"></Icon>
              Group By
            </p>
            {groupList.map((item, index) => (
              <DropdownItem key={index} className="d-flex align-items-center">
                <Icon name={item.icon} className="mr-2"></Icon>
                {item.text}
                <Icon name={item.isSelected ? "eye" : "eye-off"} className="ml-auto"></Icon>
              </DropdownItem>
            ))}
            <DropdownItem divider />
            <DropdownItem className="d-flex align-items-center">
              <Icon name="trash" className="mr-2"></Icon>
              Delete
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <Dropdown
          onMouseLeave={() => setIsPropertyDropdownOpen(false)}
          isOpen={isPropertyDropdownOpen}
          className="mr-2"
        >
          <DropdownToggle style={{ width: 0, height: 0, padding: 0, border: "none", margin: 0 }}></DropdownToggle>
          <DropdownMenu right>
            <p
              style={{ fontWeight: 600, marginBottom: 0, padding: 12, cursor: "pointer" }}
              onClick={() => {
                setIsPropertyDropdownOpen(false);
                setIsMoreDropdownOpen(true);
              }}
            >
              <Icon name="back-alt-fill" className="mr-1"></Icon>
              Properties
            </p>
            {propertyList.map((item, index) => (
              <DropdownItem
                key={index}
                className="d-flex align-items-center"
                onClick={(event) => {
                  handleCLickedProperty(item);
                }}
              >
                <Icon name={item.icon} className="mr-2"></Icon>
                {item.text}
                <Icon name={keyPropertyList[item.text] ? "eye" : "eye-off"} className="ml-auto"></Icon>
              </DropdownItem>
            ))}
            <DropdownItem divider />
            <DropdownItem className="d-flex align-items-center">
              <Icon name="trash" className="mr-2"></Icon>
              Delete
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <Icon name="search" className="ml-1 mb-2" style={{ cursor: "pointer", fontSize: 16 }}></Icon>
      </div>
    </div>
  );
};

export default KanbanFilter;
