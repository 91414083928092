import { FormGroup, CustomInput, Modal, ModalBody, Progress, Badge } from "reactstrap";
import { Icon, Col, Button, RSelect } from "../../components/Component";
import { useCallback, useEffect, useState } from "react";
import Papa from "papaparse";
import { serviceGetContactAstro } from "../../services/productAstro";

const ContactSync = (props) => {
  const {
    modalSync,
    setModalSync,
    servicePutContact,
    serviceGetContactByPhone,
    token,
    refreshContacts,
    selectedSyncList,
    cookies
  } = props;
  const [progress, setProgress] = useState(0);
  const [progressMessage, setProgressMessage] = useState("Jangan tutup halaman ini selama upload berjalan!")
  const [errorUpload, setErrorUpload] = useState([]);
  const [uploadButton, setUploadButton] = useState(true);
  const [downloadLoading, setDownloadLoading] = useState(false);

  const handleUpload = useCallback(async () => {
    setUploadButton(true);
    if (selectedSyncList.length > 0) {
      for (let i = 0; i < selectedSyncList.length; i++) {
        const resGetContactAstro = await serviceGetContactAstro(cookies.AstroKey, 6289688965164);

        if (resGetContactAstro.status !== 200 || resGetContactAstro.data.status === "error") {
          const newErrorUpload = errorUpload;
          newErrorUpload.push({ name:selectedSyncList[i].name, phone: selectedSyncList[i].phone, error: "data not found" });
          setErrorUpload(newErrorUpload);
        } else {
          const resdata = resGetContactAstro.data.data
          const syncData = {
            clientAstro: "Yes",
            clientType: resdata.aclubClientType === "Not Aclub Client" ? "No" : resdata.aclubClientType,
            clientCat: resdata.isCatClient,
            clientAclub: resdata.isClientAclub,
            clientAshop: resdata.isClientAshop,
            clientOrbi: resdata.isMemberOrbi,
            clientSucor: resdata.isSucorClient,
            clientUob: resdata.isUobClient,
            membership: resdata.membership,
            salesAclub: resdata.salesAclub,
            salesCat: resdata.salesCat,
            salesOrbi: resdata.salesOrbi,
            salesSucor: resdata.sucorSales,
            salesUob: resdata.uobSales,
            productAshop: resdata.productAshop
          }

          const resSaveContact = await servicePutContact(token, selectedSyncList[i].id, syncData);
          if (resSaveContact.status !== 202) {
            const newErrorUpload = errorUpload;
            newErrorUpload.push({ name:selectedSyncList[i].name, phone: selectedSyncList[i].phone, error: "failed update data" });
            setErrorUpload(newErrorUpload);
          }
        }
        const progressPercent = ((i + 1) / selectedSyncList.length) * 100;
        setProgress(progressPercent);
      }
      refreshContacts(1);
      setUploadButton(false);
      setProgressMessage("Sync data selesai!")
    } 
  }, [selectedSyncList]);

  const handleDownloadError = useCallback(() => {
    setDownloadLoading(true);
    const csv = Papa.unparse(errorUpload);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `contact_page.csv`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setDownloadLoading(false);
  }, [errorUpload]);

  return (
    <Modal
      isOpen={modalSync}
      backdrop="static"
      keyboard={false}
      scrollable={true}
      className="modal-dialog-centered"
      size="lg"
    >
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            setModalSync(false);
            setProgress(0);
            setErrorUpload([]);
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title mb-4">Sync Contact</h5>
          <div className="mt-4 row gy-2">
            <Col md="4" lg="4" >
              <Button
                type="submit"
                color="primary"
                className="btn btn-block"
                onClick={handleUpload}
                disabled={selectedSyncList.length > 0 ? false : true}
              >
                Sync {selectedSyncList.length} Contact
              </Button>
            </Col>
            <Col md="12" lg="12">
              <ul className="contact_list_sync">
                {selectedSyncList.length > 0 && 
                selectedSyncList.map((item,i) => <li key={i+1}>{item.name}</li>)}
              </ul>
            </Col>
          </div>
          <hr/>
          {progress > 0 && 
          <>
            <p>{progressMessage}</p>
            <Progress value={progress} />
          </>
          }
        </div>
        <div className="errorReport">
        <Col md="12" lg="12">
          {errorUpload.length > 0 &&
            errorUpload.map((item, i) => (
              <p key={i + 1}>
                {item.name} - {item.phone}, Error: {item.error}
              </p>
            ))}
            <hr/>
          </Col>
          {errorUpload.length > 0 && (
            <Col md="4" lg="4">
              <Button
                type="submit"
                color="primary"
                className="btn btn-block"
                onClick={handleDownloadError}
                disabled={uploadButton}
              >
                <Icon name={downloadLoading ? "loader" : "download-cloud"}></Icon>
                <span>Download</span>
              </Button>
            </Col>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ContactSync;
