import { Icon, UserAvatar } from "components/Component";
import React, { useRef, useState, useContext } from "react";
import { Modal, Row, Col } from "reactstrap";
import { ReplyContext } from "contexts/replyContext";
import { MessagesContext } from "contexts/messagesContext";
import { useEffect } from "react";
import { serviceDownloadFileMessage } from "services/messages";

export const ImageChat = ({
  filename,
  caption,
  src,
  youChat,
  contextId,
  context,
  status,
  timestamp,
  itemId,
  cookies,
}) => {
  const [showImage, setShowImage] = useState(false);
  const [blobImage, setBlobImage] = useState({});
  const { messagesValue, statusValue } = useContext(MessagesContext);
  const [newStatusMessages, setNewStatusMessages] = statusValue;
  const [showMore, setShowMore] = useState(false);
  const limitText = 300;
  const formatedMessage = formatMessage(caption);
  const formatedMessageLimit = formatMessage(caption?.substring(0, limitText));

  const generateFile = async (src) => {
    const newLink = src.replace(process.env.REACT_APP_S3_URL_MEDIA, process.env.REACT_APP_S3_URL_DOWNLOAD);
    // console.log(newL)

    fetch(newLink)
      .then((response) => response.blob())
      .then((blob) => {
        let blobUrl = window.URL.createObjectURL(blob);
        setBlobImage(blobUrl);
      });
  };

  useEffect(() => {
    if (showImage) {
      generateFile(src);
    }
  }, [showImage]);
  const downloadFile = async (id) => {
    const resp = await serviceDownloadFileMessage(cookies, id);
    if (resp.status === 200) {
      const data = resp.data;
      const blob = new Blob([data]);
      const downloadLink = document.createElement("a");
      const url = URL.createObjectURL(blob);
      downloadLink.setAttribute("href", url);
      downloadLink.setAttribute("download", filename !== null ? filename : `${caption}.png`);
      downloadLink.style.visibility = "hidden";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } else {
      console.log("error",resp);
    }
  };
  return (
    <React.Fragment>
      <div className="chat-msg" style={{ maxWidth: 300, minWidth: 200, fontSize: 15 }}>
        {contextId != null && context != null ? <ReplySection youChat={youChat} context={context} /> : null}
        <img onClick={() => setShowImage(true)} className="image-chat d-block border" src={src} alt="gambar" />

        {caption?.length >= limitText ? (
          <div style={{ whiteSpace: "pre-line" }}>
            {showMore ? (
              <span dangerouslySetInnerHTML={formatedMessage}></span>
            ) : (
              <span dangerouslySetInnerHTML={formatedMessageLimit}></span>
            )}
            {showMore ? (
              <span className="ml-2 read-more" href="#" onClick={() => setShowMore(false)}>
                {"\nshow less.."}
              </span>
            ) : (
              <span className="ml-2 read-more" href="#" onClick={() => setShowMore(true)}>
                {"read more.."}
              </span>
            )}
            <StatusTime status={status} timestamp={timestamp} />
          </div>
        ) : (
          <div className="" style={{ whiteSpace: "pre-line" }}>
            {/* <div>{filename}</div> */}
            <span dangerouslySetInnerHTML={formatMessage(caption)} />
            <StatusTime status={status} timestamp={timestamp} />
          </div>
        )}

        <Modal isOpen={showImage} toggle={() => setShowImage(false)} size="large">
          <div className="button-bar">
            <div
              className="d-flex flex-row justify-content-end p-1"
              style={{ position: "relative", width: "100vw", background: "rgba(0, 0, 0, 0.44)" }}
            >
              <div className="mr-3" rel="noreferrer" onClick={() => downloadFile(itemId)}>
                <Icon className="icon-image-download" name="download" />
              </div>
              <a onClick={() => setShowImage(false)} className="mr-2">
                <Icon name="cross" className="icon-image-download" />
              </a>
            </div>
          </div>
          <img className="rounded" src={src} style={{ height: "100%" }} alt="Profile Pics" />
        </Modal>
      </div>
    </React.Fragment>
  );
};

export const VideoChat = ({ 
  filename, 
  caption, 
  src, 
  youChat, 
  contextId, 
  context, 
  status, 
  timestamp, 
  itemId, 
  cookies,
}) => {
  const [showVideo, setShowVideo] = useState(false);
  const [blobVideo, setBlobVideo] = useState({});
  const { messagesValue, statusValue } = useContext(MessagesContext);
  const [newStatusMessages, setNewStatusMessages] = statusValue;
  const [showMore, setShowMore] = useState(false);
  const limitText = 300;
  const formatedMessage = formatMessage(caption);
  const formatedMessageLimit = formatMessage(caption?.substring(0, limitText));

  const generateFile = async (src) => {
    try {
      const newLink = src.replace(process.env.REACT_APP_S3_URL_MEDIA, process.env.REACT_APP_S3_URL_DOWNLOAD);
      const response = await fetch(newLink);
      if (!response.ok) {
        throw new Error("Failed to fetch video");
      }
      const blob = await response.blob();
      let blobUrl = window.URL.createObjectURL(blob);
      setBlobVideo(blobUrl);
    } catch (error) {
      console.error("Error fetching video:", error);
    }
  };
  
  useEffect(() => {
    if (showVideo) {
      generateFile(src);
    }
  }, [showVideo]);

  const downloadFile = async (id) => {
  try {
    const resp = await serviceDownloadFileMessage(cookies, id);
    if (resp.status === 200) {
      const data = resp.data;
      const blob = new Blob([data]);
      const downloadLink = document.createElement("a");
      const url = URL.createObjectURL(blob);
      downloadLink.setAttribute("href", url);
      downloadLink.setAttribute("download", filename !== null ? filename : `${caption}.mp4`);
      downloadLink.style.visibility = "hidden";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } else {
      throw new Error("Failed to download file");
    }
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};

  return (
    <React.Fragment>
      <div className="chat-msg" style={{ maxWidth: 300, minWidth: 200, fontSize: 15 }}>
        {contextId != null && context != null ? <ReplySection youChat={youChat} context={context} /> : null}
        <div className="d-flex justify-content-center pt-1 position-relative">
          <Icon className="play-button" name="play-circle-fill" onClick={() => setShowVideo(true)} />
          <video preload="auto" style={{ maxHeight: "150px" }}>
            <source src={src} />
          </video>
        </div>
        {caption?.length >= limitText ? (
          <div style={{ whiteSpace: "pre-line" }}>
            {showMore ? (
              <span dangerouslySetInnerHTML={formatedMessage}></span>
            ) : (
              <span dangerouslySetInnerHTML={formatedMessageLimit}></span>
            )}
            {showMore ? (
              <span className="ml-2 read-more" href="#" onClick={() => setShowMore(false)}>
                {"\nshow less.."}
              </span>
            ) : (
              <span className="ml-2 read-more" href="#" onClick={() => setShowMore(true)}>
                {"read more.."}
              </span>
            )}
            <StatusTime status={status} timestamp={timestamp} />
          </div>
        ) : (
          <div className="" style={{ whiteSpace: "pre-line" }}>
            {/* <div>{filename}</div> */}
            <span dangerouslySetInnerHTML={formatMessage(caption)} />
            <StatusTime status={status} timestamp={timestamp} />
          </div>
        )}

        <Modal isOpen={showVideo} toggle={() => setShowVideo(false)} size="large">
          <div className="button-bar">
            <div
              className="d-flex flex-row justify-content-end p-1"
              style={{ position: "relative", width: "100vw", background: "rgba(0, 0, 0, 0.44)" }}
            >
              {/* <div className="mr-3" rel="noreferrer" onClick={() => downloadFile(itemId)}>
                  <Icon className="icon-image-download" name="download" />
              </div> */}
              <a onClick={() => setShowVideo(false)} className="mr-2">
                <Icon name="cross" className="icon-image-download" />
              </a>
            </div>
          </div>
          <video preload="auto" controls style={{ maxHeight: "90vh" }}>
            <source src={src} />
          </video>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export const StickerChat = ({ src, youChat, contextId, context, status, timestamp }) => {
  return (
    <React.Fragment>
      {contextId != null && context != null ? <ReplySection youChat={youChat} context={context} /> : null}
      <img className="d-block" src={src} alt="gambar" />
      <div
        className="timestamp mt-1"
        style={{ fontSize: 13, marginTop: "-4px", marginBottom: "-7px", textAlign: "right" }}
      >
        <i
          className={`bi ${
            status === "READ"
              ? "bi-check2-all text-info"
              : status === "DELIVERED"
              ? "bi-check2-all"
              : status === "SENT"
              ? "bi-check2"
              : status === "FAILED"
              ? "bi-x-circle text-danger"
              : ""
          }`}
        ></i>
        <span> </span>
        {timestamp}
      </div>
    </React.Fragment>
  );
};

export const FileChat = ({
  id,
  cookies,
  caption,
  link,
  youChat,
  contextId,
  context,
  contentSize,
  timestamp,
  status,
  text,
}) => {
  console.log(text)
  const downloadFile = async (id, caption) => {
    const resp = await serviceDownloadFileMessage(cookies, id);
    if (resp.status === 200) {
      const data = resp.data;
      const blob = new Blob([data]);
      const downloadLink = document.createElement("a");
      const url = URL.createObjectURL(blob);
      downloadLink.setAttribute("href", url);
      downloadLink.setAttribute("download", caption);
      downloadLink.style.visibility = "hidden";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } else {
      console.log(resp);
    }
  };
  return (
    <React.Fragment>
      {contextId != null && context != null ? <ReplySection youChat={youChat} context={context} /> : null}
      <div
        style={{ cursor: "pointer" }}
        onClick={() => downloadFile(id, caption)}
        className={youChat ? "top-content-you" : "top-content"}
        target="_blank"
        rel="noreferrer"
      >
        <Icon name="file-fill" className="file-icon" />
        <span>
          <div>{caption}</div>
          <div>{contentSize}</div>
        </span>
      </div>
      <span></span>
      <div
        className="timestamp mt-1"
        style={{
          fontSize: 13,
          marginTop: "-4px",
          marginBottom: "-7px",
          textAlign: "right",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <span>{text === caption ? "" : text}</span>

        <div style={{ display: "flex" }}>
          {" "}
          <i
            className={`bi ${
              status === "READ"
                ? "bi-check2-all text-info"
                : status === "DELIVERED"
                ? "bi-check2-all"
                : status === "SENT"
                ? "bi-check2"
                : status === "FAILED"
                ? "bi-x-circle text-danger"
                : ""
            }`}
          ></i>
          <span> </span>
          <p>{timestamp}</p>
        </div>
      </div>
    </React.Fragment>
  );
};

export const LocationChat = ({ name, address, link, youChat, contextId, context }) => {
  return (
    <React.Fragment>
      {contextId != null && context != null ? <ReplySection youChat={youChat} context={context} /> : null}
      <a href={link} className={youChat ? "top-content-you" : "top-content"} target="_blank" rel="noreferrer">
        <Icon name="map-pin-fill" className="file-icon" />
        <span>
          <h6>{name}</h6>
          <p>{address}</p>
        </span>
      </a>
      <span></span>
    </React.Fragment>
  );
};

export const ButtonChat = () => {
  return (
    <div className="d-flex justify-content-end">
      {/* Button 1 */}
      {/* <Row className="gx-1" style={{ width: 260 }}>
            <Col>
              <div className="button-chat">Button 1</div>
            </Col>
          </Row> */}

      {/* Button 2 */}

      <Row className="gx-1" style={{ width: 260 }}>
        <Col md="6" lg="6">
          <div className="button-chat">Button 1</div>
        </Col>
        <Col md="6" lg="6">
          <div className="button-chat">Button 2</div>
        </Col>
      </Row>

      {/* Button 3 */}
      {/* <Row className="px-2 gy-1 mx-0">
          <Col md="12" lg="12">
            <div className="button-chat">Button 1</div>
          </Col>
          <Col md="12" lg="12">
            <div className="button-chat">Button 2</div>
          </Col>
          <Col md="12" lg="12">
            <div className="button-chat">
              Button 3
            </div>
          </Col>
        </Row> */}
    </div>
  );
};

export const VoiceChat = ({ link, youChat, contextId, context }) => {
  const [isPlay, setIsPlay] = useState(false);
  const [audioLength, setAudioLength] = useState("0:00");
  const [currentTime, setCurrentTime] = useState("0:00");
  const [progressBarWidth, setProgressBarWidth] = useState("0%");
  const [checkProgress, setCheckProgress] = useState(null);

  const [audio] = useState(new Audio(link));
  const timelineRef = useRef(null);

  audio.addEventListener("loadeddata", () => {
    setAudioLength(getTimeCodeFromNum(audio.duration));
  });

  const getTimeCodeFromNum = (num) => {
    let seconds = parseInt(num);
    let minutes = parseInt(seconds / 60);
    seconds -= minutes * 60;
    const hours = parseInt(minutes / 60);
    minutes -= hours * 60;

    if (hours === 0) return `${minutes}:${String(seconds % 60).padStart(2, 0)}`;
    return `${String(hours).padStart(2, 0)}:${minutes}:${String(seconds % 60).padStart(2, 0)}`;
  };

  const togglePlay = () => {
    if (!isPlay) {
      audio.play();
      const checkID = setInterval(() => {
        if (!audio.ended) {
          setProgressBarWidth((audio.currentTime / audio.duration) * 100 + "%");
          setCurrentTime(getTimeCodeFromNum(audio.currentTime));
        } else {
          setProgressBarWidth("0%");
          setCurrentTime("0:00");
          setIsPlay(false);
          clearInterval(checkID);
          setCheckProgress(null);
        }
      }, 500);
      setCheckProgress(checkID);
    } else {
      audio.pause();
      clearInterval(checkProgress);
      setCheckProgress(null);
    }
    setIsPlay(!isPlay);
  };

  const timelineClick = (e) => {
    const timelineWidth = timelineRef.current.offsetWidth;
    const timeToSeek = (e.nativeEvent.offsetX / parseInt(timelineWidth)) * audio.duration;
    audio.currentTime = timeToSeek;
  };

  return (
    <React.Fragment>
      {contextId != null && context != null ? <ReplySection youChat={youChat} context={context} /> : null}
      <div className="d-flex align-items-center">
        <Icon
          name={isPlay ? "pause-fill" : "play-fill"}
          className={youChat ? "audio-icon-you" : "audio-icon"}
          onClick={() => togglePlay()}
        />{" "}
        <div className="pl-1 pr-2 w-100 pt-2">
          <div
            className={youChat ? "audio-timeline-you" : "audio-timeline"}
            ref={timelineRef}
            onClick={(e) => timelineClick(e)}
          >
            <div className={youChat ? "audio-progress-you" : "audio-progress"} style={{ width: progressBarWidth }} />
          </div>
          <div className={youChat ? "pt-1 text-black" : "pt-1"}>
            {currentTime} / {audioLength}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const StatusTime = ({ timestamp, status }) => {
  return (
    <div
      className="timestamp ml-2 mt-2"
      style={{
        width: "max-content",
        fontSize: 12,
        textAlign: "right",
        float: "right",
      }}
    >
      <i
        className={`bi ${
          status === "READ"
            ? "bi-check2-all text-info"
            : status === "DELIVERED"
            ? "bi-check2-all"
            : status === "SENT"
            ? "bi-check2"
            : status === "FAILED"
            ? "bi-x-circle text-danger"
            : ""
        }`}
      ></i>
      {timestamp}
    </div>
  );
};

export const TextChat = ({ contextId, context, text, status, timestamp, youChat, header }) => {
  const [showMore, setShowMore] = useState(false);
  const limitText = 300;
  const formatedMessage = formatMessage(text);
  const formatedMessageLimit = formatMessage(text?.substring(0, limitText));

  return (
    <React.Fragment>
      <div className="chat-msg" style={{ maxWidth: 300, minWidth: 200, fontSize: 15 }}>
      <span style={{fontWeight:'700'}} dangerouslySetInnerHTML={typeof header === 'string' ? formatMessage(header) : formatMessageImage(header)} />
        {contextId != null && context != null ? <ReplySection youChat={youChat} context={context} /> : null}
        {/* {context === undefined && contextId == undefined ? "test" : ""} */}
        {text?.length >= limitText ? (
          <div style={{ whiteSpace: "pre-line" }}>
            {showMore ? (
              <span dangerouslySetInnerHTML={formatedMessage}></span>
            ) : (
              <span dangerouslySetInnerHTML={formatedMessageLimit}></span>
            )}
            {showMore ? (
              <span className="ml-2 read-more" href="#" onClick={() => setShowMore(false)}>
                {"\nshow less.."}
              </span>
            ) : (
              <span className="ml-2 read-more" href="#" onClick={() => setShowMore(true)}>
                {"read more.."}
              </span>
            )}
            <StatusTime status={status} timestamp={timestamp} />
          </div>
        ) : (
          <div className="" style={{ whiteSpace: "pre-line" }}>
            <span dangerouslySetInnerHTML={formatMessage(text)} />
            <StatusTime status={status} timestamp={timestamp} />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export const InteractiveChat = ({ contextId, context, text, status, timestamp, youChat }) => {
  const [showMore, setShowMore] = useState(false);
  const limitText = 300;
  const jsonText = text && JSON.parse(text);
  return (
    <React.Fragment>
      <div className="chat-msg" style={{ maxWidth: 425, minWidth: 200, fontSize: 15 }}>
        {/* {context === "interactive" ? (
          <div className="reply-section-me">
            <div className="reply-section-bubble">
              <p className="reply-section-text">-</p>
            </div>
          </div>
        ) : (
          ""
        )} */}
        {text?.length >= limitText ? (
          <div style={{ whiteSpace: "pre-line" }}>
            {showMore ? (
              <span className="ml-2 read-more" href="#" onClick={() => setShowMore(false)}>
                {"\nshow less.."}
              </span>
            ) : (
              <span className="ml-2 read-more" href="#" onClick={() => setShowMore(true)}>
                {"read more.."}
              </span>
            )}
            <StatusTime status={status} timestamp={timestamp} />
          </div>
        ) : (
          <div className="" style={{ whiteSpace: "pre-line" }}>
            {jsonText?.button_reply && <span dangerouslySetInnerHTML={formatMessage(jsonText?.button_reply?.title)} />}
            {jsonText?.list_reply ? (
              <div>
                <p style={{ color: "#4461F2" }}>Silakan Pilih</p>
                <ul>
                  <li className="d-flex" style={{ alignItems: "center", justifyContent: "space-between" }}>
                    <div style={{ width: "100%" }}>
                      <p
                        style={{ fontWeight: 800, marginBottom: 0 }}
                        dangerouslySetInnerHTML={formatMessage(jsonText?.list_reply?.title)}
                      />

                      <p
                        style={{ fontSize: "12px", marginTop: "2px", lineHeight: "15px" }}
                        dangerouslySetInnerHTML={formatMessage(jsonText?.list_reply?.description)}
                      />
                    </div>
                    <div style={{ border: "solid 3px #526484", height: "16px", width: "16px", borderRadius: "8px" }}>
                      {" "}
                    </div>
                  </li>
                </ul>
              </div>
            ) : (
              ""
            )}

            <StatusTime status={status} timestamp={timestamp} />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export const ButtonReplyChat = ({ options, text, status, timestamp, header }) => {
  const [showMore, setShowMore] = useState(false);
  const limitText = 300;
  return (
    <React.Fragment>
      <div className="chat-msg" style={{ maxWidth: 425, minWidth: 200, fontSize: 15 }}>
      <span style={{fontWeight:'700'}} dangerouslySetInnerHTML={typeof header === 'string' ? formatMessage(header) : formatMessageImage(header)} />
        {text?.length >= limitText ? (
          <div style={{ whiteSpace: "pre-line" }}>
            {showMore ? (
              <>
                <div className="" style={{ whiteSpace: "pre-line" }}>
                  <span dangerouslySetInnerHTML={formatMessage(text)} />
                  <StatusTime status={status} timestamp={timestamp} />
                </div>
                <span className="ml-2 read-more" href="#" onClick={() => setShowMore(false)}>
                  {"\nread less.."}
                </span>
              </>
            ) : (
              <>
                <div className="" style={{ whiteSpace: "pre-line" }}>
                  <span dangerouslySetInnerHTML={formatMessage(text.slice(0, 300))} />
                  <StatusTime status={status} timestamp={timestamp} />
                </div>
                <span className="ml-2 read-more" href="#" onClick={() => setShowMore(true)}>
                  {"read more.."}
                </span>
              </>
            )}
            <StatusTime status={status} timestamp={timestamp} />
          </div>
        ) : (
          <div className="" style={{ whiteSpace: "pre-line" }}>
            <span dangerouslySetInnerHTML={formatMessage(text)} />
            <StatusTime status={status} timestamp={timestamp} />
          </div>
        )}
      </div>
      <div className="button-reply-container">
        {options.map((opt) => {
          return (
            <a className="button-reply" key={opt.id}>
              {opt.title}
            </a>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export const ListChat = ({ options, text, status, timestamp, header }) => {
  const [showMore, setShowMore] = useState(false);
  const limitText = 300;
  return (
    <React.Fragment>
      <div className="chat-msg" style={{ maxWidth: 425, minWidth: 200, fontSize: 15 }}>
      <span style={{fontWeight:'700'}} dangerouslySetInnerHTML={typeof header === 'string' ? formatMessage(header) : formatMessageImage(header)} />
        {text?.length >= limitText ? (
          <div className="" style={{ whiteSpace: "pre-line" }}>
            {showMore ? (
              <>
                <span dangerouslySetInnerHTML={formatMessage(text)} />
                <span className="ml-2 read-more" href="#" onClick={() => setShowMore(false)}>
                  {"\nread less.."}
                </span>
                <StatusTime status={status} timestamp={timestamp} />
                <div className="list-menu-chat">
                  <Icon name="icon ni ni-list" />
                  <span>Pilihan Anda</span>
                </div>
              </>
            ) : (
              <>
                <span dangerouslySetInnerHTML={formatMessage(text.slice(0, 300))} />
                <span className="ml-2 read-more" href="#" onClick={() => setShowMore(true)}>
                  {"\nread more.."}
                </span>
                <StatusTime status={status} timestamp={timestamp} />
                <div className="list-menu-chat">
                  <Icon name="icon ni ni-list" />
                  <span>Pilihan Anda</span>
                </div>
              </>
            )}
        </div>
        ) : (
          <div className="" style={{ whiteSpace: "pre-line" }}>
          <span dangerouslySetInnerHTML={formatMessage(text)} />
          <StatusTime status={status} timestamp={timestamp} />
          <div className="list-menu-chat">
            <Icon name="icon ni ni-list" />
            <span>Pilihan Anda</span>
          </div>
        </div>
        )}
      </div>
      {/*  <ul className="list-chat-container">
                {options.map((opt) => {
                  return (
                    <li
                      key={opt?.id}
                      className="d-flex"
                      style={{ alignItems: "center", justifyContent: "space-between" }}
                    >
                      <p style={{ width: "100%" }}>
                        Ketik <strong style={{fontWeight:'800'}}>{opt?.title}</strong> : {opt?.description}
                      </p>
                    </li>
                  );
                })}
              </ul>*/}
    </React.Fragment>
  );
};

export const ReplySection = ({ context, youChat }) => {
  const { value, value2 } = useContext(ReplyContext);
  const [contextMessage, setContextMessage] = value2;
  const noCaption = (type) => {
    if (type === "IMAGE") {
      return "Image";
    } else if (type === "FILE") {
      return "File";
    } else if (type === "LOCATION") {
      return "Location";
    } else if (type === "VOICE") {
      return "Voice";
    } else if (type === "CONTACTS") {
      return "Contact";
    } else if (type === "STICKER") {
      return "Sticker";
    }
  };
  return (
    <React.Fragment>
      <div
        className={youChat ? "reply-section-you" : "reply-section"}
        onClick={(ev) => {
          ev.preventDefault();
          setContextMessage(context);
        }}
      >
        {context.type === "TEXT" ? (
          <div className="reply-section-bubble">
            <p className="reply-section-text" dangerouslySetInnerHTML={formatMessage(context.text)}></p>
          </div>
        ) : (
          <div className="reply-section-media">
            <Icon
              name={
                context.type === "IMAGE"
                  ? "img-fill"
                  : context.type === "DOCUMENT"
                  ? "file-fill"
                  : context.type.toLowerCase() === "voice"
                  ? "mic"
                  : context.type === "LOCATION"
                  ? "map-pin-fill"
                  : context.type === "CONTACTS"
                  ? "user-fill-c"
                  : context.type === "STICKER"
                  ? "notify"
                  : ""
              }
            />
            <span>
              {context.text !== "" ? (
                <div dangerouslySetInnerHTML={formatMessage(context.text)}></div>
              ) : (
                <div>{noCaption(context.type)}</div>
              )}
            </span>
          </div>
        )}
        {context.type === "IMAGE" || context.type === "STICKER" ? (
          <div className="reply-section-image">
            <img
              alt="gambar"
              className="reply-image"
              src={context.content.includes("https") ? context.content : process.env.REACT_APP_S3_URL + context.content}
            />
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export const ContactChat = ({ contextId, context, youChat, contactData, timestamp }) => {
  const [showContact, setShowContact] = useState(false);
  return (
    <React.Fragment>
      {contextId != null && context != null ? <ReplySection youChat={youChat} context={context} /> : null}
      <div
        className={youChat ? "top-content-you" : "top-content"}
        target="_blank"
        style={{ cursor: "pointer" }}
        onClick={() => setShowContact(true)}
      >
        <Icon name="user-fill-c" className="file-icon" />
        <span>
          <div>{contactData[0].name.formatted_name}</div>
        </span>
      </div>
      <div
        className="timestamp mt-1"
        style={{ fontSize: 13, marginTop: "-4px", marginBottom: "-7px", textAlign: "right" }}
      >
        <span> </span>
        {timestamp}
      </div>

      <Modal isOpen={showContact} toggle={() => setShowContact(false)}>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            setShowContact(false);
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="py-2 px-3">
          <div className="d-flex align-items-center border-bottom pb-2">
            <UserAvatar size="xl" image={contactData[0].url} icon="user-alt" />
            <span className="ml-2 contact-modal-name">{contactData[0].name.formatted_name}</span>
          </div>
          {contactData[0].phones.map((item) => {
            return (
              <div key={item.phone} className="d-flex justify-content-between align-items-center border-bottom py-2">
                <div className="d-flex align-items-center">
                  <Icon name="call-fill" style={{ fontSize: 24 }} />
                  <span className="ml-4">
                    <div className="contact-item-title">{item.phone}</div>
                    <div>{item.type}</div>
                  </span>
                </div>
                <span className="justify-content-end mr-3">
                  <Icon
                    name="copy"
                    style={{ fontSize: 16, cursor: "pointer" }}
                    onClick={() => {
                      navigator.clipboard.writeText(item.phone);
                    }}
                  />
                </span>
              </div>
            );
          })}

          {contactData[0].emails.map((item) => {
            return (
              <div key={item.email} className="d-flex justify-content-between align-items-center border-bottom py-2">
                <div className="d-flex align-items-center">
                  <Icon name="mail-fill" style={{ fontSize: 24 }} />
                  <span className="ml-4">
                    <div className="contact-item-title">{item.email}</div>
                    <div></div>
                  </span>
                </div>
                <span className="justify-content-end mr-3">
                  <Icon
                    name="copy"
                    style={{ fontSize: 16, cursor: "pointer" }}
                    onClick={() => {
                      navigator.clipboard.writeText(item.email);
                    }}
                  />
                </span>
              </div>
            );
          })}
        </div>
      </Modal>
    </React.Fragment>
  );
};

export const formatMessage = (messageText) => {
  if (messageText) {
    let result = messageText;
    const regexHyperlink =
      /((ftp|http|https):\/\/)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g;
    // const regexItalic = /_([A-Za-z]+( [A-Za-z]+)+)_|_([A-Za-z]+)_/gi;
    // const regexBold = /\*([A-Za-z]+( [A-Za-z]+)+)\*|\*([A-Za-z]+)\*/g;
    // const regexStrike = /~([A-Za-z]+( [A-Za-z]+)+)~|~([A-Za-z]+)~/g;
    // const regexMono = /```([A-Za-z]+( [A-Za-z]+)+)```|```([A-Za-z]+)```/g;

    const regexBold = /\*.+\*/g;
    const regexItalic = /_.+_/gi;
    const regexStrike = /~.+~/g;
    const regexMono = /```.+```/g;

    result = messageText.replace(regexHyperlink, function (x) {
      if (x.includes("://")) {
        return "<a href='" + x + "' target='_blank' style='overflow-wrap: break-word;'>" + x + "</a>";
      } else {
        return "<a href='https://" + x + "' target='_blank'>" + x + "</a>";
      }
    });

    result = result.replace(regexBold, function (x) {
      return x.substr(1, x.length - 2).bold();
    });

    result = result.replace(regexItalic, function (x) {
      return x.substr(1, x.length - 2).italics();
    });

    result = result.replace(regexStrike, function (x) {
      return x.substr(1, x.length - 2).strike();
    });

    result = result.replace(regexMono, function (x) {
      return `<p style="font-family:'Lucida Console', monospace">${x.substr(3, x.length - 6)}</p>`;
    });

    return {
      __html: result,
    };
  } else {
    return null;
  }
};

export const formatMessageImage = (imageUrl) => {
if (imageUrl) {
    return {
      __html: `<img src="${imageUrl.props.src}" alt="image" />`,
    };
  } else {
    return null;
  }
};