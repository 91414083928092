import { axiosPrivate } from "../api/axiosCore";



export const deleteRotators = async (deptId, token) => {
    try {
      axiosPrivate.interceptors.request.use((config) => {
        config.headers["Authorization"] = `Bearer ${token}`;
        return config;
      });
  
      const response = await axiosPrivate.delete("api/rotators/" + deptId);
      return response;
    } catch (error) {
      if (!error.response) {
        return { status: "No Server Response" };
      } else {
        return error.response;
      }
    }
  };