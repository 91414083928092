const getTextColor = (hex) => {
    let rgba = hex2rgba(hex);
    rgba = rgba.match(/\d+/g)
    if((rgba[0]*0.299)+(rgba[1]*0.587)+(rgba[2]*0.114)>186) {
      return 'black';
    } else {
      return 'white';
    }
}

const hex2rgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

export default getTextColor;