import React, { useEffect } from "react";
import { FormGroup, Form, Modal, ModalBody, Collapse, Badge } from "reactstrap";
import { Icon, Col, Button, RSelect } from "../../components/Component";
import getTextColor from "../../components/custom/getTextColor";
import { useState } from "react";
import { getTags } from "../../services/tags";
import { ProvinceDataList } from "pages/app/chat/ProvinceDataList";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { serviceGetContact, servicePutContact } from "services/contact";
import Swal from "sweetalert2";
import { serviceGetResolveByContact } from "services/crmResolve";
import { serviceGetProvinceAstro, serviceGetCityAstro, serviceGetSubdistrictAstro } from "services/productAstro";

const ContactDetail = ({
  contactData,
  membershipOption,
  clientOption,
  cookies,
  modalDetail,
  modalToggle,
  roleFunctions,
  ...props
}) => {
  const [tagOption, setTagOption] = useState([]);
  const [isOpen, setIsOpen] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);
  const [clientDept, setClientDept] = useState([
    { isClient: false, salesId: "", products: [] },
    { isClient: false, salesId: "", products: [] },
    { isClient: false, salesId: "", products: [] },
    { isClient: false, salesId: "", products: [] },
    { isClient: false, salesId: "", products: [] },
    { isClient: false, salesId: "", products: [] },
  ]);
  const [changedFormData, setChangedFormData] = useState({});
  const titleOption = [
    { value: "Kak", label: "Kak" },
    { value: "Bapak", label: "Bapak" },
    { value: "Ibu", label: "Ibu" },
  ];
  const [formData, setFormData] = useState();

  const openAccordion = (id) => {
    if (!checkOpen(id)) {
      const allOpen = [...isOpen];
      allOpen.push(id);
      setIsOpen(allOpen);
    } else {
      closeAccordion(id);
    }
  };

  const checkOpen = (id) => {
    return isOpen.includes(id);
  };

  const closeAccordion = (id) => {
    const allOpen = [...isOpen];
    const index = allOpen.indexOf(id);
    if (index !== -1) {
      allOpen.splice(index, 1);
      setIsOpen(allOpen);
    }
  };

  const refreshTag = async () => {
    if (tagOption) return;
    const responseGetTags = await getTags(cookies.SIDikey, "", 1);
    if (new RegExp(/20+/).test(responseGetTags.status)) {
      const resultData = responseGetTags.data;
      const finalData = [];
      resultData.data.forEach((tag) => {
        const tempData = {
          value: tag.id,
          label: tag.name,
          color: tag.color,
        };
        finalData.push(tempData);
      });
      setTagOption(finalData);
    } else {
      console.log(responseGetTags);
    }
  };

  const refreshData = async (contactData) => {
    const getProv = await serviceGetProvinceAstro(cookies.AstroKey);
    const provList = getProv.data.map((item) => ({ value: item.province, label: item.province, id: item.province_id }));
    setProvinceList(provList);

    const selectedProv = provList.find((item) => item.value === contactData.province);
    let selectedCity;
    let selectedSubDistrict;
    if (selectedProv) {
      const getCity = await serviceGetCityAstro(cookies.AstroKey, selectedProv.id);
      const cityList = getCity.data.map((item) => ({ value: item.city_name, label: item.city_name, id: item.city_id }));
      selectedCity = cityList.find((item) => item.value === contactData.district);
      setCityList(cityList);
      if (selectedCity) {
        const getSubDistrict = await serviceGetSubdistrictAstro(cookies.AstroKey, selectedCity.id);
        const subDistrictList = getSubDistrict.data.map((item) => ({
          value: item.subdistrict_name,
          label: item.subdistrict_name,
          id: item.subdistrict_id,
        }));
        selectedSubDistrict = subDistrictList.find((item) => item.value === contactData.subDistrict);
        setSubDistrictList(subDistrictList);
      }
    }

    setFormData({
      greeting: { value: contactData.greeting, label: contactData.greeting },
      name: contactData.name,
      email1: contactData.emails[0]?.email || "",
      email2: contactData.emails[1]?.email || "",
      phone1: contactData.phones[0]?.phone || "",
      phone2: contactData.phones[1]?.phone || "",
      birthday: contactData?.birthday ? Date.parse(contactData?.birthday) : null,
      province: selectedProv,
      district: selectedCity,
      subDistrict: selectedSubDistrict,
      zipCode: contactData.zipCode,
      address: contactData.address,
      notes: contactData.notes,
      tags: contactData.tags,
      clientAclub: contactData.clientAclub,
      clientType: contactData.clientType,
      membership: contactData.membership,
      salesAclub: contactData.salesAclub,
      clientCAT: contactData.clientCAT,
      clientAshop: contactData.clientAshop,
      productAshop: contactData.productAshop,
      clientOrbi: contactData.memberOrbi,
      salesOrbi: contactData.salesOrbi,
      clientUOB: contactData.clientUOB,
      salesUOB: contactData.salesUOB,
      clientSucor: contactData.clientSucor,
      salesSucor: contactData.salesSucor,
      createdAt: contactData.createdAt,
      updatedAt: contactData.updatedAt,
      createdBy: contactData.createdBy?.firstName
        ? contactData.createdBy?.firstName + " " + contactData.createdBy?.lastName
        : "Admin",
      updatedBy: contactData.updatedBy?.firstName
        ? contactData.updatedBy?.firstName + " " + contactData.updatedBy?.lastName
        : "Has not been updated.",
    });
    return;
  };
  const refreshDept = async () => {
    const response = await serviceGetResolveByContact(cookies.SIDikey, contactData.id);
    if (new RegExp(/20+/).test(response.status)) {
      const respFinal = response.data.data;
      const temp = clientDept;
      for (let i = 0; i < respFinal.length; i++) {
        temp[respFinal[i].pc].isClient = true;
        temp[respFinal[i].pc].products.push(JSON.parse(respFinal[i].productDescription)[0]);
      }
      setClientDept(temp);
    }
  };

  useEffect(() => {
    if (modalDetail) {
      refreshTag();
      refreshData(contactData);
      refreshDept();
    }
  }, []);

  const cleanPhoneNumber = (phone) => {
    if (!phone.includes("+")) {
      phone = "+" + phone;
    }
    phone = phone.replace(/[^0-9+]/g, "");
    return phone;
  };

  const handleAddressChange = async (data, name) => {
    if (name === "province") {
      setFormData((current) => {
        return {
          ...current,
          [name]: data,
          district: "",
          subDistrict: "",
        };
      });
      const getCity = await serviceGetCityAstro(cookies.AstroKey, data.id);
      const cityList = getCity.data.map((item) => ({ value: item.city_name, label: item.city_name, id: item.city_id }));
      setCityList(cityList);
    } else if (name === "district") {
      setFormData((current) => {
        return {
          ...current,
          [name]: data,
          subDistrict: "",
        };
      });
      const getSubDistrict = await serviceGetSubdistrictAstro(cookies.AstroKey, data.id);
      const subDistrictList = getSubDistrict.data.map((item) => ({
        value: item.subdistrict_name,
        label: item.subdistrict_name,
        id: item.subdistrict_id,
      }));
      setSubDistrictList(subDistrictList);
    } else if (name === "subDistrict") {
      setFormData((current) => {
        return {
          ...current,
          [name]: data,
        };
      });
    }
  }; 

  const handleFormObjectChange = (e, newValue, name) => {
    setFormData((current) => {
      return {
        ...current,
        [name]: newValue,
      };
    });

    setChangedFormData((current) => ({
      ...current,
      [name]: newValue,
      isSaved: "S",
    }));

    switch (name) {
      case "province":
        setChangedFormData((current) => {
          return {
            ...current,
            province: newValue,
          };
        });
        handleAddressChange(e, name);
        break;
      case "district":
        setChangedFormData((current) => {
          return {
            ...current,
            district: newValue,
          };
        });
        handleAddressChange(e, name);
        break;
      case "subDistrict":
        setChangedFormData((current) => {
          return {
            ...current,
            subDistrict: newValue,
          };
        });
        handleAddressChange(e, name);
        break;
      case "phone1":
        e.target.value = cleanPhoneNumber(newValue);
        setChangedFormData((current) => {
          return {
            ...current,
            phones: [{ phone: cleanPhoneNumber(formData.phone2) }, { phone: cleanPhoneNumber(newValue) }],
          };
        });
        break;
      case "phone2":
        e.target.value = cleanPhoneNumber(newValue);
        setChangedFormData((current) => {
          return {
            ...current,
            phones: [{ phone: cleanPhoneNumber(newValue) }, { phone: cleanPhoneNumber(formData.phone1) }],
          };
        });
        break;
      case "email1":
        setChangedFormData((current) => {
          return {
            ...current,
            emails: formData.email2 ? [{ email: newValue }, { email: formData.email2 }] : [{ email: newValue }],
          };
        });
        break;
      case "email2":
        setChangedFormData((current) => {
          return {
            ...current,
            emails: [{ email: formData.email1 }, { email: newValue }],
          };
        });
        break;
      default:
        setChangedFormData((current) => {
          return {
            ...current,
            [name]: newValue,
          };
        });
        break;
    }
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    const resp = await servicePutContact(cookies.SIDikey, contactData.id, changedFormData);
    if (/20+/.test(resp.status)) {
      Swal.fire("Edit Contact", "Berhasil", "success");
      modalToggle();
    } else {
      Swal.fire("Edit Contact", "Gagal", "error");
    }
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={modalDetail}
        backdrop="static"
        keyboard={false}
        scrollable={true}
        toggle={() => modalToggle()}
        className="modal-dialog-centered"
        size="lg"
      >
        <ModalBody>
          <a
            href="#cancel"
            onClick={(ev) => {
              ev.preventDefault();
              modalToggle();
              //   onFormCancel();
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">Detail Kontak</h5>
            <div className="mt-4">
              {formData && (
                <Form className="row gy-2" onSubmit={(e) => handleSubmitForm(e)}>
                  <Col md="4" lg="4">
                    <FormGroup>
                      <label className="form-label">Sapaan</label>
                      <RSelect
                        name="greeting"
                        options={titleOption}
                        defaultValue={formData.greeting || ""}
                        //onChange={(e) => handleFormObjectChange(e, e.target.value, "greeting")}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="8" lg="8">
                    <FormGroup>
                      <label className="form-label">Nama</label>
                      <input
                        name="name"
                        className="form-control"
                        type="text"
                        defaultValue={formData.name || ""}
                        onChange={(e) => handleFormObjectChange(e, e.target.value, "name")}
                      />
                    </FormGroup>
                  </Col>

                  <Col md="12" lg="12">
                    <label className="form-label">Tanggal Ulang Tahun</label>
                    <ReactDatePicker
                      className="form-control date-picker"
                      showYearDropdown
                      scrollableYearDropdown
                      dropdownMode="select"
                      selected={formData.birthday || ""}
                      onChange={(date) => handleFormObjectChange("event", date, "birthday")}
                      name="birthday"
                    />
                  </Col>

                  <Col md="6" lg="6">
                    <FormGroup>
                      <label className="form-label">Phone 1</label>
                      <input
                        name="phone1"
                        className="form-control"
                        type="text"
                        defaultValue={formData.phone1}
                        onChange={(e) => handleFormObjectChange(e, e.target.value, "phone1")}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="6">
                    <FormGroup>
                      <label className="form-label">Phone 2</label>
                      <input
                        name="phone2"
                        className="form-control"
                        type="text"
                        defaultValue={formData.phone2 || ""}
                        onChange={(e) => handleFormObjectChange(e, e.target.value, "phone2")}
                      />
                    </FormGroup>
                  </Col>

                  <Col md="6" lg="6">
                    <FormGroup>
                      <label className="form-label">Email 1</label>
                      <input
                        name="email1"
                        className="form-control"
                        type="text"
                        defaultValue={formData.email1 || ""}
                        onChange={(e) => handleFormObjectChange(e, e.target.value, "email1")}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="6">
                    <FormGroup>
                      <label className="form-label">Email 2</label>
                      <input
                        name="email2"
                        className="form-control"
                        type="text"
                        defaultValue={formData.email2 || ""}
                        onChange={(e) => handleFormObjectChange(e, e.target.value, "email2")}
                      />
                    </FormGroup>
                  </Col>

                  <Col md="6" lg="6">
                    <FormGroup>
                      <label className="form-label">Provinsi</label>
                      <RSelect
                        name="province"
                        options={provinceList}
                        placeholder="Pilih Provinsi"
                        defaultValue={formData.province}
                        value={formData.province}
                        onChange={(e) => handleFormObjectChange(e, e.value, "province")}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="6">
                    <FormGroup>
                      <label className="form-label">Kota</label>
                      <RSelect
                        name="city"
                        options={cityList}
                        defaultValue={formData.district || ""}
                        value={formData.district}
                        onChange={(e) => handleFormObjectChange(e, e.value, "district")}
                      />
                    </FormGroup>
                  </Col>

                  <Col md="6" lg="6">
                    <FormGroup>
                      <label className="form-label">Kecamatan</label>
                      <RSelect
                        name="subDistrict"
                        options={subDistrictList}
                        defaultValue={formData.subDistrict || ""}
                        value={formData.subDistrict}
                        onChange={(e) => handleFormObjectChange(e, e.value, "subDistrict")}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="6">
                    <FormGroup>
                      <label className="form-label">Kode Pos</label>
                      <input
                        name="zipCode"
                        className="form-control"
                        type="number"
                        defaultValue={formData.zipCode || ""}
                        onChange={(e) => handleFormObjectChange(e, e.target.value, "zipCode")}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12" lg="12">
                    <FormGroup>
                      <label className="form-label">Detail Alamat</label>
                      <textarea
                        name="address"
                        className="form-control no-resize"
                        defaultValue={formData.address || ""}
                        onChange={(e) => handleFormObjectChange(e, e.target.value, "address")}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12" lg="12">
                    <FormGroup>
                      <label className="form-label">Catatan</label>
                      <textarea
                        name="notes"
                        className="form-control no-resize "
                        defaultValue={formData.notes || ""}
                        onChange={(e) => handleFormObjectChange(e, e.target.value, "notes")}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12" lg="12">
                    <FormGroup>
                      <label className="form-label">Tag</label>
                      <RSelect
                        name="tags"
                        options={tagOption}
                        isMulti
                        defaultValue={formData.tags || ""}
                        styles={{
                          multiValue: (styles, { data }) => {
                            const color = data.color;
                            return {
                              ...styles,
                              backgroundColor: color + " !important",
                              borderRadius: "1.5rem !important",
                            };
                          },
                          multiValueLabel: (styles, { data }) => {
                            const color = getTextColor(data.color);
                            return {
                              ...styles,
                              color: color,
                            };
                          },
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="12" className=""><label className="form-label mb-0">Astro</label></Col>
                  <Col lg="2" className="mb-2">Astro Client</Col><Col lg="4" className="">: Yes</Col>
                  <Col lg="6" className=""></Col>

                  <Col lg="12" className=""><label className="form-label mb-0">A-Club</label></Col>
                  <Col lg="2" className="">Client A-Club</Col><Col lg="4" className="">: {contactData.clientAclub ? contactData.clientAclub : "No"}</Col>
                  <Col lg="2" className="">Sales A-Club</Col><Col lg="4" className="">: {contactData.salesAclub ? contactData.salesAclub : "-"}</Col>
                  <Col lg="2" className="">Client Type</Col><Col lg="4" className="">: {contactData.clientType ? contactData.clientType : "-"}</Col>
                  <Col lg="2" className="mb-2">Membership</Col><Col lg="4" className="">: {contactData.membership ? contactData.membership : "-"}</Col>

                  <Col lg="12" className=""><label className="form-label mb-0">A-Shop</label></Col>
                  <Col lg="2" className="">Client A-Shop</Col><Col lg="4" className="">: {contactData.clientAshop ? contactData.clientAshop : "No"}</Col>
                  <Col lg="2" className="">Product A-Shop</Col><Col lg="4" className="">: {contactData.productAshop ? contactData.productAshop : "-"}</Col>

                  <Col lg="12" className=""><label className="form-label mb-0">CAT</label></Col>
                  <Col lg="2" className="">Client CAT</Col><Col lg="4" className="">: {contactData.clientCat ? contactData.clientCat : "No"}</Col>
                  <Col lg="2" className="mb-2">Sales CAT</Col><Col lg="4" className="">: {contactData.salesCat ? contactData.salesCat : "-"}</Col>

                  <Col lg="12" className=""><label className="form-label mb-0">Orbi</label></Col>
                  <Col lg="2" className="">Member Orbi</Col><Col lg="4" className="">: {contactData.clientOrbi ? contactData.clientOrbi : "No"}</Col>
                  <Col lg="2" className="mb-2">Sales Orbi</Col><Col lg="4" className="">: {contactData.salesOrbi ? contactData.salesOrbi : "-"}</Col>

                  <Col lg="12" className=""><label className="form-label mb-0">UOB</label></Col>
                  <Col lg="2" className="">UOB Client</Col><Col lg="4" className="">: {contactData.clientUob ? contactData.clientUob : "No"}</Col>
                  <Col lg="2" className="mb-2">UOB Sales</Col><Col lg="4" className="">: {contactData.salesUob ? contactData.salesUob : "-"}</Col>

                  <Col lg="12" className=""><label className="form-label mb-0">Sucor</label></Col>
                  <Col lg="2" className="">Sucor Client</Col><Col lg="4" className="">: {contactData.clientSucor ? contactData.clientSucor : "No"}</Col>
                  <Col lg="2" className="mb-2">Sucor Sales</Col><Col lg="4" className="">: {contactData.salesSucor ? contactData.salesSucor : "-"}</Col>

                  <Col md="12" lg="12" className="border-top pt-2 pb-4">
                    <p className="text-soft">
                      {"Created at " + moment(formData.createdAt).format("MMM DD, YYYY") + " by " + formData.createdBy}
                    </p>
                    <p className="text-soft">
                      {"Modify at " + moment(formData.updatedAt).format("MMM DD, YYYY") + " by " + formData.updatedBy}
                    </p>
                  </Col>
                  <Col md="6" lg="6">
                    <Button type="button" color="gray" outline onClick={() => modalToggle()} className="btn btn-block">
                      Batal
                    </Button>
                  </Col>
                  <Col md="6" lg="6">
                    {!roleFunctions.includes("update") || (
                      <Button type="submit" color="primary" className="btn btn-block">
                        Simpan
                      </Button>
                    )}
                  </Col>
                </Form>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default ContactDetail;
