import React, { useState } from "react";
import classNames from "classnames";
import SimpleBar from "simplebar-react";
import Logo from "../logo/Logo";
import Menu from "../menu/Menu";
import Toggle from "./Toggle";
import EcommerceMenu from "../menu/EcommerceMenu";
import User from "layout/header/dropdown/user/User";
import { UserAvatar } from "components/Component";

const Sidebar = ({ fixed, theme, className, sidebarToggle, mobileView, setVisibility, ...props }) => {
  const [collapseSidebar, setSidebar] = useState(false);
  const [mouseEnter, setMouseEnter] = useState(false);
  const [headerMouseEnter, setHeaederMouseEnter] = useState(false);

  const toggleCollapse = () => {
    setSidebar(!collapseSidebar);
  };

  const handleMouseEnter = () => setMouseEnter(true);
  const handleMouseLeave = () => setMouseEnter(false);

  const handleHeaderMouseEnter = () => setHeaederMouseEnter(true);
  const handleHeaderMouseLeave = () => setHeaederMouseEnter(false);

  const classes = classNames({
    "nk-sidebar": true,
    "nk-sidebar-fixed": fixed,
    "is-compact": collapseSidebar,
    "has-hover": collapseSidebar && mouseEnter,
    [`is-light`]: theme === "white",
    [`is-${theme}`]: theme !== "white" && theme !== "light",
    [`${className}`]: className,
  });

  const isMinimize = () => {
    if (collapseSidebar) {
      if (headerMouseEnter || mouseEnter) {
        return true;
      }
    } else {
      return true;
    }
    return false;
  }

  return (
    <div className={classes}>
      <div
        className="nk-sidebar-element nk-sidebar-head"
        onMouseEnter={handleHeaderMouseEnter}
        onMouseLeave={handleHeaderMouseLeave}
      >
        <div className="nk-sidebar-brand">
          <Logo
            mouseEnter={headerMouseEnter}
            contentMouseEnter={mouseEnter}
            collapseSidebar={collapseSidebar}
            fixed={fixed}
          />
        </div>
        <div className="nk-menu-trigger mr-n2">
          <Toggle className="nk-nav-toggle nk-quick-nav-icon d-xl-none mr-n2" icon="arrow-left" click={sidebarToggle} />
          <Toggle
            className={`nk-nav-compact nk-quick-nav-icon d-none d-xl-inline-flex ${
              collapseSidebar ? "compact-active" : ""
            }`}
            click={toggleCollapse}
            icon="menu"
          />
        </div>
      </div>
      <div className="nk-sidebar-content" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <SimpleBar className="nk-sidebar-menu k">
          <div
            className="d-flex nk-profile"            
            onClick={() => setVisibility(false)}
          >
            <div className="nk-profile-item rounded" style={isMinimize() ? { backgroundColor: "#E4E8FF" } : { backgroundColor: "" }}>
              <User collapseSidebar={collapseSidebar} mouseEnter={headerMouseEnter} contentMouseEnter={mouseEnter} />
            </div>
          </div>
          {window.location.pathname.split("/")[2] === "ecommerce" ? (
            <EcommerceMenu sidebarToggle={sidebarToggle} mobileView={mobileView} />
          ) : (
            <Menu sidebarToggle={sidebarToggle} mobileView={mobileView} />
          )}
        </SimpleBar>
      </div>
    </div>
  );
};
export default Sidebar;
