import React, { Suspense, useLayoutEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { UserContextProvider } from "../pages/pre-built/user-manage/UserContext";
import { RedirectAs404 } from "../utils/Utils";

// import Homepage from "../pages/Homepage";
import SuperAdminDashboard from "pages/home/SuperAdminDashboard";

// Custom Import
import Channels from "../pages/channels/Channels";
import MasterFunction from "pages/masterFunction/MasterFunction";
import MasterAction from "pages/masterAction/MasterAction";
import MasterRole from "pages/masterRole/MasterRole";
import MasterLevel from "pages/masterLevel/MasterLevel";

import Users from "../pages/user/Users";
import UserRelation from "../pages/user/UserRelation";
import UserRotatorSummary from "../pages/user/UserRotatorSummary";

import Departements from "../pages/departements/Departements";
import Tags from "../pages/tags/Tags";

import QuickReply from "../pages/quick-reply/QuickReply";
import AddQuickReply from "../pages/quick-reply/AddQuickReply";
import DetailQuickReply from "../pages/quick-reply/DetailQuickReply";

import Campaign from "../pages/campaign/Campaign";
import AddCampaign from "../pages/campaign/AddCampaign";
import DetailCampaign from "../pages/campaign/DetailCampaign";

import Ticket from "../pages/app/ticket/Ticket";
import Chat from "../pages/app/chat/Chat";
import LeadsMonitoring from "pages/leadsMonitoring/Kanban";

import FileManager from "pages/app/file-manager/FileManager";

import Contact from "../pages/contact/Contact";

import LogManagement from "pages/logManagement/LogManagement";

import Chatbot from "pages/chatbot/Chatbot";

import { ChatContextProvider } from "../pages/app/chat/ChatContext";
import { DepartementContextProvider } from "../pages/departements/DepartementsContext";
import { TagsContextProvider } from "../pages/tags/TagsContext";
import { CampaignContextProvider } from "../pages/campaign/CampaignContext";
import { ChannelContextProvider } from "../pages/channels/ChannelContext";
import { QuickReplyContextProvider } from "../pages/quick-reply/QuickReplyContext";
import { ContactContextProvider } from "../pages/contact/ContactContext";
import { LogManagementContextProvider } from "pages/logManagement/LogManagementContext";
import { FileManagerContextProvider } from "pages/app/file-manager/FileManagerContext";
import { ChatbotContextProvider } from "pages/chatbot/ChatbotContext";

import SystemSettingsLayout from "pages/systemSettings/SystemSettingsLayout";
import { TicketContextProvider } from "pages/app/ticket/TicketContext";
import DetailTicket from "pages/app/ticket/DetailTicket";
import { useCookies } from "react-cookie";
import SalesDashboard from "pages/home/SalesDashboard";
import RolexFunctionxLevel from "pages/rolexfunctionxlevel/RolexFunctionxLevel";
import AddChatbot from "pages/chatbot/chatbotDetail/AddChatbot";
import EditChatbot from "pages/chatbot/chatbotDetail/EditChatbot";

const Pages = () => {
  const [cookies] = useCookies(["user"]);
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const DashboardSwitcher = (role) => {
    switch (role) {
      case "SALES":
        return SalesDashboard;
      case "CS":
        return SalesDashboard;
      case "HEAD":
        return SalesDashboard;
      default:
        return SuperAdminDashboard;
    }
  };

  return (
    <Suspense fallback={<div />}>
      <Switch>
        {/* Custom sidebar */}
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/channels`}
          render={() => (
            <ChannelContextProvider>
              <Channels />
            </ChannelContextProvider>
          )}
        ></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/users`}
          render={() => (
            <UserContextProvider>
              <Users />
            </UserContextProvider>
          )}
        ></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/users/relasi`} component={UserRelation}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/users/rotator-summary`} component={UserRotatorSummary}></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/departments`}
          render={() => (
            <DepartementContextProvider>
              <Departements />
            </DepartementContextProvider>
          )}
        ></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/tags`}
          render={() => (
            <TagsContextProvider>
              <Tags />
            </TagsContextProvider>
          )}
        ></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/quick-reply`}
          render={() => (
            <QuickReplyContextProvider>
              <QuickReply />
            </QuickReplyContextProvider>
          )}
        ></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/quick-reply/add`} component={AddQuickReply}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/quick-reply/detail`} component={DetailQuickReply}></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/campaign`}
          render={() => (
            <CampaignContextProvider>
              <Campaign />
            </CampaignContextProvider>
          )}
        ></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/campaign/add`} component={AddCampaign}></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/campaign/detail`} component={DetailCampaign}></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/ticket`}
          render={() => (
            <TicketContextProvider>
              <Ticket />
            </TicketContextProvider>
          )}
        ></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/ticket/detail`} component={DetailTicket}></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/chat-platform`}
          render={() => (
            <ChatContextProvider>
              <Chat />
            </ChatContextProvider>
          )}
        ></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/log-management`}
          render={() => (
            <LogManagementContextProvider>
              <LogManagement />
            </LogManagementContextProvider>
          )}
        ></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/contact`}
          render={() => (
            <ContactContextProvider>
              <Contact />
            </ContactContextProvider>
          )}
        ></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/contact`}
          render={() => (
            <ContactContextProvider>
              <Contact />
            </ContactContextProvider>
          )}
        ></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/file`}
          render={() => (
            <FileManagerContextProvider>
              <FileManager />
            </FileManagerContextProvider>
          )}
        ></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/chat-bot`}
          render={() => (
            <ChatbotContextProvider>
              <Chatbot />
            </ChatbotContextProvider>
          )}
        ></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/chatbot/add`} component={AddChatbot}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/chatbot/edit/:id`} component={EditChatbot}></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/leads-monitoring`} render={() => <LeadsMonitoring />}></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/master-function`} render={() => <MasterFunction />}></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/master-action`} render={() => <MasterAction />}></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/master-role`} render={() => <MasterRole />}></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/master-level`} render={() => <MasterLevel />}></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/role-function-level`}
          render={() => <RolexFunctionxLevel />}
        ></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/system-settings-profile`}
          render={() => <SystemSettingsLayout />}
        ></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/system-settings`} render={() => <SystemSettingsLayout />}></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/system-settings-notification`}
          render={() => <SystemSettingsLayout />}
        ></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/`} component={DashboardSwitcher(cookies.role.name)}></Route>
        <Route component={RedirectAs404}></Route>
      </Switch>
    </Suspense>
  );
};
export default Pages;
