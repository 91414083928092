export const DefaultLeadFilters = [
    {
      text: 'Status',
      value: 'leadStatus',
      icon: 'disk',
      isSelected: false,
      type: "checkbox",
      keyOptions: "chats"
    },
    {
      text: 'Address',
      value: 'address',
      icon: 'location',
      isSelected: false,
      type: "text",
      keyOptions: "contact"
    },
    {
      text: 'City',
      value: 'city',
      icon: 'building',
      isSelected: false,
      type: "text",
      keyOptions: "contact"
    },
    {
      text: 'Sales',
      value: 'sales',
      icon: 'coins',
      isSelected: false,
      type: "checkbox",
      keyOptions: "roots"
    },
    {
      text: 'Email',
      value: 'email',
      icon: 'emails',
      isSelected: false,
      type: "text",
      keyOptions: "contact"
    },
    {
      text: 'Phone',
      value: 'customerPhone',
      icon: 'call',
      isSelected: false,
      type: "text",
      keyOptions: "chats"
    },
    {
      text: 'Product',
      value: 'products',
      icon: 'briefcase',
      isSelected: false,
      type: "checkbox",
      keyOptions: "contact"
    },
    {
      text: 'Lead',
      value: 'name',
      icon: 'user',
      isSelected: false,
      type: "checkbox",
      keyOptions: "contact"
    },
  ]

export const DefaultKeyLeadsFilter = {
  Status:true,
  Address:false,
  City:false,
  Sales:false,
  Email:false,
  Phone:false,
  Product:true,
  Lead:true,
  Media:false
}