import React, { useState, useEffect } from "react";
import { Card, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { Icon, LineChartExample } from "components/Component";
import { ChatPerDayData } from "./homeData";
import { useCookies } from "react-cookie";
import {
  getDashboardTotal
} from "../../services/dashboard";

const TotalNewProspect = (props) => {
  const { selectedDepartment } = props
  const [cookies] = useCookies(["user"]);
  const [data, setData] = useState(null);
  const [datePeriod, setDatePeriod] = useState("DAILY");
  const [cardType, setCardType] = useState("PROSPECT");
  const [percentage, setPercentage] = useState(0);
  const roleFunctions = JSON.parse(localStorage.getItem("permission"))["dashboard"].access || [];

  const getDataTotal = async (datePeriod) => {
    // const responseGetDataTotal = await getDashboardTotal(cookies.SIDikey, datePeriod, cardType, cookies.departmentId, cookies.id, roleFunctions);
    const responseGetDataTotal = await getDashboardTotal(cookies.SIDikey, datePeriod, cardType, selectedDepartment.value, cookies.id, roleFunctions);
    setData(responseGetDataTotal.data)
    const currentTotal = Number(responseGetDataTotal?.data?.currentTotal)
    const lastTotal = Number(responseGetDataTotal?.data?.lastTotal)
    if(currentTotal !== lastTotal){
      if(lastTotal !== 0){
        setPercentage(Math.round((currentTotal-lastTotal)/lastTotal*100))
      }else{
        setPercentage(100)
      }
    }else{
      setPercentage(0)
    }
  };

  useEffect(() => {
      getDataTotal(datePeriod)
  }, [selectedDepartment]);

  const handleDatePeriod = (val) => {
    setDatePeriod(val)
    getDataTotal(val)
  }

  return (
    <Card className="card-full " style={{ height: "180px" }}>
      <div className="nk-ecwg nk-ecwg8 h-100 d-flex flex-column">
        <div className="card-inner h-100 d-flex flex-column" style={{ flex: 1 }}>
          <div className="card-title-group mb-3">
            <div className="card-title">
              <h6 className="title">Total New Prospect</h6>
            </div>
            <div className="card-tools">
              <UncontrolledDropdown>
                <DropdownToggle
                  tag="a"
                  href="#toggle"
                  onClick={(ev) => ev.preventDefault()}
                  className="link link-light link-sm dropdown-indicator"
                >
                  {datePeriod}
                </DropdownToggle>
                <DropdownMenu right className="dropdown-menu-sm">
                  <ul className="link-list-opt no-bdr">
                    <li>
                      <DropdownItem
                        tag="a"
                        href="#dropdown"
                        onClick={(ev) => {
                          ev.preventDefault();
                          handleDatePeriod('DAILY');
                        }}
                        className={datePeriod === "DAILY" ? "active" : ""}
                      >
                        <span>Daily</span>
                      </DropdownItem>
                    </li>
                    <li>
                      <DropdownItem
                        tag="a"
                        href="#dropdown"
                        onClick={(ev) => {
                          ev.preventDefault();
                          handleDatePeriod('WEEKLY');
                        }}
                        className={datePeriod === "WEEKLY" ? "active" : ""}
                      >
                        <span>Weekly</span>
                      </DropdownItem>
                    </li>
                    <li>
                      <DropdownItem
                        tag="a"
                        href="#dropdown"
                        onClick={(ev) => {
                          ev.preventDefault();
                          handleDatePeriod('MONTHLY');
                        }}
                        className={datePeriod === "MONTHLY" ? "active" : ""}
                      >
                        <span>Monthly</span>
                      </DropdownItem>
                    </li>
                    <li>
                      <DropdownItem
                        tag="a"
                        href="#dropdown"
                        onClick={(ev) => {
                          ev.preventDefault();
                          handleDatePeriod('YEARLY');
                        }}
                        className={datePeriod === "YEARLY" ? "active" : ""}
                      >
                        <span>Yearly</span>
                      </DropdownItem>
                    </li>
                  </ul>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
          <div className="report-panel">
            <h3 className="report-panel-title">{data?.currentTotal}</h3>
            <div className="text-right">
              {
                percentage >= 0 ?
                  <div className="report-panel__trend__up">
                    <Icon name="upword-alt-fill" />
                    <span>{percentage}%</span>
                  </div>
                :
                  <div className="report-panel__trend__down">
                    <Icon name="downward-alt-fill" />
                    <span>{Math.abs(percentage)}%</span>
                  </div>
              }
              <p>
                {
                  `Than 
                  ${
                    datePeriod === 'DAILY' ?
                      'Yesterday'
                    : datePeriod === 'WEEKLY' ?
                      'Last Week'
                    : datePeriod === 'MONTHLY' ?
                      'Last Month'
                    : datePeriod === 'YEARLY' ?
                      'Last Year'
                    : null
                  }
                  : ${data?.lastTotal}`
                }
              </p>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
export default TotalNewProspect;
