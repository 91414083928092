import React, { useState, useRef, useEffect } from "react";
import Head from "layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Col,
  RSelect,
} from "components/Component";
import { useForm } from "react-hook-form";
import { useCookies } from "react-cookie";
import { Form, FormGroup } from "reactstrap";
import Swal from "sweetalert2";
import { servicePutAuthProfile } from "../../services/users";
import { serviceGetProfile } from "services/settings";
import { getCountries } from "../../services/countries";
import { serviceStoreMediaChatbot } from "services/chatbot";

const Profile = ({ sm, updateSm }) => {
  const [cookies, setCookie] = useCookies(["user"]);
  const [urlAvatar, setUrlAvatar] = useState();
  const [opsiCountries, setOpsiCountries] = useState([]);
  const [formData, setFormData] = useState({
    avatar: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
  });

  const getProfile = async () => {
    const response = await serviceGetProfile(cookies.SIDikey);
    if (response.status === 200) {
      setFormData(() => {
        return {
          avatar: response.data.avatar,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          phonePrefix: response.data.phonePrefix,
          phone: response.data.phone,
          email: response.data.email,
        };
      });
    }
  };

  const refreshOpsiCountries = async () => {
    const responseGetCountries = await getCountries(cookies.SIDikey);
    const resultData = responseGetCountries.data;
    const finalData = [];
    for (let countries of resultData) {
      const tempData = {
        value: countries.phonePrefix,
        label: (
          <div>
            <img src={countries.flag} style={{ width: 25 }} alt={countries.name} /> {countries.phonePrefix}
          </div>
        ),
      };
      finalData.push(tempData);
    }
    setOpsiCountries(finalData);
  };

  const onEditSubmit = async (submitData) => {
    const { firstName, lastName, email } = submitData;

    formData.phone = String(formData.phone).replace(String(formData.phonePrefix), "");
    const data = {
      avatar: formData.avatar,
      firstName: submitData.firstName,
      lastName: submitData.lastName,
      email: submitData.email,
      phonePrefix: formData.phonePrefix,
      phone: formData.phone,
    };

    if (formData.phone.length <= 13 && formData.phone.length >= 9) {
      if (data.phone.slice(0, 2) === "08") {
        data.phone = formData.phonePrefix + data.phone.slice(1);
      } else {
        data.phone = formData.phonePrefix + data.phone;
      }
    }

    const responsePutUser = await servicePutAuthProfile(cookies.id, cookies.SIDikey, data);

    const response = await serviceGetProfile(cookies.SIDikey);
    if (response.status === 200) {
      setCookie("avatar", response.data.avatar || "images/db2e0c20-5b64-11ef-8c72-43ed9a2dc459.png");
      setCookie("firstName", response.data.firstName);
      setCookie("lastName", response.data.lastName);
      setCookie("email", response.data.email);
      setCookie("phone", response.data.phone);
    }

    setUrlAvatar(null);
    if (new RegExp(/20+/).test(responsePutUser.status)) {
      Swal.fire({
        icon: "success",
        title: "Updated Succesfull",
        focusConfirm: false,
      }).then(() => {
        getProfile();
        window.location.reload();
      });
    } else {
      Swal.fire({
        icon: "error",
        title: responsePutUser,
        focusConfirm: false,
      });
    }
  };

  const photoInput = useRef();

  const handleChange = async function (e) {
    e.preventDefault();

    if (e.target.files && e.target.files.length > 1) {
      Swal.fire("Gagal upload gambar", "Hanya satu gambar yang dapat diupload", "error");
      return;
    }

    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const fileType = file["type"];
      const validImageTypes = ["image/jpeg", "image/png"];

      if (!validImageTypes.includes(fileType)) {
        Swal.fire("Gagal upload gambar", "Mohon pilih format media (jpeg, png)", "error");
        return;
      }

      // const imageToSend = { file: file };

      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (e) => {
        setUrlAvatar(e.target.result);
      }

      setFormData((current) => {
        return {
          ...current,
          avatar: e.target.files[0],
        };
      });

      // const resp = await serviceStoreMediaChatbot(cookies.SIDikey, imageToSend);
      // if (/20+/.test(resp.status)) {
      //   setUrlAvatar(resp.data.url);
      //   // setFormData((current) => {
      //   //   return {
      //   //     ...current,
      //   //     avatar: URL.createObjectURL(e.target.files[0]),
      //   //   };
      //   // });
      //   Swal.fire("Upload gambar", "Berhasil", "success");
      // } else if (resp.status === 400) {
      //   Swal.fire("Gagal upload gambar", "Mohon pilih format media (jpeg, png)", "error");
      // } else {
      //   Swal.fire("Gagal upload gambar", "Periksa kembali isian", "error");
      // }
    }
  };

  useEffect(() => {
    getProfile();
    refreshOpsiCountries();
  }, []);

  const { errors, register, handleSubmit, getValues, setValue, watch } = useForm();

  return (
    <React.Fragment>
      <Head title="System Settings - Profile"></Head>

      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Edit Profile</BlockTitle>
          </BlockHeadContent>
          <BlockHeadContent className="align-self-start d-lg-none">
            <Button
              className={`toggle btn btn-icon btn-trigger mt-n1 ${sm ? "active" : ""}`}
              onClick={() => updateSm(!sm)}
            >
              <Icon name="menu-alt-r"></Icon>
            </Button>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Block>
        <Form className="row gy-4" onSubmit={handleSubmit(onEditSubmit)}>
          <Col md="12">
            <div className="d-flex">
              <div style={{ position: "relative", width: "fit-content", margin: "0px auto" }}>
                <img
                  src={urlAvatar ? urlAvatar : `${process.env.REACT_APP_S3_URL}${formData.avatar}`}
                  alt="User Profile"
                  style={{ width: 150,
                    height: 150,
                    borderRadius: "50%",
                    objectFit: "cover",
                    border: "2px solid #ccc", }}
                />
                <div
                  style={{
                    width: 36,
                    height: 36,
                    borderRadius: "50%",
                    backgroundColor: "#4461F2",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                    bottom: -12,
                    right: -12,
                    cursor: "pointer",
                  }}
                  onClick={() => photoInput.current.click()}
                >
                  <Icon name="pen-fill" style={{ color: "white", fontSize: 18 }} />
                </div>
                <input type="file" ref={photoInput} onChange={handleChange} style={{ display: "none" }} />
              </div>
            </div>
          </Col>
          <Col md="6">
            <FormGroup>
              <label className="form-label">Nama Depan</label>
              <input
                className="form-control"
                type="text"
                name="firstName"
                defaultValue={formData.firstName}
                placeholder="Masukkan Nama Admin"
                ref={register({ required: "This field is required" })}
              />
              {errors.firstName && <span className="custom-invalid">{errors.firstName.message}</span>}
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <label className="form-label">Nama Belakang</label>
              <input
                className="form-control"
                type="text"
                name="lastName"
                defaultValue={formData.lastName}
                placeholder="Masukkan Nama Admin"
                ref={register({ required: "This field is required" })}
              />
              {errors.lastName && <span className="custom-invalid">{errors.lastName.message}</span>}
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <label className="form-label">Nomor Handphone</label>
              <div className="form-control-wrap">
                <div className="input-group">
                  <RSelect
                    name="phonePrefix"
                    options={opsiCountries}
                    defaultValue={{
                      value: "+62",
                      label: (
                        <div>
                          <img src="https://flagcdn.com/id.svg" style={{ width: 25 }} alt="ID" /> +62
                        </div>
                      ),
                    }}
                    onChange={(e) => {
                      setValue("phonePrefix", e.value);
                      const fd = formData;
                      fd.phonePrefix = e;
                      setFormData({ ...fd });
                    }}
                    //ref={register()}
                    // {...register("phonePrefix", { required: false })}
                    styles={{
                      control: (provided, state) => ({
                        // Styles for the control (input)
                        ...provided,
                        width: 100,
                        borderRadius: 0,
                        minHeight: 36,
                      }),
                    }}
                  />
                  <input
                    className="form-control"
                    type="tel"
                    name="phone"
                    placeholder="e.g. 81234567890"
                    maxLength="13"
                    minLength="9"
                    value={Number(String(formData.phone).replace(String(formData.phonePrefix), ""))}
                    onChange={(e) => {
                      const fd = formData;
                      fd.phone = e.target.value;
                      setFormData({ ...fd });
                    }}
                    ref={register({
                      required: "This field is required",
                      pattern: {
                        value: /^[0123456789]*$/,
                        message: "invalid number",
                      },
                    })}
                  />
                </div>
              </div>
              {errors.phone && <span className="custom-invalid">{errors.phone.message}</span>}
              {Number(String(formData.phone).replace(String(formData.phonePrefix), "").length) > 13 && (
                <span className="custom-invalid">Max length of number is 13</span>
              )}
              {Number(String(formData.phone).replace(String(formData.phonePrefix), "").length) !== 0 &&
                Number(String(formData.phone).replace(String(formData.phonePrefix), "").length) < 9 && (
                  <span className="custom-invalid">Min length of number is 9</span>
                )}
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <label className="form-label">Email</label>
              <input
                className="form-control"
                type="text"
                name="email"
                defaultValue={formData.email}
                placeholder="Masukkan Email Admin"
                ref={register({
                  required: "This field is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "invalid email address",
                  },
                })}
              />
              {errors.email && <span className="custom-invalid">{errors.email.message}</span>}
            </FormGroup>
          </Col>
          {/* <Col md="6">
            <FormGroup>
              <label className="form-label" htmlFor="password">
                Password
              </label>
              <input
                type="password"
                id="password"
                className="form-control"
                name="password"
                onChange={(e) => onInputChange(e)}
                defaultValue={formData.password}
                placeholder="Password"
              />
            </FormGroup>
          </Col> */}
          <Col size="12">
            <Button color="primary" size="lg">
              Save
            </Button>
          </Col>
        </Form>
      </Block>
    </React.Fragment>
  );
};
export default Profile;
