import React, { useEffect } from "react";
import { useQuill } from "react-quilljs";

export const QuillComponent = () => {
  let placeholder = "Hello World!";
  const { quillRef } = useQuill({ placeholder });
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div ref={quillRef} />
    </div>
  );
};

export const QuillComponentMinimal = ({ setTextInput, quillContent }) => {
  const modules = {
    toolbar: [["bold", "italic"]],
  };

  const placeholder = "Masukkan teks disini ...";

  const formats = [
    "bold",
    "italic",
    "underline",
    "strike",
    "align",
    "list",
    "indent",
    "size",
    "header",
    "link",
    "image",
    "video",
    "color",
    "background",
    "clean",
  ];
  const { quill, quillRef } = useQuill({ modules, formats, placeholder });
  useEffect(() => {
    console.log(quillContent);
    if (quill) {
      quill.on("text-change", (delta, oldDelta, source) => {
        // console.log("Text change!");
        // console.log(quill.getText()); // Get text only
        // console.log(quill.getContents()); // Get delta contents
        // console.log(quill.root.innerHTML); // Get innerHTML using quill
        setTextInput(quillRef.current.firstChild.innerHTML);
      });
      quill.insertText(0, quillContent);
    }
  }, [quill, quillContent]);
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div ref={quillRef} />
    </div>
  );
};
