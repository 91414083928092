import { Card } from "reactstrap";
import KnobChart from "pages/home/KnobChart"

const TagChart = ({ title, value, total, chartConfiguration }) => {
  return (
    <Card className="card-full overflow-hidden">
      <div className="nk-ecwg nk-ecwg6 h-100">
        <div className="card-inner d-flex flex-column h-100">
          <div className="card-title-group">
            <div className="card-title w-100">
              <h6 className="title text-center">{title}</h6>
            </div>
          </div>
          <div className="data w-100 d-flex flex-column" style={{ flex: 1 }}>
            <div className="data-group w-100">
              <h6 className="text-center w-100 mb-2">Rp. {value}</h6>
            </div>
            <div className="h-100 d-flex align-items-center justify-content-center" style={{ margin: 'auto 0px' }}>
              <KnobChart data={chartConfiguration} type="full" centerText={chartConfiguration.centerText} />
            </div>
            <h6 className="text-center w-100 mt-2">Rp. {total}</h6>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default TagChart