import React, { useEffect, useState } from "react";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import { Icon } from "../../../../components/Component";
import { LinkList, LinkItem } from "../../../../components/links/Links";
import UserAvatar from "../../../../components/user/UserAvatar";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import { servicePutUsersOffline } from "services/users";
import { requestForToken, unsubscribeFirebaseToken } from "services/firebase";
import { socketIO } from "services/socket";

const User = ({ collapseSidebar, mouseEnter, contentMouseEnter }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  const [tokenFound, setTokenFound] = useState("");
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const toggle = () => setOpen((prevState) => !prevState);
  requestForToken(setTokenFound);
  const handleSignout = () => {
    unsubscribeFirebaseToken(cookies.SIDikey, tokenFound); 
    const resp = servicePutUsersOffline(cookies.SIDikey, cookies.id);
    socketIO.emit("admin", JSON.stringify({sign:true,type:"signOut", id:cookies.id, status:"OFFLINE"}));
    removeCookie("SIDikey");
  };

  const isExpand = () => {
    if (collapseSidebar) {
      if (mouseEnter || contentMouseEnter) {
        return true;
      }
    } else {
      return true;
    }
    if (open) {
      setOpen(false);
    }
    return false;
  };

  return (
    <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#toggle"
        className="dropdown-toggle "
        onClick={(ev) => {
          ev.preventDefault();
        }}
      >
        <div className="user-toggle">
          {cookies.avatar ? (
            <UserAvatar
              image={`${process.env.REACT_APP_S3_URL}${cookies.avatar}`}
              className="chat-media overflow-hidden"
            />
          ) : (
            <UserAvatar icon="user-alt-fill" className="chat-media overflow-hidden" />
          )}
          <div className={isExpand() ? "ml-2" : "ml-2 d-none"}>
            <div className="user-status">{cookies.role.name}</div>
            <span className="user-name text-truncate pr-2">{cookies.firstName + " " + cookies.lastName}</span>
            <Icon name="downward-ios" />
          </div>
        </div>
      </DropdownToggle>
      <DropdownMenu right className="border border-secondary">
        {/* <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
          <div className="user-card sm">
            <div className="user-avatar">
              <span>AB</span>
            </div>
            {
              cookies.avatar ?
                <UserAvatar image={`${process.env.REACT_APP_S3_URL}${cookies.avatar}`} className="chat-media overflow-hidden" />
              :
                <UserAvatar icon="user-alt-fill" className="chat-media overflow-hidden" />
            }
            <div className="user-info">
              <span className="lead-text">{cookies.firstName + " " + cookies.lastName}</span>
              <span className="sub-text">{cookies.email}</span>
            </div>
          </div>
        </div> */}
        <div className="dropdown-inner">
          {cookies.role.name === "SUPER ADMIN" ? (
            <LinkList>
              <LinkItem link={`${process.env.PUBLIC_URL}/system-settings-profile`} icon="user-alt" onClick={toggle}>
                View Profile
              </LinkItem>
              <LinkItem
                link={`${process.env.PUBLIC_URL}/system-settings-notification`}
                icon="setting-alt"
                onClick={toggle}
              >
                System Setting
              </LinkItem>
              <LinkItem link={`${process.env.PUBLIC_URL}/log-management`} icon="activity-alt" onClick={toggle}>
                Login Activity
              </LinkItem>
            </LinkList>
            ): 
            <LinkList>
                <LinkItem link={`${process.env.PUBLIC_URL}/system-settings-profile`} icon="user-alt" onClick={toggle}>
                  View Profile
                </LinkItem>
            </LinkList>
          }
        </div>
        <div className="dropdown-inner">
          <LinkList>
            <a href={`${process.env.PUBLIC_URL}/auth-login`} onClick={handleSignout}>
              <Icon name="signout"></Icon>
              <span>Sign Out</span>
            </a>
          </LinkList>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default User;
