import React, { useEffect, useContext } from "react";
import { FormGroup, Form, Modal, ModalBody, Collapse, Spinner, Badge } from "reactstrap";
import { Icon, Col, Button, RSelect } from "components/Component";
import { useForm } from "react-hook-form";
import getTextColor from "components/custom/getTextColor";
import { useState } from "react";
import { getTags } from "services/tags";
import { ProvinceDataList } from "pages/app/chat/ProvinceDataList";
import ReactDatePicker from "react-datepicker";
import { servicePutContact, serviceGetContact, serviceCreateContact } from "services/contact";
import Swal from "sweetalert2";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { servicePutChat } from "services/chats";
import { serviceGetProducts } from "services/productAstro";
import { serviceGetResolveByContact } from "services/crmResolve";
import { ChatContext } from "./ChatContext";

const ChatModalAddContact = (props) => {
  // const { token, chat,isModalAddContactOpen, setIsModalAddContactOpen } = props
  const { cookies, chatData, setChatData, modalDetail, modalToggle, AfterAssign } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [contactEntity, setContactEntity] = useState(null);
  const deptIdAstro = 2;
  const clientOption = [
    { value: "All", label: "All" },
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];


  const refreshContact = async () => {
    if(!chatData?.contactId){
      setContactEntity(false);
      setIsLoading(false);
      return;
    }
    const resp = await serviceGetContact(cookies.SIDikey, chatData.contactId);
    const temp = chatData;
    if(resp.status === 200){

      setContactEntity(resp.data);
      
      let phone1 = resp.data.phones[0]?.phone || chatData.phoneNumber;
      let phone2 = resp.data.phones[1]?.phone;

      if (!phone1.includes("+")) {
        phone1 = "+" + phone1;
      }
      setFormData({
        greeting: { value: resp.data.greeting, label: resp.data.greeting },
        name: resp.data.name,
        email1: resp.data?.emails[0]?.email || "",
        email2: resp.data?.emails[1]?.email || "",
        phone1: phone1,
        phone2: phone2,
        birthday: new Date(resp.data.birthday),
        province: resp.data.province, //select
        district: resp.data.district, //select
        subDistrict: resp.data.subDistrict, //select
        zipCode: resp.data.zipCode,
        address: resp.data.address,
        notes: resp.data.notes,
        tags: resp.data.tags,
        metadata: {
          aClub: {
            isClient: resp.data?.metadata?.aClub?.isClient || "",
            clientType: resp.data?.metadata?.aClub?.clientType || "",
            membership: resp.data?.metadata?.aClub?.membership || [],
            sales: resp.data?.metadata?.aClub?.sales || "",
          },
          cat: {
            isClient: resp.data?.metadata?.cat?.isClient || "",
          },
          aShop: {
            isClient: resp.data?.metadata?.aShop?.isClient || "",
            product: resp.data?.metadata?.aShop?.product || [],
          },
          orbi: {
            isClient: resp.data?.metadata?.orbi?.isClient || "",
            sales: resp.data?.metadata?.orbi?.sales || "",
          },
          uob: {
            isClient: resp.data?.metadata?.uob?.isClient || "",
            sales: resp.data?.metadata?.sales?.sales || "",
          },
          sucor: {
            isClient: resp.data?.metadata?.sucor?.isClient || "",
            sales: resp.data?.metadata?.sales?.sales || "",
          },
        },
        // clientAclub: resp.data.clientAclub,
        // clientType: resp.data.clientType,
        // membership: resp.data.membership,
        // salesAclub: resp.data.salesAclub,
        // clientCAT: resp.data.clientCAT,
        // clientAshop: resp.data.clientAshop,
        // productAshop: resp.data.productAshop,
        // clientOrbi: resp.data.memberOrbi,
        // salesOrbi: resp.data.salesOrbi,
        // clientUOB: resp.data.clientUOB,
        // salesUOB: resp.data.salesUOB,
        // clientSucor: resp.data.clientSucor,
        // salesSucor: resp.data.salesSucor,
        createdAt: resp.data.createdAt,
        updatedAt: resp.data.updatedAt,
        createdBy: resp.data.createdBy?.firstName
          ? resp.data.createdBy?.firstName + " " + resp.data.createdBy?.lastName
          : "Admin",
        updatedBy: resp.data.updatedBy?.firstName
          ? resp.data.updatedBy?.firstName + " " + resp.data.updatedBy?.lastName
          : "Has not been updated.",
      });
      
      temp.contact = resp
      setChatData(temp);
      setIsLoading(false);
    }

  };

  const membershipOption = [
    { value: "All", label: "All" },
    { value: "Futures", label: "Futures" },
    { value: "Stocks", label: "Stocks" },
  ];

  const clientTypeOption = [
    { value: "All", label: "All" },
    { value: "Loyal", label: "Loyal" },
    { value: "New", label: "New" },
    { value: "Basic Client", label: "Basic Client" },
  ];

  const tempSales = [
    { value: "Budi", label: "Budi" },
    { value: "Yuki", label: "Yuki" },
    { value: "Ahmad", label: "Ahmad" },
    { value: "Raya", label: "Raya" },
  ];

  const [tagOption, setTagOption] = useState([]);
  const [isOpen, setIsOpen] = useState([]);
  const provinceList = ProvinceDataList;
  const [districtList, setCityList] = useState([]);
  const [subDistrictList, setDistrictList] = useState([]);
  const [dataProducts, setDataProducts] = useState([]);
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "IDR",
  });

  const [clientDept, setClientDept] = useState([
    {isClient:false, salesId:'', products:[]},
    {isClient:false, salesId:'', products:[]},
    {isClient:false, salesId:'', products:[]},
    {isClient:false, salesId:'', products:[]},
    {isClient:false, salesId:'', products:[]},
    {isClient:false, salesId:'', products:[]},
  ]);

  const [changedForm, setChangedForm] = useState({});
  const [formData, setFormData] = useState({});

  // console.log('contact',resp.data);
  const titleOption = [
    { value: "Kak", label: "Kak" },
    { value: "Bapak", label: "Bapak" },
    { value: "Ibu", label: "Ibu" },
  ];

  const openAccordion = (id) => {
    if (!checkOpen(id)) {
      const allOpen = [...isOpen];
      allOpen.push(id);
      setIsOpen(allOpen);
    } else {
      closeAccordion(id);
    }
  };

  const refreshDataProducts = async () => {
    const response = await serviceGetProducts(cookies.AstroKey, deptIdAstro);

    if (response.status === 200) {
      setDataProducts(response.data.data);
    }
  };

  const checkOpen = (id) => {
    return isOpen.includes(id);
  };

  const closeAccordion = (id) => {
    const allOpen = [...isOpen];
    const index = allOpen.indexOf(id);
    if (index !== -1) {
      allOpen.splice(index, 1);
      setIsOpen(allOpen);
    }
  };

  const refreshTag = async () => {
    const responseGetTags = await getTags(cookies.SIDikey);
    if (new RegExp(/20+/).test(responseGetTags.status)) {
      const resultData = responseGetTags.data;
      const finalData = [];
      resultData.data.forEach((tag) => {
        const tempData = {
          value: tag.id,
          label: tag.name,
          color: tag.color,
        };
        finalData.push(tempData);
      });
      setTagOption(finalData);
    } else {
      console.log(responseGetTags);
    }
  };

  const refreshDept = async () => {
    if(!chatData?.contactId){
      return;
    }
    const response = await serviceGetResolveByContact(cookies.SIDikey, chatData?.contactId);
    if (new RegExp(/20+/).test(response.status)) {
      const respFinal = response.data.data;
      const temp = clientDept;
      for (let i = 0; i < respFinal.length; i++) {
        temp[respFinal[i].pc].isClient = true;
        temp[respFinal[i].pc].products.push(JSON.parse(respFinal[i].productDescription)[0]);
      }
      setClientDept(temp);
    }
  };

  useEffect(() => {

    setIsLoading(true);
    if(modalDetail){
      refreshTag();
      refreshDataProducts();
      refreshContact();
      refreshDept();
      if (chatData) {
  
        setFormData({
          greeting: { value: "Kak", label: "Kak" },
          name: null,
          email1: null,
          email2: null,
          phone1: "+" + chatData.customerPhone,
          phone2: null,
          birthday: null,
          province: null,
          district: null,
          subDistrict: null,
          zipCode: null,
          address: null,
          notes: null,
          tags: null,
          metadata: {
            aClub: {
              isClient: "",
              clientType: "",
              membership: [],
              sales: "",
            },
            cat: {
              isClient: "",
            },
            aShop: {
              isClient: "",
              product: "",
            },
            orbi: {
              isClient: "",
              sales: "",
            },
            uob: {
              isClient: "",
              sales: "",
            },
            sucor: {
              isClient: "",
              sales: "",
            },
          },
          // clientAclub: null,
          // clientType: null,
          // membership: null,
          // salesAclub: null,
          // clientCAT: null,
          // clientAshop: null,
          // productAshop: null,
          // clientOrbi: null,
          // salesOrbi: null,
          // clientUOB: null,
          // salesUOB: null,
          // clientSucor: null,
          salesSucor: null,
          createdAt: null,
          updatedAt: null,
          createdBy: null,
          updatedBy: null,
        });
      }
    }

    setIsLoading(false);

  }, []);

  const handleFormObjectChange = (newValue, name) => {

    let changedName = name;
    let changedValue = newValue;

    if (
      name.includes("aClub") ||
      name.includes("cat") ||
      name.includes("aShop") ||
      name.includes("orbi") ||
      name.includes("uob") ||
      name.includes("sucor")
    ) {
      const [departmentName,fieldName] = name.split(".");
      // const splitName = name.split(".");
      // const [departmentName] = splitName[0];
      // const fieldName = splitName[1];

      setChangedForm((current) => {
        return {
          ...current,
          metadata: {
            ...formData.metadata,
            [departmentName]: {
              ...formData.metadata[departmentName],
              [fieldName]: newValue,
            },
          },
        };
      });

      setFormData((current) => {
        return {
          ...current,
          metadata: {
            ...current.metadata,
            [departmentName]: {
              ...current.metadata[departmentName],
              [fieldName]: newValue,
            },
          },
        };
      });

    } else {
      setFormData((current) => {
        return {
          ...current,
          [name]: newValue,
        };
      });

      if (name.includes("email")) {
        changedName = "emails";
        if (formData.email1) {
          if (name === "email2") {
            changedValue = [{ email: formData.email1 }, { email: newValue }];
          }else{
            changedValue = [{ email: newValue }];
          }
        } else {
          changedValue = [{ email: newValue }];
        }
      } else if (name.includes("phone2")) {
        changedName = "phones";
        changedValue = [{ phone: formData.phone1 }, { phone: newValue }];
      }
      setChangedForm((current) => {
        return {
          ...current,
          [changedName]: changedValue,
          isSaved: "S"
        };
      });
    }
  };

  const { isSavedArray } = useContext(ChatContext);
  const [isSaved, setIsSaved] = isSavedArray;
  const handleSubmitForm = async (e) => {
    e.preventDefault();
    if (contactEntity) {
      const resp = await servicePutContact(cookies.SIDikey, chatData.contactId, changedForm);
      console.log(changedForm)
      if (resp.status === 202) {
        Swal.fire("Berhasil Edit contact", "Edit Contact", "success");
        const temp = {...chatData};
        if(chatData.name !== formData.name || chatData.name !== changedForm.name ){
          if(changedForm.hasOwnProperty("name")){
            temp.name =  changedForm.name;
            setChatData({...temp});
            await servicePutChat(cookies.SIDikey, chatData.id,{name:changedForm.name});
            AfterAssign()
          }else{
            temp.name =  formData.name;
            setChatData(temp);
            await servicePutChat(cookies.SIDikey, chatData.id,{name:formData.name});
            AfterAssign()
          }
          // setIsSaved((current) => {
          //   return current.map(item => 
          //     item.id === chatData.contactId 
          //     ? {...item, isSaved: "S"} 
          //     : item
          //   );
          // });
        }
      } else {
        Swal.fire("Gagal Edit Contact", resp.message, "error");
        console.log(resp);
      }
    } else {
      const resp = await serviceCreateContact( cookies.SIDikey, {
        ...changedForm,
        phones: [{ phone: "+" + chatData.customerPhone }],
      });
      if (resp.status === 201) {
        Swal.fire("Berhasil Tambah contact", "Add Contact", "success");
        // const contact = await serviceGetContact(resp.data.id, cookies.SIDikey);
        const contact = resp.data;
        await servicePutChat(cookies.SIDikey, chatData.id, { contactId: contact.id });
        const newChat = {...chatData};
        newChat.contact = contact;
        newChat.name = contact.name;
        newChat.contactId = contact.id;
        setChatData((chat)=>newChat);
        AfterAssign()
       
      } else {
        Swal.fire("Gagal Edit Contact", resp.message, "error");
        console.log(resp);
      }
      console.log(changedForm);
    }
    modalToggle();
  };

  return (
      <React.Fragment>
        <Modal
          isOpen={modalDetail}
          backdrop="static"
          keyboard={false}
          scrollable={true}
          toggle={() => modalToggle()}
          className="modal-dialog-centered"
          size="lg"
        >

          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                modalToggle();
                setFormData({});
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
          {!isLoading ?
            <React.Fragment>
            <div className="p-2">
              <h5 className="title">Detail Kontak</h5>
              <div className="mt-4">
                <Form className="row gy-2" onSubmit={(e) => handleSubmitForm(e)}>
                  <Col md="4" lg="4">
                    <FormGroup>
                      <label className="form-label">Sapaan</label>
                      <RSelect
                        name="greeting"
                        options={titleOption}
                        defaultValue={formData.greeting}
                        onChange={(e) => handleFormObjectChange(e.target, "greeting")}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="8" lg="8">
                    <FormGroup>
                      <label className="form-label">Nama</label>
                      <input
                        name="name"
                        className="form-control"
                        type="text"
                        defaultValue={formData.name}
                        onChange={(e) => handleFormObjectChange(e.target.value, "name")}
                      />
                    </FormGroup>
                  </Col>

                  <Col md="12" lg="12">
                    <label className="form-label">Tanggal Ulang Tahun</label>
                    <ReactDatePicker
                      className="form-control date-picker"
                      showYearDropdown
                      scrollableYearDropdown
                      dropdownMode="select"
                      selected={formData.birthday}
                      onChange={(date) => handleFormObjectChange(date, "birthday")}
                      name="birthday"
                    />
                  </Col>

                  <Col md="6" lg="6">
                    <FormGroup>
                      <label className="form-label">Phone 1</label>
                      <PhoneInput
                        defaultCountry="ID"
                        name="phone1"
                        className=""
                        value={formData.phone1}
                        onChange={(e) => handleFormObjectChange(e.target.value, "phone1")}
                        disabled={formData.phone1 ? true : false}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="6">
                    <FormGroup>
                      <label className="form-label">Phone 2</label>
                      <PhoneInput
                        name="phone2"
                        defaultCountry="ID"
                        value={formData.phone2}
                        onChange={(e) => handleFormObjectChange(e, "phone2")}
                      />
                    </FormGroup>
                  </Col>

                  <Col md="6" lg="6">
                    <FormGroup>
                      <label className="form-label">Email 1</label>
                      <input
                        name="email1"
                        className="form-control"
                        type="text"
                        defaultValue={formData.email1}
                        onChange={(e) => handleFormObjectChange(e.target.value, "email1")}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="6">
                    <FormGroup>
                      <label className="form-label">Email 2</label>
                      <input
                        name="email2"
                        className="form-control"
                        type="text"
                        defaultValue={formData.email2}
                        onChange={(e) => handleFormObjectChange(e.target.value, "email2")}
                      />
                    </FormGroup>
                  </Col>

                  <Col md="6" lg="6">
                    <FormGroup>
                      <label className="form-label">Provinsi</label>
                      <input
                        name="province"
                        className="form-control"
                        type="text"
                        defaultValue={formData.province}
                        onChange={(e) => handleFormObjectChange(e.target.value, "province")}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="6">
                    <FormGroup>
                      <label className="form-label">Kota</label>
                      <input
                        name="city"
                        className="form-control"
                        type="text"
                        defaultValue={formData.district}
                        onChange={(e) => handleFormObjectChange(e.target.value, "district")}
                      />
                    </FormGroup>
                  </Col>

                  <Col md="6" lg="6">
                    <FormGroup>
                      <label className="form-label">Kecamatan</label>
                      <input
                        name="district"
                        className="form-control"
                        type="text"
                        defaultValue={formData.subDistrict}
                        onChange={(e) => handleFormObjectChange(e.target.value, "subDistrict")}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="6">
                    <FormGroup>
                      <label className="form-label">Kode Pos</label>
                      <input
                        name="zipCode"
                        className="form-control"
                        type="number"
                        defaultValue={formData.zipCode}
                        onChange={(e) => handleFormObjectChange(e.target.value, "zipCode")}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12" lg="12">
                    <FormGroup>
                      <label className="form-label">Detail Alamat</label>
                      <textarea
                        name="address"
                        className="form-control no-resize"
                        defaultValue={formData.address}
                        onChange={(e) => handleFormObjectChange(e.target.value, "address")}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12" lg="12">
                    <FormGroup>
                      <label className="form-label">Catatan</label>
                      <textarea
                        name="notes"
                        className="form-control no-resize "
                        defaultValue={formData.notes}
                        onChange={(e) => handleFormObjectChange(e.target.value, "notes")}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12" lg="12">
                    {/* TAG KOMENTAR DULU Sebentar */}
                    {/* <FormGroup>
                      <label className="form-label">Tag</label>
                      <RSelect
                        name="tags"
                        options={tagOption}
                        isMulti
                        defaultValue={formData.tags}
                        styles={{
                          multiValue: (styles, { data }) => {
                            const color = data.color;
                            return {
                              ...styles,
                              backgroundColor: color + " !important",
                              borderRadius: "1.5rem !important",
                            };
                          },
                          multiValueLabel: (styles, { data }) => {
                            const color = getTextColor(data.color);
                            return {
                              ...styles,
                              color: color,
                            };
                          },
                        }}
                      />
                    </FormGroup> */}
                  </Col>
                  <Col md="12" lg="12">
                    <div className="accordion">
                      <div className="accordion-item">
                        <div className="accordion-head" onClick={() => openAccordion("A-Club")}>
                          <h6 className="title">A-Club</h6>
                          <span className="accordion-icon"></span>
                        </div>
                        <Collapse className="accordion-body" isOpen={checkOpen("A-Club")}>
                          <div className="accordion-inner row gy-2">
                            <Col md="4" lg="4">
                              <FormGroup>
                                <label className="form-label">Client A-Club</label>
                                <RSelect
                                  name="clientAClub"
                                  options={clientOption}
                                  defaultValue={{
                                    value: formData.metadata?.aClub?.isClient || "",
                                    label: formData.metadata?.aClub?.isClient || "",
                                  }}
                                  onChange={(e) => handleFormObjectChange(e.value, "aClub.isClient")}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="8" lg="8">
                              <FormGroup>
                                <label className="form-label">Client Type</label>
                                <RSelect
                                  name="clientType"
                                  options={clientTypeOption}
                                  defaultValue={{
                                    value: formData.metadata?.aClub?.clientType || "",
                                    label: formData.metadata?.aClub?.clientType || "",
                                  }}
                                  onChange={(e) => handleFormObjectChange(e.value, "aClub.clientType")}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="7" lg="7">
                              <FormGroup>
                                <label className="form-label">Membership</label>
                                <RSelect
                                  isMulti
                                  name="membership"
                                  options={membershipOption}
                                  defaultValue={formData.metadata?.aClub?.membership || []}
                                  onChange={(e) => handleFormObjectChange(e, "aClub.membership")}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="5" lg="5">
                              <FormGroup>
                                <label className="form-label">Sales A-Club</label>
                                <RSelect
                                  name="salesAClub"
                                  options={tempSales}
                                  defaultValue={{
                                    value: formData.metadata?.aClub?.sales || "",
                                    label: formData.metadata?.aClub?.sales || "",
                                  }}
                                  onChange={(e) => handleFormObjectChange(e.value, "aClub.sales")}
                                />
                              </FormGroup>
                            </Col>
                          </div>
                        </Collapse>
                      </div>
                      <div className="accordion-item">
                        <div className="accordion-head collapsed" onClick={() => openAccordion("CAT")}>
                          <h6 className="title">CAT</h6>
                          <span className="accordion-icon"></span>
                        </div>
                        <Collapse className="accordion-body" isOpen={checkOpen("CAT")}>
                          <div className="accordion-inner row gy-2">
                            <Col md="12" lg="12">
                              <FormGroup>
                                <label className="form-label">Client CAT</label>
                                <RSelect
                                  name="clientCAT"
                                  options={clientOption}
                                  defaultValue={{
                                    value: formData.metadata?.cat?.isClient || "",
                                    label: formData.metadata?.cat?.isClient || "",
                                  }}
                                  onChange={(e) => handleFormObjectChange(e.value, "cat.isClient")}
                                />
                              </FormGroup>
                            </Col>
                          </div>
                        </Collapse>
                      </div>
                      <div className="accordion-item">
                        <div className="accordion-head collapsed" onClick={() => openAccordion("A-Shop")}>
                          <h6 className="title">A-Shop</h6>
                          <span className="accordion-icon"></span>
                        </div>
                        <Collapse className="accordion-body" isOpen={checkOpen("A-Shop")}>
                        <div className="accordion-inner row gy-2">
                          <Col md="12" lg="12">
                            <FormGroup>
                              <label className="form-label mr-2">Client A-Shop</label>
                              {clientDept.at(2).isClient ? <Badge color="success">True</Badge> :
                              <Badge color="danger">False</Badge>}
                              {/* <RSelect name="clientAShop" 
                                options={clientOption} 
                                defaultValue={formData.clientAshop} 
                                onChange={(e) => handleFormObjectChange(e.target.value,'clientAshop')}/> */}
                            </FormGroup>
                          </Col>
                          <Col md="12" lg="12">
                            <FormGroup>
                              <label className="form-label">Product A-Shop</label>
                              <textarea
                                className="form-control no-resize"
                                name="productAshop"
                                disabled
                                value={clientDept.at(2).products ? clientDept.at(2).products.map(item => {
                                  return `${item.id} - ${item.productName}`}).join('\n'):''}
                                // onChange={(e) => handleFormObjectChange(e.target.value,'productAshop')}
                              />
                            </FormGroup>
                          </Col>
                        </div>
                      </Collapse>
                      </div>
                      <div className="accordion-item">
                        <div className="accordion-head collapsed" onClick={() => openAccordion("Orbi")}>
                          <h6 className="title">Orbi</h6>
                          <span className="accordion-icon"></span>
                        </div>
                        <Collapse className="accordion-body" isOpen={checkOpen("Orbi")}>
                          <div className="accordion-inner row gy-2">
                            <Col md="7" lg="7">
                              <FormGroup>
                                <label className="form-label">Client Orbi</label>
                                <RSelect
                                  name="clientOrbi"
                                  options={clientOption}
                                  defaultValue={{
                                    value: formData.metadata?.orbi?.isClient || "",
                                    label: formData.metadata?.orbi?.isClient || "",
                                  }}
                                  onChange={(e) => handleFormObjectChange(e.value, "orbi.isClient")}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="5" lg="5">
                              <FormGroup>
                                <label className="form-label">Sales Orbi</label>
                                <RSelect
                                  name="salesOrbi"
                                  options={tempSales}
                                  defaultValue={{
                                    value: formData.metadata?.orbi?.sales || "",
                                    label: formData.metadata?.orbi?.sales || "",
                                  }}
                                  onChange={(e) => handleFormObjectChange(e.value, "orbi.sales")}
                                />
                              </FormGroup>
                            </Col>
                          </div>
                        </Collapse>
                      </div>
                      <div className="accordion-item">
                        <div className="accordion-head collapsed" onClick={() => openAccordion("UOB")}>
                          <h6 className="title">UOB</h6>
                          <span className="accordion-icon"></span>
                        </div>
                        <Collapse className="accordion-body" isOpen={checkOpen("UOB")}>
                          <div className="accordion-inner row gy-2">
                            <Col md="7" lg="7">
                              <FormGroup>
                                <label className="form-label">Client UOB</label>
                                <RSelect
                                  name="clientUOB"
                                  options={clientOption}
                                  defaultValue={{
                                    value: formData.metadata?.uob?.isClient || "",
                                    label: formData.metadata?.uob?.isClient || "",
                                  }}
                                  onChange={(e) => handleFormObjectChange(e.value, "uob.isClient")}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="5" lg="5">
                              <FormGroup>
                                <label className="form-label">Sales UOB</label>
                                <RSelect
                                  name="salesUOB"
                                  options={tempSales}
                                  defaultValue={{
                                    value: formData.metadata?.uob?.sales || "",
                                    label: formData.metadata?.uob?.sales || "",
                                  }}
                                  onChange={(e) => handleFormObjectChange(e.value, "uob.sales")}
                                />
                              </FormGroup>
                            </Col>
                          </div>
                        </Collapse>
                      </div>
                      <div className="accordion-item">
                        <div className="accordion-head collapsed" onClick={() => openAccordion("Sucor")}>
                          <h6 className="title">Sucor</h6>
                          <span className="accordion-icon"></span>
                        </div>
                        <Collapse className="accordion-body" isOpen={checkOpen("Sucor")}>
                          <div className="accordion-inner row gy-2">
                            <Col md="7" lg="7">
                              <FormGroup>
                                <label className="form-label">Client Sucor</label>
                                <RSelect
                                  name="clientSucor"
                                  options={clientOption}
                                  defaultValue={{
                                    value: formData.metadata?.sucor?.isClient || "",
                                    label: formData.metadata?.sucor?.isClient || "",
                                  }}
                                  onChange={(e) => handleFormObjectChange(e.value, "sucor.isClient")}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="5" lg="5">
                              <FormGroup>
                                <label className="form-label">Sales Sucor</label>
                                <RSelect
                                  name="salesSucor"
                                  options={tempSales}
                                  defaultValue={{
                                    value: formData.metadata?.sucor?.sales || "",
                                    label: formData.metadata?.sucor?.sales || "",
                                  }}
                                  onChange={(e) => handleFormObjectChange(e.value, "sucor.sales")}
                                />
                              </FormGroup>
                            </Col>
                          </div>
                        </Collapse>
                      </div>
                    </div>
                  </Col>
                  <Col md="12" lg="12" className="border-top pt-2 pb-4">
                    <p className="text-soft">{"Created at " + formData.createdAt + " by " + formData.createdBy}</p>
                    <p className="text-soft">{"Modify at " + formData.updatedAt + " by " + formData.updatedBy}</p>
                  </Col>
                  <Col md="6" lg="6">
                    <Button type="button" color="gray" outline onClick={() => modalToggle()} className="btn btn-block">
                      Batal
                    </Button>
                  </Col>
                  <Col md="6" lg="6">
                    <Button type="submit" color="primary" className="btn btn-block">
                      Simpan
                    </Button>
                  </Col>
                </Form>
              </div>
            </div>
            </React.Fragment>  

            : <Spinner></Spinner>
          }

          </ModalBody>
        </Modal>
      </React.Fragment>

  );
};

export default ChatModalAddContact;
