import React, { useState, useEffect } from "react";
import { Card, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
// import { tagList, ticketSystemData } from './homeData'
import { BarChartExample, Icon, Row, Col } from "components/Component";
import TagChart from "pages/home/TagChart";
import { useCookies } from "react-cookie";
import moment from "moment";
import {
  getDashboardTicketSystem,
  getDashboardTotalSalesTarget,
  getAstroTotalSalesTarget
} from "../../services/dashboard";

const TicketSystem = (props) => {
  const { selectedDepartment } = props
  const [cookies] = useCookies(["user"]);
  const [dataTicketSystem, setDataTicketSystem] = useState([]);
  const [dataTotalSalesTarget, setDataTotalSalesTarget] = useState([]);
  const [targetCard1, setTargetCard1] = useState(0);
  const [targetCard2, setTargetCard2] = useState(0);
  const [targetCard3, setTargetCard3] = useState(0);
  const [targetCard4, setTargetCard4] = useState(0);
  const [targetCard5, setTargetCard5] = useState(0);
  const [dataChart, setDataChart] = useState(
    {
      labels: [],
      stacked: true,
      dataUnit: "USD",
      datasets: []
    }
  );
  const [datePeriod, setDatePeriod] = useState("MONTHLY");
  const roleFunctions = JSON.parse(localStorage.getItem("permission"))["dashboard"].access || [];


  const getDataTicketSystem = async (datePeriod) => {
    const responseTicketSystem = await getDashboardTicketSystem(cookies.SIDikey, datePeriod, selectedDepartment.value, roleFunctions);
    const responseTotalSalesTarget = await getDashboardTotalSalesTarget(cookies.SIDikey, datePeriod);
    
    setDataTicketSystem(responseTicketSystem.data)
    setDataTotalSalesTarget(responseTotalSalesTarget.data)
    
    responseTotalSalesTarget.data.forEach(async (item,i) => {
      const responseAstroTotalSalesTarget = await getAstroTotalSalesTarget(cookies.AstroKey, item.pc, moment().format('YYYY'), moment().format('MM'))
      if(i===0){
        setTargetCard1(responseAstroTotalSalesTarget.data.data.target ? responseAstroTotalSalesTarget.data.data.target : '0')
      }
      if(i===1){
        setTargetCard2(responseAstroTotalSalesTarget.data.data.target ? responseAstroTotalSalesTarget.data.data.target : '0')
      }
      if(i===2){
        setTargetCard3(responseAstroTotalSalesTarget.data.data.target ? responseAstroTotalSalesTarget.data.data.target : '0')
      }
      if(i===3){
        setTargetCard4(responseAstroTotalSalesTarget.data.data.target ? responseAstroTotalSalesTarget.data.data.target : '0')
      }
      if(i===4){
        setTargetCard5(responseAstroTotalSalesTarget.data.data.target ? responseAstroTotalSalesTarget.data.data.target : '0')
      }
    })
    const arrDataSet = []
    responseTicketSystem.data.datasets.forEach(item => {
      arrDataSet.push({
        label: item.department.name,
        backgroundColor: item.department.color,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
        data: item.data
      })
    })
    const arrLabel = []
    responseTicketSystem.data.label.forEach(item => {
      if (datePeriod === 'DAILY'){
        arrLabel.push(moment(item).format('ll'))
      }else if(datePeriod === 'WEEKLY'){
        arrLabel.push(moment(item).format('MMM DD'))
      }else if(datePeriod === 'MONTHLY'){
        arrLabel.push(moment(item).format('MMM'))
      }else if(datePeriod === 'YEARLY'){
        arrLabel.push(moment(item).format('YYYY'))
      }
    })
    setDataChart({
      labels: arrLabel,
      stacked: true,
      dataUnit: "USD",
      datasets: arrDataSet
    })
  };

  useEffect(() => {
    getDataTicketSystem(datePeriod)
  }, [selectedDepartment]);

  const handleDatePeriod = (val) => {
    setDatePeriod(val)
    getDataTicketSystem(val)
  }

  return (
    <React.Fragment>
      <Card className="p-5">
        <div>
        <div className="nk-ecwg nk-ecwg8 h-100">
          <div className="card-inner">
            <div className="card-title-group mb-3">
              <div className="card-title">
                <h6 className="title">Ticket System</h6>
              </div>
              <div className="card-tools">
                <UncontrolledDropdown>
                  <DropdownToggle
                    tag="a"
                    href="#toggle"
                    onClick={(ev) => ev.preventDefault()}
                    className="link link-light link-sm dropdown-indicator"
                  >
                    {
                      datePeriod === 'DAILY' ?
                        'Daily'
                      : datePeriod === 'WEEKLY' ?
                        'Weekly'
                      : datePeriod === 'MONTHLY' ?
                        'Monthly'
                      : datePeriod === 'YEARLY' ?
                        'Annually'
                      : null
                    }
                  </DropdownToggle>
                  <DropdownMenu right className="dropdown-menu-sm">
                    <ul className="link-list-opt no-bdr">
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#dropdown"
                          onClick={(ev) => {
                            ev.preventDefault();
                            handleDatePeriod('DAILY');
                          }}
                          className={datePeriod === "DAILY" ? "active" : ""}
                        >
                          <span>Daily</span>
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#dropdown"
                          onClick={(ev) => {
                            ev.preventDefault();
                            handleDatePeriod('WEEKLY');
                          }}
                          className={datePeriod === "WEEKLY" ? "active" : ""}
                        >
                          <span>Weekly</span>
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#dropdown"
                          onClick={(ev) => {
                            ev.preventDefault();
                            handleDatePeriod('MONTHLY');
                          }}
                          className={datePeriod === "MONTHLY" ? "active" : ""}
                        >
                          <span>Monthly</span>
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#dropdown"
                          onClick={(ev) => {
                            ev.preventDefault();
                            handleDatePeriod('YEARLY');
                          }}
                          className={datePeriod === "YEARLY" ? "active" : ""}
                        >
                          <span>Annually</span>
                        </DropdownItem>
                      </li>
                    </ul>
                  </DropdownMenu>
                </UncontrolledDropdown>
                {/* <div className="btn btn-round btn-icon btn-light dropdown-toggle ml-2" style={{ backgroundColor: 'unset', border: 'unset' }}>
                  <Icon name="download-cloud"></Icon>
                </div> */}
              </div>
            </div>
            <ul className="nk-ecwg8-legends">
              {dataTicketSystem?.datasets?.map((item, index) => (
                <li key={index}>
                  <div className="title">
                    <span className="dot dot-lg sq" style={{ background: item.department.color }}></span>
                    <span>{item.department.name}</span>
                  </div>
                </li>
              ))}
            </ul>
            <div className="nk-ecwg8-ck">
              <BarChartExample stacked data={dataChart} />
            </div>
            <div className="chart-label-group pl-5">
              <div className="chart-label">
                {
                  datePeriod === 'DAILY' ?
                    moment(dataTicketSystem?.label?.[0]).format('DD MMM YYYY')
                  : datePeriod === 'WEEKLY' ?
                    moment(dataTicketSystem?.label?.[0]).format('MMM YYYY')
                  : datePeriod === 'MONTHLY' ?
                    moment(dataTicketSystem?.label?.[0]).format('MMM YYYY')
                  : datePeriod === 'YEARLY' ?
                    moment(dataTicketSystem?.label?.[0]).format('YYYY')
                  : null
                }
              </div>
              <div className="chart-label">
                {
                  datePeriod === 'DAILY' ?
                    moment(dataTicketSystem?.label?.[dataTicketSystem?.label?.length-1]).format('DD MMM YYYY')
                  : datePeriod === 'WEEKLY' ?
                    moment(dataTicketSystem?.label?.[dataTicketSystem?.label?.length-1]).format('MMM YYYY')
                  : datePeriod === 'MONTHLY' ?
                    moment(dataTicketSystem?.label?.[dataTicketSystem?.label?.length-1]).format('MMM YYYY')
                  : datePeriod === 'YEARLY' ?
                    moment(dataTicketSystem?.label?.[dataTicketSystem?.label?.length-1]).format('YYYY')
                  : null
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr style={{ borderTop: "2px dashed" }}></hr>
      <Col xxl="12">
        <h2 className="text-center" style={{ marginTop: 28, marginBottom: 28 }}>Total Sales per-PC vs Target</h2>
      </Col>
      {/* <Col sm={12} md lg={12} xl xxl={12}>
        <Row>
          <Col sm={1} md lg={1} xl xxl={1}></Col>
          <Col sm={2} md lg={2} xl xxl={2}>
            <TagChart
              title={'Test'}
              value={'1000000'.toLocaleString().replaceAll(",", ".")}
              total={'1000000'.toLocaleString().replaceAll(",", ".")}
              chartConfiguration={
                {
                  chartConfiguration: {
                    labels: ["", "", ""],
                    dataUnit: "BTC",
                    legend: false,
                    datasets: [
                      {
                        borderColor: "transparent",
                        backgroundColor: ["#0364FF", "#d9e5f7"],
                        data: [200000000, 40000000],
                      },
                    ],
                    centerText: "83%",
                    total: 240000000,
                  }
                }
              }
            />
          </Col>
          <Col sm={2} md lg={2} xl xxl={2}>
            <TagChart
              title={'Test'}
              value={'1000000'.toLocaleString().replaceAll(",", ".")}
              total={'1000000'.toLocaleString().replaceAll(",", ".")}
              chartConfiguration={
                {
                  chartConfiguration: {
                    labels: ["", "", ""],
                    dataUnit: "BTC",
                    legend: false,
                    datasets: [
                      {
                        borderColor: "transparent",
                        backgroundColor: ["#0364FF", "#d9e5f7"],
                        data: [200000000, 40000000],
                      },
                    ],
                    centerText: "83%",
                    total: 240000000,
                  }
                }
              }
            />
          </Col>
          <Col sm={2} md lg={2} xl xxl={2}>
            <TagChart
              title={'Test'}
              value={'1000000'.toLocaleString().replaceAll(",", ".")}
              total={'1000000'.toLocaleString().replaceAll(",", ".")}
              chartConfiguration={
                {
                  chartConfiguration: {
                    labels: ["", "", ""],
                    dataUnit: "BTC",
                    legend: false,
                    datasets: [
                      {
                        borderColor: "transparent",
                        backgroundColor: ["#0364FF", "#d9e5f7"],
                        data: [200000000, 40000000],
                      },
                    ],
                    centerText: "83%",
                    total: 240000000,
                  }
                }
              }
            />
          </Col>
          <Col sm={2} md lg={2} xl xxl={2}>
            <TagChart
              title={'Test'}
              value={'1000000'.toLocaleString().replaceAll(",", ".")}
              total={'1000000'.toLocaleString().replaceAll(",", ".")}
              chartConfiguration={
                {
                  chartConfiguration: {
                    labels: ["", "", ""],
                    dataUnit: "BTC",
                    legend: false,
                    datasets: [
                      {
                        borderColor: "transparent",
                        backgroundColor: ["#0364FF", "#d9e5f7"],
                        data: [200000000, 40000000],
                      },
                    ],
                    centerText: "83%",
                    total: 240000000,
                  }
                }
              }
            />
          </Col>
          <Col sm={2} md lg={2} xl xxl={2}>
            <TagChart
              title={'Test'}
              value={'1000000'.toLocaleString().replaceAll(",", ".")}
              total={'1000000'.toLocaleString().replaceAll(",", ".")}
              chartConfiguration={
                {
                  chartConfiguration: {
                    labels: ["", "", ""],
                    dataUnit: "BTC",
                    legend: false,
                    datasets: [
                      {
                        borderColor: "transparent",
                        backgroundColor: ["#0364FF", "#d9e5f7"],
                        data: [200000000, 40000000],
                      },
                    ],
                    centerText: "83%",
                    total: 240000000,
                  }
                }
              }
            />
          </Col>
          <Col sm={1} md lg={1} xl xxl={1}></Col>
        </Row>
      </Col> */}
      <Col className="d-flex justify-content-between">
        {/* Card 1 */}
        <Row className="mx-2">
          <TagChart
            title={dataTotalSalesTarget[0]?.name}
            value={
              Number(
                datePeriod === 'DAILY' ?
                  targetCard1/30
                : datePeriod === 'WEEKLY' ?
                  targetCard1/4
                : datePeriod === 'YEARLY' ?
                  targetCard1*12
                : targetCard1
              ).toLocaleString().replaceAll(",", ".")
            }
            total={dataTotalSalesTarget[0]?.total.toLocaleString().replaceAll(",", ".")}
            chartConfiguration={
              {
                labels: ["", "", ""],
                dataUnit: "BTC",
                legend: false,
                datasets: [
                  {
                    borderColor: "transparent",
                    backgroundColor: ["#0364FF", "#d9e5f7"],
                    data: [
                      datePeriod === 'DAILY' ?
                        Math.floor((dataTotalSalesTarget[0]?.total/(targetCard1/30))*100)
                      : datePeriod === 'WEEKLY' ?
                        Math.floor((dataTotalSalesTarget[0]?.total/(targetCard1/4))*100)
                      : datePeriod === 'YEARLY' ?
                        Math.floor((dataTotalSalesTarget[0]?.total/(targetCard1*12))*100)
                      : Math.floor((dataTotalSalesTarget[0]?.total/targetCard1)*100)
                      , 100],
                  },
                ],
                centerText: 
                  datePeriod === 'DAILY' ?
                    `${dataTotalSalesTarget && targetCard1 && targetCard1 !== '0' ? Math.floor((dataTotalSalesTarget[0]?.total/(targetCard1/30))*100) : 0}%`
                  : datePeriod === 'WEEKLY' ?
                    `${dataTotalSalesTarget && targetCard1 && targetCard1 !== '0' ? Math.floor((dataTotalSalesTarget[0]?.total/(targetCard1/4))*100) : 0}%`
                  : datePeriod === 'YEARLY' ?
                    `${dataTotalSalesTarget && targetCard1 && targetCard1 !== '0' ? Math.floor((dataTotalSalesTarget[0]?.total/(targetCard1*12))*100) : 0}%`
                  : `${dataTotalSalesTarget && targetCard1 && targetCard1 !== '0' ? Math.floor((dataTotalSalesTarget[0]?.total/targetCard1)*100) : 0}%`  
                ,
                total: 100,
              }
            }
          />
        </Row>
        {/* Card 2 */}
        <Row className="mx-2">
          <TagChart
            title={dataTotalSalesTarget[1]?.name}
            value={
              Number(
                datePeriod === 'DAILY' ?
                  targetCard2/30
                : datePeriod === 'WEEKLY' ?
                  targetCard2/4
                : datePeriod === 'YEARLY' ?
                  targetCard2*12
                : targetCard2
              ).toLocaleString().replaceAll(",", ".")
            }
            total={dataTotalSalesTarget[1]?.total.toLocaleString().replaceAll(",", ".")}
            chartConfiguration={
              {
                labels: ["", "", ""],
                dataUnit: "BTC",
                legend: false,
                datasets: [
                  {
                    borderColor: "transparent",
                    backgroundColor: ["#FFDA05", "#d9e5f7"],
                    data: [
                      datePeriod === 'DAILY' ?
                        Math.floor((dataTotalSalesTarget[1]?.total/(targetCard2/30))*100)
                      : datePeriod === 'WEEKLY' ?
                        Math.floor((dataTotalSalesTarget[1]?.total/(targetCard2/4))*100)
                      : datePeriod === 'YEARLY' ?
                        Math.floor((dataTotalSalesTarget[1]?.total/(targetCard2*12))*100)
                      : Math.floor((dataTotalSalesTarget[1]?.total/targetCard2)*100)
                      , 100],
                  },
                ],
                centerText: 
                  datePeriod === 'DAILY' ?
                    `${dataTotalSalesTarget && targetCard2 && targetCard2 !== '0' ? Math.floor((dataTotalSalesTarget[1]?.total/(targetCard2/30))*100) : 0}%`
                  : datePeriod === 'WEEKLY' ?
                    `${dataTotalSalesTarget && targetCard2 && targetCard2 !== '0' ? Math.floor((dataTotalSalesTarget[1]?.total/(targetCard2/4))*100) : 0}%`
                  : datePeriod === 'YEARLY' ?
                    `${dataTotalSalesTarget && targetCard2 && targetCard2 !== '0' ? Math.floor((dataTotalSalesTarget[1]?.total/(targetCard2*12))*100) : 0}%`
                  : `${dataTotalSalesTarget && targetCard2 && targetCard2 !== '0' ? Math.floor((dataTotalSalesTarget[1]?.total/targetCard2)*100) : 0}%`  
                ,
                total: 100,
              }
            }
          />
        </Row>
        {/* Card 3 */}
        <Row className="mx-2">
          <TagChart
            title={dataTotalSalesTarget[2]?.name}
            value={
              Number(
                datePeriod === 'DAILY' ?
                  targetCard3/30
                : datePeriod === 'WEEKLY' ?
                  targetCard3/4
                : datePeriod === 'YEARLY' ?
                  targetCard3*12
                : targetCard3
              ).toLocaleString().replaceAll(",", ".")
            }
            total={dataTotalSalesTarget[2]?.total.toLocaleString().replaceAll(",", ".")}
            chartConfiguration={
              {
                labels: ["", "", ""],
                dataUnit: "BTC",
                legend: false,
                datasets: [
                  {
                    borderColor: "transparent",
                    backgroundColor: ["#C49104", "#d9e5f7"],
                    data: [
                      datePeriod === 'DAILY' ?
                        Math.floor((dataTotalSalesTarget[2]?.total/(targetCard3/30))*100)
                      : datePeriod === 'WEEKLY' ?
                        Math.floor((dataTotalSalesTarget[2]?.total/(targetCard3/4))*100)
                      : datePeriod === 'YEARLY' ?
                        Math.floor((dataTotalSalesTarget[2]?.total/(targetCard3*12))*100)
                      : Math.floor((dataTotalSalesTarget[2]?.total/targetCard3)*100)
                      , 100],
                  },
                ],
                centerText: 
                  datePeriod === 'DAILY' ?
                    `${dataTotalSalesTarget && targetCard3 && targetCard3 !== '0' ? Math.floor((dataTotalSalesTarget[2]?.total/(targetCard3/30))*100) : 0}%`
                  : datePeriod === 'WEEKLY' ?
                    `${dataTotalSalesTarget && targetCard3 && targetCard3 !== '0' ? Math.floor((dataTotalSalesTarget[2]?.total/(targetCard3/4))*100) : 0}%`
                  : datePeriod === 'YEARLY' ?
                    `${dataTotalSalesTarget && targetCard3 && targetCard3 !== '0' ? Math.floor((dataTotalSalesTarget[2]?.total/(targetCard3*12))*100) : 0}%`
                  : `${dataTotalSalesTarget && targetCard3 && targetCard3 !== '0' ? Math.floor((dataTotalSalesTarget[2]?.total/targetCard3)*100) : 0}%`  
                ,
                total: 100,
              }
            }
          />
        </Row>
        {/* Card 4 */}
        <Row className="mx-2">
          <TagChart
            title={dataTotalSalesTarget[3]?.name}
            value={
              Number(
                datePeriod === 'DAILY' ?
                  targetCard4/30
                : datePeriod === 'WEEKLY' ?
                  targetCard4/4
                : datePeriod === 'YEARLY' ?
                  targetCard4*12
                : targetCard4
              ).toLocaleString().replaceAll(",", ".")
            }
            total={dataTotalSalesTarget[3]?.total.toLocaleString().replaceAll(",", ".")}
            chartConfiguration={
              {
                labels: ["", "", ""],
                dataUnit: "BTC",
                legend: false,
                datasets: [
                  {
                    borderColor: "transparent",
                    backgroundColor: ["#ADADAD", "#d9e5f7"],
                    data: [
                      datePeriod === 'DAILY' ?
                        Math.floor((dataTotalSalesTarget[3]?.total/(targetCard4/30))*100)
                      : datePeriod === 'WEEKLY' ?
                        Math.floor((dataTotalSalesTarget[3]?.total/(targetCard4/4))*100)
                      : datePeriod === 'YEARLY' ?
                        Math.floor((dataTotalSalesTarget[3]?.total/(targetCard4*12))*100)
                      : Math.floor((dataTotalSalesTarget[3]?.total/targetCard4)*100)
                      , 100],
                  },
                ],
                centerText: 
                  datePeriod === 'DAILY' ?
                    `${dataTotalSalesTarget && targetCard4 && targetCard4 !== '0' ? Math.floor((dataTotalSalesTarget[3]?.total/(targetCard4/30))*100) : 0}%`
                  : datePeriod === 'WEEKLY' ?
                    `${dataTotalSalesTarget && targetCard4 && targetCard4 !== '0' ? Math.floor((dataTotalSalesTarget[3]?.total/(targetCard4/4))*100) : 0}%`
                  : datePeriod === 'YEARLY' ?
                    `${dataTotalSalesTarget && targetCard4 && targetCard4 !== '0' ? Math.floor((dataTotalSalesTarget[3]?.total/(targetCard4*12))*100) : 0}%`
                  : `${dataTotalSalesTarget && targetCard4 && targetCard4 !== '0' ? Math.floor((dataTotalSalesTarget[3]?.total/targetCard4)*100) : 0}%`  
                ,
                total: 100,
              }
            }
          />
        </Row>
        {/* Card 5 */}
        <Row className="mx-2">
          <TagChart
            title={dataTotalSalesTarget[4]?.name}
            value={
              Number(
                datePeriod === 'DAILY' ?
                  targetCard5/30
                : datePeriod === 'WEEKLY' ?
                  targetCard5/4
                : datePeriod === 'YEARLY' ?
                  targetCard5*12
                : targetCard5
              ).toLocaleString().replaceAll(",", ".")
            }
            total={dataTotalSalesTarget[4]?.total.toLocaleString().replaceAll(",", ".")}
            chartConfiguration={
              {
                labels: ["", "", ""],
                dataUnit: "BTC",
                legend: false,
                datasets: [
                  {
                    borderColor: "transparent",
                    backgroundColor: ["#2B9C51", "#d9e5f7"],
                    data: [
                      datePeriod === 'DAILY' ?
                        Math.floor((dataTotalSalesTarget[4]?.total/(targetCard5/30))*100)
                      : datePeriod === 'WEEKLY' ?
                        Math.floor((dataTotalSalesTarget[4]?.total/(targetCard5/4))*100)
                      : datePeriod === 'YEARLY' ?
                        Math.floor((dataTotalSalesTarget[4]?.total/(targetCard5*12))*100)
                      : Math.floor((dataTotalSalesTarget[4]?.total/targetCard5)*100)
                      , 100],
                  },
                ],
                centerText: 
                  datePeriod === 'DAILY' ?
                    `${dataTotalSalesTarget && targetCard5 && targetCard5 !== '0' ? Math.floor((dataTotalSalesTarget[4]?.total/(targetCard5/30))*100) : 0}%`
                  : datePeriod === 'WEEKLY' ?
                    `${dataTotalSalesTarget && targetCard5 && targetCard5 !== '0' ? Math.floor((dataTotalSalesTarget[4]?.total/(targetCard5/4))*100) : 0}%`
                  : datePeriod === 'YEARLY' ?
                    `${dataTotalSalesTarget && targetCard5 && targetCard5 !== '0' ? Math.floor((dataTotalSalesTarget[4]?.total/(targetCard5*12))*100) : 0}%`
                  : `${dataTotalSalesTarget && targetCard5 && targetCard5 !== '0' ? Math.floor((dataTotalSalesTarget[4]?.total/targetCard5)*100) : 0}%`  
                ,
                total: 100,
              }
            }
          />
        </Row>
      </Col>
      </Card>
    </React.Fragment>
  );
};
export default TicketSystem;
