import { axiosCoreAstro } from "../api/axiosCoreAstro";

export const serviceGetProducts = async (token,deptIdAstro) => {
  try {
    axiosCoreAstro.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosCoreAstro.get("api/crm/products?pc="+deptIdAstro);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetContactAstro = async (token, phone) => {
  try {
    axiosCoreAstro.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosCoreAstro.get("api/crm/member/" + phone);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetProvinceAstro = async (token) => {
  try {
    axiosCoreAstro.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response= await axiosCoreAstro.get("api/crm/province");

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetCityAstro = async (token, id) => {
  try {
    axiosCoreAstro.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response= await axiosCoreAstro.get("api/crm/city/" + id);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetSubdistrictAstro = async (token, id) => {
  try {
    axiosCoreAstro.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response= await axiosCoreAstro.get("api/crm/subdistrict/" + id);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetResolveAstro = async (token,data) => {
  try {
    axiosCoreAstro.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosCoreAstro.post("api/crm/resolves",data);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};
