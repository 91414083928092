import { axiosPrivate } from "../api/axiosCore";

const limit = 15;
const ALL_DEPT = 'Access_All_Dept'
const OWN_DEPT = 'Access_Own_Dept'
const OWN = 'Access_Own'

export const getDepartments = async (token, query,departmentId, userId, roleFunctions=[], take, page) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const queryData = query ? "&q="+query : "";
    
    const basicUrl = take === undefined ? 
    `api/departments?take=50&page=${page||1}&order=DESC`+queryData : 
    `api/departments?take=${take}&page=${page||1}&order=DESC`+queryData
    var response = null;

    if (roleFunctions.includes(ALL_DEPT)) {
      response = await axiosPrivate.get(basicUrl);
    } else if (roleFunctions.includes(OWN_DEPT)) {
      response = await axiosPrivate.get(basicUrl + `&departmentIds=${departmentId}`); //deptId
    } else if (roleFunctions.includes(OWN)) {
      response = await axiosPrivate.get(basicUrl + `&userId=${userId}`); //deptId
    }
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const getDepartmentsAll = async (token, roleFunctions=[], order, f) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const basicUrl = `api/departments`
    let response = axiosPrivate.get(`${basicUrl}/list?order=${order}&f=${f}`);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const getDepartmentsAndOrder = async (token, query, departmentId, userId, roleFunctions=[], take, page, field, order) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const queryData = query ? "&q="+query : "";
    
    const basicUrl = `api/departments?take=${take ?? 10}&page=${page||1}&order=${order}`+queryData+`&fo=${field}`
    
    let response = axiosPrivate.get(basicUrl);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const getDepartmentsByField = async (token, field, page,order="DESC") => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const fieldData = field ? "&f="+field : "";
    const response = await axiosPrivate.get(`api/departments?take=10&page=${page||1}&order=${order}`+fieldData);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const getDepartmentByID = async (token, departmentId) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.get("api/departments/"+departmentId);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const getDepartment = async (token, departmentId) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.get("api/departments/?id="+departmentId);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const postDepartment = async (token, data) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.post("api/departments", data);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const putDepartment = async (id, token, data) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.put("api/departments/" + id, data);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const deleteDepartment = async (id, token) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.delete("api/departments/" + id);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};
