import React, { useState, useEffect } from "react";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Col,
  Row,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  Button,
  TooltipComponent,
} from "components/Component";
import Content from "layout/content/Content";
import Head from "layout/head/Head";
import moment from "moment";

import {
  FormGroup,
  Modal,
  ModalBody,
  Form,
  Card,
  CardBody,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { useCookies } from "react-cookie";
import Swal from "sweetalert2";
import { 
  serviceDeleteSodPages, 
  serviceGetSodPages, 
  servicePostSodPages, 
  servicePutSodPages
} from "services/sod-pages";
const MasterFunction = () => {
  const [data, setData] = useState([]);
  const [cookies] = useCookies(["user"]);
  const [onSearchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(15);
  const [metaData, setMetaData] = useState({});
  const [editContent, setEditContent] = useState(false);
  const [confirmState, setConfirmState] = useState(false);
  const [modal, setModal] = useState({
    detail: false,
    add: false,
  });

  const [formData, setFormData] = useState({
    id: "",
    key: "",
    name: "",
    description: "",
    createdAt: "",
    createdBy: "",
    modifiedAt: "",
    modifiedBy: "",
  });

  const handleDelete = (id) => {
    if(!roleFunctions.includes('delete')){return}
    Swal.fire({
      title: "Are you sure for delete " + id + " ?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result)=> {
      if(result.isConfirmed){
        deleteProcess(id);
      }
    })  
  };

  const deleteProcess = async (id) => {
    const resp = await serviceDeleteSodPages(id, cookies.SIDikey);
    if(/20/.test(resp.status)){
      Swal.fire('Delete Function','Berhasil Menghapus', "success");
      refreshData(metaData?.page || 1);
    }else{
      Swal.fire('Delete Function Failed',resp.data.message[0].detail, "error");
    }
  };

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  const [sm, updateSm] = useState(false);
  const roleFunctions = JSON.parse(localStorage.getItem("permission"))['function'].access || [];
  // Change Page
  const paginate = (pageNumber) => refreshData(pageNumber);

  // unselects the data on mount
  useEffect(() => {
    refreshData(1);
  }, []);

  useEffect(() => {
    refreshData(1);
  }, [onSearchText]);


  const resetForm = () => {
    setFormData({
      id: "",
      key: "",
      name: "",
      description: "",
      createdAt: "",
      createdBy: "",
      modifiedAt: "",
      modifiedBy: "",
    });
  };
  const { errors, register, handleSubmit, getValues, watch } = useForm();

  const onDetailClick = (id) => {
    data.forEach((item) => {
      if (item.id === id) {
        setFormData({
          id: item.id,
          key: item.key,
          name: item.name,
          description: item.description,
          createdAt: item.createdAt,
          createdBy: item.createdBy || 'System',
          modifiedAt: item.updatedAt || '-',
          modifiedBy: item.updatedBy === null ? 'System' : item.updatedBy.firstName + " " + item.updatedBy.lastName,
        });
        setModal({ detail: true, add: false });
      }
    });
  };
  const onEditClick = (id) => {
    onDetailClick(id);
    setEditContent(true);
  };

  const onFormCancel = () => {
    setModal({ add: false, detail: false });
    setConfirmState(false);

    setEditContent(false);
    resetForm();
  };


  const refreshData = async(page) =>{
    if(!roleFunctions.includes('read')){return}
    const response = await serviceGetSodPages(cookies.SIDikey, onSearchText, [], page);
    if(response.status === 200){
      const newData = [];
      response.data.data.forEach((da)=> { 
        da.createdBy = da.createdBy ? da.createdBy.firstName + ' ' + da.createdBy.lastName : null
        da.modifiedBy = da.modifiedBy ? da.modifiedBy.firstName + ' ' + da.modifiedBy.lastName : null
        newData.push(da);
      } );
      setData(newData);
      const meta = response.data.meta;
      setMetaData(meta);
    }
  }

  const onEditSubmit = async (submittedData) => {
    submittedData.url = submittedData.key;
    const response = await servicePutSodPages(formData.id, cookies.SIDikey, submittedData);
    
    console.log(response);
    if(response.status === 202){
      Swal.fire('Edit Roles', 'Berhasil', 'success');
      refreshData();
    } else if (response.status === 400) {
      if (response.data.message === "error.pageAlreadyExists") {
        Swal.fire("Gagal Edit Function", "Link dan Page Name Duplicate", "error");
      }
    }else{
      Swal.fire('Edit Roles', 'Gagal', 'error');
    }
  }

  const onAddSubmit = async (submittedData) => {
    submittedData.url = submittedData.key;
    submittedData.pageCategoryId = '9d03963b-f6c2-4fa8-bac3-d43d5eb8989b';
    const response = await servicePostSodPages(cookies.SIDikey, submittedData);
    console.log(response)
    if(response.status === 201){
      Swal.fire('Add Function', 'Berhasil', 'success');
      refreshData();
    } else if (response.status === 400) {
      if (response.data.message === "error.pageAlreadyExists") {
        Swal.fire("Gagal Add Function", "Link dan Page Name Duplicate", "error");
      }
    } else{
      Swal.fire('Add Function', 'Gagal', 'error');
    }
  }

  return (
    <React.Fragment>
      <Head title="Function"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Function
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
          <Card className="card-bordered my-4 card-bluesky">
            <CardBody className="card-bluesky-body">
              <strong>Info:</strong>
              <p>This page is used to control the actions for other pages</p>
            </CardBody>
          </Card>
          <Block className="d-flex justify-content-end">
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    {/* <li>
                        <a
                          href="#export"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="btn btn-white btn-outline-light"
                        >
                          <Icon name="download-cloud"></Icon>
                          <span>Export</span>
                        </a>
                      </li> */}

                    <li className="nk-block-tools-opt">
                      <Button color="primary" className="btn" onClick={() => setModal({ add: true })}>
                        <i className="ni ni-plus"></i>
                        <span>Tambah Baru</span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </Block>
        </BlockHead>
        <Block>
          <DataTable className="card-stretch">
            <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group">
                <div className="form-control-wrap w-25">
                  <div className="form-icon form-icon-left">
                    <Icon name="search"></Icon>
                  </div>
                  <input 
                    type="text" 
                    className="form-control w-100" 
                    id="default-04" 
                    placeholder="Search by Page Name" 
                    onInput={(e) => setSearchText(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <DataTableBody>
              <DataTableHead>
                <DataTableRow className="nk-tb-col-check">
                  <span className="sub-text">No</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Link</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Page Name</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Description</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text"></span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Created At</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Created By</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Modified At</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Modified By</span>
                </DataTableRow>
              </DataTableHead>
              {/*Head*/}
              {currentItems.length > 0
                ? currentItems.map((item,idx) => {
                    return (
                      <DataTableItem key={item.id}>
                        <DataTableRow size="mb">
                          <span className="tb-amount">{(metaData.page - 1) * metaData.take + (idx + 1)}</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="tb-amount">{item.key}</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="tb-amount">{item.name}</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="tb-amount">{item.description}</span>
                        </DataTableRow>
                        <DataTableRow noWrap>
                          <ul className="nk-tb-actions table-action">
                            <li className="nk-tb-action" onClick={() => onDetailClick(item.id)}>
                              <TooltipComponent
                                tag="a"
                                containerClassName="btn btn-trigger btn-icon"
                                id={"detail" + item.id}
                                icon="eye-fill"
                                direction="top"
                                text="Detail"
                              />
                            </li>
                            {roleFunctions.includes('update') &&     <li className="nk-tb-action" onClick={() => onEditClick(item.id)}>
                              <TooltipComponent
                                tag="a"
                                containerClassName="btn btn-trigger btn-icon"
                                id={"edit" + item.id}
                                icon="edit-alt-fill"
                                direction="top"
                                text="Edit"
                              />
                            </li>}

                            {roleFunctions.includes('delete') &&     <li className="nk-tb-action" onClick={() => handleDelete(item.id)}>
                              <TooltipComponent
                                tag="a"
                                containerClassName="btn btn-trigger btn-icon"
                                id={"suspend" + item.id}
                                icon="trash-fill"
                                direction="top"
                                text="Hapus"
                              />
                            </li>}
                          </ul>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="tb-amount">{moment(item.createdAt).format("MMM DD, YYYY, HH:mm")}</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="tb-amount">{item.createdBy || 'System'}</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="tb-amount">{moment(item.updatedAt).format("MMM DD, YYYY, HH:mm")}</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="tb-amount">{item.updatedBy === null ? 'System' : item.updatedBy?.firstName + " " + item.updatedBy?.lastName}</span>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                : null}
            </DataTableBody>
            <div className="card-inner">
              {currentItems.length > 0 ? (
                <PaginationComponent
                  itemPerPage={metaData?.take}
                  totalItems={metaData?.itemCount}
                  paginate={paginate}
                  currentPage={metaData?.page}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )}
            </div>
          </DataTable>
        </Block>
      </Content>
      <Modal
        isOpen={modal.add}
        backdrop="static"
        keyboard={false}
        toggle={() => setModal({ detail: false })}
        className="modal-dialog-centered"
        size="lg"
      >
        <ModalBody>
          <a
            href="#cancel"
            onClick={(ev) => {
              ev.preventDefault();
              onFormCancel();
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">Tambah Function</h5>
            <Block className="d-flex justify-content-end"></Block>
            <div className="mt-4">
              <Form className="row gy-4" onSubmit={handleSubmit(onAddSubmit)}>
                <Col md="12">
                  <FormGroup>
                    <label className="form-label">Link</label>
                    <input
                      className="form-control"
                      type="text"
                      name="key"
                      defaultValue={formData.key}
                      placeholder="Link"
                      ref={register({ required: "This field is required" })}
                    />
                    {errors.key && <span className="custom-invalid">{errors.key.message}</span>}
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <label className="form-label">Page Name</label>
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      defaultValue={formData.name}
                      placeholder="Page Name"
                      ref={register({ required: "This field is required" })}
                    />
                    {errors.name && <span className="custom-invalid">{errors.name.message}</span>}
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <label className="form-label">Description</label>
                    <input
                      className="form-control"
                      type="text"
                      name="description"
                      defaultValue={formData.description}
                      placeholder="Description"
                      ref={register({ required: "This field is required" })}
                    />
                    {errors.description && <span className="custom-invalid">{errors.description.message}</span>}
                  </FormGroup>
                </Col>
                
                <Col size="12">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button
                        outline
                        color="primary"
                        onClick={(ev) => {
                          ev.preventDefault();
                          onFormCancel();
                        }}
                      >
                        Batal
                      </Button>
                    </li>
                    <li>
                      <Button color="primary" size="md" type="submit" >
                        Simpan
                      </Button>
                    </li>
                  </ul>
                </Col>
              </Form>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modal.detail}
        backdrop="static"
        keyboard={false}
        toggle={() => setModal({ detail: false })}
        className="modal-dialog-centered"
        size="lg"
      >
        <ModalBody>
          <a
            href="#cancel"
            onClick={(ev) => {
              ev.preventDefault();
              onFormCancel();
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">Detail Function</h5>
            <Block className="d-flex justify-content-end"></Block>
            <div className="mt-4">
              <Form className="row gy-4" onSubmit={handleSubmit(onEditSubmit)}>
              <Col md="6">
                  <FormGroup>
                    <label className="form-label">Link</label>
                    <input
                      className="form-control"
                      type="text"
                      name="key"
                      defaultValue={formData.key}
                      placeholder="Link"
                      disabled={!editContent}
                      ref={register({ required: "This field is required" })}
                    />
                    {errors.key && <span className="custom-invalid">{errors.key.message}</span>}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label">Page Name</label>
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      defaultValue={formData.name}
                      disabled={!editContent}
                      placeholder="Page Name"
                      ref={register({ required: "This field is required" })}
                    />
                    {errors.name && <span className="custom-invalid">{errors.name.message}</span>}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label">Description</label>
                    <input
                      className="form-control"
                      type="text"
                      name="description"
                      defaultValue={formData.description}
                      disabled={!editContent}
                      placeholder="Description"
                      ref={register({ required: "This field is required" })}
                    />
                    {errors.description && <span className="custom-invalid">{errors.description.message}</span>}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label">Created By</label>
                    <input
                      className="form-control"
                      type="text"
                      name="createdBy"
                      defaultValue={formData.createdBy}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label">Created At</label>
                    <input
                      disabled
                      name="createdAt"
                      className="form-control"
                      placeholder=""
                      defaultValue={formData.createdAt}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label">Modified By</label>
                    <input
                      disabled
                      name="modifiedBy"
                      className="form-control"
                      placeholder=""
                      defaultValue={formData.modifiedBy}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label">Modified At</label>
                    <input
                      disabled
                      name="modifiedAt"
                      className="form-control"
                      placeholder=""
                      defaultValue={formData.modifiedAt}
                    />
                  </FormGroup>
                </Col>
                {/* <Col md="6" className="d-flex justify-content-end align-items-end">
                  <div className="toggle-wrap nk-block-tools-toggle">
                    <Button
                      className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                      onClick={() => updateSm(!sm)}
                    >
                      <Icon name="menu-alt-r"></Icon>
                    </Button>
                    <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                      <ul className="nk-block-tools g-3">
                        <li className="nk-block-tools-opt">
                          <Button
                            color="primary"
                            className={editContent ? "btn d-none" : ""}
                            onClick={() => setEditContent(true)}
                          >
                            Ubah
                          </Button>
                        </li>

                        <li className="nk-block-tools-opt">
                          <Button 
                            color="danger" 
                            outline 
                            className="btn" 
                            onClick={(e) => {
                              e.preventDefault();
                              handleDelete(formData.id)
                            }}
                          >
                            Hapus
                          </Button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col> */}
                <Col size="12" className={editContent ? "" : "d-none"}>
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button
                        outline
                        color="primary"
                        onClick={(ev) => {
                          ev.preventDefault();
                          onFormCancel();
                        }}
                      >
                        Batal
                      </Button>
                    </li>
                    <li>
                      <Button color="primary" size="md" type="submit" disabled={!editContent}>
                        Simpan
                      </Button>
                    </li>
                  </ul>
                </Col>
              </Form>
              <Row className={"row gy-4"}>
              <Col md="2">
                <div className="toggle-wrap nk-block-tools-toggle">
                  {!roleFunctions.includes('update') || 
                    <Button
                    className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                    onClick={() => updateSm(!sm)}
                    >
                      <Icon name="menu-alt-r"></Icon>
                    </Button>
                  }
                  <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                    <ul className="nk-block-tools g-3">
                      <li className="nk-block-tools-opt">
                        <Button
                          color="primary"
                          className={editContent ? "btn d-none" : ""}
                          onClick={() => setEditContent(true)}
                        >
                          Ubah
                        </Button>
                      </li> 
                    </ul>
                  </div>
                </div>
              </Col>
              <Col md="2">
                <div className="toggle-wrap nk-block-tools-toggle">
                  {!roleFunctions.includes('delete') || 
                    <Button
                    color="danger"
                    outline
                    className={editContent ? "btn d-none" : ""}
                    onClick={() => handleDelete(formData.id)}
                  >
                    Hapus
                  </Button>
                  }
                </div>
              </Col>
            </Row>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
export default MasterFunction;
