import React, { useMemo } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Card, Col, Form, FormGroup } from "reactstrap";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import Swal from "sweetalert2";
import { useEffect } from "react";
import classNames from "classnames";
import DatePicker from "react-datepicker";
import { ReplyContext } from "contexts/replyContext";
import { useHistory } from "react-router-dom";
import { Block, BlockHead, BlockHeadContent, BlockTitle, Icon } from "components/Component";
import Content from "layout/content/Content";
import Head from "layout/head/Head";
import getTextColor from "components/custom/getTextColor";
// import { serviceGetChatById } from "services/chats";
import { serviceGetTicketById } from "services/tickets";
import { serviceGetContact, servicePutContact } from "services/contact";
import ChatModalTagAssign from "../chat/ChatModalTagAssign";
import moment from "moment";
import ChatDetailTicket from "./ChatDetailTicket";
import { Spinner } from "reactstrap";

const DetailTicket = ({ ...props }) => {
  const dataTicket = useMemo(() => {
    return { data: props.location?.state.ticket, isEditing: props.location?.state.edit };
  }, []);
  const picOptionMapped = useMemo(() => {
    return props.location?.state.picOptions.map((item) => ({
      label: item.firstName + " " + item.lastName,
      value: item.id,
    }));
  }, []);

  // const [isModalTagAssignOpen, setIsModalTagAssignOpen] = useState(false);
  const [cookies] = useCookies(["user"]);
  const [selectedTag, setSelectedTag] = useState([]);
  const [showMessageDetails, setShowMessageDetails] = useState(false);
  const [replyMessage, setReplyMessage] = useState(null);
  const [contextMessage, setContextMessage] = useState(null);
  const [selectedChat, setSelectedChat] = useState({});
  const [loading, setLoading] = useState(false);
  const startChat = moment(selectedChat?.createdAt);
  const lastChat = moment(selectedChat?.updatedAt);

  const difference = moment.duration(lastChat.diff(startChat));
  const dayDifference =
    difference.days() > 0 ? (difference.days() > 1 ? difference.days() + " days" : difference.days() + " day") : "";
  const hoursDifference =
    difference.hours() > 0
      ? difference.hours() > 1
        ? difference.hours() + " hours"
        : difference.hours() + " hour"
      : "";
  const minutesDifference =
    difference.minutes() > 0
      ? difference.minutes() > 1
        ? difference.minutes() + " minutes"
        : difference.minutes() + " minute"
      : "";

  const { errors, register, handleSubmit, control, reset } = useForm();

  const { errors: errorLink, register: registerLink, handleSubmit: handleSubmitLink } = useForm();
  let history = useHistory();
  let dataSelect = {
    waTarget: [
      // { value: "Rotator", label: "Rotator" },
      { value: "Single", label: "Single" },
    ],
  };
  const refreshChatDataByID = async () => {
    setLoading(true);
    console.log("contact", dataTicket.data.chat.contact.id);
    console.log("data", dataTicket.data);
    const responseChat = await serviceGetTicketById(cookies.SIDikey, dataTicket.data.id);
    setSelectedTag(dataTicket.data.tags);
    setSelectedChat(responseChat.data);
    const responseContact = await serviceGetContact(cookies.SIDikey, dataTicket.data.chat.contact.id);
    if (responseContact.status === 200 && responseContact.data.success !== false) {
      setFormData({ ...formData, followUpDate: responseContact.data.followUpDate });
      console.log("responseContact", formData.followUpDate);
    } else {
      Swal.fire({
        icon: "error",
        title: "Failed to get contact data",
        focusConfirm: false,
      }).then(() => history.push("/ticket"));
    }
    setLoading(false);
  };

  useEffect(() => {
    refreshChatDataByID();
  }, []);

  const [formData, setFormData] = useState(() => {
    return {
      pic: {
        value: dataTicket.data?.user?.id,
        label: dataTicket.data?.user?.firstName + " " + dataTicket.data?.user?.lastName,
      },
      followUpDate: null,
    };
  }, [dataTicket.data?.user?.firstName, dataTicket.data?.user?.id, dataTicket.data?.user?.lastName]);

  const onFormSubmit = async (e) => {
    let submittedData = {
      followUpDate: moment(formData.followUpDate).format("YYYY-MM-DD"),
    };

    const responsePutContact = await servicePutContact(cookies.SIDikey, dataTicket.data.chat.contact.id, submittedData);

    if (/20+/.test(responsePutContact.status)) {
      Swal.fire({
        icon: "success",
        title: "Succesfull update ticket!",
        focusConfirm: false,
      }).then(() => history.push("/ticket"));
    } else {
      Swal.fire({
        icon: "error",
        title: "Failed update ticket!",
        focusConfirm: false,
      }).then(() => history.push("/ticket"));
    }
  };

  return (
    <React.Fragment>
      <Head title={dataTicket.isEditing ? "Edit Ticket" : "Ticket Detail"} />
      <Content>
        <Form key="addData" onSubmit={handleSubmit(onFormSubmit)}>
          <BlockHead size="sm">
            <BlockHeadContent className="d-flex justify-content-between">
              <BlockTitle tag="h3" page>
                Ticket {dataTicket?.data?.id}
              </BlockTitle>
              {dataTicket.isEditing ? (
                <div>
                  <Link to={`${process.env.PUBLIC_URL + "/ticket"}`}>
                    <Button size="xl" outline type="button" color="primary" className="mr-3">
                      Batal
                    </Button>
                  </Link>
                  <Button size="xl" color="primary">
                    Simpan
                  </Button>
                </div>
              ) : (
                <Link to={`${process.env.PUBLIC_URL + "/ticket"}`}>
                  <Button size="xl" outline type="button" color="primary" className="mr-3">
                    Kembali
                  </Button>
                </Link>
              )}
            </BlockHeadContent>
          </BlockHead>

          <Block>
            <Card className="p-4">
              <Col size="12" className="d-flex">
                <Col md="7" lg="7">
                  <div className="mb-4">
                    <h6>General Information</h6>
                    <table className="w-100 table table-bordered">
                      <tbody>
                        <tr>
                          <th>Nama</th>
                          <td>{dataTicket.data?.chat.contact.name}</td>
                        </tr>
                      </tbody>
                      <tbody>
                        <tr>
                          <th>Email</th>
                          <td>
                            {dataTicket.data.chat.contact?.emails.length
                              ? dataTicket.data.chat.contact?.emails[0].email
                              : "-" || "-"}
                          </td>
                        </tr>
                      </tbody>
                      <tbody>
                        <tr>
                          <th>Nomor telepon</th>
                          <td>{dataTicket.data?.chat.customerPhone && "+" + dataTicket.data?.chat.customerPhone}</td>
                        </tr>
                      </tbody>
                      <tbody>
                        <tr>
                          <th>Department</th>
                          <td>
                            {" "}
                            <span
                              className="badge rounded-pill text-bg-primary"
                              style={{
                                background: dataTicket.data?.department?.color
                                  ? dataTicket.data?.department.color
                                  : "#fffff",
                                borderColor: dataTicket.data?.department?.color
                                  ? dataTicket.data?.department.color
                                  : "#fffff",
                                color: getTextColor(
                                  dataTicket.data?.department?.color ? dataTicket.data.department.color : "#fffff"
                                ),
                              }}
                            >
                              {dataTicket.data?.department?.name}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <FormGroup className="mb-4">
                    <label className="form-label">Ticket Name</label>
                    <p>{dataTicket.data?.name}</p>
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <label className="form-label">PIC</label>
                    {/* <Controller
                      name="pic"
                      as={RSelect}
                      defaultValue={formData.pic}
                      control={control}
                      rules={{ required: true }}
                      options={picOptionMapped}
                    /> */}
                    <p>{dataTicket.data?.user?.firstName + " " + dataTicket.data?.user?.lastName}</p>
                  </FormGroup>

                  <FormGroup className="mb-4">
                    <label className="form-label">Catatan</label>
                    <textarea
                      disabled={true}
                      placeholder={dataTicket.data?.chat?.contact?.notes || "Tidak ada catatan"}
                      className="form-control"
                      // ref={register({
                      //   required: "This field is required",
                      // })}
                      // placeholder={dataTicket.isEditing ? "Tulis Catatan Disini" : ""}
                      // name="catatan"
                    />
                    {/* {errors.catatan && <span className="invalid">Please fill this field</span>} */}
                  </FormGroup>

                  <FormGroup className="mb-4">
                    <label className="form-label">Rating from Customer</label>
                    <p>{dataTicket.data?.rating ? dataTicket.data?.rating : "0"} / 5</p>
                  </FormGroup>
                </Col>
                <Col md="5" lg="5">
                  <FormGroup className="mb-4">
                    <label className="form-label">Tags</label>

                    <div className="badge-list d-flex">
                      <div>
                        {dataTicket.data.tags &&
                          dataTicket.data.tags.map((item, idx) => {
                            return (
                              <span
                                key={idx}
                                className="badge rounded-pill text-bg-primary"
                                style={{
                                  background: item.color,
                                  color: getTextColor(item.color),
                                  margin: "2px",
                                }}
                              >
                                {item.name}
                              </span>
                            );
                          })}
                      </div>
                      {/* <div>
                        <div
                          style={{ marginInlineStart: "5px" }}
                          className="more-icon"
                          onClick={() => {
                            setIsModalTagAssignOpen(true);
                          }}
                        >
                          <Icon name="downward-ios" className={classNames({ "d-none": dataTicket.isEditing })} />
                        </div>
                      </div> */}
                    </div>
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <label className="form-label">Follow Up Date</label>
                    <div style={{ position: "relative" }}>
                      {dataTicket.isEditing ? (
                        <>
                          <DatePicker
                            className="form-control date-picker"
                            style={{ paddingRight: "30px" }}
                            showYearDropdown
                            scrollableYearDropdown
                            dropdownMode="select"
                            selected={formData.followUpDate != null ? new Date(formData.followUpDate) : null}
                            onChange={(date) => setFormData({ ...formData, followUpDate: date })}
                            name="followUpDate"
                          />
                          <Icon
                            name="calendar"
                            style={{ position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)" }}
                          />
                        </>
                      ) : (
                        <>
                          <DatePicker
                            className="form-control date-picker"
                            style={{ paddingRight: "30px" }}
                            disabled={true}
                            showYearDropdown
                            scrollableYearDropdown
                            dropdownMode="select"
                            placeholderText={formData.followUpDate != null ? moment(formData.followUpDate).format("YYYY-MM-DD")
                            : ""}
                            name="followUpDate"
                          />
                          <Icon
                            name="calendar"
                            style={{ position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)" }}
                          />
                        </>
                      )}
                    </div>
                  </FormGroup>

                  {/* Temporary commented, waiting the data */}
                  {/*                 <FormGroup className="mb-4">
                    <label className="form-label">Alur Ticket</label>
                    <table className="table table-borderless">
                      <tbody>
                        <tr>
                          <td>{moment(dataTicket.data?.createdAt).format("DD MMM YYYY , HH:MM")}</td>
                          <td>ticket status berubah benjadi solved</td>
                        </tr>
                        <tr>
                          <td>{moment(selectedChat.createdAt).format("DD MMM YYYY, hh:mm")}</td>
                          <td>ticket sedang di tangani oleh chatbot</td>
                        </tr>
                      </tbody>
                    </table>
                  </FormGroup> */}
                </Col>
              </Col>
              <Col size="12">
                <FormGroup className="mb-4">
                  <label className="form-label">Messages</label>

                  <table className="w-100 table table-borderless shadow">
                    <thead>
                      <tr>
                        <th>Nama Customer</th>
                        <th>No HP Customer</th>
                        <th>Department</th>
                        <th>Durasi Chat</th>
                        <th>Chat Pertama</th>
                        <th>Chat Terakhir</th>
                        <th onClick={() => setShowMessageDetails(!showMessageDetails)} style={{ cursor: "pointer" }}>
                          <Icon name={`caret-${showMessageDetails ? "up" : "down"}-fill`} />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{dataTicket.data?.chat.contact.name}</td>
                        <td>{"+" + dataTicket.data?.chat.customerPhone}</td>
                        <td style={{ color: dataTicket.data?.department?.color }}>
                          {dataTicket.data?.department?.name}
                        </td>
                        <td>
                          {loading ? (
                            <Spinner size="sm" animation="border" role="status" color="blue" />
                          ) : (
                            dayDifference + " " + hoursDifference + " " + minutesDifference
                          )}
                        </td>
                        <td>
                          {loading ? (
                            <Spinner size="sm" animation="border" role="status" color="blue" />
                          ) : (
                            startChat.format("DD/MM/YYYY HH:MM")
                          )}{" "}
                        </td>
                        <td>
                          {loading ? (
                            <Spinner size="sm" animation="border" role="status" color="blue" />
                          ) : (
                            lastChat.format("DD/MM/YYYY HH:MM")
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <ReplyContext.Provider
                    value={{ value: [replyMessage, setReplyMessage], value2: [contextMessage, setContextMessage] }}
                  >
                    {showMessageDetails && (
                      <ChatDetailTicket cookies={cookies} id={dataTicket.data?.chatId}></ChatDetailTicket>
                    )}
                  </ReplyContext.Provider>
                </FormGroup>
              </Col>
              <Col size="12">
                <FormGroup className="mb-4">
                  <p>
                    Created at {moment(dataTicket.data?.createdAt).format("MMM DD, YYYY, hh:mm")}{" "}
                    {dataTicket.data?.createdBy &&
                      "by " + dataTicket.data?.createdBy.firstname + " " + dataTicket.data?.createdBy.lastName}
                  </p>
                  <p>
                    Modified at {moment(dataTicket.data?.updatedAt).format("MMM DD, YYYY, hh:mm")}{" "}
                    {dataTicket.data?.updatedBy &&
                      "by " + dataTicket.data?.updatedBy.firstName + " " + dataTicket.data?.updatedBy.lastName}
                  </p>
                </FormGroup>
              </Col>
            </Card>
          </Block>
        </Form>
      </Content>
      {/* <ChatModalTagAssign
        cookies={cookies}a
        checkedTag={selectedTag}
        setIsModalTagAssignOpen={setIsModalTagAssignOpen}
        chatId={[dataTicket.data?.chatId]}
        isModalTagAssignOpen={isModalTagAssignOpen}
      /> */}
    </React.Fragment>
  );
};

export default DetailTicket;
