import { axiosPrivate } from "../api/axiosCore";

export const servicePutContact = async (token, id, data) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.put("api/contacts/" + id, data);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetContacts = async (token, page) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`; 
      return config;
    });

    const response = await axiosPrivate.get("api/contacts/?take=10&order=DESC&withCrms=true&page=" + page);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};
export const serviceGetAllContacts = async (token) => {
  try {
    let hasNextPage = true;
    let page = 1;
    let contact = [];

    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    while (hasNextPage) {
      var basicUrl = `api/contacts/?take=1000&order=DESC&page=${page}`;
      const response = await axiosPrivate.get(basicUrl);
      const responseData = response.data.data;
      contact.push(...responseData);
      if (!response.data.meta.hasNextPage) {
        hasNextPage = false;
      } else {
        page += 1;
      }
    }

    return contact;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetContactsByQuery = async (token, page, query, field, sortVal, sortName, dateFilter) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const dateBirthday = dateFilter ? `&birthday=${dateFilter}` : "";
    const basicUrl = `api/contacts/?take=10&order=${sortVal ? sortVal : "DESC"}&withCrms=true&page=${page || 1}`;
    const q = query ? `&q=${query}` : "";
    const f = field || sortName ? `&f=${field}${sortName ? ","+sortName : ""}` : "";
    const response = await axiosPrivate.get(basicUrl + dateBirthday + q + f + "&c=AND");

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetContact = async (token, id) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.get("api/contacts/" + id);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetContactbyIDS = async (token, ids) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const queryPath = ids.map((itm) => `ids=${itm}`)
    const response = await axiosPrivate.get(`api/contacts?page=1&take=20&${queryPath.join('&')}`);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetContactByPhone = async (token, phone) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.get("api/contacts?order=DESC&take=1&f=phones.phone&q=" + phone);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceCreateContact = async (token, data) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const dataJson = JSON.stringify(data);
    const response = await axiosPrivate.post("api/contacts", dataJson);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};