import { components } from "react-select";
import {Button, Icon} from "../Component"

const SelectMenuButton = ({selectProps: { btnAdd },...props}) => {
  // console.log(props.children);
  return (
    <components.MenuList {...props}>
      <Button color="link" type="button" className="button-link" onClick={btnAdd}>
        <Icon name="plus-sm"></Icon>
        <span>Tambah Baru</span>
      </Button>
      {props.children}
    </components.MenuList>
  );
};

export default SelectMenuButton;
