import React from "react";
import { forwardRef } from "react";
import Select from "react-select";

const RSelect = forwardRef(({ ...props }, ref) => {
  return (
    <div className="form-control-select">
      <Select
        className={`react-select-container ${props.className ? props.className : ""}`}
        classNamePrefix="react-select"
        isDisabled={props.disabled ? props.disabled : false}
        ref={ref}
        name={props.name}
        {...props}
      />
    </div>
  );
});

export default RSelect;
