import React, { useState, useEffect } from "react";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Col,
  Row,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  Button,
  TooltipComponent,
} from "components/Component";
import Content from "layout/content/Content";
import Head from "layout/head/Head";
import { masterRoleList } from "./masterRoleData";
import {
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  DropdownItem,
  Form,
  Card,
  CardBody,
  Alert,
} from "reactstrap";
import { useForm } from "react-hook-form";
import moment from "moment";
import { 
  serviceDeleteSodRole, 
  serviceGetSodRole, 
  servicePostSodRole, 
  servicePutSodRole,
  serviceSearchSodRole
} from "services/sod-role";
import { useCookies } from "react-cookie";
import Swal from "sweetalert2";

const MasterRole = () => {
  const [data, setData] = useState([]);
  const [onSearchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(15);
  const [editContent, setEditContent] = useState(false);
  const [confirmState, setConfirmState] = useState(false);
  const [cookies] = useCookies(["user"]);
  const [modal, setModal] = useState({
    detail: false,
    add: false,
  });
  const [metaData, setMetaData] = useState({
    itemCount: 0,
    page: 1,
    take: 0,
    pageCount: 0,
    hasPreviousPage: false,
    hasNextPage: false,
  });

  const [formData, setFormData] = useState({
    id: "",
    name: "",
    description: "",
    createdAt: "",
    createdBy: "",
    modifiedAt: "",
    modifiedBy: "",
  });

  const handleDelete = (id,name) => {
    if(!roleFunctions.includes('delete')){return}
    Swal.fire({
      title: "Are you sure for delete " + name + " ?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result)=> {
      if(result.isConfirmed){
        onDeleteSubmit(id);
      }
    })  
    // Swal.fire('Delete Role', 'Role ini telah digunakan, tidak bisa dihapus', 'error');
  };

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  const [sm, updateSm] = useState(false);
  const roleFunctions = JSON.parse(localStorage.getItem("permission"))['roles'].access || [];
  // Change Page
  const paginate = (pageNumber) => refreshData(pageNumber);

  const refreshData = async(pageNumber) =>{
    if(!roleFunctions.includes('read')){return}

    const response = await serviceGetSodRole(cookies.SIDikey, onSearchText, [], pageNumber);
    setMetaData(response.data.meta);
    if(response.status === 200){
      const newData = [];
      response.data.data.forEach((da)=> { 
        da.createdBy = da.createdBy ? da.createdBy.firstName + ' ' + da.createdBy.lastName : null
        da.modifiedBy = da.modifiedBy ? da.modifiedBy.firstName + ' ' + da.modifiedBy.lastName : null
        newData.push(da);
      } );
      setData(newData);
    }
  }

  // unselects the data on mount
  useEffect(() => {
    refreshData(1);
    let newData;
    newData = masterRoleList.map((item) => {
      item.checked = false;
      return item;
    });
    setData([...newData]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    refreshData(1);
  }, [onSearchText]); 

  const resetForm = () => {
    setFormData({
      id: "",
      name: "",
      description: "",
      createdAt: "",
      createdBy: "",
      modifiedAt: "",
      modifiedBy: "",
    });
  };
  const { errors, register, handleSubmit, getValues, watch } = useForm();

  const onDetailClick = (id) => {
    data.forEach((item) => {
      console.log("item", item)
      if (item.id === id) {
        setFormData({
          id: item.id,
          name: item.name,
          createdAt: item.createdAt,
          description: item.description,
          createdBy: item.createdBy || 'System',
          modifiedAt: item.updatedAt || '-',
          modifiedBy: item.updatedBy === null ? 'System' : item.updatedBy.firstName + " " + item.updatedBy.lastName,
        });
        setModal({ detail: true, add: false });
      }
    });
  };
  const onEditClick = (id) => {
    onDetailClick(id);
    setEditContent(true);
  };

  const onFormCancel = () => {
    setModal({ add: false, detail: false });
    setConfirmState(false);

    setEditContent(false);
    resetForm();
  };

  const onEditSubmit = async (submittedData) => {
    const response = await servicePutSodRole(formData.id, cookies.SIDikey, submittedData);
    if(!roleFunctions.includes('update')){return}
    if(response.status === 202){
      Swal.fire('Edit Roles', 'Berhasil', 'success');
      refreshData();
    }else if (response.status === 400) {
      if (response.data.message === "error.roleAlreadyExists") {
        Swal.fire("Gagal Edit Role", "Role Nama Duplicate", "error");
      }
    }else{
      Swal.fire('Edit Roles', 'Gagal', 'error');
    }
  }

  const onSaveSubmit = async (submittedData) => {
    if(!roleFunctions.includes('create')){return}
    const response = await servicePostSodRole(cookies.id, submittedData);
    console.log(submittedData, response);
    if(response.status === 201){
      Swal.fire('Add Roles', 'Berhasil', 'success');
      refreshData();
    }else if (response.status === 400) {
      if (response.data.message === "error.roleAlreadyExists") {
        Swal.fire("Gagal Add Role", "Role Nama Duplicate", "error");
      }
    }else{
      Swal.fire('Add Roles', 'Gagal', 'error');
    }
  }

  const onDeleteSubmit = async (id) => {
    const response = await serviceDeleteSodRole(id, cookies.SIDikey);
    console.log(response);
    if(/20+/.test(response.status)){
      Swal.fire('Delete Roles', 'Berhasil', 'success');
      refreshData();
    }else{
      Swal.fire('Delete Roles Failed',response.data.message[0].detail, "error");
    }
  }

  return (
    <React.Fragment>
      <Head title="Role"></Head>

      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Role
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
          <Card className="card-bordered my-4 card-bluesky">
            <CardBody className="card-bluesky-body">
              <strong>Info:</strong>
              <p>This page is used to control the roles for other pages</p>
            </CardBody>
          </Card>
          <Block className="d-flex justify-content-end">
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt">
                      <Button color="primary" className="btn" onClick={() => setModal({ add: true })}>
                        <i className="ni ni-plus"></i>
                        <span>Tambah Baru</span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </Block>
        </BlockHead>
        <Block>
          <DataTable className="card-stretch">
            <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group">
                <div className="form-control-wrap w-25">
                  <div className="form-icon form-icon-left">
                    <Icon name="search"></Icon>
                  </div>
                  <input 
                    type="text" 
                    className="form-control w-100" 
                    id="default-04" 
                    placeholder="Search" 
                    onInput={(e) => setSearchText(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <DataTableBody>
              <DataTableHead>
                <DataTableRow className="nk-tb-col-check">
                  <span className="sub-text">No</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Role</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Description</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Actions</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Created At</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Created By</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Modified At</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Modified By</span>
                </DataTableRow>
              </DataTableHead>
              {/*Head*/}
              {currentItems.length > 0
                ? currentItems.map((item, idx) => {
                    return (
                      <DataTableItem key={item.id}>
                        <DataTableRow size="mb">
                          <span className="tb-amount">{(metaData.page - 1) * metaData.take + (idx + 1)}</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="tb-amount">{item.name}</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="tb-amount">{item.description}</span>
                        </DataTableRow>
                        <DataTableRow noWrap>
                          <ul className="nk-tb-actions table-action">
                            <li className="nk-tb-action" onClick={() => onDetailClick(item.id)}>
                              <TooltipComponent
                                tag="a"
                                containerClassName="btn btn-trigger btn-icon"
                                id={"detail" + item.id}
                                icon="eye-fill"
                                direction="top"
                                text="Detail"
                              />
                            </li>
                            {roleFunctions.includes('update') &&   <li className="nk-tb-action" onClick={() => onEditClick(item.id)}>
                              <TooltipComponent
                                tag="a"
                                containerClassName="btn btn-trigger btn-icon"
                                id={"edit" + item.id}
                                icon="edit-alt-fill"
                                direction="top"
                                text="Edit"
                              />
                            </li>}

                            {roleFunctions.includes('delete') &&  <li className="nk-tb-action" onClick={() => handleDelete(item.id, item.name)}>
                              <TooltipComponent
                                tag="a"
                                containerClassName="btn btn-trigger btn-icon"
                                id={"suspend" + item.id}
                                icon="trash-fill"
                                direction="top"
                                text="Hapus"
                              />
                            </li>}
                          </ul>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="tb-amount">{moment(item.createdAt).format("MMM DD, YYYY, HH:mm")}</span>
                        </DataTableRow>
                        <DataTableRow size="md"><span className="tb-amount">{item.createdBy || 'System'}</span></DataTableRow>
                        <DataTableRow size="md">
                          <span className="tb-amount">{moment(item.updatedAt).format("MMM DD, YYYY, HH:mm")}</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="tb-amount">{item.updatedBy === null ? 'System' : item.updatedBy?.firstName + " " + item.updatedBy?.lastName}</span>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                : null}
            </DataTableBody>
            <div className="card-inner">
              {currentItems.length > 0 ? (
                <PaginationComponent
                itemPerPage={metaData.take}
                totalItems={metaData.itemCount}
                paginate={paginate}
                currentPage={metaData.page}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )}
            </div>
          </DataTable>
        </Block>
      </Content>
      {!roleFunctions.includes('create') || 
        <Modal
          isOpen={modal.add}
          backdrop="static"
          keyboard={false}
          toggle={() => setModal({ detail: false })}
          className="modal-dialog-centered"
          size="lg"
        >
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Tambah Role</h5>
              <Block className="d-flex justify-content-end"></Block>
              <div className="mt-4">
                <Form className="row gy-4" onSubmit={handleSubmit(onSaveSubmit)}>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-label">Role Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        defaultValue={formData.name}
                        placeholder="Role Name"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.name && <span className="custom-invalid">{errors.name.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-label">Role Description</label>
                      <input
                        className="form-control"
                        type="text"
                        name="description"
                        defaultValue={formData.description}
                        placeholder="Role Description"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.description && <span className="custom-invalid">{errors.description.message}</span>}
                    </FormGroup>
                  </Col>

                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button
                          outline
                          color="primary"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                        >
                          Batal
                        </Button>
                      </li>
                      <li>
                        <Button color="primary" size="md" type="submit">
                          Simpan
                        </Button>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      }

      <Modal
        isOpen={modal.detail}
        backdrop="static"
        keyboard={false}
        toggle={() => setModal({ detail: false })}
        className="modal-dialog-centered"
        size="lg"
      >
        <ModalBody>
          <a
            href="#cancel"
            onClick={(ev) => {
              ev.preventDefault();
              onFormCancel();
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">Detail Role</h5>
            <Block className="d-flex justify-content-end"></Block>
            <div className="mt-4">
              <Form className="row gy-4" onSubmit={handleSubmit(onEditSubmit)}>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label">Role Name</label>
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      defaultValue={formData.name}
                      disabled={!editContent}
                      placeholder="Masukkan Nama Role"
                      ref={register({ required: "This field is required" })}
                    />
                    {errors.name && <span className="custom-invalid">{errors.name.message}</span>}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label">Description</label>
                    <input
                      className="form-control"
                      type="text"
                      name="description"
                      defaultValue={formData.description}
                      disabled={!editContent}
                      placeholder="Masukkan Deskripsi Role"
                      ref={register({ required: "This field is required" })}
                    />
                    {errors.description && <span className="custom-invalid">{errors.description.message}</span>}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label">Created By</label>
                    <input
                      className="form-control"
                      type="text"
                      name="createdBy"
                      defaultValue={formData.createdBy}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label">Created At</label>
                    <input
                      disabled
                      name="createdAt"
                      className="form-control"
                      defaultValue={formData.createdAt}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label">Modified By</label>
                    <input
                      disabled
                      name="modifiedBy"
                      className="form-control"
                      defaultValue={formData.modifiedBy}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label">Modified At</label>
                    <input
                      disabled
                      name="modifiedAt"
                      className="form-control"
                      defaultValue={formData.modifiedAt}
                    />
                  </FormGroup>
                </Col>
                
                <Col size="12" className={editContent ? "" : "d-none"}>
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button
                        outline
                        color="primary"
                        onClick={(ev) => {
                          ev.preventDefault();
                          onFormCancel();
                        }}
                      >
                        Batal
                      </Button>
                    </li>
                    <li>
                      <Button color="primary" size="md" type="submit" disabled={!editContent}>
                        Simpan
                      </Button>
                    </li>
                  </ul>
                </Col>
              </Form>
              <Row className={"row gy-4"}>
              <Col md="2">
                <div className="toggle-wrap nk-block-tools-toggle">
                  {!roleFunctions.includes('update') || 
                    <Button
                    className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                    onClick={() => updateSm(!sm)}
                    >
                      <Icon name="menu-alt-r"></Icon>
                    </Button>
                  }
                  <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                    <ul className="nk-block-tools g-3">
                      <li className="nk-block-tools-opt">
                        <Button
                          color="primary"
                          className={editContent ? "btn d-none" : ""}
                          onClick={() => setEditContent(true)}
                        >
                          Ubah
                        </Button>
                      </li> 
                    </ul>
                  </div>
                </div>
              </Col>
              <Col md="2">
                <div className="toggle-wrap nk-block-tools-toggle">
                  {!roleFunctions.includes('delete') || 
                    <Button
                    color="danger"
                    outline
                    className={editContent ? "btn d-none" : ""}
                    onClick={() => handleDelete(formData.id, formData.name)}
                  >
                    Hapus
                  </Button>
                  }
                </div>
              </Col>
            </Row>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
export default MasterRole;
