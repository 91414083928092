import axios from "axios";
// const baseURL = "https://database.astronacci.com/";
const baseURL = process.env.REACT_APP_BASE_URL_ASTRO;
const headers = {
    'Content-Type': 'application/json',
    'Origin': 'http://localhost:3000'
};


export default axios.create({
    baseURL: baseURL,
    headers: headers
});

export const axiosCoreAstro = axios.create({
    baseURL: baseURL,
    headers: headers
});