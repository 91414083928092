import { Icon } from "../Component";
import { components } from "react-select";
import axios from "axios";
import Swal from "sweetalert2";
import { useCookies } from "react-cookie";

const OptionWithButton = ({ selectProps: { btnDelete, table }, ...props }) => {
  const [cookies, setCookie] = useCookies(["user"]);

  const deleteOption = () => {
    Swal.fire({
      title: "Are you sure for delete " + props.data.label + " ?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.interceptors.request.use((config) => {
          config.headers["Authorization"] = `Bearer ${cookies.SIDikey}`;
          config.headers["Content-Type"] = "application/json";
          config.headers["AccessControl-Allow-Origin"] = "http://localhost:3000";
          return config;
        });
        axios
          .delete(process.env.REACT_APP_BASE_URL + "api/"+ table +"/" + props.data.value)
          .then((response) => {
            Swal.fire({
              icon: "success",
              title: "Succesfull Transasction",
              focusConfirm: false,
            }).then(() => {
              btnDelete();
            });
          })
          .catch((error) => {
            console.log(error);
            let message="";
            if(error.response.status == 409){
              message= "Category is currently in use"
            }
            Swal.fire({
              icon: "error",
              title: "Unsuccesfull Transasction",
              text: message,
              focusConfirm: false,
            });
          });
      }
    });
  };

  return (
    <div className="d-flex align-items-center">
      <components.Option {...props}></components.Option>
      {
        props.children === "Status Prospect" || props.children === "Lead Source"
        ? ""
        : <Icon name="trash-alt" className="icon-option" onClick={deleteOption} />
      }
    </div>
  );
};

export default OptionWithButton;
