export const notificationData = [
  {
    id: 1,
    function: '[Master Data] Chat',
    api: 'api/exports?exportType=CHATS'
  },
  {
    id: 2,
    function: '[Master Data] Contact',
    api: '/api/exports?exportType=CONTACTS'
  },
  {
    id: 3,
    function: '[Data Management] Ticket',
    api: 'api/exports?exportType=TICKETS'
  },
  {
    id: 4,
    function: '[Settings] Log Management',
    api: 'api/exports?exportType=LOGS'
  },
]