export const columnData = {
  task: {
    "1": {
      id: "1",
      title: "[Dummy Customer 1]",
      desc: "Start implementing new design in coding @dashlite",
      meta: {
        users: [{ value: "Sara Dervashi", label: "Sara Dervashi", theme: "light" }],
        tags: [
          { value: "Dashlite", label: "Dashlite", theme: "info" },
          // { value: "HTML", label: "HTML", theme: "danger" },
        ],
        date: "15 Dec 2020",
        category: "Frontend",
        comment: "2",
        address: '[Dummy Address 1]',
        city: '[Dummy City 1]',
        startDate: '[Dummy Start Date 1]',
        endDate: '[Dummy End Date 1]',
        email: '[Dummy Email 1]',
        phone: '[Dummy Phone 1]',
        platform: '[Dummy Platform 1]',
      },
    },
    "2": {
      id: "2",
      title: "[Dummy Customer 2]",
      desc: "Implement new UI design in react version @dashlite template as soon as possible.",
      meta: {
        users: [{ value: "Cooper Jones", label: "Cooper Jones", theme: "blue" }],
        tags: [
          { value: "Dashlite", label: "Dashlite", theme: "info" },
          // { value: "React", label: "React", theme: "dark" },
        ],
        date: "15 Dec 2020",
        category: "Frontend",
        comment: "5",
        attachment: "3",
        address: '[Dummy Address 2]',
        city: '[Dummy City 2]',
        startDate: '[Dummy Start Date 2]',
        endDate: '[Dummy End Date 2]',
        email: '[Dummy Email 2]',
        phone: '[Dummy Phone 2]',
        platform: '[Dummy Platform 2]',
      },
    },
    "3": {
      id: "3",
      title: "[Dummy Customer 3]",
      desc: "Update the new UI design for @dashlite template with based on feedback.",
      board: "In Progress",
      meta: {
        users: [{ value: "Ashraf Raneem", label: "Ashraf Raneem", theme: "primary" }],
        tags: [
          { value: "Dashlite", label: "Dashlite", theme: "info" },
          // { value: "UI Design", label: "UI Design", theme: "warning" },
        ],
        due: "2",
        category: "Design",
        comment: "4",
        attachment: "1",
        address: '[Dummy Address 3]',
        city: '[Dummy City 3]',
        startDate: '[Dummy Start Date 3]',
        endDate: '[Dummy End Date 3]',
        email: '[Dummy Email 3]',
        phone: '[Dummy Phone 3]',
        platform: '[Dummy Platform 3]',
      },
    },
    "4": {
      id: "4",
      title: "[Dummy Customer 4]",
      desc: "Keyword recarch for @techyspec business profile and there other websites, to improve ranking.",
      board: "In Progress",
      meta: {
        users: [{ value: "Vernon Hollander", label: "Vernon Hollander", theme: "danger" }],
        tags: [
          { value: "Techyspec", label: "Techyspec", theme: "dark" },
          // { value: "SEO", label: "SEO", theme: "success" },
        ],
        date: "02 Jan 2021",
        category: "Research",
        comment: "21",
        attachment: "31",
        address: '[Dummy Address 4]',
        city: '[Dummy City 4]',
        startDate: '[Dummy Start Date 4]',
        endDate: '[Dummy End Date 4]',
        email: '[Dummy Email 4]',
        phone: '[Dummy Phone 4]',
        platform: '[Dummy Platform 4]',
      },
    },
    "5": {
      id: "5",
      title: "[Dummy Customer 5]",
      desc: "Design a awesome website for @fitness_next new product launch.",
      board: "In Progress",
      meta: {
        users: [{ value: "Patrick Newman", label: "Patrick Newman", theme: "pink" }],
        tags: [
          { value: "Fitness Next", label: "Fitness Next", theme: "primary" },
          // { value: "UI Design", label: "UI Design", theme: "warning" },
        ],
        due: "8",
        category: "Design",
        comment: "5",
        attachment: "1",
        address: '[Dummy Address 5]',
        city: '[Dummy City 5]',
        startDate: '[Dummy Start Date 5]',
        endDate: '[Dummy End Date 5]',
        email: '[Dummy Email 5]',
        phone: '[Dummy Phone 5]',
        platform: '[Dummy Platform 5]',
      },
    },
    "6": {
      id: "6",
      title: "[Dummy Customer 6]",
      desc: "Redesign there old/backdated website new modern and clean look keeping minilisim in mind.",
      board: "In Progress",
      meta: {
        users: [
          { value: "Jose Fayman", label: "Jose Fayman", theme: "purple" },
          { value: "Indever Clay", label: "Indever City", theme: "success" },
        ],
        tags: [
          { value: "Redesign", label: "Redesign", theme: "light" },
          // { value: "UI Design", label: "UI Design", theme: "warning" },
        ],
        date: "10 Jan 2022",
        category: "Design",
        comment: "15",
        attachment: "19",
        address: '[Dummy Address 6]',
        city: '[Dummy City 6]',
        startDate: '[Dummy Start Date 6]',
        endDate: '[Dummy End Date 6]',
        email: '[Dummy Email 6]',
        phone: '[Dummy Phone 6]',
        platform: '[Dummy Platform 6]',
      },
    },
    "7": {
      id: "7",
      title: "[Dummy Customer 7]",
      desc: "Wordlab Android App with with react native.",
      board: "In Progress",
      meta: {
        users: [{ value: "Jose Fayman", label: "Jose Fayman", theme: "purple" }],
        tags: [
          { value: "Wordlab", label: "Wordlab", theme: "success" },
          // { value: "Android", label: "Android", theme: "light" },
        ],
        date: "25 Dec 2022",
        category: "Design",
        comment: "50",
        attachment: "11",
        address: '[Dummy Address 7]',
        city: '[Dummy City 7]',
        startDate: '[Dummy Start Date72]',
        endDate: '[Dummy End Date 7]',
        email: '[Dummy Email 7]',
        phone: '[Dummy Phone 7]',
        platform: '[Dummy Platform 7]',
      },
    },
    "8": {
      id: "8",
      title: "[Dummy Customer 8]",
      desc: "Complete website development for Oberlo limited.",
      board: "To Review",
      meta: {
        users: [
          { value: "Ober Mayers", label: "Ober Mayers", theme: "purple" },
          { value: "Sergei Surnama", label: "Sergei Surnama", theme: "success" },
        ],
        tags: [
          { value: "Oberlo", label: "Oberlo", theme: "info" },
          // { value: "Development", label: "Development", theme: "danger" },
        ],
        due: "1",
        category: "Backend",
        comment: "9",
        attachment: "1",
        address: '[Dummy Address 8]',
        city: '[Dummy City 8]',
        startDate: '[Dummy Start Date 8]',
        endDate: '[Dummy End Date 8]',
        email: '[Dummy Email 8]',
        phone: '[Dummy Phone 8]',
        platform: '[Dummy Platform 8]',
      },
    },
    "9": {
      id: "9",
      title: "[Dummy Customer 9]",
      desc: "Design and develop app for Getsocio IOS.",
      board: "To Review",
      meta: {
        users: [
          { value: "Jermaine Klaus", label: "Jermaine Klaus", theme: "purple" },
          { value: "Sergei Surnama", label: "Sergei Surnama", theme: "success" },
        ],
        tags: [
          { value: "Getsocio", label: "Getsocio", theme: "dark" },
          // { value: "IOS", label: "IOS", theme: "light" },
        ],
        due: "4",
        category: "Frontend",
        comment: "8",
        attachment: "5",
        address: '[Dummy Address 9]',
        city: '[Dummy City 9]',
        startDate: '[Dummy Start Date 9]',
        endDate: '[Dummy End Date 9]',
        email: '[Dummy Email 9]',
        phone: '[Dummy Phone 9]',
        platform: '[Dummy Platform 9]',
      },
    },
  },
  columns: {
    "1": {
      id: "column-lead-1",
      text: "[Lead 1]",
      theme: "light",
      tasks: ["1", "2"],
    },
    "2": {
      id: "column-lead-2",
      text: "[Lead 2]",
      theme: "primary",
      tasks: ["3", "4", "5", "6", "7"],
    },
    "3": {
      id: "column-lead-3",
      text: "[Lead 3]",
      theme: "warning",
      tasks: ["8", "9"],
    },
    "4": {
      id: "column-lead-4",
      text: "[Lead 4]",
      theme: "success",
      tasks: [],
    },
  },
  columnOrder: ["1", "2", "3", "4"],
};

export const tagSet = [
  {
    value: "Dashlite",
    label: "Dashlite",
    theme: "info",
  },
  {
    value: "HTML",
    label: "HTML",
    theme: "danger",
  },
  {
    value: "UI Design",
    label: "UI Design",
    theme: "warning",
  },
  {
    value: "React",
    label: "React",
    theme: "dark",
  },
  {
    value: "Techyspec",
    label: "Techyspec",
    theme: "dark",
  },
  {
    value: "Development",
    label: "Development",
    theme: "danger",
  },
  {
    value: "SEO",
    label: "SEO",
    theme: "success",
  },
  {
    value: "IOS",
    label: "IOS",
    theme: "grey",
  },
];

export const teamList = [
  { value: "Abu Bin", label: "Abu Bin", theme: "purple" },
  { value: "Newman John", label: "Newman John", theme: "primary" },
  { value: "Milagros Betts", label: "Milagros Betts", theme: "purple" },
  { value: "Joshua Wilson", label: "Joshua Wilson", theme: "pink" },
  { value: "Ryu Duke", label: "Ryu Duke", theme: "orange" },
  { value: "Aliah Pitts", label: "Aliah Pitts", theme: "blue" },
];

export const themes = [
  { value: "primary", label: "Primary" },
  { value: "secondary", label: "Secondary" },
  { value: "info", label: "Info" },
  { value: "danger", label: "Danger" },
  { value: "warning", label: "Warning" },
  { value: "success", label: "Success" },
  { value: "dark", label: "Dark" },
  { value: "light", label: "Light" },
];
