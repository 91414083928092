import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { axiosPrivate } from "../api/axiosCore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_apiKey || "AIzaSyCBDijB6jctbEb3VWjM8B5DSKvJLWZbkgQ",
  authDomain: process.env.REACT_APP_FIREBASE_authDomain || "crm-astronacci.firebaseapp.com",
  projectId: process.env.REACT_APP_FIREBASE_projectId || "crm-astronacci",
  storageBucket: process.env.REACT_APP_FIREBASE_storageBucket || "crm-astronacci.appspot.com",
  messagingSenderId: process.env.REACT_APP_FIREBASE_messagingSenderId || "1089731983306",
  appId: process.env.REACT_APP_FIREBASE_appId || "1:1089731983306:web:691ba664fe0621d4bec133",
  measurementId: process.env.REACT_APP_FIREBASE_measurementId || "G-1V1J9E5SCR",
};

initializeApp(firebaseConfig);
const messaging = getMessaging();

export function requestPermission(setFoundToken) {
  console.log("Requesting permission", process.env.REACT_APP_FIREBASE_apiKey);
  Notification.requestPermission().then(async (permssion) => {
    if (permssion === "granted") {
      console.log("Notification permission granted");
      const firebaseApp = initializeApp(firebaseConfig);
      const messaging = getMessaging(firebaseApp);
      const recToken = getToken(messaging, {
        vapidKey: process.env.REACT_APP_VAPID_KEY,
      }).then((currentToken) => {
        if (currentToken) {
          setFoundToken(currentToken);
        } else {
          console.log("Can't get token");
        }
      });
      return await recToken;
    } else {
      console.log("Permission not granted");
    }
  });
}

export const requestForToken = async (setTokenFound) => {
  try {
    const currentToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY });
    if (currentToken) {
      setTokenFound(currentToken)
    } else {
      // Show permission request UI
      console.log('No registration token available. Request permission to generate one.');
    }
  } catch (err) {
    console.log('An error occurred while retrieving token. ', err);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload)
      resolve(payload);
    });
  });


// export const onForegroundMessage = () => new Promise((resolve) => onMessage(messaging, (payload) => resolve(payload)));
export const subscribeFirebaseToken = async (token, firebaseToken) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    console.log(process.env.REACT_APP_FIREBASE_appId)

    const response = await axiosPrivate.post("api/notifications/subscribe", { token: firebaseToken });
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const unsubscribeFirebaseToken = async (token, firebaseToken) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.post("api/notifications/unsubscribe", { token: firebaseToken });
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};
