import React, { useEffect, useState } from "react";
import Head from "layout/head/Head";
import Content from "layout/content/Content";
import { DropdownToggle, DropdownMenu, UncontrolledDropdown, DropdownItem } from "reactstrap";
import { useCookies } from "react-cookie";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  BlockBetween,
} from "components/Component";
import TicketSystem from "./TicketSystem";
import TrafficSources from "./TrafficSources";
import LeadConvertionRate from "./LeadConvertionRate";
import TotalNewLead from "./TotalNewLead";
import TotalNewProspect from "./TotalNewProspect";
import TotalClosing from "./TotalClosing";
import RatingSalesAll from "./RatingSalesAll";
import CampaignOverview from "./CampaignOverview";
import {
  getDepartmentList
} from "../../services/dashboard";

const SuperAdminDashboard = () => {
  const [cookies] = useCookies(["user"]);
  const roleFunctions = JSON.parse(localStorage.getItem("permission"))["dashboard"].access || [];
  const [departmentList, setDepartmentList] = useState([
    { name: "All Department", value: roleFunctions.includes("Access_All_Dept") ? null : cookies.departmentIds},
  ]);
  const [sm, updateSm] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(departmentList[0]);

  console.log('departmentList', !roleFunctions.includes("Access_All_Dept"),departmentList, roleFunctions)
  
  const getDataDepartmentList = async () => {
    const responseDepartmentList = await getDepartmentList(cookies.SIDikey, roleFunctions, cookies.departmentIds);
    const arrDepartmentList = [{ name: "All Department", value: roleFunctions.includes("Access_All_Dept") ? null : cookies.departmentIds.toString() }]
    responseDepartmentList.data.data.map(item => 
      arrDepartmentList.push({
        name: item.name,
        value: item.id
      })
    )
    setDepartmentList(arrDepartmentList)
  };

  useEffect(() => {
    getDataDepartmentList()
  }, []);

  return (
    <React.Fragment>
      <Head title="Home"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                Dashboard
              </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v" />
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          onClick={(ev) => ev.preventDefault()}
                          tag="a"
                          className="dropdown-toggle btn btn-white btn-dim btn-outline-light"
                        >
                          <span>
                            <span className="d-none d-md-inline">{selectedDepartment.name}</span>
                          </span>
                          <Icon className="dd-indc" name="chevron-down" />
                        </DropdownToggle>
                        <DropdownMenu right>
                          <ul className="link-list-opt no-bdr">
                            {
                              departmentList.map((item, i) => 
                                <li key={i}>
                                  <DropdownItem
                                    tag="a"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setSelectedDepartment(item);
                                    }}
                                    href="#!"
                                  >
                                    <span>{item.name}</span>
                                  </DropdownItem>
                                </li>
                              )
                            }
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                    
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Row className="g-gs">
            <Col sm={6} md lg={6} xl xxl={6}>
              <TotalNewLead selectedDepartment={selectedDepartment} />
            </Col>
            <Col sm={6} md lg={6} xl xxl={6}>
              <TotalNewProspect selectedDepartment={selectedDepartment} />
            </Col>
            <Col sm={6} md lg={6} xl xxl={6}>
              <TotalClosing selectedDepartment={selectedDepartment} />
            </Col>
            <Col sm={6} md lg={6} xl xxl={6}>
              <RatingSalesAll selectedDepartment={selectedDepartment} />
            </Col>
            <Col xxl="12">
              <TicketSystem selectedDepartment={selectedDepartment} />
            </Col>
            {/* <Col xxl="12">
              <h2 className="text-center">Total Sales per-PC vs Target</h2>
            </Col>
            <Col className="d-flex justify-content-between">
              {tagList.map((item, index) => (
                <React.Fragment key={index}>
                  <Row className="mx-2">
                    <TagChart
                      title={item.text}
                      value={item.chartConfiguration.datasets[0].data[0].toLocaleString().replaceAll(",", ".")}
                      total={item.chartConfiguration.total.toLocaleString().replaceAll(",", ".")}
                      chartConfiguration={item.chartConfiguration}
                    />
                  </Row>
                </React.Fragment>
              ))}
            </Col> */}
            <Col sm={6} md lg={6} xl xxl={6}>
              <LeadConvertionRate selectedDepartment={selectedDepartment} />
            </Col>
            <Col sm={6} md lg={6} xl xxl={6}>
              <TrafficSources selectedDepartment={selectedDepartment} />
            </Col>
            <Col sm={12} md lg={12} xl xxl={12}>
              <CampaignOverview selectedDepartment={selectedDepartment} />
            </Col>
            {/* <Col sm={6} md lg={6} xl xxl={6}>
              <ChatPerDay />
            </Col>
            <Col sm={6} md lg={6} xl xxl={6}>
              <ChatPerCampaign />
            </Col> */}
          </Row>
        </Block>
      </Content>
    </React.Fragment>
  );
};
export default SuperAdminDashboard;
