import { axiosPrivate } from "api/axiosCore";

const limit = 15;
const basic_url = 'api/pages/'
const ALL_DEPT = "Access_All_Dept";
const OWN_DEPT = "Access_Own_Dept";
const OWN = "Access_Own";

export const serviceGetSodPages = async (token, query, roleFunctions,  page, limit=15) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    roleFunctions = roleFunctions || [];
    var response = null;
    const queryData = query ? "&q="+query : "";
    response = await axiosPrivate.get(basic_url+`?order=DESC&take=${limit}&page=${page||1}&f=page.name%2Cpage.updatedAt`+queryData);
    console.log(response);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const servicePostSodPages = async (token, data) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.post(basic_url, data);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const servicePutSodPages = async (id, token, data) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.put(basic_url + id, data);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceDeleteSodPages = async (id, token) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const response = await axiosPrivate.delete(basic_url + id);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};
