import React, { useState, useEffect, useCallback } from "react";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Col,
  Row,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  Button,
  RSelect,
  TooltipComponent,
} from "components/Component";
import Content from "layout/content/Content";
import Head from "layout/head/Head";

import {
  FormGroup,
  Modal,
  ModalBody,
  Form,
  Card,
  CardBody,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { useCookies } from "react-cookie";
import Swal from "sweetalert2";
import { serviceGetSodPages } from "services/sod-pages";
import {
  serviceDeleteSodPermission,
  serviceGetSodAllPermission,
  servicePostSodPermission,
  servicePutSodPermission,
} from "services/sod-permissions";
import { serviceGetSodRole } from "services/sod-role";
import { serviceGetSodLevel } from "services/sod-level";
import moment from "moment";

const RolexFunctionxLevel = () => {
  const [data, setData] = useState([]);
  const [cookies] = useCookies(["user"]);
  const [onSearchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(15);
  const [metaData, setMetaData] = useState({});
  const [editContent, setEditContent] = useState(false);
  const [confirmState, setConfirmState] = useState(false);
  const [optionRole, setOptionRole] = useState([]);
  const [optionPage, setOptionPage] = useState([]);
  const [optionLevel, setOptionLevel] = useState([]);
  const [valueRole, setValueRole] = useState({});
  const [valuePage, setValuePage] = useState({});
  const [valueLevel, setValueLevel] = useState({});
  const roleFunctions = JSON.parse(localStorage.getItem("permission"))["permission"].access || [];
  const [searchParam, setSearchParam] = useState({ roleId: "", pageId: "", accessLevelId: "" });
  const roleIdInputRef = React.useRef();
  const pageIdInputRef = React.useRef();
  const accessLevelIdInputRef = React.useRef();
  const [modal, setModal] = useState({
    detail: false,
    add: false,
  });

  const [formData, setFormData] = useState({
    id: "",
    role: {},
    page: {},
    level: {},
    levelDescription: "",
    createdAt: "",
    createdBy: "",
    modifiedAt: "",
    modifiedBy: "",
  });

  // Changing state value when searching name
  useEffect(() => {
    if (onSearchText !== "") {
    } else {
      // setData([]);
    }
  }, [onSearchText, setData]);

  const handleDelete = (id) => {
    if(!roleFunctions.includes('delete')){return}
    Swal.fire({
      title: "Are you sure for delete " + id + " ?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result)=> {
      if(result.isConfirmed){
        deleteProcess(id);
      }
    });
  };

  const handleChange = useCallback((e, name) => {
    let inputVal = e
    if(name === "level") {
      const level = e.label.split(" ")
      inputVal = {label:level[0],value:e.value}
    }
    setFormData((prevState) => ({
      ...prevState,
      [name]: inputVal
    }));
  }, []);

  const deleteProcess = async (id) => {
    const resp = await serviceDeleteSodPermission(id, cookies.SIDikey);
    if(/20/.test(resp.status)){
      Swal.fire('Delete Permission','Berhasil Menghapus', "success");
      refreshData(1);
    }else{
      Swal.fire('Delete Permission','Gagal Menghapus', "error");
    }
  };

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  const [sm, updateSm] = useState(false);
  // Change Page
  const paginate = (pageNumber) => refreshData(pageNumber);

  // unselects the data on mount
  useEffect(() => {
    refreshData(1);
    refreshOptions();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const resetForm = () => {
    setFormData({
      id: "",
      role: {},
      page: {},
      level: {},
      levelDescription: "",
      createdAt: "",
      createdBy: "",
      modifiedAt: "",
      modifiedBy: "",
    });

    setValueRole({});
    setValuePage({});
    setValueLevel({});
  };
  const { errors, register, handleSubmit, getValues, watch } = useForm();

  const onDetailClick = (id) => {
    data.forEach((item) => {
      if (item.id === id) {
        setFormData({
          id: id,
          role: { label: item?.role?.name, value: item?.role?.id },
          page: { label: item?.page?.key, value: item?.page?.id },
          level: { label: item?.accessLevel?.level, value: item?.accessLevel?.id },
          levelDescription: item?.accessLevel?.description,
          createdAt: item.createdAt,
          createdBy: item.createdBy || 'System',
          modifiedAt: item.updatedAt || '-',
          modifiedBy: item.updatedBy === null ? 'System' : item.updatedBy?.firstName + " " + item.updatedBy?.lastName,
        });
        setValueRole({ label: item?.role?.name, value: item?.role?.id });
        setValuePage({ label: item?.page?.key, value: item?.page?.id });
        setValueLevel({ label: item?.accessLevel?.level, value: item?.accessLevel?.id });
        setModal({ detail: true, add: false });
      }
    });
  };
  const onEditClick = (id) => {
    onDetailClick(id);
    setEditContent(true);
  };

  const onFormCancel = () => {
    setModal({ add: false, detail: false });
    setConfirmState(false);

    setEditContent(false);
    resetForm();
  };

  const refreshData = async (page) => {
    if (!roleFunctions.includes("read")) {
      return;
    }
    const response = await serviceGetSodAllPermission(cookies.SIDikey, null, [], searchParam, page);
    if (response.status === 200) {
      const newData = [];
      response.data.data.forEach((da) => {
        da.createdBy = da.createdBy ? da.createdBy.firstName + " " + da.createdBy.lastName : null;
        da.modifiedBy = da.modifiedBy ? da.modifiedBy.firstName + " " + da.modifiedBy.lastName : null;
        newData.push(da);
      });
      setData(newData);
      const meta = response.data.meta;
      setMetaData(meta);
    }
  };

  const refreshOptions = async () => {
    if (!roleFunctions.includes("read")) {
      return;
    }
    const responseRole = await serviceGetSodRole(cookies.SIDikey, null, [], 1, 50);
    const responsePage = await serviceGetSodPages(cookies.SIDikey, null, [], 1, 50);
    const responseLevel = await serviceGetSodLevel(cookies.SIDikey, null, [], 1,50);

    if (responseRole.status === 200 && responsePage.status === 200 && responseLevel.status === 200) {
      setOptionRole(responseRole.data.data.map((item) => ({ label: item.name, value: item.id })));
      setOptionPage(responsePage.data.data.map((item) => ({ label: item.key, value: item.id })));
      setOptionLevel(
        responseLevel.data.data.map((item) => {
          // Map each item from responseLevel.data.data to set its label
          // Include pageActions names here
          const pageActionNames = item.pageActions.map(action => action.name).join(', ');
          return {
            label: `${item.level} [${item.description}] - ${pageActionNames}`,
            value: item.id,
          };
        })
      );
    } 
  };

  const onEditSubmit = async () => {
    if (!roleFunctions.includes("update")) {
      Swal.fire("Edit Permission", "Unauthorized", "error");
      return;
    }
    
    const newData = {
      "roleId": formData.role.value,
      "pageId": formData.page.value,
      "accessLevelId": formData.level.value
    }

    const response = await servicePutSodPermission(formData.id, cookies.SIDikey, newData);
    if (response.status === 202) {
      Swal.fire("Edit RxFxL", "Berhasil", "success");
      refreshData(1);
    } else if (response.status === 400) {
      if (response.data.message === "error.permissionAlreadyExists") {
        Swal.fire("Gagal Edit RxFxL", "Role, Page, dan Level Duplicate", "error");
      }
    } else {
      Swal.fire("Edit RxFxL", "Gagal", "error");
    }
  };

  const onAddSubmit = async () => {
    if (!roleFunctions.includes("create")) {
      Swal.fire("Add Permission", "Unauthorized", "error");
      return;
    }
    const addData = {
      roleId: valueRole.value,
      pageId: valuePage.value,
      accessLevelId: valueLevel.value,
    };
    const response = await servicePostSodPermission(cookies.SIDikey, addData);
    if (response.status === 201) {
      Swal.fire("Add RxFxL", "Berhasil", "success");
      refreshData(1);
    } else if (response.status === 400) {
      if (response.data.message === "error.permissionAlreadyExists") {
        Swal.fire("Gagal Add RxFxL", "Role, Page, dan Level Duplicate", "error");
      }
    } else {
      Swal.fire("Add RxFxL", "Gagal", "error");
    }
  };

  return (
    <React.Fragment>
      <Head title="RxFxL"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                RxFxL
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
          <Card className="card-bordered my-4 card-bluesky">
            <CardBody className="card-bluesky-body">
              <strong>Info:</strong>
              <p>This page is used to control the permissions of role</p>
            </CardBody>
          </Card>
          <Block className="d-flex justify-content-end">
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt">
                      <Button color="primary" className="btn" onClick={() => setModal({ add: true })}>
                        <i className="ni ni-plus"></i>
                        <span>Tambah Baru</span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </Block>
        </BlockHead>
        <Block>
          <DataTable className="card-stretch">
            <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group">
                <div className="role_search_input_container">
                  <div className="role_search_input">
                    <RSelect
                      ref={roleIdInputRef}
                      placeholder="Select Role"
                      options={optionRole}
                      onChange={(e) => {
                        const currents = searchParam;
                        currents.roleId = e.value;
                        setSearchParam(currents);
                        refreshData(1);
                      }}
                    />
                  </div>
                  <div className="role_search_input">
                    <RSelect
                      ref={pageIdInputRef}
                      placeholder="Select Page"
                      options={optionPage}
                      onChange={(e) => {
                        const currents = searchParam;
                        currents.pageId = e.value;
                        setSearchParam(currents);
                        refreshData(1);
                      }}
                    />
                  </div>
                  <div className="role_search_input">
                    <RSelect
                      ref={accessLevelIdInputRef}
                      placeholder="Select Level"
                      options={optionLevel}
                      onChange={(e) => {
                        const currents = searchParam;
                        currents.accessLevelId = e.value;
                        setSearchParam(currents);
                        refreshData(1);
                      }}
                    />
                  </div>
                  <Button
                    disabled={
                      searchParam.accessLevelId === "" && searchParam.pageId === "" && searchParam.roleId === ""
                    }
                    color="danger"
                    className="btn"
                    onClick={() => {
                      roleIdInputRef?.current?.select.setValue({ value: "", label: "Select Role" });
                      pageIdInputRef?.current?.select.setValue({ value: "", label: "Select Page" });
                      accessLevelIdInputRef?.current?.select.setValue({ value: "", label: "Select level" });
                      const currents = searchParam;
                      currents.accessLevelId = "";
                      currents.pageId = "";
                      currents.roleId = "";
                      setSearchParam(currents);
                      refreshData(1);
                    }}
                  >
                    <em className="icon ni ni-reload"></em>
                    <span>Reset</span>
                  </Button>
                  {/* <input type="text" className="form-control w-100" id="default-04" placeholder="Search" /> */}
                </div>
              </div>
            </div>
            <DataTableBody>
              <DataTableHead>
                <DataTableRow className="nk-tb-col-check">
                  <span className="sub-text">No</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Role</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Page</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Level</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Level Description</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text"></span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Created At</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Created By</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Modified At</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Modified By</span>
                </DataTableRow>
              </DataTableHead>
              {/*Head*/}
              {currentItems.length > 0
                ? currentItems.map((item, idx) => {
                    return (
                      <DataTableItem key={item.id}>
                        <DataTableRow size="mb">
                          <span className="tb-amount">{(metaData.page - 1) * metaData.take + (idx + 1)}</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="tb-amount">{item?.role?.name}</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="tb-amount">{item?.page?.key}</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="tb-amount">{item?.accessLevel?.level}</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="tb-amount">{item?.accessLevel?.description}</span>
                        </DataTableRow>
                        <DataTableRow noWrap>
                          <ul className="nk-tb-actions table-action">
                            <li className="nk-tb-action" onClick={() => onDetailClick(item.id)}>
                              <TooltipComponent
                                tag="a"
                                containerClassName="btn btn-trigger btn-icon"
                                id={"detail" + item.id}
                                icon="eye-fill"
                                direction="top"
                                text="Detail"
                              />
                            </li>
                            <li className="nk-tb-action" onClick={() => onEditClick(item.id)}>
                              <TooltipComponent
                                tag="a"
                                containerClassName="btn btn-trigger btn-icon"
                                id={"edit" + item.id}
                                icon="edit-alt-fill"
                                direction="top"
                                text="Edit"
                              />
                            </li>

                            <li className="nk-tb-action" onClick={() => handleDelete(item.id)}>
                              <TooltipComponent
                                tag="a"
                                containerClassName="btn btn-trigger btn-icon"
                                id={"suspend" + item.id}
                                icon="trash-fill"
                                direction="top"
                                text="Hapus"
                              />
                            </li>
                          </ul>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="tb-amount">{moment(item.createdAt).format("MMM DD, YYYY, HH:mm")}</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="tb-amount">{item.createdBy || "System"}</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="tb-amount">{moment(item.updatedAt).format("MMM DD, YYYY, HH:mm")}</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="tb-amount">{item.updatedBy === null ? 'System' : item.updatedBy?.firstName + " " + item.updatedBy?.lastName}</span>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                : null}
            </DataTableBody>
            <div className="card-inner">
              {currentItems.length > 0 ? (
                <PaginationComponent
                  itemPerPage={metaData?.take}
                  totalItems={metaData?.itemCount}
                  paginate={paginate}
                  currentPage={metaData?.page}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )}
            </div>
          </DataTable>
        </Block>
      </Content>
      <Modal
        isOpen={modal.add}
        backdrop="static"
        keyboard={false}
        toggle={() => setModal({ detail: false })}
        className="modal-dialog-centered"
        size="lg"
      >
        <ModalBody>
          <a
            href="#cancel"
            onClick={(ev) => {
              ev.preventDefault();
              onFormCancel();
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">Tambah RxFxL</h5>
            <Block className="d-flex justify-content-end"></Block>
            <div className="mt-4">
              <Form className="row gy-4" onSubmit={handleSubmit(onAddSubmit)}>
                <Col md="12">
                  <FormGroup>
                    <label className="form-label">Role</label>
                    <RSelect
                      value={valueRole}
                      placeholder="Role Name"
                      options={optionRole}
                      onChange={(e) => setValueRole(e)}
                    />
                    {errors.key && <span className="custom-invalid">{errors.key.message}</span>}
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <label className="form-label">Page</label>
                    <RSelect
                      value={valuePage}
                      placeholder="Page Name"
                      options={optionPage}
                      onChange={(e) => setValuePage(e)}
                    />
                    {errors.name && <span className="custom-invalid">{errors.name.message}</span>}
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <label className="form-label">Level</label>
                    <RSelect
                      placeholder="Level Name"
                      value={valueLevel}
                      options={optionLevel}
                      onChange={(e) => setValueLevel(e)}
                    />
                    {errors.description && <span className="custom-invalid">{errors.description.message}</span>}
                  </FormGroup>
                </Col>

                <Col size="12">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button
                        outline
                        color="primary"
                        onClick={(ev) => {
                          ev.preventDefault();
                          onFormCancel();
                        }}
                      >
                        Batal
                      </Button>
                    </li>
                    <li>
                      <Button color="primary" size="md" type="submit">
                        Simpan
                      </Button>
                    </li>
                  </ul>
                </Col>
              </Form>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modal.detail}
        backdrop="static"
        keyboard={false}
        toggle={() => setModal({ detail: false })}
        className="modal-dialog-centered"
        size="lg"
      >
        <ModalBody>
          <a
            href="#cancel"
            onClick={(ev) => {
              ev.preventDefault();
              onFormCancel();
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">Detail RxFxL</h5>
            <Block className="d-flex justify-content-end"></Block>
            <div className="mt-4">
              <Form className="row gy-4" onSubmit={handleSubmit(onEditSubmit)}>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label">Role</label>
                    <RSelect
                      name="role"
                      options={optionRole}
                      placeholder="Role Name"
                      disabled={!editContent}
                      value={formData.role}
                      onChange={(e) => handleChange(e, "role")}
                      // ref={register({ required: "This field is required" })}
                    />
                    {errors.key && <span className="custom-invalid">{errors.key.message}</span>}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label">Page</label>
                    <RSelect
                      name="page"
                      options={optionPage}
                      placeholder="Page Name"
                      disabled={!editContent}
                      value={formData.page}
                      onChange={(e) => handleChange(e, "page")}
                      // ref={register({ required: "This field is required" })}
                    />
                    {errors.name && <span className="custom-invalid">{errors.name.message}</span>}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label">Level</label>
                    <RSelect
                      name="accessLevel"
                      options={optionLevel}
                      placeholder="Level Name"
                      disabled={!editContent}
                      value={formData.level}
                      onChange={(e) => handleChange(e, "level")}
                      // ref={register({ required: "This field is required" })}
                    />
                    {errors.description && <span className="custom-invalid">{errors.description.message}</span>}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label">Created By</label>
                    <input
                      className="form-control"
                      type="text"
                      name="createdBy"
                      defaultValue={formData.createdBy}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label">Created At</label>
                    <input
                      disabled
                      name="createdAt"
                      className="form-control"
                      placeholder=""
                      defaultValue={formData.createdAt}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label">Modified By</label>
                    <input
                      disabled
                      name="modifiedBy"
                      className="form-control"
                      placeholder=""
                      defaultValue={formData.modifiedBy}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label">Modified At</label>
                    <input
                      disabled
                      name="modifiedAt"
                      className="form-control"
                      placeholder=""
                      defaultValue={formData.modifiedAt}
                    />
                  </FormGroup>
                </Col>
                {/* <Col md="6" className="d-flex justify-content-end align-items-end">
                  <div className="toggle-wrap nk-block-tools-toggle">
                    <Button
                      className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                      onClick={() => updateSm(!sm)}
                    >
                      <Icon name="menu-alt-r"></Icon>
                    </Button>
                    <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                      <ul className="nk-block-tools g-3">
                        <li className="nk-block-tools-opt">
                          <Button
                            color="primary"
                            className={editContent ? "btn d-none" : ""}
                            onClick={() => setEditContent(true)}
                          >
                            Ubah
                          </Button>
                        </li>

                        <li className="nk-block-tools-opt">
                          <Button color="danger" outline className="btn" onClick={() => handleDelete(formData.id)}>
                            Hapus
                          </Button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col> */}
                <Col size="12" className={editContent ? "" : "d-none"}>
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button
                        outline
                        color="primary"
                        onClick={(ev) => {
                          ev.preventDefault();
                          onFormCancel();
                        }}
                      >
                        Batal
                      </Button>
                    </li>
                    <li>
                      <Button color="primary" size="md" type="submit" disabled={!editContent}>
                        Simpan
                      </Button>
                    </li>
                  </ul>
                </Col>
              </Form>
              <Row className={"row gy-4"}>
              <Col md="2">
                <div className="toggle-wrap nk-block-tools-toggle">
                  {!roleFunctions.includes('update') || 
                    <Button
                    className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                    onClick={() => updateSm(!sm)}
                    >
                      <Icon name="menu-alt-r"></Icon>
                    </Button>
                  }
                  <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                    <ul className="nk-block-tools g-3">
                      <li className="nk-block-tools-opt">
                        <Button
                          color="primary"
                          className={editContent ? "btn d-none" : ""}
                          onClick={() => setEditContent(true)}
                        >
                          Ubah
                        </Button>
                      </li> 
                    </ul>
                  </div>
                </div>
              </Col>
              <Col md="2">
                <div className="toggle-wrap nk-block-tools-toggle">
                  {!roleFunctions.includes('delete') || 
                    <Button
                    color="danger"
                    outline
                    className={editContent ? "btn d-none" : ""}
                    onClick={() => handleDelete(formData.id)}
                  >
                    Hapus
                  </Button>
                  }
                </div>
              </Col>
            </Row>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
export default RolexFunctionxLevel;
