import React, { useState, useEffect } from "react";
import { ChartDoughnut } from "./ChartDoughnut";
// import { trafficSourceList } from './homeData'
import { Card, FormGroup } from "reactstrap";
import { Icon } from "components/Component";
import { useCookies } from "react-cookie";
import DatePicker from "react-datepicker";
import moment from 'moment';
import {
  getDashboardTopTraffic
} from "../../services/dashboard";

const TrafficSources = (props) => {
  const { selectedDepartment } = props
  const [cookies] = useCookies(["user"]);
  const [startDate, setStartDate] = useState(new Date(moment().subtract(1, 'months').format('YYYY-MM-DD')))
  const [endDate, setEndDate] = useState(new Date(moment().format('YYYY-MM-DD')))
  const [dataChart, setDataChart] = useState({
    labels: [],
    dataUnit: "Top Traffic",
    legend: true,
    datasets: [
      {
        borderColor: "#fff",
        backgroundColor: [],
        data: [],
      },
    ],
  })
  const roleFunctions = JSON.parse(localStorage.getItem("permission"))["dashboard"].access || [];

  const getDataTopTraffic = async (startDate, endDate) => {
    // const responseTopTraffic = await getDashboardTopTraffic(cookies.SIDikey, startDate, endDate);
    const responseTopTraffic = await getDashboardTopTraffic(cookies.SIDikey, selectedDepartment.value, startDate, endDate, roleFunctions);
    setDataChart({
      labels: responseTopTraffic.data.map(item => item.leadSource),
      dataUnit: "Top Traffic",
      legend: true,
      datasets: [
        {
          borderColor: "#fff",
          backgroundColor: responseTopTraffic.data.map(item => item.color),
          data: responseTopTraffic.data.map(item => item.total),
        },
      ],
    })
  };
  
  useEffect(() => {
    getDataTopTraffic(startDate, endDate)
  }, [selectedDepartment]);
  
  const handleDate = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if(start && end){
      getDataTopTraffic(start, end)
    }
  }

  // const handleStartDate = (val) => {
  //   setStartDate(val)
  //   getDataTopTraffic(val, endDate)
  // }

  // const handleEndDate = (val) => {
  //   setEndDate(val)
  //   getDataTopTraffic(startDate, val)
  // }

  return (
    <Card className="card-full overflow-hidden">
      <div className="nk-ecwg nk-ecwg7 h-100">
        <div className="card-inner flex-grow-1">
          <div className="card-title-group mb-4">
            <div className="card-title">
              <h6 className="title">Top 4 Traffic Sources</h6>
            </div>
            <div className="card-tools">
              <FormGroup className="mb-0" style={{ width: 212 }}>
                <label className="form-label">Select Date</label>
                <div className="form-control-wrap mr-2">
                  <DatePicker
                    className="form-control date-picker"
                    selectsRange
                    selected={startDate}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={handleDate}
                    dateFormat={'yyyy-MM-dd'}
                  />
                </div>
              </FormGroup>
              {/* <FormGroup className="mb-0">
                <label className="form-label">Start Date</label>
                <div className="form-control-wrap mr-2">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => handleStartDate(date)}
                    className="form-control date-picker"
                    dateFormat={'yyyy-MM-dd'}
                  />
                </div>
              </FormGroup> */}
              {/* <FormGroup className="mb-0">
                <label className="form-label">End Date</label>
                <div className="form-control-wrap mr-2">
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => handleEndDate(date)}
                    className="form-control date-picker"
                    dateFormat={'yyyy-MM-dd'}
                  />
                </div>
              </FormGroup> */}
              {/* <div className="btn btn-round btn-icon btn-light dropdown-toggle ml-2" style={{ backgroundColor: 'unset', border: 'unset' }}>
                <Icon name="download-cloud"></Icon>
              </div> */}
            </div>
          </div>
          <div className="nk-ecwg7-ck">
            <ChartDoughnut data={dataChart}/>
          </div>
          <ul className="nk-ecwg7-legends">
            {dataChart.labels.map((item, index) => (
              <li key={index}>
                <div className="title">
                  <span className="dot dot-lg sq" style={{ background: dataChart.datasets[0].backgroundColor[index] }}></span>
                  <span>{item}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Card>
  );
};
export default TrafficSources;
