import axiosCore from "../api/axiosCore";
import axiosCoreAstro from "api/axiosCoreAstro";

export const signInUser = async (email, password) => {
  try {
    const response = await axiosCore.post("api/auth/login", {
      email: email,
      password: password,
    });

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const signInAstronacci = async (email, password) => {
  try{
    const response = await axiosCoreAstro.post("api/login",
    {
      email: email,
      password: password
    });
    return response;
  }catch (error){
    if(!error.response){
      return {status: "No Server Response"};
    }else{
      return error.response;
    }
  }
}
